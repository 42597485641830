import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import PATHWAY_SLUGS from 'bundles/unified-description-page-common/constants/pathways';

// Full rollout for NAMER region only
export const showPathwaysNamer = () => {
  return GrowthDiscoveryEpicClient.get('showPathwaysNAMER');
};

export const showPathwaysUserBased = () => {
  return showPathwaysNamer() || GrowthDiscoveryEpicClient.get('showPathwaysUserBasedGlobal');
};

export const getPathwaysUserBasedVariant = () => {
  return showPathwaysNamer() || GrowthDiscoveryEpicClient.preview('showPathwaysUserBasedGlobal');
};

export const showPathwaysSessionBased = () => {
  return showPathwaysNamer() || GrowthDiscoveryEpicClient.get('showPathwaysSessionBasedGlobal');
};

export const getPathwaysSessionBasedVariant = () => {
  return showPathwaysNamer() || GrowthDiscoveryEpicClient.preview('showPathwaysSessionBasedGlobal');
};

export const isPathwaysXDP = (slug: string) => {
  return PATHWAY_SLUGS.includes(slug);
};

export const showPathwaysXDP = (slug: string) => {
  if (!slug || !PATHWAY_SLUGS.includes(slug)) {
    return false;
  }

  if (
    showPathwaysNamer() ||
    GrowthDiscoveryEpicClient.get('showPathwaysSessionBasedGlobal') ||
    GrowthDiscoveryEpicClient.preview('showPathwaysUserBasedGlobal')
  ) {
    return true;
  }

  return false;
};

export const showPathwaysCareerAcademyPages = () => {
  const userBasedPathwaysVariant = getPathwaysUserBasedVariant();

  // make impression for session-base experiment
  const sessionBasedPathwaysVariant = GrowthDiscoveryEpicClient.get('showPathwaysSessionBasedGlobal');

  if (userBasedPathwaysVariant) {
    return true;
  }

  return sessionBasedPathwaysVariant;
};
