/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import EditorContainer from 'bundles/cml/editor/components/EditorContainer';
import StickyToolbar from 'bundles/cml/editor/components/toolbars/StickyToolbar';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';
import type { ToolbarPosition } from 'bundles/cml/editor/types/cmlEditorProps';

const styles = {
  root: css`
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: var(--cds-spacing-100);
  `,
  label: css`
    flex: none;
  `,
  container: css`
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cds-color-neutral-primary-weak);
    border-radius: var(--cds-border-radius-50);
  `,
  scrollContainer: css`
    flex: 1;
    min-height: 0;
    overflow: auto;
  `,
  focused: css`
    border-color: var(--cds-color-blue-700);
  `,
  readOnly: css`
    border-color: var(--cds-color-neutral-stroke-primary-weak);
  `,
  toolbar: css`
    flex: none;
    position: static;
  `,
};

export type Props = {
  focused: boolean;
  readOnly?: boolean;
  label?: React.ReactNode;
  borderColor?: string;
  children: React.ReactNode;
  toolbarPosition?: ToolbarPosition;
};

const FluidCMLEditor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { focused, readOnly, borderColor, children, toolbarPosition = 'bottom', label } = props;
  const { tools, options } = useToolsContext();

  return (
    <div data-testid="fluid-cml-editor" ref={ref} css={styles.root}>
      <Typography2 css={label && styles.label} variant="subtitleMedium" component="label">
        {label}
      </Typography2>

      <div css={[styles.container, focused && styles.focused, readOnly && styles.readOnly]} style={{ borderColor }}>
        <EditorContainer
          toolbar={
            !readOnly && (
              <StickyToolbar customTools={tools} options={options} position={toolbarPosition} css={styles.toolbar} />
            )
          }
          toolbarPosition={toolbarPosition}
          css={styles.scrollContainer}
        >
          {children}
        </EditorContainer>
      </div>
    </div>
  );
});

export default React.memo(FluidCMLEditor);
