import { get } from 'js/lib/user';

import localStorageEx from 'bundles/common/utils/localStorageEx';

const getKey = () => `DIGITAL_BADGE_AGREEMENT_ACCEPTED~${get().id}`;

export const isDigitalBadgeAgreementAccepted = () => {
  return localStorageEx.getItem(getKey(), JSON.parse, false) === true;
};

export const setDigitalBadgeAgreementAccepted = () => {
  localStorageEx.setItem(getKey(), true, JSON.stringify);
};
