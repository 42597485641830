import * as React from 'react';

import classNames from 'classnames';

import { Typography, Typography2 } from '@coursera/cds-core';
import { Box, Col, Row, color } from '@coursera/coursera-ui';
import { SvgCheck } from '@coursera/coursera-ui/svg';

import { CML } from 'bundles/cml';
import type { GraphQLCML } from 'bundles/collections-tool/types/collectionTypesSU';
import { getCMLShape } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  rootClassName?: string;
  titleTag?: React.ElementType;
  cmlLearningObjectives: Array<GraphQLCML>;
  oneColumnPerRow?: boolean;
};

export default function CmlLearningObjectives({
  rootClassName,
  oneColumnPerRow,
  titleTag = 'h2',
  cmlLearningObjectives = [],
}: Props) {
  if (cmlLearningObjectives.length === 0) return null;
  return (
    <div
      className={classNames('CmlLearningObjectives', 'border-a', 'p-x-2', 'p-t-1', rootClassName)}
      style={{ borderRadius: 4 }}
    >
      <Typography component={titleTag} className="text-secondary text-uppercase m-b-2" variant="body1">
        {_t('What you will learn')}
      </Typography>
      <Row tag="ul" rootClassName="list-style-none p-a-0 p-l-1 m-b-0">
        {cmlLearningObjectives.map(({ cml }) => {
          const htmlMarkup = (
            <Box key={`${cml.dtdId}~${cml.value}`} rootClassName="m-b-1s">
              <span className="m-r-1">
                <SvgCheck color={color.success} suppressTitle={true} />
              </span>
              <Typography2 style={{ maxWidth: '90%' }} variant="bodyPrimary" component="div">
                {cml && (
                  <CML
                    cml={getCMLShape(cml)}
                    shouldRenderMath={false}
                    shouldRenderCode={false}
                    shouldRenderAssets={false}
                    isCdsEnabled
                  />
                )}
              </Typography2>
            </Box>
          );

          return oneColumnPerRow ? (
            <Col xs={12} key={cml.value} rootClassName="m-b-1" tag="li">
              {htmlMarkup}
            </Col>
          ) : (
            <Col xs={12} md={6} key={cml.value} rootClassName="m-b-1" tag="li">
              {htmlMarkup}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
