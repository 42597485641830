import * as React from 'react';

import { SvgEdit } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  linkLabel: string;
  isCourse: boolean;
  slug?: string;
};

const AdminLink = (props: Props) => {
  const { slug, isCourse, linkLabel } = props;

  const getLink = (): string | undefined => {
    if (!slug) {
      return undefined;
    }

    if (!isCourse) {
      return `/teach-specialization/${slug}`;
    } else if (isCourse) {
      return `/teach/${slug}`;
    }
    return undefined;
  };

  return (
    <div className="rc-AdminLink admin-banner-link">
      <div className="admin-link body-1-text">
        <a href={getLink()} className="primary horizontal-box">
          <SvgEdit size={24} color="#2a73cc" />
          <span>{linkLabel || _t('Admin')}</span>
        </a>
      </div>
    </div>
  );
};

export default AdminLink;
