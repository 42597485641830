import * as React from 'react';
import { useMemo } from 'react';

import config from 'js/app/config';
import { simplifyQueryValue } from 'js/lib/queryUtils';
import { useLocation } from 'js/lib/useRouter';

import { TabName } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import type { TabName as TabNameType } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import PageNavLink from 'bundles/page-header/components/page-navigation/PageNavLink';
import PageNavigation from 'bundles/page-header/components/page-navigation/PageNavigation';
import { QUERY_PARAMS } from 'bundles/unified-home-common/constants';

import _t from 'i18n!nls/program-home';

const LINK_ORDER = [
  TabName.Catalog,
  TabName.Recommendations,
  TabName.SkillSets,
  TabName.CareerAcademy,
  TabName.MyLearning,
  TabName.MyCourses,
];

const getLinkLabel = (link: TabNameType) => {
  switch (link) {
    case TabName.CareerAcademy:
      return _t('Find your New Career');
    case TabName.Recommendations:
      return _t('My Recommendations');
    case TabName.Catalog:
      return _t('Home');
    case TabName.SkillSets:
      return _t('SkillSets');
    case TabName.MyCourses:
      return _t('My Learning');
    case TabName.MyLearning:
      return _t('My Learning');
    default:
      return '';
  }
};

const PageNav = ({ links, programSlug }: { links: TabNameType[]; programSlug?: string }) => {
  const location = useLocation();

  const highlightedTab = useMemo(() => {
    if (!location.pathname?.startsWith(`/programs/${programSlug}`)) {
      return undefined;
    }
    const currentTab = Object.keys(location.query).find((key) => key === QUERY_PARAMS.CURRENT_TAB);
    if (!currentTab) {
      return undefined;
    } else {
      return location.query[currentTab];
    }
  }, [location.query, location.pathname, programSlug]);

  if (links.length === 0 || !programSlug) {
    return null;
  }

  const sortedLinks: TabNameType[] = [];
  LINK_ORDER.forEach((link: TabNameType) => {
    if (links.includes(link)) {
      sortedLinks.push(link);
    }
  });

  return (
    <PageNavigation>
      {sortedLinks.map((link) => {
        const pathname = link === TabName.CareerAcademy ? '/career-academy/programs' : '/programs';
        const url = new URL(`${pathname}/${programSlug}`, config.url.base);
        let isCurrent = link === highlightedTab;

        Object.keys(location.query).forEach((key) => {
          if (key === QUERY_PARAMS.MY_LEARNING_TAB) {
            return;
          }

          if (key !== QUERY_PARAMS.CURRENT_TAB) {
            url.searchParams.append(key, simplifyQueryValue(location.query[key]) ?? '');
          } else {
            url.searchParams.set(key, link);
          }
        });

        if (link === TabName.CareerAcademy) {
          isCurrent = location.pathname?.startsWith('/career-academy/programs');
          url.searchParams.delete(QUERY_PARAMS.CURRENT_TAB);
        } else if (url.searchParams.get(QUERY_PARAMS.CURRENT_TAB) === null) {
          url.searchParams.set(QUERY_PARAMS.CURRENT_TAB, link);
        }

        const href = url.toString().replace(config.url.base, '/');

        return (
          <PageNavLink
            key={link}
            label={getLinkLabel(link)}
            name={link?.toLowerCase()}
            href={href}
            isCurrent={isCurrent}
          />
        );
      })}
    </PageNavigation>
  );
};

export default PageNav;
