import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { get as getUser } from 'js/lib/user';

const DEFAULT_LANG = 'en-US';

export enum SpeechRecognitionStatus {
  Idle = 'idle',
  Listening = 'listening',
  SpeechStarted = 'speechStarted',
  SoundEnded = 'soundEnded',
  StoppedListening = 'stoppedListening',
  Error = 'error',
}

export const addPunctuation = (text: string): string => {
  let punctuatedText = text.trim();
  if (!punctuatedText) return '';

  const questionWords = [
    'who',
    'what',
    'where',
    'when',
    'why',
    'how',
    'can',
    'would',
    'could',
    'should',
    'is',
    'are',
    'will',
    'does',
    'did',
  ];
  const conjunctionWords = ['and', 'but', 'or', 'so', 'nor', 'yet'];
  const lowerCasedText = punctuatedText.toLowerCase();

  // Determine if the sentence is a question
  if (questionWords.some((word) => lowerCasedText.startsWith(word))) {
    punctuatedText = punctuatedText.charAt(0).toUpperCase() + punctuatedText.slice(1) + '?';
  } else if (!punctuatedText.endsWith('.') && !punctuatedText.endsWith('?') && !punctuatedText.endsWith('!')) {
    // If it doesn't end with punctuation, add a period
    punctuatedText = punctuatedText.charAt(0).toUpperCase() + punctuatedText.slice(1) + '.';
  }

  // scan for conjunctions and add commas
  for (let i = 0; i < conjunctionWords.length; i += 1) {
    const conjunction = conjunctionWords[i];

    if (punctuatedText.includes(` ${conjunction} `)) {
      punctuatedText = punctuatedText.replaceAll(` ${conjunction}`, `, ${conjunction}`);
    }
  }

  return punctuatedText;
};

/**
 * Wrapper around the Web Speech API for speech recognition with customizations like
 * automatic punctuation and stopping after a certain number of seconds.
 *
 * Note: currently only English and Spanish have been tested to work reliably.
 */
const useSpeechToText = (props: {
  stopAfterSeconds?: number;
  automaticPunctuation?: boolean;
  language?: string;
  supportedLanguages?: string[];
  onStopListening?: () => void;
  onStartListening?: () => void;
  onError?: (error: string) => void;
  onSupportStatus?: (isSupported: boolean) => void;
  onTranscript?: (transcript: string, isInterim: boolean) => void;
}) => {
  const {
    stopAfterSeconds,
    automaticPunctuation,
    language,
    supportedLanguages,
    onStartListening,
    onStopListening,
    onError,
    onSupportStatus,
    onTranscript,
  } = props;

  const [transcriptFinal, setTranscriptFinal] = useState<string>('');
  const [transcriptInterim, setTranscriptInterim] = useState<string>('');
  const [listening, setListening] = useState<boolean>(false);
  const [status, setStatus] = useState<SpeechRecognitionStatus>(SpeechRecognitionStatus.Idle);
  const [error, setError] = useState<string | null>(null);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState<boolean>(true);

  const [recognition, setRecognition] = useState<null | SpeechRecognition>(null);

  const userLanguage = language ?? getUser().locale ?? DEFAULT_LANG;

  // default support all languages supported by Web Speech API
  const isSupportedLanguage = supportedLanguages ? supportedLanguages.includes(userLanguage) : true;
  const recognitionLanguage = isSupportedLanguage ? userLanguage : DEFAULT_LANG;

  useEffect(() => {
    const SpeechRecognition = globalThis.SpeechRecognition || globalThis.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;
      recognitionInstance.lang = recognitionLanguage;
      setRecognition(recognitionInstance);
    } else {
      setError('Your browser does not support the Web Speech API.');
      setIsSupportedBrowser(false);
      onSupportStatus?.(false);
    }
  }, [recognitionLanguage, onSupportStatus, onError, language]);

  const startListening = () => {
    if (recognition) {
      recognition.start();
      onStartListening?.();
    }
  };

  const stopListening = useCallback(() => {
    if (recognition) {
      recognition.stop();
      onStopListening?.();
    }
  }, [recognition]); // eslint-disable-line react-hooks/exhaustive-deps

  const stopListeningDebounce = useMemo(() => {
    if (stopAfterSeconds == null) {
      return undefined;
    }

    return debounce(stopListening, stopAfterSeconds * 1_000);
  }, [stopListening, stopAfterSeconds]);

  useEffect(() => {
    // cancel on unmount
    return () => stopListeningDebounce?.cancel();
  }, [stopListeningDebounce]);

  useEffect(() => {
    if (recognition) {
      recognition.onstart = () => {
        setStatus(SpeechRecognitionStatus.Listening);
        setListening(true);
      };

      recognition.onspeechstart = () => {
        setStatus(SpeechRecognitionStatus.SpeechStarted);
        setListening(true);
      };

      recognition.onsoundend = () => {
        setStatus(SpeechRecognitionStatus.SoundEnded);
        setListening(false);
      };
      recognition.onend = () => {
        setStatus(SpeechRecognitionStatus.StoppedListening);
        setListening(false);
      };

      recognition.onresult = (event: SpeechRecognitionEvent) => {
        let interimTranscript = '';
        let finalTranscript = '';
        let transcriptResult = '';

        for (let i = event.resultIndex; i < event.results.length; i += 1) {
          transcriptResult = event.results[i][0].transcript;

          if (event.results[i].isFinal) {
            finalTranscript += automaticPunctuation ? addPunctuation(transcriptResult) : transcriptResult;
          } else {
            interimTranscript += transcriptResult;
          }
        }

        if (finalTranscript) {
          onTranscript?.(finalTranscript + ' ', false);
          setTranscriptFinal(finalTranscript);
          setTranscriptInterim('');
        } else {
          onTranscript?.(interimTranscript, true);
          setTranscriptInterim(interimTranscript);
        }

        stopListeningDebounce?.();
      };

      recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
        setError(event.error);
        setStatus(SpeechRecognitionStatus.Error);
        onError?.(event.error);
      };
    }

    return () => {
      if (recognition) {
        recognition.stop();
        recognition.abort();
      }
    };
  }, [
    automaticPunctuation,
    recognition,
    stopListeningDebounce,
    stopAfterSeconds,
    onError,
    recognitionLanguage,
    onTranscript,
  ]);

  return {
    transcriptFinal,
    transcriptInterim,
    listening,
    status,
    error,
    startListening,
    stopListening,
    clearTranscript: () => setTranscriptFinal(''),
    isSupportedBrowser,
    isSupportedLanguage,
    recognition,
  };
};

export default useSpeechToText;
