import * as React from 'react';

import gql from 'graphql-tag';

import { Col, Container, Row, StyleSheet, color } from '@coursera/coursera-ui';

import type { Domain } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import { getBreadcrumbsFromDomain } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import Breadcrumbs from 'bundles/enterprise-legacy-xdp/components/PdpBreadcrumbs';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import type {
  PdpBreadcrumb_domains as GeneratedDomainType,
  PdpBreadcrumb as PdpBreadcrumbType,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpBreadcrumb';
import withFragments from 'bundles/graphql/components/withFragments';

type Props = Omit<PdpBreadcrumbType, '__typename'>;

function toDomain(domain: GeneratedDomainType): Domain {
  return {
    domainId: domain.domainId,
    domainName: domain.domainName,
    subdomainId: domain.subdomainId || undefined,
    subdomainName: domain.subdomainName || undefined,
  };
}

const sectionStyles = StyleSheet.create({
  root: {
    // Breadcrumb only wants to inerhit the left/right padding
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
});

const styles = StyleSheet.create({
  BreadCrumb: {
    marginTop: '12px',
    marginBottom: '12px',
    lineHeight: '18px',
    height: '18px',
    flexWrap: 'wrap',
  },
  breadcrumbFontSize: {
    fontSize: '12px',
  },
  breadcrumbLink: {
    ':hover': {
      color: color.primaryText,
    },
    ':focus': {
      color: color.primaryText,
    },
  },
});

export function PdpBreadcrumb({ domains }: Props) {
  const domain = domains[0];
  const routes = domain && getBreadcrumbsFromDomain(toDomain(domain));
  return (
    <PdpSection styles={sectionStyles}>
      <Container isFluid>
        <Row>
          <Col>{routes && <Breadcrumbs routes={routes} styles={styles} chevronColor={color.bgDarkGray} />}</Col>
        </Row>
      </Container>
    </PdpSection>
  );
}

export default withFragments({
  PdpBreadcrumb: gql`
    fragment PdpBreadcrumb on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      domains {
        domainId
        domainName
        subdomainName
        subdomainId
      }
    }
  `,
})(PdpBreadcrumb);
