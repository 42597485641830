/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { breakpoints } from '@coursera/cds-core';

import ChatFooter from 'bundles/ai-coach-platform/components/patterns/chat/ChatFooter';
import ChatInputField from 'bundles/ai-coach-platform/components/patterns/chat/ChatInputField';
import type { HideMessageType } from 'bundles/ai-coach-platform/components/patterns/chat/ChatMessages';
import ChatMessages from 'bundles/ai-coach-platform/components/patterns/chat/ChatMessages';
import ChatRichInputField from 'bundles/ai-coach-platform/components/patterns/chat/ChatRichInputField';
import { ChatHeader, ChatHistoryLoading } from 'bundles/ai-coach-platform/components/patterns/chat/common';
import type { GlobalState } from 'bundles/ai-coach-platform/components/patterns/chat/store/types';
import type { AnyFunction, SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  conversationContainer: css`
    height: 100%;
  `,
  conversation: css`
    background-color: var(--cds-color-neutral-background-primary);
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    z-index: 10;
    border: none;
    border-radius: var(--cds-border-radius-200);
    position: relative;

    ${breakpoints.down('xs')} {
      border-radius: 0;
    }

    .coach-message {
      background-color: var(--cds-color-neutral-primary-invert);
    }

    &.active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.hidden {
      z-index: -1;
      pointer-events: none;
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }
  `,
  headerContainer: css`
    flex-grow: 0;
    border-bottom: 1px solid var(--cds-color-grey-100);
  `,
  messageContainer: css`
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
    max-width: 100%;
    position: relative;
  `,
  messageContainerResponses: css`
    max-width: 770px;
    margin: 0 auto;
  `,
  senderContainer: css`
    flex-grow: 0;
    width: 100%;
    border-top: 1px solid var(--cds-color-grey-100);
  `,
  chatInput: css`
    max-width: 770px;
    margin: 0 auto;
  `,
  footerContainer: css`
    flex-grow: 0;
    max-width: 770px;
    margin: 0 auto;
  `,
};

export type ConversationProps = {
  sendMessage: SendMessageFunc;
  toggleChat: AnyFunction;
  showStartNewChat?: boolean;
  emptyState?: ReactElement;
  senderPlaceHolder?: string;
  showCloseButton?: boolean;
  disabledInput?: boolean;
  autofocus?: boolean;
  profileClientAvatar?: string;
  onTextInputChange?: (text: string) => void;
  handleNewSession?: () => void;
  isChatHistoryLoading?: boolean;
  courseId?: string;
  customHeader?: ReactElement | null;
  customFooter?: ReactElement | null;
  hideFooter?: boolean;
  hideMessage?: HideMessageType;
  showSettings?: boolean;
  handleSettingsClick?: () => void;
  isCoachStudio?: boolean;
  showFullScreenButton?: boolean;
  toggleFullScreen?: () => void;
  isFullScreen?: boolean;
  showPositionToggle?: boolean;
  handlePositionToggleClick?: () => void;
  enableRichInputField?: boolean;
};

/**
 * Standalone component that handles all the conversations with Coach.
 */
const Conversation = ({
  senderPlaceHolder,
  showCloseButton = true,
  disabledInput = false,
  autofocus = true,
  showStartNewChat = true,
  sendMessage,
  toggleChat,
  profileClientAvatar,
  onTextInputChange,
  handleNewSession,
  emptyState,
  isChatHistoryLoading = false,
  courseId,
  customHeader,
  customFooter,
  hideFooter = false,
  hideMessage,
  showSettings = false,
  handleSettingsClick = () => {},
  isCoachStudio = false,
  showFullScreenButton = false,
  toggleFullScreen,
  isFullScreen = false,
  showPositionToggle,
  handlePositionToggleClick,
  enableRichInputField = false,
}: ConversationProps) => {
  const { isMessageLoading } = useSelector((state: GlobalState) => ({
    isMessageLoading: state.behavior.messageLoader,
  }));

  return (
    <div css={styles.conversation} className="coach-conversation-container">
      {/* 
        If customHeader is undefined we show the default header. 
        If a React element is passed for customHeader, the custom header is displayed. 
        If customHeader is null, we hide the header. 
      */}
      {customHeader === undefined && (
        <div css={styles.headerContainer}>
          <ChatHeader
            onClose={toggleChat}
            showStartNewChat={showStartNewChat}
            showClose={showCloseButton}
            handleNewSession={handleNewSession}
            handleSettingsClick={handleSettingsClick}
            showSettings={showSettings}
            loading={isChatHistoryLoading || isMessageLoading}
            isCoachStudio={isCoachStudio}
            showFullScreenButton={showFullScreenButton}
            toggleFullScreen={toggleFullScreen}
            isFullScreen={isFullScreen}
            showPositionToggle={showPositionToggle}
            handlePositionToggleClick={handlePositionToggleClick}
          />
        </div>
      )}
      {customHeader !== undefined && customHeader !== null && <div css={styles.headerContainer}>{customHeader}</div>}

      <div css={styles.messageContainer}>
        <div css={styles.messageContainerResponses}>
          {isChatHistoryLoading && <ChatHistoryLoading />}
          {!isChatHistoryLoading && (
            <ChatMessages
              profileClientAvatar={profileClientAvatar}
              emptyState={emptyState}
              sendMessage={sendMessage}
              courseId={courseId}
              hideMessage={hideMessage}
            />
          )}
        </div>
      </div>

      <div css={styles.senderContainer}>
        <div css={styles.chatInput}>
          {enableRichInputField ? (
            <ChatRichInputField
              sendMessage={sendMessage}
              placeholder={senderPlaceHolder || _t('Ask me anything')}
              disabledInput={disabledInput}
            />
          ) : (
            <ChatInputField
              sendMessage={sendMessage}
              placeholder={senderPlaceHolder || _t('Ask me anything')}
              disabledInput={disabledInput}
              autofocus={autofocus}
              onTextInputChange={onTextInputChange}
            />
          )}
        </div>
      </div>
      {!hideFooter && (
        <div css={styles.footerContainer} className="coach-conversation-footer-container">
          {customFooter || <ChatFooter />}
        </div>
      )}
    </div>
  );
};

export default Conversation;
