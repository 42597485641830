/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { throttle } from 'lodash';

import { useFocusRing } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import ResponsiveToolbar from 'bundles/cml/editor/components/toolbars/ResponsiveToolbar';
import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const styles = {
  root: css`
    position: sticky;
    top: 0;
    left: 0;
    order: 0;
    z-index: ${zIndex.xl}; /* Needs to be less than top-most header-container and some modals which are a 100. */
    background-color: var(--cds-color-neutral-background-primary);
    border-bottom: 1px solid transparent;
    transition: border-color 150ms ease-in-out;
    width: 100%;
  `,
  overlap: css`
    border-bottom-color: var(--cds-color-neutral-disabled-strong);
  `,
};

type Props = {
  customTools: ToolsKeys[];
  options: ToolOptions;
  scrollingContainer?: HTMLElement | null;
};

const PagelessToolbar: React.FC<Props> = ({ scrollingContainer, customTools, options }) => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(() => setScrollTop(scrollingContainer?.scrollTop ?? 0), 25);
    scrollingContainer?.addEventListener('scroll', handleScroll);

    return () => {
      scrollingContainer?.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [scrollingContainer]);

  const { isFocusVisible, focusProps } = useFocusRing({ within: true });
  const { focused, setFocused } = useFocusedContext();

  useEffect(() => {
    if (isFocusVisible) {
      setFocused(true);
      return () => setFocused(false);
    }
    return () => undefined;
  }, [isFocusVisible, focused, setFocused]);

  const overlapping = scrollTop > 0;
  const toolbar = (
    <div {...focusProps} css={[styles.root, overlapping && styles.overlap]}>
      <ResponsiveToolbar customTools={customTools} options={options} pageless />
    </div>
  );

  if (scrollingContainer) {
    return createPortal(toolbar, scrollingContainer);
  }

  return toolbar;
};

export default PagelessToolbar;
