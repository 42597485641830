import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { color } from '@coursera/coursera-ui';
import { SvgExternalLink } from '@coursera/coursera-ui/svg';

import type { CmlContent } from 'bundles/cml';
import { CML } from 'bundles/cml';
import { buildLearnerHelpUrl } from 'bundles/common/utils/urlUtils';
import { HELP_CENTER_LINK } from 'bundles/enterprise-admin-constants/help';
import { getDefaultBackgroundLevelCml } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import type {
  DifficultyLevel,
  S12nGlanceItem,
  Specialization,
} from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import {
  DIFFICULTY_LEVEL_TO_ICON,
  getDifficultyLevelString,
  getLanguageString,
} from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import { renderToggleButtonWithoutCollapse } from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import TogglableContent from 'bundles/page/components/TogglableContent';
import { TrackedReactLink } from 'bundles/page/components/TrackedLink2';
import { getS12nDisplayName, getSdpUrl } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const make100percentOnlineGlance = (title: string, showFlexibleScheduleCopy: boolean) => ({
  icon: 'SvgGlobe',
  title,
  subtitle: showFlexibleScheduleCopy ? _t('Start instantly and learn at your own schedule.') : undefined,
});

export const make100percentOnlineCourseGlance = (showFlexibleScheduleCopy: boolean): S12nGlanceItem => {
  const title = _t('100% online');
  return make100percentOnlineGlance(title, showFlexibleScheduleCopy);
};

export const make100percentOnlineS12nGlance = (): S12nGlanceItem => {
  const title = _t('100% online courses');
  return make100percentOnlineGlance(title, true);
};

export const makeFlexibleDeadlineGlance = (): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Flexible deadlines'),
  subtitle: _t('Reset deadlines in accordance to your schedule.'),
});

export const makeFlexibleScheduleGlance = (): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Flexible Schedule'),
  subtitle: _t('Set and maintain flexible deadlines.'),
});

export const makeShareAbleCertificateGlance = (): S12nGlanceItem => ({
  icon: 'SvgCertificate',
  title: _t('Shareable Certificate'),
  subtitle: _t('Earn a Certificate upon completion'),
});

export const makeShareAbleC4CCertificateGlance = (isEnterpriseAdmin?: boolean): S12nGlanceItem => ({
  icon: 'SvgCertificate',
  title: _t('Shareable Certificate'),
  subtitle: isEnterpriseAdmin
    ? _t('Learners will earn a certificate upon completion issued by the institution that created the course.')
    : _t('Earn a certificate upon completion issued by the institution that created the course.'),
});

export const makeExclusiveCourseGlance = (): S12nGlanceItem => ({
  icon: 'SvgGlobe',
  title: _t('Exclusive online course'),
  subtitle: _t('Only available to you and other members of your organization'),
});

export const makeFixedScheduleGlance = (startDate: number, endDate: number): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Course availability'),
  subtitle: (
    <FormattedMessage
      message={_t(
        'This course starts on {startDate} and ends on {endDate}. You must finish the course by the end date. You cannot reset your deadlines.'
      )}
      startDate={formatDateTimeDisplay(startDate, 'MMM D, YYYY')}
      endDate={formatDateTimeDisplay(endDate, 'MMM D, YYYY')}
    />
  ),
});

export const makeMultipleAvailabilityOptionsGlance = (): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Multiple availability options'),
  subtitle: _t('Select from existing availability options or define a custom limited availability.'),
  hasBottomBorder: true,
});

export const makeCourseLimitedAvailabilityGlance = (): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Limited availability'),
  subtitle: _t('This course is offered on a limited availability by the institution who created the course.'),
  hasBottomBorder: true,
});

export const makeS12nLimitedAvailabilityGlance = (): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Limited availability'),
  subtitle: _t(
    'This specialization is offered on a limited availability by the institution who created the specialization.'
  ),
  hasBottomBorder: true,
});

export const makeCourseCanBeOfferedForCreditGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Can be offered for credit'),
  subtitle: _t(
    "Offer this course for credit and we'll indicate to learners they'll be awarded credit upon completion."
  ),
});

export const makeS12nCanBeOfferedForCreditGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Can be offered for credit'),
  subtitle: _t(
    "Offer this specialization for credit and we'll indicate to learners they'll be awarded credit upon completion."
  ),
});

export const makeCourseRecommendedForCreditGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Recommended for credit'),
  subtitle: (
    <FormattedHTMLMessage
      message={_t(
        `<div class="m-b-1s">Our content experts recommend this course for credit. Offer this course for credit and we'll indicate to learners they'll be awarded credit upon completion.</div><a href="{link}" target="_blank" rel="noopener noreferrer">Visit our Help Center for more information {icon}</a>`
      )}
      link={HELP_CENTER_LINK}
      icon={<SvgExternalLink size={18} style={{ marginLeft: '12px' }} color={color.primary} />}
    />
  ),
});

export const makeS12nRecommendedForCreditGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Recommended for credit'),
  subtitle: (
    <FormattedHTMLMessage
      message={_t(
        `<div class="m-b-1s">Our content experts recommend this specialization for credit. Offer this specialization for credit and we'll indicate to learners they'll be awarded credit upon completion.</div><a href="{link}" target="_blank" rel="noopener noreferrer">Visit our Help Center for more information {icon}</a>`
      )}
      link={HELP_CENTER_LINK}
      icon={<SvgExternalLink size={18} style={{ marginLeft: '12px' }} color={color.primary} />}
    />
  ),
});

export const makeCourseOfferedForCreditGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Course offered for credit'),
  subtitle: _t('Your institution will award credit for completing this course.'),
});

export const makeS12nOfferedForCreditGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Specialization offered for credit'),
  subtitle: _t('Your institution will award credit for completing this specialization.'),
});

export const makeS12nCourseAvailabilityGlance = () => ({
  icon: 'SvgCalendar',
  title: _t('Course availability'),
  subtitle: _t(
    'Some of the courses in this Specialization are offered with fixed start and end dates. Please check the course page for more details.'
  ),
});

export const makeDifficultyLevelGlance = (
  level: DifficultyLevel,
  backgroundLevelCml: CmlContent | undefined | null
): S12nGlanceItem => {
  const ret: S12nGlanceItem = {
    icon: DIFFICULTY_LEVEL_TO_ICON[level],
    title: getDifficultyLevelString(level),
  };

  if (backgroundLevelCml) {
    ret.subtitle = (
      <CML
        cml={backgroundLevelCml}
        shouldRenderMath={false}
        shouldRenderCode={false}
        shouldRenderAssets={false}
        isCdsEnabled
      />
    );
  }

  return ret;
};

export const makeDifficultyLevelGlanceWithDefault = (
  level: DifficultyLevel,
  backgroundLevelCml: CmlContent | undefined | null,
  backgroundLevelHtml: string | undefined | null
): S12nGlanceItem => {
  const ret: S12nGlanceItem = {
    icon: DIFFICULTY_LEVEL_TO_ICON[level],
    title: getDifficultyLevelString(level),
  };

  const backgroundLevelContent = backgroundLevelCml && backgroundLevelHtml;
  const backgroundLevelMarkUp = (
    <CML
      cml={backgroundLevelCml}
      shouldRenderMath={false}
      shouldRenderCode={false}
      shouldRenderAssets={false}
      isCdsEnabled
    />
  );

  ret.subtitle = (
    <TogglableContent
      childrenWrapperTag="div"
      collapsedHeight={60}
      disableCollapseScroll
      hideShowLessBtn
      renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse('PREREQUISITES')}
      toggleButtonType="link"
      trackingName="background-level"
      useSmartCollapse
    >
      {backgroundLevelContent ? backgroundLevelMarkUp : getDefaultBackgroundLevelCml()[level]}
    </TogglableContent>
  );

  return ret;
};

export const makeHoursToCompleteGlance = (avgLearningHoursAdjusted: number): S12nGlanceItem => {
  const title = (
    <FormattedMessage
      avgLearningHoursAdjusted={avgLearningHoursAdjusted}
      message={_t(
        'Approx. {avgLearningHoursAdjusted} {avgLearningHoursAdjusted, plural, =1 {hour} other {hours}} to complete'
      )}
    />
  );

  return {
    icon: 'SvgClock',
    iconTitle: _t('Hours to complete'),
    title,
    subtitle: undefined,
  };
};

export const makeLanguagesGlance = (
  primaryLanguages: Array<string>,
  subtitleLanguages: Array<string>
): S12nGlanceItem => {
  const subtitle = (
    <TogglableContent
      childrenWrapperTag="div"
      collapsedHeight={60}
      disableCollapseScroll
      hideShowLessBtn
      renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse('SUBTITLE LANGUAGES')}
      toggleButtonType="link"
      trackingName="subtitle-languages"
      useSmartCollapse
    >
      <FormattedMessage
        message={_t('Subtitles: {subtitleString}')}
        subtitleString={getLanguageString(subtitleLanguages)}
      />
    </TogglableContent>
  );

  return {
    icon: 'SvgCommentDots',
    iconTitle: _t('Available languages'),
    title: getLanguageString(primaryLanguages),
    subtitle,
  };
};

export const makePathwayToInDemandJobsGlance = (): S12nGlanceItem => ({
  icon: 'SvgLevelBeginner',
  title: _t('Pathway to in-demand jobs'),
  subtitle: _t('No previous experience necessary'),
});
export const makeFastAndFelxibleLearningGlance = (): S12nGlanceItem => ({
  icon: 'SvgCalendar',
  title: _t('Fast and flexible learning'),
  subtitle: _t('100% online, self-paced learning designed to fit your life'),
});

export const makeValuableCareerServicesGlance = (): S12nGlanceItem => ({
  icon: 'SvgLayer',
  title: _t('Valuable career services'),
  subtitle: _t('Resources to help during your job search'),
});

export const makeProfessionalCertificateGlance = (partnerName: string): S12nGlanceItem => ({
  icon: 'SvgCertificate',
  title: _t('Professional Certificate from #{partnerName}', { partnerName }),
  subtitle: _t('A recognized career credential to enhance your resume'),
});

export const makeApproximatelyMonthsToCompleteGlance = (
  avgLearningMonthsAdjusted: number,
  avgLearningHoursPerWeek: number | undefined | null
): S12nGlanceItem => {
  const item: S12nGlanceItem = {
    icon: 'SvgClock',
    iconTitle: _t('Hours to complete'),
    title: (
      <FormattedMessage
        message={_t(
          'Approximately {avgLearningMonthsAdjusted} {avgLearningMonthsAdjusted, plural, =1 {month} other {months}} to complete'
        )}
        avgLearningMonthsAdjusted={avgLearningMonthsAdjusted}
      />
    ),
  };

  if (avgLearningHoursPerWeek) {
    item.subtitle = (
      <FormattedMessage
        message={_t(
          'Suggested pace of {avgLearningHoursPerWeek} {avgLearningHoursPerWeek, plural, =1 {hour} other {hours}}/week'
        )}
        avgLearningHoursPerWeek={avgLearningHoursPerWeek}
      />
    );
  }

  return item;
};

export const makePriceGlance = (priceString?: JSX.Element): S12nGlanceItem => ({
  icon: 'SvgMoney',
  title: _t('7-day free trial'),
  subtitle: (
    <>
      <FormattedMessage message={_t('{price} per month after trial ends.')} price={priceString} />
      <p>{_t('Cancel any time.')}</p>
    </>
  ),
});

export const makeS12nGlance = ({
  activeCourseNumber,
  numCourses,
  activeS12n,
}: {
  activeCourseNumber: number | undefined | null;
  numCourses: number;
  activeS12n: Specialization;
}): S12nGlanceItem => {
  const s12nGlanceItem: S12nGlanceItem = {
    icon: 'SvgStacks',
    iconTitle: _t('Specialization'),
    title: (
      <FormattedMessage
        message={_t('Course {activeCourseNumber} of {numCourses} in the')}
        activeCourseNumber={activeCourseNumber}
        numCourses={numCourses}
      />
    ),
    subtitle: (
      <TrackedReactLink
        trackingName="course_glance_to_specialization"
        to={getSdpUrl(activeS12n.productVariant, activeS12n.slug)}
      >
        {getS12nDisplayName(activeS12n.name, activeS12n.productVariant)}
      </TrackedReactLink>
    ),
  };

  return s12nGlanceItem;
};

export const makeHasCourseraLabsGlance = (): S12nGlanceItem => ({
  icon: 'SvgGraduationCap',
  title: _t('Coursera Labs'),
  subtitle: (
    <FormattedHTMLMessage
      message={_t(
        `<div class="m-b-1s">Includes hands on learning projects.</div><a href="{link}" target="_blank" rel="noopener noreferrer">Learn more about Coursera Labs {icon}</a>`
      )}
      link={buildLearnerHelpUrl('360044758731-Solving-common-issues-with-Coursera-Labs')}
      icon={<SvgExternalLink size={18} style={{ marginLeft: '12px' }} color={color.primary} />}
    />
  ),
});
