import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { compose, withProps } from 'recompose';

import { getSkillSearchTerm } from 'bundles/enterprise-learner-search/components/skillSearch/SkillNameMapping';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const MAX_SKILL_MATCHES = 3;
const DEFAULT_SKILL_LOGO = `data:image/svg+xml;.svg;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyNiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjAwNTYgOS40NTgwMUwxNC45NzU0IDEzLjY3OTRMMTkuMzg2MyAxNC4zNTVMMTYuMTk1OSAxNy42NDIzTDE2Ljk0ODQgMjIuMjgwNkwxMy4wMDU2IDIwLjA5MjRMOS4wNjI3MyAyMi4yODA2TDkuODE1MiAxNy42NDIzTDYuNjI0ODUgMTQuMzU1TDExLjAzMjYgMTMuNjc5NEwxMy4wMDU2IDkuNDU4MDFaIiBmaWxsPSIjRkZDNTAwIiBzdHJva2U9IiNFQkE2MDAiIHN0cm9rZS13aWR0aD0iMS42MTYxNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPg==`;

export type SkillResult = {
  name: string;
  imageUrl: string;
  objectID: string;
  trackingName: string;
};

export type SkillSearchResults = {
  sectionTitle: string;
  hits: SkillResult[];
};

type Props = {
  currentRefinement?: string;
  programIds: string[]; // Will contain one programId in program home
};

type OutputProps = {
  skillHits: SkillSearchResults[];
};

type Skill = {
  skillId: string;
  skillName: string;
};

type SkillsData = {
  skills: Skill[];
  loading: boolean;
  error: boolean;
};

export const DomainOrSmallSubsetSkillDataQueryText = gql`
  query DomainOrSmallSubsetSkillDataQuery($programId: [String!]!) {
    DomainOrSmallSubsetSkillDataQuery(input: { program_id: $programId })
      @rest(
        path: "grpc/enterpriseskills/programskills/v1beta1/ProgramSkillsAPI/GetSkillsByProgram"
        type: "Skill"
        method: "POST"
      ) {
      skills
    }
  }
`;

type DomainOrSmallSubsetSkillDataQuery = {
  DomainOrSmallSubsetSkillDataQuery?: {
    skills: Skill[];
  };
};

const withProgramSkillData = graphql<Props, DomainOrSmallSubsetSkillDataQuery, {}, SkillsData>(
  DomainOrSmallSubsetSkillDataQueryText,
  {
    options: ({ programIds }) => ({
      variables: {
        programId: programIds[0],
      },
    }),
    props: ({ data }) => {
      const loading = data?.loading ?? true;
      const error = !!data?.error;
      const skills = data?.DomainOrSmallSubsetSkillDataQuery?.skills || [];

      return {
        loading,
        error,
        skills,
      };
    },
  }
);

export const scoreSkill = (skill: Skill, search: string) => {
  const skillNameToCompare = skill.skillName.toLowerCase();
  const searchToCompare = search.toLowerCase();
  const searchTokens = searchToCompare.split(' ');
  const skillNameTokens = skillNameToCompare.split(' ');
  if (skillNameToCompare === searchToCompare) {
    return 99999;
  } else {
    let score = 0;
    searchTokens.forEach((searchToken) => {
      skillNameTokens.forEach((skillNameToken) => {
        if (skillNameToken === searchToken) {
          score += 100;
        } else if (skillNameToken.startsWith(searchToken)) {
          score += 10;
        } else if (skillNameToken.includes(searchToken)) {
          score += 1;
        }
      });
    });
    return score;
  }
};

const searchSkills = (skills: Skill[], search: string, maxMatches: number): Skill[] => {
  const scoredSkills = skills.map((skill) => ({
    skill,
    score: scoreSkill(skill, search),
  }));

  // If equal scores then rank the shorter name first as it was at a disadvantage because it had less tokens
  const sortedSkills = scoredSkills.sort(
    (a, b) => b.score - a.score || a.skill.skillName.length - b.skill.skillName.length
  );
  return sortedSkills
    .slice(0, maxMatches)
    .filter((skill) => skill.score)
    .map((skill) => skill.skill);
};

const mapSkillToHit = (skill: Skill, sectionTitle: string) => ({
  name: skill.skillName,
  imageUrl: DEFAULT_SKILL_LOGO,
  objectID: `skill~${skill.skillId}`,
  trackingName: 'skill_autocomplete_item',
  sectionTitle,
});

const withFilteredSkills = withProps<OutputProps, Props & SkillsData>((data) => {
  const loading = data.loading;
  const error = !!data.error;
  const search = getSkillSearchTerm(data.currentRefinement ?? '');

  const hasSkillData = !loading && !error;
  const shouldSearch = hasSkillData && !isEmpty(search);
  const hits = shouldSearch ? searchSkills(data.skills, search, MAX_SKILL_MATCHES) : [];
  const sectionTitle = _t('Skills');
  if (hits.length === 0) {
    return {
      loading,
      error,
      skillHits: [],
    };
  }

  return {
    loading,
    error,
    skillHits: [
      {
        sectionTitle,
        hits: hits.map((hit) => mapSkillToHit(hit, sectionTitle)),
      },
    ],
  };
});

const withSkillSearchResults = compose<OutputProps & Props, Props>(withProgramSkillData, withFilteredSkills);

export { withFilteredSkills, DEFAULT_SKILL_LOGO, withSkillSearchResults, withProgramSkillData };
