import * as React from 'react';

import CourseCard from 'bundles/course-cards/components/course-card/enterprise/CourseCard';
import type { PropsFromCaller as SaveSkillSetBannerProps } from 'bundles/enterprise-legacy-learner-home/components/available/SaveSkillSetBanner';
import SaveSkillSetBanner, {
  SKILLSET_DISMISSED_STATE,
  getUserSkillSetData,
} from 'bundles/enterprise-legacy-learner-home/components/available/SaveSkillSetBanner';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { ProductStub } from 'bundles/program-home/types/Products';
import S12nVersioningNotification from 'bundles/s12n-common/components/s12n-versioning/S12nVersioningNotification';
import S12nCard from 'bundles/s12n-common/components/s12nCard/S12nCard';

type ProductEnrolledCardProps = {
  product: ProductStub;
  programId: string;
  userId: number;
  isGrouped?: boolean;
  thirdPartyOrganizationId: string;
};

const ProductEnrolledCard: React.FC<ProductEnrolledCardProps> = ({
  product,
  programId,
  userId,
  isGrouped,
  thirdPartyOrganizationId,
}) => {
  return (
    <div>
      {product.isS12n && (
        <div className="vertical-box">
          {product.isS12n && product.canUpgrade && (
            <S12nVersioningNotification product={product} userId={userId} programId={programId} />
          )}
          <S12nCard
            userId={userId}
            s12nId={product.s12nId}
            programId={programId}
            isS12nEnrolled={product.isEnrolled}
            canUnenroll={product.canUnenroll}
          />
        </div>
      )}
      {product.isCourse && (
        <CourseCard
          courseId={product.courseId}
          programId={programId}
          isEnrolled={product.isEnrolled}
          canUnenroll={product.canUnenroll}
          isGrouped={isGrouped}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
        />
      )}
    </div>
  );
};

export const ProductEnrolledCardWithSaveSkillSetBanner: React.FC<
  SaveSkillSetBannerProps &
    ProductEnrolledCardProps &
    PropsFromSaveSkillSetToggle & { showSaveSkillSetBanner?: boolean }
> = ({
  product,
  programId,
  productCount,
  userId,
  targetSkillProfileId,
  programSlug,
  showSaveSkillSetBanner,
  onSkillSetSaveToggle,
  thirdPartyOrganizationId,
}) => {
  const isDismissedSkillSetBanner = () => {
    const userSkillSetData = getUserSkillSetData(userId, programId);
    return !!targetSkillProfileId && userSkillSetData?.[targetSkillProfileId] === SKILLSET_DISMISSED_STATE;
  };
  return (
    <div>
      {showSaveSkillSetBanner && !isDismissedSkillSetBanner() && (
        <SaveSkillSetBanner
          productCount={productCount}
          userId={userId}
          programId={programId}
          targetSkillProfileId={targetSkillProfileId}
          programSlug={programSlug}
          onSkillSetSaveToggle={onSkillSetSaveToggle}
        />
      )}
      <ProductEnrolledCard
        programId={programId}
        product={product}
        userId={userId}
        isGrouped
        thirdPartyOrganizationId={thirdPartyOrganizationId}
      />
    </div>
  );
};

export default ProductEnrolledCard;
