import * as React from 'react';
import { graphql } from 'react-apollo';

import type { EnterpriseSkills_Skillset as Skillset } from '__generated__/graphql-types';
import PropTypes from 'prop-types';
import { compose, getContext, setDisplayName } from 'recompose';

import Retracked from 'js/lib/retracked';
import type { EventData, WithTrackingData } from 'js/lib/retracked';
import { useRouter } from 'js/lib/useRouter';

import type { PropsFromCaller } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetsTabContent';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import type { org_coursera_skill_GoalType as GoalType } from 'bundles/program-home/components/enterprise-home/__generated__/globalTypes';
import GetAllOccupationsQuery from 'bundles/program-home/queries/GetAllOccupations.graphql';
import type {
  GetAllOccupationsQuery as GetAllOccupationsQueryData,
  GetAllOccupationsQueryVariables,
} from 'bundles/program-home/queries/__generated__/GetAllOccupations';

type PropsFromGraphql = {
  skillsetsDGS: Skillset[];
  loading: boolean;
  error: boolean;
};

export type AllOccupations = Record<string, string | undefined>;

type PropsFromOccupationMetadataQuery = {
  allOccupations: AllOccupations;
};

export type SkillNames = Record<string, string | undefined>;

export type FilterOptions = {
  roles: Set<string>;
  skills: Set<string>;
  goals: Set<GoalType>;
};

export type FilterSelections = {
  role: string;
  skills: string;
  goal: string;
};

export type FilterChangeHandler = (newFilterSelections: FilterSelections) => void;

export type SelectOption = {
  value: string;
  label: string;
};

type PropsFromGetContext = {
  _eventData: EventData;
  _withTrackingData: WithTrackingData;
};

type FilterInputChangeHandler = (e: string, type: 'role' | 'skill' | 'goal') => void;

// TODO vraviraj: condense these props
export type PropsFromSkillSetFiltersHOC = {
  filterOptions?: FilterOptions;
  allOccupations?: AllOccupations;
  skillNames?: SkillNames;
  filterSelections?: FilterSelections;
  isFilterSelected: boolean;
  onRoleChange?: (selectedOption: SelectOption) => void;
  onSkillsChange?: (selectedOptions: SelectOption[]) => void;
  onGoalChange?: (selectedOption: SelectOption) => void;
  onFilterReset?: () => void;
  onFilterInputChange?: FilterInputChangeHandler;
};

export type Props = PropsFromCaller & PropsFromSkillSetFiltersHOC & PropsFromGraphql & PropsFromOccupationMetadataQuery;

type Props0 = Props & PropsFromGetContext;

export function filter(
  skillSets: Skillset[],
  { role, goal, skills }: FilterSelections
): {
  filteredSkillSets: Skillset[];
  filterOptions: FilterOptions;
  skillNames: SkillNames;
  isFilterSelected: boolean;
} {
  const filteredSkillSets: Skillset[] = [];
  const skillNames: SkillNames = {};
  const filterOptions: FilterOptions = {
    roles: new Set(),
    skills: new Set(),
    goals: new Set(),
  };
  skillSets.forEach((skillSet) => {
    const { goalType, occupation, skillProficiencyTargets } = skillSet;

    const skillNodes = skillProficiencyTargets?.edges?.map((_) => _.node) ?? [];

    const matchesRole = role === '' || role === occupation?.id;
    const matchesGoal = goal === '' || goal === goalType;
    const matchesSkills = skills.length === 0 || skillNodes.some(({ id }) => skills.includes(id));

    if (occupation?.id && matchesGoal && matchesSkills) {
      filterOptions.roles.add(occupation?.id);
    }

    if (goalType && matchesRole && matchesSkills) {
      filterOptions.goals.add(goalType as GoalType);
    }

    if (matchesRole && matchesGoal) {
      skillNodes.forEach(({ id, skillName }) => {
        filterOptions.skills.add(id);
        skillNames[id] = skillName;
      });
    }

    if (matchesRole && matchesGoal && matchesSkills) {
      filteredSkillSets.push(skillSet);
    }
  });
  return { filteredSkillSets, filterOptions, skillNames, isFilterSelected: Boolean(role || goal || skills.length) };
}

const SkillSetFiltersHOCDGS = (Component: React.ComponentType<Props>) => {
  const SkillSetFilterHOC0 = ({ skillsetsDGS: skillSets, _eventData, _withTrackingData, ...props }: Props0) => {
    const router = useRouter();
    const routeRoleSelection = router.location.query.role || '';
    const routeGoalSelection = router.location.query.goal || '';
    const routeSkillsSelections = router.location.query.skills || '';
    const filterSelections: FilterSelections = {
      role: routeRoleSelection,
      goal: routeGoalSelection,
      skills: routeSkillsSelections === '' ? [] : routeSkillsSelections.split(','),
    };

    const onRoleChange = (newSelection: SelectOption | null) => {
      if (newSelection == null) {
        return;
      }
      const { value } = newSelection;
      Retracked.trackComponent(
        _eventData,
        { skillSetFilters: { ...filterSelections, role: value } },
        'skillset_filters_role_selection',
        'change',
        _withTrackingData
      );
      router.replace({
        ...router.location,
        query: {
          ...router.location.query,
          role: value,
        },
      });
    };

    const onSkillsChange = (newSelection: SelectOption[]) => {
      const selectedSkills = newSelection.map(({ value }) => value);
      Retracked.trackComponent(
        _eventData,
        { skillSetFilters: { ...filterSelections, skills: selectedSkills } },
        'skillset_filters_skills_selection',
        'change',
        _withTrackingData
      );
      router.replace({
        ...router.location,
        query: {
          ...router.location.query,
          skills: selectedSkills.length ? selectedSkills.join(',') : undefined,
        },
      });
    };

    const onGoalChange = (newSelection: SelectOption | null) => {
      if (newSelection == null) {
        return;
      }
      const { value } = newSelection;
      Retracked.trackComponent(
        _eventData,
        { skillSetFilters: { ...filterSelections, goal: value } },
        'skillset_filters_goal_selection',
        'change',
        _withTrackingData
      );
      router.replace({
        ...router.location,
        query: {
          ...router.location.query,
          goal: value,
        },
      });
    };

    const onFilterReset = () => {
      router.replace({
        ...router.location,
        query: {
          ...router.location.query,
          role: undefined,
          skills: undefined,
          goal: undefined,
        },
      });
    };

    const onFilterInputChange: FilterInputChangeHandler = (e, type) => {
      // Checking that e is not an empty string because, when a filter option is selected, it results in this event being
      // sent as an empty string whether there was typed input or not. See a more detailed explanation here:
      // https://github.com/webedx-spark/web/pull/15022#discussion_r766820241
      if (e !== '') {
        const propertyName = type + 'Input';
        Retracked.trackComponent(
          _eventData,
          { skillSetFilters: { ...filterSelections, [propertyName]: e } },
          'skillset_filters_input_typing',
          'change',
          _withTrackingData
        );
      }
    };

    const { filteredSkillSets, filterOptions, skillNames, isFilterSelected } = filter(
      skillSets ?? [],
      filterSelections
    );

    return (
      <Component
        {...props}
        skillsetsDGS={filteredSkillSets}
        filterOptions={filterOptions}
        skillNames={skillNames}
        filterSelections={filterSelections}
        onRoleChange={onRoleChange}
        onSkillsChange={onSkillsChange}
        onGoalChange={onGoalChange}
        onFilterReset={onFilterReset}
        onFilterInputChange={onFilterInputChange}
        isFilterSelected={isFilterSelected}
      />
    );
  };
  return SkillSetFilterHOC0;
};

export const enhancer = compose<Props, PropsFromCaller>(
  setDisplayName('SkillSetFiltersHOCDGS'),
  graphql<
    PropsFromCaller,
    GetAllOccupationsQueryData,
    GetAllOccupationsQueryVariables,
    PropsFromOccupationMetadataQuery
  >(GetAllOccupationsQuery, {
    options: { ssr: false, context: { clientName: 'gatewayGql' } },
    props: ({ data }): PropsFromOccupationMetadataQuery => {
      const occupations = filterExistsOrDefault(data?.Occupations?.getAllOccupations?.elements);
      const allOccupations = occupations.reduce((memo, { id, name }) => {
        // eslint-disable-next-line no-param-reassign
        memo[id] = name;
        return memo;
      }, {} as AllOccupations);
      return { allOccupations };
    },
  }),
  getContext({
    _eventData: PropTypes.object,
    _withTrackingData: PropTypes.func,
  }),
  SkillSetFiltersHOCDGS,
  Retracked.createTrackedContainer<Props>(({ filterSelections }) => ({ skillSetFilters: filterSelections }))
);

export default enhancer;
