import * as React from 'react';

import { Typography } from '@coursera/cds-core';
import { StyleSheet, css, fontFamily } from '@coursera/coursera-ui';

const styles = StyleSheet.create({
  captionCommon: {
    color: '#335475',
    fontWeight: 'bold',
    fontFamily: fontFamily.bold,
    fontSize: '1rem',
    lineHeight: '1.875rem',
  },

  howItWorksCaption: {
    margin: '0rem',
  },

  captionHowToStart: {
    margin: '1.125rem 0',
  },

  cardTitle: {
    color: '#335475',
    fontWeight: 'normal',
    fontFamily: fontFamily.body,
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    margin: 0,
  },
});

/*
 * hierarchy assumptions:
 * - page has h1
 * - card title is h2
 * - card subheadings are h3
 */

export const CardTitle: React.FC = (props) => <h2 {...css(styles.cardTitle)}>{props.children}</h2>;

export const CardTitleH1: React.FC = (props) => (
  <Typography
    variant="h1"
    component="h2"
    css={css`
      color: #335475;
      margin: 0;
    `}
  >
    {props.children}
  </Typography>
);

export const HowItWorksCaption: React.FC = (props) => (
  <h3 {...css(styles.captionCommon, styles.howItWorksCaption)}>{props.children}</h3>
);

export const HowToStartCaption: React.FC = (props) => (
  <h3 {...css(styles.captionCommon, styles.captionHowToStart)}>{props.children}</h3>
);

export const SkillCaption: React.FC = (props) => (
  <Typography
    variant="h2"
    component="h3"
    css={css`
      margin: var(--cds-spacing-300) 0;
    `}
  >
    {props.children}
  </Typography>
);
