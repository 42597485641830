import { getAllProducts } from 'bundles/enterprise-collections/components/getCollectionItemList';
import type { Product } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import { LEADERSHIP_RECS_COLLECTION_ID, PRODUCT_RECS_COLLECTION_ID } from 'bundles/program-personalized-tab/constants';
import type { EnterpriseCourseRecommendationsForSkillId as EnterpriseCourseRecommendationsForSkillIdQueryData } from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseCourseRecommendationsForSkillId';
import type { EnterpriseS12nRecommendationsForSkillId as EnterpriseS12nRecommendationsForSkillIdQueryData } from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseS12nRecommendationsForSkillId';
import type { CollectionTypeForRecsTab, ProductWithMetadata } from 'bundles/program-personalized-tab/types/sharedTypes';

const getCollectionId = (areLeadershipRecs?: boolean) =>
  areLeadershipRecs ? LEADERSHIP_RECS_COLLECTION_ID : PRODUCT_RECS_COLLECTION_ID;

export const courseRecsDataToCollectionType = (
  data?: EnterpriseCourseRecommendationsForSkillIdQueryData,
  areLeadershipRecs?: boolean
): CollectionTypeForRecsTab => {
  // TODO: There's probably a cleaner way to write this but the types are giving me grief for now
  // Maybe Object.keys()...
  const collectionResults = [
    ...(data?.ProgramCurriculumCollectionsV1Resource?.courseRecs1?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.courseRecs2?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.courseRecs3?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.courseRecs4?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.courseRecs5?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.courseRecs6?.elements || []),
  ];

  const allRecs = collectionResults.map((collection) => {
    if (collection?.courses) {
      const coursesWithCollectionTrackingId = collection.courses.elements.map((course) => {
        if (!course) {
          return null;
        }
        return {
          ...course,
          collectionTrackingId: collection.collectionTrackingId,
        };
      });

      return getAllProducts({ ...collection, courses: { elements: coursesWithCollectionTrackingId }, s12ns: null });
    }
    return null;
  });

  const roundRobinRecs: ProductWithMetadata[] = [];
  const maxLength = Math.max(...allRecs.map((item) => item?.length || 0));
  const ids: string[] = [];

  for (let i = 0; i < maxLength; i += 1) {
    for (const collection of allRecs) {
      if (collection?.[i] && !ids.includes(collection[i].id) && collection[i].isCourse) {
        roundRobinRecs.push(collection[i].product);
        ids.push(collection[i].id);
      }
    }
  }

  const collection: CollectionTypeForRecsTab = {
    ...collectionResults[0],
    id: getCollectionId(areLeadershipRecs),
    title: '',
    trackId: getCollectionId(areLeadershipRecs),
    description: {},
    courses: { elements: roundRobinRecs },
    courseIds: ids,
    s12ns: null,
  };

  return collection;
};

// Keeping this function separate from the course one above, because while they are currently
// similar, we will change the source for s12n recs later.
export const s12nRecsDataToCollectionType = (
  data?: EnterpriseS12nRecommendationsForSkillIdQueryData,
  areLeadershipRecs?: boolean
): CollectionTypeForRecsTab => {
  // TODO: There's probably a cleaner way to write this but the types are giving me grief for now
  // Maybe Object.keys()...
  const collectionResults = [
    ...(data?.ProgramCurriculumCollectionsV1Resource?.s12nRecs1?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.s12nRecs2?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.s12nRecs3?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.s12nRecs4?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.s12nRecs5?.elements || []),
    ...(data?.ProgramCurriculumCollectionsV1Resource?.s12nRecs6?.elements || []),
  ];

  const allRecs = collectionResults.map((collection) => {
    if (collection?.s12ns) {
      const s12nsWithLogoAndCollectionTrackingId: Array<Product | null> = (collection.s12ns?.elements ?? [])?.map(
        (element) => {
          if (!element) {
            return null;
          }
          return {
            ...element,
            promoPhoto: element?.logo ?? '',
            collectionTrackingId: collection.collectionTrackingId,
          };
        }
      );

      return getAllProducts({
        ...collection,
        s12ns: { elements: s12nsWithLogoAndCollectionTrackingId },
        courses: null,
      });
    }

    return null;
  });

  const roundRobinRecs: Product[] = [];
  const maxLength = Math.max(...allRecs.map((item) => item?.length || 0));
  const ids: string[] = [];

  for (let i = 0; i < maxLength; i += 1) {
    for (const collection of allRecs) {
      if (collection?.[i] && !ids.includes(collection[i].id) && !collection[i].isCourse) {
        roundRobinRecs.push(collection[i].product);
        ids.push(collection[i].id);
      }
    }
  }

  const collection: CollectionTypeForRecsTab = {
    ...collectionResults[0],
    id: getCollectionId(areLeadershipRecs),
    title: '',
    trackId: getCollectionId(areLeadershipRecs),
    description: {},
    courses: null,
    s12ns: { elements: roundRobinRecs },
    s12nIds: ids,
    proCerts: { elements: [] },
  };

  return collection;
};
