/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { AiCoach_Action as AiCoachAction } from '__generated__/graphql-types';
import type { AiCoach_CoachActionConfig as AiCoachCoachActionConfig } from '__generated__/graphql-types';

import { Grid, Typography2 } from '@coursera/cds-core';

import { COACH_RECOMMENDED_ACTION_CLICK } from 'bundles/ai-coach-platform/components/patterns/chat/constants';
import { RecommendedActionButton } from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/RecommendedActionButton';
import type { SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import sessionStorageEx from 'bundles/common/utils/sessionStorageEx';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  container: css`
    margin: var(--cds-spacing-200) 0 var(--cds-spacing-200);
  `,
  flexContainer: css`
    gap: var(--cds-spacing-100);
  `,
  label: css`
    margin-bottom: var(--cds-spacing-150);
  `,
};

type Props = {
  recommendedActions: AiCoachCoachActionConfig[];
  sendMessage: SendMessageFunc;
  timestamp: Date;
};

export const RecommendedActions = ({
  recommendedActions,
  sendMessage,
  timestamp,
}: Props): React.ReactElement | null => {
  const storedTimestampOfClickedMessage = sessionStorageEx.getItem<string>(
    COACH_RECOMMENDED_ACTION_CLICK,
    (value) => value,
    ''
  );

  const isRecommendedAction = React.useMemo(
    () => (recommendedActions || []).every((obj) => obj.coachActionId === AiCoachAction.GenericAction),
    [recommendedActions]
  );

  // convert learner click timestamp string to date object
  const dateOfClickedMessage = new Date(storedTimestampOfClickedMessage);

  const isLearnerClickTSBeforeMsgTS = timestamp.getTime() <= dateOfClickedMessage.getTime();

  const actionNotClicked = !storedTimestampOfClickedMessage;
  const showPlaceholderText = actionNotClicked || isLearnerClickTSBeforeMsgTS;

  if (!recommendedActions?.length) {
    return null;
  }

  return (
    <Grid container css={styles.container}>
      {isRecommendedAction && showPlaceholderText && (
        <Typography2 component="p" css={styles.label}>
          {_t('And if you want to continue exploring this topic, try one of these follow-up questions:')}
        </Typography2>
      )}
      <Grid xs={12} container item css={styles.flexContainer} wrap="wrap">
        {recommendedActions.map(({ actionLabel, coachActionId }) => (
          <RecommendedActionButton
            action={coachActionId}
            actionMessage={actionLabel}
            key={`${coachActionId}-${actionLabel}`}
            sendMessage={sendMessage}
            actionNotClicked={actionNotClicked}
            isRecommendedAction={isRecommendedAction}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default RecommendedActions;
