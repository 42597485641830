import * as React from 'react';

import type UserAgentInfo from 'js/lib/useragent';

import { CML } from 'bundles/cml';
import type { GraphQLCML } from 'bundles/collections-tool/types/collectionTypesSU';
import { getCMLShape } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import AboutSectionTogglableContent from 'bundles/enterprise-legacy-xdp/components/sdp/AboutSectionTogglableContent';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/sdp/__styles__/AppliedLearningProject';

type Props = {
  cmlProjectsOverview?: GraphQLCML;
  htmlProjectsOverview?: string;
  skillsAndLearningObjExist?: boolean;
  userAgent: UserAgentInfo;
};

const getCMLElement = (cml?: { value: string; dtdId: string }) => {
  const cmlReactElement = cml && (
    <CML
      cml={getCMLShape(cml)}
      shouldRenderMath={false}
      shouldRenderCode={false}
      shouldRenderAssets={false}
      isCdsEnabled
    />
  );

  return cmlReactElement;
};

const AppliedLearningProject: React.FC<Props> = ({
  cmlProjectsOverview,
  skillsAndLearningObjExist,
  userAgent,
}: Props) => {
  if (!cmlProjectsOverview) {
    return null;
  }

  const projectsOverview = getCMLElement(cmlProjectsOverview?.cml);
  const title = _t('Applied Learning Project');

  return (
    <TrackedDiv
      className="rc-AppliedLearningProject m-t-1 m-b-3"
      data-e2e="applied-learning-project"
      trackingName="applied_project_description"
      withVisibilityTracking={true}
    >
      <h2 className="m-r-1 m-b-1 m-b-0 bold headline-4-text about-subheader">{title}</h2>

      <div className="applied-project-description-container">
        <AboutSectionTogglableContent
          renderedContentClassName="applied-project-description"
          skillsAndLearningObjExist={skillsAndLearningObjExist}
          userAgent={userAgent}
          trackingName="applied_project_description"
        >
          {projectsOverview}
        </AboutSectionTogglableContent>
      </div>
    </TrackedDiv>
  );
};

export default AppliedLearningProject;
