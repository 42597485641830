/* eslint-disable camelcase */
import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import {
  EnterpriseBadgeCollectionsViewPlaceholder,
  EnterpriseBadgeComponent,
} from 'bundles/enterprise-legacy-learner-home/components/single-program/EnterpriseBadgeComponent';
import type { EnterpriseBadgeWithCollection } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

export type EnterpriseBadgeEnrollInput = {
  input: { user_id: number; badge_template_id: string; request_origin: string };
};

type PropsFromCaller = {
  userId: number;
  programId: string;
  programSlug: string;
  enterpriseBadgesWithCollection: Array<EnterpriseBadgeWithCollection>;
  loading: boolean;
  isProgramMember: boolean;
  onProductCardClick: OnProductCardClick;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

export type Props = PropsFromCaller;

export function EnterpriseBadgeCollectionsView({
  enterpriseBadgesWithCollection,
  userId,
  programId,
  programSlug,
  loading,
  isProgramMember,
  onProductCardClick,
  refetchEnterpriseBadgeCollections,
}: Props) {
  React.useEffect(() => {
    if (document.location.hash && !loading) {
      const el = document.getElementById(document.location.hash.slice(1));
      el?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [loading]);

  if (loading) {
    return <EnterpriseBadgeCollectionsViewPlaceholder />;
  }

  return (
    <div>
      {enterpriseBadgesWithCollection.map((enterpriseBadgeWithCollection) => {
        return (
          <EnterpriseBadgeComponent
            key={enterpriseBadgeWithCollection.enterpriseBadge.badgeTemplateId}
            enterpriseBadgeWithCollection={enterpriseBadgeWithCollection}
            userId={userId}
            programId={programId}
            programSlug={programSlug}
            onProductCardClick={onProductCardClick}
            refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
            isProgramMember={isProgramMember}
          />
        );
      })}
    </div>
  );
}

export default EnterpriseBadgeCollectionsView;
