import type { Maybe } from '__generated__/graphql-types';

import type { CollectionListItemFragmentFragment } from 'bundles/enterprise-collections/queries/fragments/__generated__/CollectionListItemFragment';
import type { CollectionListItemSkillsMetaFragmentFragment } from 'bundles/enterprise-collections/queries/fragments/__generated__/CollectionListItemSkillsMetaFragment';
import type { CollectionListItemStruct, Item } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import type {
  CourseElement,
  S12nElement,
} from 'bundles/program-skills-objectives/components/ProgramSkillRecommendationsPage/generateFilterDropdownOptions';

import _t from 'i18n!nls/program-skills-objectives';

type CollectionListItemFragmentType = Maybe<
  { __typename?: 'ProgramCurriculumCollectionsV1' } & CollectionListItemFragmentFragment
>;
type CollectionListItemSkillsMetaFragmentType = Maybe<
  { __typename?: 'ProgramCurriculumCollectionsV1' } & CollectionListItemSkillsMetaFragmentFragment
>;

type StructDataSource = undefined | CollectionListItemFragmentType | CollectionListItemSkillsMetaFragmentType;

/**
 * TODO remove this check in favor of a simple strict comparison
 * after skill id format becomes consistent across learner/enterprise apis.
 */
export const isSkillIdEqual = (a?: string, b?: string) => a?.split('~')[0] === b?.split('~')[0];

export const convertLearnerDiagnosticIdToBaseSkillId = (a: string) => a.split('~')[1] ?? a;

export const convertTSPSkillIdToBaseSkillId = (a: string) => a.split('~')[0] ?? a;

export const convertVersionedSkillIdToBaseSkillId = (a: string) => a.split('@')[0] ?? a;

export const MAX_SCORE_CONVERSANT = 50;
export const MAX_SCORE_BEGINNER = 150;
export const MAX_SCORE_INTERMEDIATE = 350;
export const MAX_SCORE_ADVANCED = 500;
export const SKILL_LEVEL_SEGMENTS = [MAX_SCORE_CONVERSANT, MAX_SCORE_BEGINNER, MAX_SCORE_INTERMEDIATE];

export type SkillsetLevel = 'conversant' | 'beginner' | 'intermediate' | 'advanced';

export const getCurrentLevel = (score: number): SkillsetLevel => {
  if (score <= MAX_SCORE_CONVERSANT) return 'conversant';
  if (score <= MAX_SCORE_BEGINNER) return 'beginner';
  if (score <= MAX_SCORE_INTERMEDIATE) return 'intermediate';
  return 'advanced';
};

export const getLevelNames = (): { [level in SkillsetLevel]: string } => ({
  conversant: _t('Conversant'),
  beginner: _t('Beginner'),
  intermediate: _t('Intermediate'),
  advanced: _t('Advanced'),
});

export const getLevelName = (score: number) => getLevelNames()[getCurrentLevel(score)];

// For each skill with score increase, display skill score with score increase from this assessment and competency statement about new level

export const getLevelDescription = (score: number) => {
  return {
    conversant: _t(
      'Keep going! At a conversant level, you have familiarity with the vocabulary and are able to pass easy content.'
    ),
    beginner: _t(
      'Keep going! At a beginner level, you have a working knowledge and are able to pass beginner content. You have limited experience applying it.'
    ),
    intermediate: _t(
      'Well done! At an intermediate level, you have a solid understanding of the material and are able to pass intermediate content. You can apply key concepts on most tasks.'
    ),
    advanced: _t(
      'Well done! At an advanced level, you have a mastery of the material and are able to pass advanced content. You can both teach others and identify novel applications of this skill.'
    ),
  }[getCurrentLevel(score)];
};

export const castToProduct = (element: CourseElement | S12nElement) => {
  // This conversion has been created to more closely reflect our data shape,
  // but also still meet the existing interface of browse utilities.
  const maybeCourseIds: undefined | string[] =
    'courseIds' in element
      ? element.courseIds?.filter((val) => !!val)?.map<string>((cid) => String(cid)) ?? undefined
      : undefined;
  return {
    ...element,
    courseIds: maybeCourseIds,
  };
};

export const castToStruct = (collection: StructDataSource) => {
  const struct: CollectionListItemStruct = {
    ...collection,
    __typename: collection?.__typename,
    id: collection?.id ?? '',
    title: collection?.title ?? '',
    image: collection?.image ?? null,
    collectionTrackingId: collection?.collectionTrackingId ?? '',
    courseIds: collection?.courseIds?.map((v) => String(v)) ?? [],
    s12nIds: collection?.s12nIds?.map((v) => String(v)) ?? [],
    description: collection?.description ?? {
      cml: undefined,
    },
    trackId: collection?.trackId ?? '',
    courses: collection?.courses ?? null,
    // @ts-expect-error Missing field is valid for s12ns
    s12ns: collection?.s12ns ?? null,
    items: collection?.items?.map((v) => v as Item) ?? [],
    collectionContextMetadata: (collection as CollectionListItemSkillsMetaFragmentType)
      ?.collectionContextMetadata as CollectionListItemStruct['collectionContextMetadata'],
    associatedSessions: collection?.associatedSessions,
  };

  return struct;
};
