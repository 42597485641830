/* eslint-disable camelcase */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { languageCodeToName } from 'js/lib/language';

import { Grid, Typography2, breakpoints } from '@coursera/cds-core';

import { CML } from 'bundles/cml';
import type { CourseType as CourseTypeName } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';
import { getNaptimeCMLShape } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import type { LogoIconType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/BannerInfoLogoText';
import BannerInfoLogoText from 'bundles/enterprise-legacy-xdp/components/miniPDP/BannerInfoLogoText';
import BannerInfoText from 'bundles/enterprise-legacy-xdp/components/miniPDP/BannerInfoText';

/* migrate */
import type {
  BannerInfoBox as BannerInfoBoxType,
  BannerInfoBox_cmlLearningObjectives,
  BannerInfoBox_cmlLearningObjectives_cml as LearningObjectivesCML,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/BannerInfoBox';
import type { org_coursera_ondemand_common_DifficultyLevel as DifficultyLevel } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/globalTypes';
import usePageData from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/usePageData';
import withFragments from 'bundles/graphql/components/withFragments';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type LogoTextType = {
  logo: LogoIconType;
  text: string;
  hoverText?: React.ReactNode;
};

type PropsFromCaller = Omit<BannerInfoBoxType, '__typename'> & {
  canEnrollWithFullDiscount?: boolean;
};

type PropsToComponent = PropsFromCaller;

const styles = {
  container: css`
    background: white;
    padding: 24px;
    font-size: 14px;
    line-height: 24px;
  `,
  summaryContainer: css`
    width: 100%;

    @media (max-width: ${breakpoints.values.md - 1}px) and (min-width: ${breakpoints.values.sm}px) {
      border-right: 1px solid var(--cds-color-grey-300);
    }

    @media (max-width: ${breakpoints.values.sm - 1}px) {
      border: 0;
    }
  `,
  summary: css`
    border-bottom: 1px solid var(--cds-color-grey-300);
    width: 100%;

    @media (max-width: ${breakpoints.values.md - 1}px) and (min-width: ${breakpoints.values.sm}px) {
      border: 0;
    }

    @media (max-width: ${breakpoints.values.sm - 1}px) {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--cds-color-grey-300);
    }
  `,
  bannerInfoTextContainer: css`
    margin: 12px 0 0;
    padding-bottom: 12px;
    width: 100%;
  `,
  bannerInfoLogoTextColumn: css`
    width: 100%;

    @media (max-width: ${breakpoints.values.md - 1}px) {
      &:nth-child(2n) {
        padding-top: 12px;
      }
    }
  `,
  bannerInfoLogoTextContainer: css`
    width: 100%;
    margin-top: 24px;

    @media (max-width: ${breakpoints.values.md - 1}px) and (min-width: ${breakpoints.values.sm}px) {
      margin-top: 0;
    }

    @media (max-width: ${breakpoints.values.sm - 1}px) {
      margin-top: 16px;
    }
  `,
};

export const LEVEL_TO_ICON = {
  BEGINNER: 'BarChartOneFillIcon',
  INTERMEDIATE: 'BarChartTwoFillIcon',
  ADVANCED: 'BarChartThreeFillIcon',
} as const;

const getLevelToString = (level: DifficultyLevel, canEnrollWithFullDiscount?: boolean): string => {
  const beginnerLevelText = canEnrollWithFullDiscount ? _t('Beginner-friendly') : _t('Beginner');
  const levels = {
    BEGINNER: beginnerLevelText,
    INTERMEDIATE: _t('Intermediate'),
    ADVANCED: _t('Advanced'),
  };
  return levels[level];
};

const concatenateLanguages = (languageCodes: string[]): string => {
  return languageCodes.length > 0 ? languageCodes.map(languageCodeToName).join(', ') : '';
};

/* TODO(dle): Commenting out for now. Use this when we want to add subtitles info.
const getLanguageInfo = (primaryLanguages: string[], subtitleLanguages: string[]): string => {
  const languages = concatenateLanguages(primaryLanguages);
  return subtitleLanguages.length > 0 ? _t('#{languageList} + subtitles', { languageList: languages }) : languages;
};

const getSubtitleTooltip = (subtitleLanguages: string[]): string | undefined => {
  const languages = concatenateLanguages(subtitleLanguages);
  return subtitleLanguages.length > 0 ? _t('Subtitles: #{subtitleList}', { subtitleList: languages }) : undefined;
};
*/
type ConstructInfoLogoTextArgs = {
  cmlRecommendedBackground: { cml: { dtdId: string; value: string } } | null;
  level: DifficultyLevel | null;
  courseType: CourseTypeName;
  primaryLanguages: Array<string>;
  workload: string | null;
  canEnrollWithFullDiscount?: boolean;
};

const constructInfoLogoText = ({
  level,
  courseType,
  cmlRecommendedBackground,
  workload,
  primaryLanguages,
  canEnrollWithFullDiscount,
}: ConstructInfoLogoTextArgs): Array<LogoTextType> => {
  const infoLogoText: Array<LogoTextType> = [];
  if (workload) {
    infoLogoText.push({ logo: 'ClockOutlineIcon', text: workload });
  }
  if (level) {
    const hoverTextCML = cmlRecommendedBackground?.cml && (
      <CML cml={getNaptimeCMLShape(cmlRecommendedBackground.cml)} />
    );
    infoLogoText.push({
      logo: LEVEL_TO_ICON[level],
      text: getLevelToString(level, canEnrollWithFullDiscount),
      hoverText: hoverTextCML,
    });
  }
  infoLogoText.push({ logo: 'CloudDownloadIcon', text: _t('No download needed') });
  if (courseType === 'Project') {
    infoLogoText.push({ logo: 'CertificateIcon', text: _t('Shareable certificate') });
  } else {
    infoLogoText.push({ logo: 'VideoIcon', text: _t('Split-screen video') });
  }
  infoLogoText.push({
    logo: 'CommentDotsIcon',
    text: concatenateLanguages(primaryLanguages),
  });
  infoLogoText.push({ logo: 'LaptopIcon', text: _t('Desktop only') });
  return infoLogoText;
};

const getInfoLogoTextColumns = (infoLogoText: Array<LogoTextType>): Array<Array<LogoTextType>> => {
  const length = infoLogoText.length;
  const midPoint = Math.ceil(length / 2); // use ceil to ensure first column is longer for odd number of elements
  return [infoLogoText.slice(0, midPoint), infoLogoText.slice(midPoint, length)];
};

const getLearningObjectives = (
  cmlLearningObjectives: BannerInfoBox_cmlLearningObjectives[],
  canEnrollWithFullDiscount: boolean | undefined
): BannerInfoBox_cmlLearningObjectives[] => {
  const extraObjectiveForFreeGuidedProjects = {
    __typename: 'XdpV1_cmlMember',
    cml: {
      __typename: 'XdpV1_org_coursera_ondemand_models_CmlContentType',
      dtdId: 'course/1',
      // This is an exception to the warning here, we need to add the CML tags to wrap
      // around the translated string.
      // eslint-disable-next-line no-restricted-syntax
      value: '<co-content><text>' + _t('Showcase this hands-on experience in an interview') + '</text></co-content>',
    } as LearningObjectivesCML,
  } as BannerInfoBox_cmlLearningObjectives;

  if (canEnrollWithFullDiscount) {
    return [...cmlLearningObjectives, extraObjectiveForFreeGuidedProjects];
  }
  return cmlLearningObjectives;
};

export const BannerInfoBox: React.FunctionComponent<PropsToComponent> = ({
  cmlRecommendedBackground,
  cmlLearningObjectives,
  primaryLanguages,
  workload,
  level,
  canEnrollWithFullDiscount,
}) => {
  const { bannerInfoBoxViewController, pageViewController } = usePageData();
  const infoLogoText = constructInfoLogoText({
    level: level as DifficultyLevel,
    courseType: pageViewController.courseType,
    cmlRecommendedBackground,
    workload,
    primaryLanguages,
    canEnrollWithFullDiscount,
  });

  return (
    <div css={styles.container}>
      <Grid container>
        <Grid item sm={7} md={12} css={styles.summaryContainer}>
          <div css={styles.summary}>
            <Typography2 variant="bodySecondary" component="span">
              <FormattedMessage
                message={_t('In this {guidedProject}, you will:')}
                guidedProject={<strong>{bannerInfoBoxViewController.productTitle}</strong>}
              />
            </Typography2>
            <div css={styles.bannerInfoTextContainer} role="list">
              {getLearningObjectives(cmlLearningObjectives, canEnrollWithFullDiscount).map(({ cml }) => (
                <BannerInfoText key={`banner-info-text-${cml.dtdId}`} cml={cml} />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item sm={5} md={12}>
          <Grid container css={styles.bannerInfoLogoTextContainer}>
            {getInfoLogoTextColumns(infoLogoText).map((infoLogoTextColumn, colIndex) => (
              <Grid
                item
                md={6}
                // eslint-disable-next-line react/no-array-index-key
                key={`banner-info-logo-text-column-${colIndex}`}
                css={styles.bannerInfoLogoTextColumn}
              >
                {infoLogoTextColumn.map((info) => (
                  <BannerInfoLogoText
                    key={`banner-info-logo-text-${info.logo}`}
                    logo={info.logo}
                    value={info.text}
                    hoverText={info.hoverText}
                  />
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withFragments({
  BannerInfoBox: gql`
    fragment BannerInfoBox on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      level
      workload
      subtitleLanguages
      primaryLanguages
      avgLearningHoursAdjusted
      cmlRecommendedBackground {
        ... on XdpV1_cmlMember {
          cml {
            value
            dtdId
          }
        }
      }
      cmlLearningObjectives {
        ... on XdpV1_cmlMember {
          cml {
            value
            dtdId
          }
        }
      }
    }
  `,
})(BannerInfoBox);
