/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import Authoring from 'bundles/epic/data/defaults/Authoring.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  enableFullStory: boolean;
  enablePendo: boolean;
  enabledItemSharingTypes: any;
  experimentalItemSharingTypes: [] | ['discussionPrompt', 'gradedDiscussionPrompt', 'ungradedWidget'];
  enableGradebookRelease: boolean;
  enabledItemAuthoringFrameworkTypes: any;
  enableCourseOfferings: boolean;
  enableSessionStaff: boolean;
  enabledCompareItemVersionTypes: any;
  enableExperimentalCourseBuilderItemTypes: boolean;
  enablePluginUpdatedUi: boolean;
  enableV2PluginItemAuthoring: boolean;
  enableV2PluginManager: boolean;
  enableCourseOutlineSummaryExports: boolean;
  enableWhisperTranscriptionFlowFE: boolean;
  enableAiGuidedReview: boolean;
  enableVideoTrimming: boolean;
  enableOutlineGenerationFromScratch: boolean;
  enableExportItemFeedback: boolean;
  enableCoachForAuthors: boolean;
  isFullStoryEnabledForAuthoringCoachItems: boolean;
  isFullStoryEnabledForAuthoringProgrammingAssignmentItems: boolean;
  isFullStoryEnabledForAuthoringUngradedLabItems: boolean;
  enableCommonCartridgeSmartIngestion: boolean;
  enableCbAutoSplit: boolean;
};

const NAMESPACE = 'Authoring';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([Authoring as $TSFixMe]);

const AuthoringEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default AuthoringEpicClient;
