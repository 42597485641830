import * as React from 'react';

import gql from 'graphql-tag';

import Imgix from 'js/components/Imgix';

import { StyleSheet, color, css } from '@coursera/coursera-ui';

import ExpandingCarousel from 'bundles/enterprise-legacy-xdp/components/miniPDP/ExpandingCarousel';
import withFragments from 'bundles/graphql/components/withFragments';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

// At 608px page width, the Carousel is at its maximum width, which is 551px. Make it 552 so it's divisible by 4.
const MAX_WIDTH = 552;
const MAX_HEIGHT = (MAX_WIDTH / 4) * 3; // 4:3 aspect ratio

const styles = StyleSheet.create({
  root: {
    paddingBottom: '32px', // account for dots, which are positioned absolutely
  },
  screenShot: {
    border: `1px solid ${color.divider}`,
    width: '100%',
  },
  largeScreenShots: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
});

export type Props = {
  screenshotUrls: string[];
  canExpand?: boolean;
};

const Screenshots: React.FunctionComponent<Props> = ({ screenshotUrls, canExpand = true }) => {
  return (
    <div {...css(styles.root)}>
      <ExpandingCarousel
        prevArrowLabel={_t('go to previous screenshot')}
        nextArrowLabel={_t('go to next screenshot')}
        expandItemLabel={_t('Expand screenshot')}
        canExpand={canExpand}
      >
        {(isExpandedView) =>
          screenshotUrls.map((screenshotUrl, index) =>
            isExpandedView ? (
              // Use the full-sized image for the expanded view.
              // eslint-disable-next-line react/no-array-index-key
              <img key={index} src={screenshotUrl} alt="" {...css(styles.largeScreenShots)} />
            ) : (
              <Imgix
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src={screenshotUrl}
                alt=""
                {...css(styles.screenShot)}
                maxWidth={MAX_WIDTH}
                maxHeight={MAX_HEIGHT}
                imgParams={{ fit: 'crop' }}
              />
            )
          )
        }
      </ExpandingCarousel>
    </div>
  );
};

export const BaseComponent = Screenshots;

export default withFragments({
  Screenshots: gql`
    fragment Screenshots on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      courseTypeMetadata {
        ... on XdpV1_rhymeProjectMember {
          rhymeProject {
            screenshotUrls
          }
        }
      }
    }
  `,
})(Screenshots);
