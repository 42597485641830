import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type {
  XdpV1_Org_Coursera_Xdp_Common_XdpCourseModule as XDPCourseModule,
  XdpV1_Org_Coursera_Xdp_Common_XdpModuleItem as XDPCourseModuleItem,
} from '__generated__/graphql-types';
import classNames from 'classnames';
import moment from 'moment';

import { Button, Typography2 } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { Box } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import GradientIcon from 'bundles/enterprise-legacy-xdp/components/GradientIcon';
import LearnerMaterialSummary from 'bundles/enterprise-legacy-xdp/components/LearnerMaterialsSummary';
import { renderToggleButtonWithoutCollapse } from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import { ITEM_GROUP_TYPES } from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/ItemGroupView';
import SyllabusModuleDetails from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/SyllabusModuleDetails';
import TogglableContent from 'bundles/page/components/TogglableContent';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/cdp/syllabus/__styles__/SyllabusModule';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type Props = XDPCourseModule & {
  slug: string;
  isLast?: boolean;
  courseId: string;
  weekNumber: number;
};

type State = {
  showModuleDetails: boolean;
};

class SyllabusModule extends React.Component<Props, State> {
  state = {
    showModuleDetails: false,
  };

  onToggleShowModuleDetails = () => {
    this.setState((state) => ({
      showModuleDetails: !state.showModuleDetails,
    }));
  };

  getTotalDurationText = (totalDuration: number) => {
    const hours = Math.round(moment.duration(totalDuration).asHours());
    if (hours < 1) {
      return (
        <FormattedMessage
          message={_t('{numMinutes} {numMinutes, plural, =1 { minute} other { minutes}} to complete')}
          numMinutes={Math.round(moment.duration(totalDuration).asMinutes())}
        />
      );
    } else {
      return (
        <FormattedMessage
          message={_t('{hours} {hours, plural, =1 { hour} other { hours}} to complete')}
          hours={hours}
        />
      );
    }
  };

  render() {
    const {
      slug,
      name,
      description,
      isLast,
      totalVideos,
      totalQuizzes,
      totalDuration,
      totalLectureDuration,
      totalReadings,
      items = [],
      courseId,
      weekNumber,
    } = this.props;
    const { showModuleDetails } = this.state;
    const videoItems = items.filter((item) => item?.typeName === ITEM_GROUP_TYPES.LECTURE);
    const readingItems = items.filter((item) => item?.typeName === ITEM_GROUP_TYPES.SUPPLEMENT);
    const practiceExerciseItems = items.filter(
      (item) => item?.typeName === ITEM_GROUP_TYPES.FORMATIVE_QUIZ || item?.typeName === ITEM_GROUP_TYPES.SUMMATIVE_QUIZ
    );

    let durationTextSection;

    if (totalDuration) {
      durationTextSection = (
        <Box data-test="duration-text-section" alignItems="center">
          <div className="m-r-1" aria-hidden="true">
            <GradientIcon title={_t('Hours to complete')} />
          </div>
          <Typography2 className="text-secondary" variant="bodyPrimary" component="strong">
            {this.getTotalDurationText(totalDuration)}
          </Typography2>
        </Box>
      );
    }
    return (
      <div className={classNames('SyllabusModule', { 'border-bottom': !isLast, 'm-b-3': !isLast })}>
        <div className="hidden-sm-down m-t-1 m-b-2">{durationTextSection}</div>
        <h3 className={`headline-2-text bold m-b-2 ${totalDuration ? '' : 'm-t-1s'}`}>{name}</h3>
        <Typography2 variant="bodyPrimary" component="span">
          <TogglableContent
            className="rc-module-description-text"
            collapsedHeight={52}
            disableCollapseScroll
            hideShowLessBtn
            renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse('SYLLABUS')}
            toggleButtonType="button"
            trackingData={{ courseId, weekNumber }}
            trackingName="module_description"
            useSmartCollapse
          >
            {description}
          </TogglableContent>
        </Typography2>
        <div className="hidden-sm-up m-t-1">{durationTextSection}</div>
        <Box alignItems="center" rootClassName="m-b-2">
          <LearnerMaterialSummary
            totalVideoLectureCount={totalVideos}
            totalReadingCount={totalReadings}
            totalQuizzesCount={totalQuizzes}
            totalLectureDuration={totalLectureDuration}
          />
          <TrackedButton
            data-test="show-module-details-btn"
            trackingName={showModuleDetails ? 'syllabus_week_see_less' : 'syllabus_week_see_all'}
            trackingData={{ courseId }}
            onClick={this.onToggleShowModuleDetails}
            variant="ghost"
            size="small"
            aria-label={
              showModuleDetails
                ? _t('See less modules in #{name}', { name })
                : _t('See all modules in #{name}', { name })
            }
          >
            {showModuleDetails ? _t('See Less') : _t('See All')}
          </TrackedButton>
        </Box>
        <div
          className="overflow-hidden"
          style={{ height: showModuleDetails ? 'auto' : 0 }}
          aria-hidden={!showModuleDetails}
        >
          <div className={classNames({ 'visibility-hidden': !showModuleDetails })}>
            <SyllabusModuleDetails
              videoItems={videoItems as Array<XDPCourseModuleItem>}
              readingItems={readingItems as Array<XDPCourseModuleItem>}
              practiceExerciseItems={practiceExerciseItems as Array<XDPCourseModuleItem>}
              slug={slug}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SyllabusModule;
