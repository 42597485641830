/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import ResponsiveToolbar from 'bundles/cml/editor/components/toolbars/ResponsiveToolbar';
import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import type { ToolbarPosition } from 'bundles/cml/editor/types/cmlEditorProps';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const styles = {
  sticky: css`
    background-color: var(--cds-color-white-0);
    position: sticky;
    z-index: 1;
  `,
  top: css`
    top: 0;
    border-bottom: solid 1px var(--cds-color-grey-50);
  `,
  bottom: css`
    bottom: 0;
    border-top: solid 1px var(--cds-color-grey-50);
  `,
};

type Props = {
  customTools: ToolsKeys[];
  options: ToolOptions;
  position: ToolbarPosition;
  className?: string;
};

const StickyToolbar: React.FC<Props> = (props) => {
  const { position, options, customTools, className } = props;
  return (
    <ResponsiveToolbar
      customTools={customTools}
      options={options}
      pageless={false}
      data-testid="sticky-toolbar"
      css={[styles.sticky, position === 'top' && styles.top, position === 'bottom' && styles.bottom]}
      className={className}
    />
  );
};

export default StickyToolbar;
