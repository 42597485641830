import React from 'react';

import { FormGroup as MuiFormGroup } from '@material-ui/core';

import clsx from 'clsx';

import { useId, useLocalizedStringFormatter } from '@coursera/cds-common';

import CheckboxCard from '@core/CheckboxCard';
import type { BaseFormControlProps } from '@core/forms/FormControl';
import { FormControl, formControlClasses } from '@core/forms/FormControl';
import type {
  OptionalInputLabelProps,
  RequireIndicatorProps,
} from '@core/forms/FormLabel';
import { FormLabel } from '@core/forms/FormLabel';
import { FormStatusText } from '@core/forms/FormStatusText';
import { FormSupportText } from '@core/forms/FormSupportText';
import { FormValidationStatus } from '@core/forms/FormValidationStatus';
import getFormGroupCss, { classes } from '@core/forms/getFormGroupCss';
import i18nMessages from '@core/forms/i18n';
import { useControlled, useValidationAriaLabel } from '@core/utils';
import VisuallyHidden from '@core/VisuallyHidden';

import type { CheckboxGroupValue } from './CheckboxGroupContext';
import { CheckboxGroupProvider } from './CheckboxGroupContext';

export type Props = {
  /**
   * List of checkbox components for the checkbox group.
   */
  children: React.ReactNode;
  /**
   * Status description for the checkbox group.
   */
  statusText?: string;
  /**
   * Selected checkbox values. Use when the component is controlled.
   */
  value?: CheckboxGroupValue;
  /**
   * Validation label for the checkbox group.
   */
  validationLabel?: string;
  /**
   * The default input element values. Use when the component is not controlled.
   */
  defaultValue?: CheckboxGroupValue;
  /**
   * Callback fired when a checkbox selection is changed.
   */
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  /**
   * Sets in how many columns to distribute the checkboxes.
   * Each number supports multiple breakpoints to guarantee full responsiveness on a smaller screens.
   *
   * @default 1
   */
  columns?: 1 | 2 | 3;
} & OptionalInputLabelProps &
  RequireIndicatorProps &
  Omit<BaseFormControlProps, 'disabled' | 'fullWidth'>;

/**
 * CheckboxGroup is a wrapper component used to group Checkbox components
 * that provides an easier API, and proper keyboard accessibility to the group
 *
 * See [Props](__storybookUrl__/components-inputs-checkbox--default#props)
 */
const CheckboxGroup = (props: Props, ref: React.Ref<HTMLDivElement>) => {
  const {
    id,
    className,
    label,
    renderLabel,
    children,
    defaultValue,
    supportText,
    statusText,
    validationStatus,
    validationLabel,
    onChange,
    optional,
    columns,
    necessityIndicator,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    ...rest
  } = props;

  const baseId = useId();
  const formLabelId = `${baseId}-formLabel`;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const [value, setValue] = useControlled<CheckboxGroupValue>({
    default: defaultValue || [],
    controlled: props.value,
    name: 'CheckboxGroup',
    state: 'value',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    let nextValue;

    if (isChecked) {
      nextValue = [...value, selectedValue];
    } else {
      nextValue = value.filter((item) => item !== selectedValue);
    }

    setValue(nextValue);
    onChange?.(event, isChecked);
  };

  const ariaValidationLabel = useValidationAriaLabel(
    validationLabel,
    validationStatus
  );

  const hasCardsInTheGroup = React.Children.toArray(children).some((child) => {
    return React.isValidElement(child) && child.type === CheckboxCard;
  });

  const hideLabel = !label && !renderLabel;

  /* Append the support and validation text to the aria-label.
   * This ensures the text is still announced even if the label is omitted and the FormLabel component doesn't render.
   */
  const ariaLabelExtended =
    ariaLabel &&
    [
      ariaLabel,
      supportText,
      validationStatus && validationLabel ? ariaValidationLabel : '',
      optional ? '' : stringFormatter.format('required'),
    ]
      .filter((str) => str)
      .join(', ');

  return (
    <FormControl
      ref={ref}
      aria-label={ariaLabelExtended}
      aria-labelledby={
        hideLabel && !ariaLabelledBy ? undefined : ariaLabelledBy || formLabelId
      }
      className={className}
      css={getFormGroupCss}
      id={id}
      optional={optional}
      role="group"
      supportText={supportText}
      validationStatus={validationStatus}
      {...rest}
    >
      {!hideLabel && (
        <FormLabel
          announceRequiredIndicator
          className={formControlClasses.formLabel}
          component="div"
          id={formLabelId}
          necessityIndicator={
            !necessityIndicator && optional ? 'text' : necessityIndicator
          }
          renderLabel={renderLabel}
        >
          {label}

          {supportText && <VisuallyHidden>{supportText}</VisuallyHidden>}

          {validationStatus && validationLabel && (
            <VisuallyHidden data-testid="validation-label-aria">
              {ariaValidationLabel}
            </VisuallyHidden>
          )}
        </FormLabel>
      )}

      {supportText && (
        <FormSupportText
          aria-hidden
          className={formControlClasses.formSupportText}
        >
          {supportText}
        </FormSupportText>
      )}

      {statusText && (
        <FormStatusText
          className={formControlClasses.formStatusText}
          role="status"
        >
          {statusText}
        </FormStatusText>
      )}

      {validationStatus && validationLabel && (
        <FormValidationStatus
          aria-hidden
          className={formControlClasses.formValidationStatus}
          data-testid="validation-label"
          label={validationLabel}
        />
      )}

      <MuiFormGroup
        className={clsx(classes.groupWrapper, {
          [classes.groupWrapperTwoColumns]: columns === 2,
          [classes.groupWrapperThreeColumns]: columns === 3,
          [classes.hasCardsInTheGroup]: hasCardsInTheGroup,
        })}
      >
        <CheckboxGroupProvider
          value={{
            value,
            onChange: handleChange,
          }}
        >
          {children}
        </CheckboxGroupProvider>
      </MuiFormGroup>
    </FormControl>
  );
};

export default React.forwardRef(CheckboxGroup);
