import * as React from 'react';

import { Col, Row, css } from '@coursera/coursera-ui';

import { howItWorksSkillItemStyles } from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/styles';
import { HowItWorksCaption } from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/typography';

type HowItWorksSkillItemProps = {
  title: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
};

const HowItWorksSkillItem: React.FC<HowItWorksSkillItemProps> = (props) => {
  const { title, children, icon: Icon } = props;

  return (
    <Row rootClassName={howItWorksSkillItemStyles.root}>
      <Col sm={12} md={2} noGutter>
        <div {...css(howItWorksSkillItemStyles.iconWrapper)}>
          <Icon />
        </div>
      </Col>
      <Col sm={12} md={10}>
        <div {...css(howItWorksSkillItemStyles.bodyWrapper)}>
          <HowItWorksCaption>{title}</HowItWorksCaption>

          {children}
        </div>
      </Col>
    </Row>
  );
};

export default HowItWorksSkillItem;
