import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import { Col } from '@coursera/coursera-ui';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import ProductListWithExpandToggle, {
  ProductListWithExpandToggleWithSkillSetViewMoreLessContext,
} from 'bundles/course-cards/components/course-card/enterprise/ProductListWithExpandToggle';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import { Heading, Section } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
import type { GroupedEnrolledProductsBySkillType } from 'bundles/enterprise-legacy-learner-home/components/available/MergedEnrolledProductsList';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { ProductStub } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

type Props = PropsFromSaveSkillSetToggle & {
  enterpriseBadgeCollections?: Array<EnterpriseBadge>;
  unsavedEnrolledProductsBySkill: GroupedEnrolledProductsBySkillType;
  enrolledProductsBySkill: GroupedEnrolledProductsBySkillType;
  enrolledProductsWithoutSkill: Array<ProductStub>;
  enrolledFutureProducts?: Array<ProductStub>;
  programId: string;
  programSlug: string;
  userId: number;
  hasSavedSkillSets: boolean;
  thirdPartyOrganizationId: string;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

const AdditionalEnrolledProductList: React.FC<Props> = ({
  enterpriseBadgeCollections,
  unsavedEnrolledProductsBySkill,
  enrolledProductsBySkill,
  enrolledProductsWithoutSkill,
  userId,
  programSlug,
  programId,
  onSkillSetSaveToggle,
  hasSavedSkillSets,
  thirdPartyOrganizationId,
  refetchEnterpriseBadgeCollections,
}) => {
  const headingText = hasSavedSkillSets ? _t('My additional courses') : _t('My courses');

  return (
    <Col col={12}>
      <Heading defaultLevel={2} className="header-section-title">
        {headingText}
      </Heading>
      <Section initialLevel={3}>
        {Object.keys(unsavedEnrolledProductsBySkill).map((tspId) => {
          const tspEnrolledProducts = enrolledProductsBySkill[tspId] || [];
          const viewMoreLessContext = hasSavedSkillSets
            ? _t('additional courses from SkillSet')
            : _t('courses from SkillSet');
          return (
            <ProductListWithExpandToggleWithSkillSetViewMoreLessContext
              key={tspId}
              products={tspEnrolledProducts}
              programId={programId}
              userId={userId}
              productCount={tspEnrolledProducts.length}
              targetSkillProfileId={tspId}
              programSlug={programSlug}
              onSkillSetSaveToggle={onSkillSetSaveToggle}
              showSaveSkillSetBanner
              viewMoreLessContext={viewMoreLessContext}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
            />
          );
        })}
        <ProductListWithExpandToggle
          enterpriseBadgeCollections={enterpriseBadgeCollections}
          products={enrolledProductsWithoutSkill}
          programId={programId}
          programSlug={programSlug}
          userId={userId}
          onSkillSetSaveToggle={onSkillSetSaveToggle}
          viewMoreLessContext={headingText}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
          refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
        />
      </Section>
    </Col>
  );
};

export default AdditionalEnrolledProductList;
