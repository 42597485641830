import * as React from 'react';

import config from 'js/app/config';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { InlineNotification, Link } from '@coursera/cds-core';

import storageUtils from 'bundles/enterprise-legacy-learner-home/utils/storageUtils';

import _t from 'i18n!nls/program-home';

const StorageKey = 'program_eula_dismissed';

type Props = {
  className?: string;
  thirdPartyOrganization: {
    name: string;
    slug: string;
  };
  programId: string;
  userId: number;
};

function ProgramEULABanner({ className, thirdPartyOrganization, userId, programId }: Props): JSX.Element | null {
  const [isDismissed, setIsDismissed] = React.useState(() => storageUtils.get(userId, programId, StorageKey, false));
  const handleOnDismiss = React.useCallback(() => {
    storageUtils.set(userId, programId, StorageKey, true);
    setIsDismissed(true);
  }, [userId, programId]);

  if (isDismissed) {
    return null;
  }

  const title = _t('Terms of Use');
  const message = (
    <FormattedMessage
      message={_t(
        'Your activity in this learning program from {orgName} is subject to {aNonStandardTermsOfUse}. Please note that the standard {privacyPolicy} is still in effect, and our standard {termsOfUse} take effect when you leave this learning program.'
      )}
      aNonStandardTermsOfUse={
        <Link
          href={`${config.url.base}about/terms-non-standard-${thirdPartyOrganization.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {_t('a non-standard Terms of Use')}
        </Link>
      }
      privacyPolicy={
        <Link href={`${config.url.base}about/privacy`} target="_blank" rel="noopener noreferrer">
          {_t('Privacy Policy')}
        </Link>
      }
      termsOfUse={
        <Link href={`${config.url.base}about/terms`} target="_blank" rel="noopener noreferrer">
          {_t('Terms of Use')}
        </Link>
      }
      orgName={thirdPartyOrganization.name || _t('organization')}
    />
  );

  return (
    <InlineNotification
      className={className}
      severity="information"
      onDismiss={handleOnDismiss}
      dismissActionProps={{ 'aria-label': _t('Dismiss: #{title}', { title }) }}
      title={title}
    >
      {message}
    </InlineNotification>
  );
}

export default ProgramEULABanner;
