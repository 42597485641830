// Slugs of Professional Certificates and Specializations that are included in the Pathways MVP
// Source: https://docs.google.com/spreadsheets/d/116hUCP8DH1V831tv7JWG8aH6oHUan-V-t-Z1aZo16tA/edit?usp=sharing
const PATHWAY_SLUGS = [
  'meta-android-developer',
  'advanced-app-android',
  'azure-developer-associate',
  'google-cloud-developer',
  'google-it-automation',
  'backend-javascript-developer',
  'cloud-engineering-gcp',
  'ibm-backend-development',
  'meta-back-end-developer',
  'intuit-bookkeeping',
  'tally-bookkeeper',
  'microsoft-business-analyst',
  'bi-analyst',
  'google-business-intelligence',
  'microsoft-power-bi-data-analyst',
  'sas-statistical-business-analyst',
  'sas-visual-business-analytics',
  'tableau-business-intelligence-analyst',
  'google-cloud-digital-leader-training',
  'goodwill-career-coach-and-navigator',
  'aws-cloud-solutions-architect',
  'gcp-cloud-architect',
  'google-it-support',
  'ibm-technical-support',
  'microsoft-it-support-specialist',
  'cvshealth-call-center-customer-service',
  'cvshealth-retail-customer-service',
  'google-cloud-cybersecurity-certificate',
  'google-cybersecurity',
  'ibm-cybersecurity-analyst',
  'microsoft-cybersecurity-analyst',
  'certifiedblockchainsecurityprofessionalexamprepspecialization',
  'generative-ai-for-cybersecurity-professionals',
  'advanced-network-security',
  'microsoft-azure-security-engineer-associate',
  'palo-alto-networks-cybersecurity-fundamentals',
  'pythonforcybersecurity',
  'ibm-isc2-cybersecurity-specialist',
  'google-advanced-data-analytics',
  'google-cloud-data-analytics-certificate',
  'google-data-analytics',
  'ibm-data-analyst',
  'ibm-data-analyst-r-excel',
  'meta-data-analyst',
  'data-analytics-business',
  'meta-database-engineer',
  'data-engineering',
  'gcp-data-engineering',
  'ibm-data-engineer',
  'azure-data-scientist',
  'certified-data-science-practitioner',
  'ibm-data-science',
  'advanced-statistics-data-science',
  'data-warehouse-engineering',
  'bi-foundations-sql-etl-data-warehouse',
  'devops-and-software-engineering',
  'ibm-applied-devops-engineering',
  'sre-devops-engineer-google-cloud',
  'aws-devops',
  'google-digital-marketing-ecommerce',
  'meta-marketing-science-certification-prep',
  'ibm-frontend-developer',
  'meta-front-end-developer',
  'amazon-junior-software-developer',
  'ibm-full-stack-cloud-developer',
  'ibm-full-stack-javascript-developer',
  'hrci-human-resource-associate',
  'meta-ios-developer',
  'ios-development',
  'swift-5-ios-app-developer',
  'app-development',
  'ibm-it-project-manager',
  'generative-ai-for-project-managers',
  'unilever-supply-chain-data-analyst',
  'supply-chain-analytics',
  'leverage-data-science-agile-supply-chain',
  'supply-chain-excellence',
  'ai-engineer',
  'certified-artificial-intelligence-practitioner',
  'ibm-machine-learning',
  'mathworks-computer-vision-engineer',
  'preparing-for-google-cloud-machine-learning-engineer-professional-certificate',
  'machine-learning-introduction',
  'advanced-machine-learning-tensorflow-gcp',
  'deep-learning',
  'machine-learning',
  'ibm-mainframe-developer',
  'ibm-z-mainframe',
  'facebook-marketing-analytics',
  'unilever-digital-marketing-analyst',
  'marketing-analytics',
  'akamai-network-engineering',
  'google-cloud-networking',
  'g-suite-administration',
  'ibm-ai-product-manager',
  'ibm-product-manager',
  'google-project-management',
  'ibm-project-manager',
  'managing-major-engineering-projects',
  'keller-williams-real-estate-agent',
  'sales-development-representative',
  'develop-with-dell-it-sales',
  'salesforce-sales-operations',
  'sales-operations',
  'professionalselling',
  'sales-management-bridging-gap-strategy-sales',
  'ibm-it-scrum-master',
  'facebook-social-media-marketing',
  'social-media-marketing',
  'marketing-with-tiktok',
  'digital-marketing',
  'akamai-customer-consulting-and-support',
  'asp-dot-net-experience',
  'sas-advanced-programmer',
  'sas-programming',
  'pwc-gst-taxation-executive',
  'microsoft-cloud-support-associate',
  'aws-cloud-technology-consultant',
  'certified-ethical-emerging-technologist',
  'sap-technology-consultant',
  'google-ux-design',
  'user-interface-design',
  'game-design-and-development',
  'programming-unity-game-development',
];

export default PATHWAY_SLUGS;
