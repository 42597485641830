import _ from 'lodash';

import user from 'js/lib/user';

import { EnterpriseContractBillingType } from 'bundles/admin-dashboard/types';
import type { EnterpriseContractTagType } from 'bundles/admin-dashboard/types';
import { generateElementId } from 'bundles/author-common/utils/IdUtils';
import type { CertificateData } from 'bundles/enterprise-admin-grow-with-google/components/CertificateSelectionScreen';
import type { CertificateType } from 'bundles/enterprise-admin-grow-with-google/components/withGoogleCertificates';

/* eslint-enable import/no-duplicates */
import type { GetAllProgramCurriculumContentsQuery_EnterpriseProgramsV1Resource_multiGet_elements as ProgramData } from 'bundles/enterprise-admin-grow-with-google/utils/__generated__/GetAllProgramCurriculumContentsQuery';
import type { org_coursera_enterprise_contract_tag_EnterpriseContractTag as ContractTag } from 'bundles/enterprise-admin-grow-with-google/utils/__generated__/globalTypes';
import {
  getITSupportCertOtherLanguages,
  gwgContractTags,
} from 'bundles/enterprise-admin-grow-with-google/utils/constants';
import { ProgramTypes } from 'bundles/enterprise-admin-grow-with-google/utils/enums';
import type { CollectionListItemStruct, Item } from 'bundles/enterprise-legacy-learner-home/types/programCommon';

type UpdateCurriculumArgs = {
  certificateIds: Array<string>;
  collectionTitle: string;
};

// eslint-disable-next-line no-restricted-syntax
enum ProductIdType {
  s12n = 's12nId',
  course = 'courseId',
  domain = 'domainId',
}

type CurriculumItem = {
  typeName: ProductIdType;
  definition: {
    id: string;
  };
  id: string;
};

type Track = {
  id: string;
  title: string;
  curriculumItems: CurriculumItem[];
};

type CurriculumApiData = {
  typeName: CurriculumType.wildcard | CurriculumType.smallSubset;
  definition: {
    coreTracks?: Track[];
    electiveTracks?: Track[];
  };
  includeClips: boolean;
};

// eslint-disable-next-line no-restricted-syntax
export enum CurriculumType {
  wildcard = 'wildcardCurriculum',
  smallSubset = 'tracksCurriculum',
}

type includesTargetContractProps = {
  contracts: Array<{
    id: string;
    endedAt?: number | null;
    billingType: string;
    contractTag?: { contractTagName: string };
  }>;
  target: Array<EnterpriseContractTagType | ContractTag>;
  skipBillingCheck?: boolean;
};

export const includesTargetContract = ({ contracts, target, skipBillingCheck }: includesTargetContractProps) => {
  if (skipBillingCheck) {
    const activeContracts = contracts.filter((item) => !item.endedAt);
    return activeContracts.some(
      (activeContract) => activeContract.contractTag && target.includes(activeContract.contractTag?.contractTagName)
    );
  }

  return (
    contracts &&
    contracts.length > 0 &&
    contracts.every(
      (contract) =>
        contract.billingType === EnterpriseContractBillingType.NONE &&
        contract.contractTag &&
        target.includes(contract.contractTag?.contractTagName)
    )
  );
};

export const getUpdateCurriculumApiData = ({
  certificateIds,
  collectionTitle,
}: UpdateCurriculumArgs): CurriculumApiData => {
  const curriculumItems = certificateIds.map((certificateId) => {
    const curriculumItem = {
      id: certificateId,
      typeName: ProductIdType.s12n,
      definition: {
        id: certificateId,
      },
    };
    return curriculumItem;
  });

  const tracks = [
    {
      allCurriculumItemIds: certificateIds,
      curriculumItems,
      id: generateElementId(),
      title: collectionTitle,
    },
  ];

  return {
    typeName: CurriculumType.smallSubset,
    definition: {
      coreTracks: tracks,
    },
    includeClips: true,
  };
};

export const reorderCertificates = (collections: Array<CollectionListItemStruct>) => {
  const reorderedCerts = [] as Array<CollectionListItemStruct>;

  _.forEach(collections, (collection) => {
    const items = collection.items;
    const regularCerts = [] as Item[];
    const otherlanguageCerts = [] as Item[];

    _.forEach(items, (item) => {
      const otherLanguageProductIds = Object.values(getITSupportCertOtherLanguages()).map(
        (language) => language.productId
      );
      const itemProductId = item.productId.split('Specialization~').pop();

      if (itemProductId && otherLanguageProductIds.includes(itemProductId)) {
        otherlanguageCerts.push(item);
      } else {
        regularCerts.push(item);
      }
    });

    const newCollection = _.cloneDeep(collection);
    newCollection.items = regularCerts.concat(otherlanguageCerts);

    reorderedCerts.push(newCollection);
  });

  return reorderedCerts;
};

export const getGwGProgramIds = ({
  programData,
  gwgLanguages,
  gwgCertificates,
}: {
  programData: Array<ProgramData>;
  gwgLanguages: Record<string, CertificateType>;
  gwgCertificates: Record<string, CertificateType>;
}) => {
  const googleProductIds = Object.values({ ...gwgCertificates, ...gwgLanguages }).map(
    (certificateData) => certificateData.productId
  );

  return programData
    .filter((program) => {
      const s12nIds = program.curriculumCollections?.elements[0]?.s12nIds;
      return s12nIds && s12nIds.length > 0 && s12nIds.every((s12nId) => googleProductIds.includes(s12nId));
    })
    .map((program) => program.id);
};

export const isGwGContract = (contractTag: string | undefined) => {
  if (!contractTag) {
    return false;
  }

  const check = gwgContractTags as Array<string>;

  return check.includes(contractTag);
};

export const shouldDisplayContract = (
  contract: { contractState: string; contractTag?: { contractTagName: string } | null; isReal: boolean },
  programType?: ProgramTypes
) => {
  const isActive = contract.contractState === 'ACTIVE';
  let matchesProgramType: boolean;
  if (programType === ProgramTypes.GwG) {
    matchesProgramType = isGwGContract(contract.contractTag?.contractTagName);
  } else {
    matchesProgramType = !isGwGContract(contract.contractTag?.contractTagName);
  }

  if (user.isSuperuser()) {
    return (isActive && matchesProgramType) || contract.contractState === 'CREATED';
  }
  return isActive && matchesProgramType && contract.isReal;
};

export const orderCertificatesOnSelectionScreen = (certificateData: CertificateData) => {
  const dataCerts = ['Data Analytics', 'Advanced Data Analytics', 'Business Intelligence'];
  return [
    certificateData.find((cert) => cert.title === 'Data Analytics'),
    certificateData.find((cert) => cert.title === 'Advanced Data Analytics'),
    certificateData.find((cert) => cert.title === 'Business Intelligence'),
    ...certificateData.filter((cert) => !dataCerts.includes(cert.title)),
  ].filter((cert) => !!cert) as CertificateData;
};
