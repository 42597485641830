import { DescriptionPage_ProductVariant as DescriptionPageProductVariant } from '__generated__/graphql-types';

import type { CourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';
import S12nProductVariants from 'bundles/s12n-common/constants/s12nProductVariants';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';

export const getProductCategory = ({
  courseType,
  s12nProductVariant,
}: {
  courseType?: CourseType;
  // DescriptionPageProductVariant comes from Unified Description Page
  // S12nProductVariant comes from XDP
  s12nProductVariant?: DescriptionPageProductVariant | S12nProductVariant;
}):
  | 'ProfessionalCertificate'
  | 'ExternalCertificate'
  | 'StandardSpecialization'
  | 'StandardCourse'
  | 'GuidedProject'
  | 'Project'
  | 'Assessment' => {
  if (
    s12nProductVariant === DescriptionPageProductVariant.ProfessionalCertificate ||
    s12nProductVariant === S12nProductVariants.ProfessionalCertificateS12n
  ) {
    return 'ProfessionalCertificate';
  } else if (
    s12nProductVariant === DescriptionPageProductVariant.ExternalCertificate ||
    s12nProductVariant === S12nProductVariants.ExternalCertificateS12n
  ) {
    return 'ExternalCertificate';
  } else if (
    s12nProductVariant === DescriptionPageProductVariant.StandardSpecialization ||
    s12nProductVariant === S12nProductVariants.NormalS12n
  ) {
    return 'StandardSpecialization';
  } else if (courseType) {
    return courseType;
  }

  return 'StandardCourse';
};
