import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { useRouter } from 'js/lib/useRouter';

import { Button, Grid, Link, Typography2, useTheme } from '@coursera/cds-core';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { TabName } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import Naptime from 'bundles/naptimejs';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type { ProductStub } from 'bundles/program-home/types/Products';
import { removeCredentialFromBadgeTitle } from 'bundles/program-home/utils/EnterpriseBadgeUtils';

import _t from 'i18n!nls/course-cards';

type PropsFromCaller = {
  product: ProductStub;
  recommendedProductIds: Set<string>;
  badgeProductMapping: { [key: string]: EnterpriseBadge };
  selectedBadge?: EnterpriseBadge;
  onEnrollInCredential: (badge: EnterpriseBadge) => void;
};

type PropsFromNaptime = {
  course?: CoursesV1;
};

type Props = PropsFromCaller & PropsFromNaptime;

const ProductBadgeEnrollment: React.VFC<Props> = ({
  course,
  product,
  recommendedProductIds,
  badgeProductMapping,
  onEnrollInCredential,
}) => {
  React.useEffect(() => {
    document.location.hash = '';
  });
  const theme = useTheme();
  const router = useRouter();
  const badgeTitle = removeCredentialFromBadgeTitle(badgeProductMapping[product.productItemId].title);

  const redirectToBadge = (e: React.MouseEvent<HTMLAnchorElement>, badgeId: string) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    router.push({
      ...router.location,
      query: {
        ...router.location.query,
        currentTab: TabName.Catalog,
      },
      hash: badgeId,
    });
  };
  return (
    <React.Fragment>
      {recommendedProductIds.has(product.productItemId) && (
        <Grid
          container
          justifyContent="space-between"
          css={{ backgroundColor: 'var(--cds-color-blue-50)', padding: 'var(--cds-spacing-200)' }}
        >
          <Grid item xs={8}>
            <Typography2 component="h3" variant="subtitleMedium">
              {_t('Don’t forget to receive your digital badge')}
            </Typography2>
            <Typography2 component="p" variant="bodySecondary">
              <FormattedMessage message={_t('{courseTitle} course is part of the ')} courseTitle={course?.name} />
              <Link
                href={`#badge-${badgeProductMapping[product.productItemId].badgeTemplateId}`}
                variant="quiet"
                onClick={(event) =>
                  redirectToBadge(event, `#badge-${badgeProductMapping[product.productItemId].badgeTemplateId}`)
                }
              >
                {badgeTitle}
              </Link>
              <FormattedMessage message={_t(', enroll and receive a digital badge upon successful completion.')} />
            </Typography2>
          </Grid>
          <Grid container xs={4} justifyContent="flex-end" alignItems="center">
            <div>
              <Button
                variant="secondary"
                size="small"
                onClick={() => onEnrollInCredential(badgeProductMapping[product.productItemId])}
                data-testid="enroll-in-credential-button"
              >
                {_t('Enroll in learning track')}
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Naptime.createContainer<Props, PropsFromCaller>(({ product: { productItemId } }) => ({
  course: CoursesV1.get(productItemId, {
    fields: [
      'description',
      'photoUrl',
      'slug',
      'upcomingSessionStartDate',
      'workload',
      'plannedLaunchDate',
      'courseStatus',
      'partnerIds',
      'instructorIds',
    ],
    params: { showHidden: true },
  }),
}))(ProductBadgeEnrollment);
