import { useQuery } from '@apollo/client';

import { ProgramMembershipsByUserQuery } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramGraphqlQueries';
import type {
  ProgramMembershipsByUserQuery as ProgramMembershipsByUserQueryData,
  ProgramMembershipsByUserQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/components/single-program/__generated__/ProgramMembershipsByUserQuery';

export type PropsForWithHasMultipleProgramProps = {
  userId: number | string;
  thirdPartyOrganizationId?: string;
};

export const useHasMultipleProgramProps = ({
  userId,
  thirdPartyOrganizationId,
}: PropsForWithHasMultipleProgramProps) => {
  const { data } = useQuery<ProgramMembershipsByUserQueryData, ProgramMembershipsByUserQueryVariables>(
    ProgramMembershipsByUserQuery,
    {
      skip: !userId,
      variables: {
        userId: String(userId),
      },
    }
  );

  const programMemberships = data?.ProgramMembershipsV2Resource?.byUser?.elements;
  const programMembershipsWithinOrg = programMemberships?.filter(
    (programMembership) => programMembership?.enterpriseProgram?.thirdPartyOrganizationId === thirdPartyOrganizationId
  );

  return {
    hasMultipleProgramsInOrg: Number(programMembershipsWithinOrg?.length) > 1,
  };
};

export default useHasMultipleProgramProps;
