import * as React from 'react';
import { useSelector } from 'react-redux';

import type { GlobalState } from 'bundles/ai-coach-platform/components/patterns/chat/store/types';
import type { OnReceiveMessage } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { isCoachMessage } from 'bundles/ai-coach-platform/components/patterns/chat/utils/messageUtils';

/** Always have the latest message sent by the server */
const useLatestCoachMessage = () => {
  const messages = useSelector((state: GlobalState) => state.messages.messages);
  const coachMessages = messages.filter(({ sender }) => isCoachMessage(sender));
  if (coachMessages.length === 0) {
    return undefined;
  }

  return coachMessages[coachMessages.length - 1];
};

const useHandleOnReceiveMessage = (onReceiveMessage?: OnReceiveMessage) => {
  const latestCoachMessage = useLatestCoachMessage();

  React.useEffect(() => {
    if (latestCoachMessage === undefined || !onReceiveMessage) {
      return;
    }

    onReceiveMessage(latestCoachMessage);
  }, [latestCoachMessage, onReceiveMessage]);
};

export default useHandleOnReceiveMessage;
