/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Link, Typography2, breakpoints } from '@coursera/cds-core';

import { Heading } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';

import _t from 'i18n!nls/program-home';

type Props = {
  programName: string;
  setEnableAgeVerification: (val: boolean) => void;
};

const TIME_TO_REDIRECT = 10000; // 10s

const styles = {
  container: css`
    ${breakpoints.up('sm')} {
      text-align: center;
    }
  `,
  text: css`
    margin: var(--cds-spacing-300) 0;
  `,
};

export function AgeVerificationSuccess({ programName, setEnableAgeVerification }: Props): JSX.Element {
  const redirectToProgramPage = React.useCallback(
    (event?: React.SyntheticEvent) => {
      setEnableAgeVerification(false);
      event?.preventDefault();
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    setTimeout(() => {
      redirectToProgramPage();
    }, TIME_TO_REDIRECT);
  }, [redirectToProgramPage]);

  return (
    <div css={styles.container}>
      <Heading defaultLevel={1} css={styles.text}>
        {_t('Congratulations!')}
      </Heading>
      <Typography2 component="p" css={styles.text}>
        {_t("You can join '#{programName}'.", { programName })}
      </Typography2>
      <Typography2 component="p" variant="bodySecondary" css={styles.text}>
        <Link variant="quiet" href={location.href} onClick={redirectToProgramPage}>
          {_t('Click here if you are not directed within 10 seconds.')}
        </Link>
      </Typography2>
    </div>
  );
}

const AgeVerificationSuccessHookProxy = (props: Props) => <AgeVerificationSuccess {...props} />;

export default AgeVerificationSuccessHookProxy;
