/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { Theme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

type StepperStyleProps = {
  isCompleted?: boolean;
  isCurrent?: boolean;
};

const bubbleStyle =
  ({ isCompleted, isCurrent }: StepperStyleProps) =>
  (theme: Theme) =>
    css({
      color: isCompleted || isCurrent ? 'var(--cds-color-blue-700)' : 'var(--cds-color-neutral-primary-weak)',
      border: isCompleted || isCurrent ? `2px solid var(--cds-color-blue-700)` : `2px solid var(--cds-color-grey-500)`,
      background: isCompleted ? 'var(--cds-color-blue-700)' : '#fff',
      width: 24,
      height: 24,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    });

const stepLabelStyle =
  ({ isCompleted, isCurrent }: StepperStyleProps) =>
  (theme: Theme) =>
    css({
      textTransform: 'uppercase',
      marginTop: 9,
      transform: 'translateX(calc(-50% + 12px))',
      // eslint-disable-next-line no-nested-ternary
      color: isCompleted ? '1F1F1F' : isCurrent ? 'var(--cds-color-blue-700)' : 'var(--cds-color-neutral-primary-weak)',
      position: 'absolute',
    });

const barStyle =
  ({ isCompleted }: StepperStyleProps) =>
  (theme: Theme) =>
    css({
      marginTop: '12px',
      width: 160,
      height: 2,
      background: isCompleted ? 'var(--cds-color-blue-700)' : 'var(--cds-color-grey-500)',
    });

const stepContainer = css({
  display: 'flex',
});

const rcProgressStepper = css({
  fontFamily: 'OpenSans',
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '18px',
  display: 'flex',
  marginBottom: '20px',
});

type StepInfo = {
  label?: string;
};

export type ProgressStepperProps = {
  currentStep: number;
  steps: Array<StepInfo>;
};

export const ProgressStepper = ({ currentStep, steps }: ProgressStepperProps) => {
  return (
    <div css={rcProgressStepper}>
      {steps.map((step, idx) => {
        const isCompleted = currentStep > idx;
        const isCurrent = currentStep === idx;
        if (idx + 1 < steps.length) {
          return (
            <div data-container="step" key={step.label ?? idx}>
              <div css={stepContainer}>
                <div css={bubbleStyle({ isCompleted, isCurrent })}>
                  {isCompleted ? <CheckIcon color="invert" size="small" /> : <span>{idx + 1}</span>}
                </div>
                <div css={barStyle({ isCompleted })} />
              </div>
              {step.label && <div css={stepLabelStyle({ isCompleted, isCurrent })}>{step.label}</div>}
            </div>
          );
        } else {
          return (
            <div data-container="step" key={step.label ?? idx}>
              <div css={bubbleStyle({ isCompleted, isCurrent })}>
                {isCompleted ? <CheckIcon color="invert" size="small" /> : <span>{idx + 1}</span>}
              </div>
              {step.label && <div css={stepLabelStyle({ isCompleted, isCurrent })}>{step.label}</div>}
            </div>
          );
        }
      })}
    </div>
  );
};
