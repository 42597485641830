/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { branch } from 'recompose';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';
import { LONG_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, Dialog, Grid, Typography2 } from '@coursera/cds-core';

import TrackedButton from 'bundles/page/components/TrackedButton';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { MODAL_TYPES } from 'bundles/payments/components/finaid-2021/OpenAidApplicationModal';
import { MAX_CART_VALID_MILLI_SECS } from 'bundles/payments/components/finaid-2021/constants/limits';
import type { WithCartProps } from 'bundles/payments/components/finaid-2021/enhancers/withCart';
import withCart from 'bundles/payments/components/finaid-2021/enhancers/withCart';
import type { WithCourseProps } from 'bundles/payments/components/finaid-2021/enhancers/withCourse';
import withCourse from 'bundles/payments/components/finaid-2021/enhancers/withCourse';
import { withSpecializations } from 'bundles/payments/components/finaid-2021/enhancers/withCoursesAndFinancialAidApplications';
import type { OnDemandSpecializationsResultProps } from 'bundles/payments/components/finaid-2021/enhancers/withCoursesAndFinancialAidApplications';

import _t from 'i18n!nls/finaid';

type InputProps = {
  cartId: number;
  s12nId?: string;
  handleClose: () => void;
  handleCancelAndEroll?: () => void;
  productType?: keyof typeof MODAL_TYPES | null;
};

type WithCourseIdProps = {
  courseId: string;
};

type Props = InputProps & WithCartProps & WithCourseProps & OnDemandSpecializationsResultProps;

const FinancialAidApprovedModal = ({
  cartId,
  cartData,
  courseName,
  handleClose,
  handleCancelAndEroll,
  productType,
  courses,
}: Props) => {
  if (!cartData || !courseName) {
    return null;
  }

  const priceExpirationDateString = formatDateTimeDisplay(
    cartData.cart.createdAt + MAX_CART_VALID_MILLI_SECS,
    LONG_DATE_ONLY_DISPLAY
  );

  const handleContinueOnClick = () => {
    redirect.setLocation(`/payments/checkout?cartId=${cartId}&isFinancialAid=true`);
  };

  if (productType !== MODAL_TYPES.COURSE && courses) {
    return (
      <Dialog open onClose={handleClose}>
        <Dialog.HeadingGroup>
          {_t("Congratulations! You've been approved for financial aid for 1 course in this #{type}.", {
            type: productType === MODAL_TYPES.PROFESSIONAL_CERTIFICATE ? 'Professional Certificate' : 'Specialization',
          })}
        </Dialog.HeadingGroup>
        <Dialog.Content>
          <Typography2 variant="bodyPrimary" component="p">
            <FormattedMessage
              message={_t(
                `You're approved to enroll in {courseName} at {discount}% off the regular price with 6 months of course access. {expiryText}`
              )}
              courseName={courseName}
              discount={cartData.cartItems?.[0]?.promotionInfo?.discountPercent}
              expiryText={
                <b> {_t('This price will expire on #{priceExpirationDateString}', { priceExpirationDateString })}</b>
              }
            />
          </Typography2>
          <br />
          <Typography2 variant="bodyPrimary" component="p">
            {_t(
              `When you pay for the #{courseLength}-course Specialization your financial aid application for #{courseName} will be canceled.`,
              { courseLength: courses?.elements.length, courseName }
            )}
          </Typography2>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                component={TrackedButton}
                trackingName="financial_aid_approved_modal_checkout"
                onClick={handleContinueOnClick}
                variant="primary"
                fullWidth
                withVisibilityTracking
                requireFullyVisible
              >
                {_t('Finish financial aid checkout')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Button
                data-testid="continue-button"
                component={TrackedButton}
                trackingName="financial_aid_deterrent_modal_enroll"
                onClick={handleCancelAndEroll}
                variant="secondary"
                fullWidth
                withVisibilityTracking
                requireFullyVisible
              >
                {_t('Pay for #{courseLength}-course Specialization', { courseLength: courses?.elements.length })}
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    );
  }
  return (
    <Dialog open onClose={handleClose}>
      <Dialog.HeadingGroup>{_t("Congratulations! You've been approved for financial aid.")}</Dialog.HeadingGroup>
      <Dialog.Content>
        <Typography2 variant="bodyPrimary" component="p">
          {_t(
            "You're approved to enroll in #{courseName} at #{discount}% off the regular price with 6 months of course access",
            { courseName, discount: cartData.cartItems?.[0]?.promotionInfo?.discountPercent }
          )}
        </Typography2>
        <br />
        <Typography2 variant="subtitleMedium" component="p">
          {_t('This price will expire on #{priceExpirationDateString}', { priceExpirationDateString })}
        </Typography2>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          component={TrackedButton}
          withVisibilityTracking
          requireFullyVisible
          fullWidth
          onClick={handleContinueOnClick}
          trackingName="financial_aid_approved_modal_checkout"
        >
          {_t('Continue')}
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export const BaseComponent = FinancialAidApprovedModal;

export default compose<Props, InputProps>(
  withCart,
  withProps<WithCourseIdProps, InputProps & WithCartProps>(({ cartData }) => ({
    courseId: (cartData?.isVC && cartData.productItemId) || '',
  })),
  withCourse(),
  branch<Props>(({ s12nId }) => !!s12nId, withSpecializations())
)(FinancialAidApprovedModal);
