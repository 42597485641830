import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';

import logger from 'js/app/loggerSingleton';
import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';

import type { ButtonName } from '@coursera/event-pulse-types';
import { Link } from '@coursera/event-pulse/react';

import EnrollmentReasonCode from 'bundles/enroll-course/common/EnrollmentReasonCode';
import CourseEnrollmentConfirmation from 'bundles/enroll-course/components/CourseEnrollmentConfirmation';
import QueryUpgradeS12n from 'bundles/enroll-course/components/QueryUpgradeS12n.graphql';
import type {
  FetchLatestVersionS12nQuery,
  FetchLatestVersionS12nQueryVariables,
} from 'bundles/enroll-course/components/__generated__/QueryUpgradeS12n';
import { enrollInCourseForFreePromise } from 'bundles/enroll-course/lib/apiClient';
import { checkSessionsV2Epic } from 'bundles/enroll-course/lib/sessionsV2ExperimentUtils';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';

import _t from 'i18n!nls/enroll-course';

type PropsFromCaller = {
  reasonCode: keyof typeof EnrollmentReasonCode;
  s12nId?: string;
};

const EnrollmentReasonMessage: React.FC<PropsFromCaller> = ({ reasonCode, s12nId }) => {
  const { course } = useCourseEnrollModalData();

  const [didEnroll, setDidEnroll] = useState(false);

  const { data: upgradeS12nData } = useQuery<FetchLatestVersionS12nQuery, FetchLatestVersionS12nQueryVariables>(
    QueryUpgradeS12n,
    {
      variables: {
        s12nId: s12nId!,
      },
      skip: !s12nId || reasonCode !== EnrollmentReasonCode.SPECIALIZATION_UPGRADE_REQUIRED,
      context: { clientName: 'gatewayGql' },
    }
  );

  if (!reasonCode) {
    return null;
  }

  if (didEnroll) {
    return <CourseEnrollmentConfirmation courseId={course.id} />;
  }

  const renderAlreadyEnrolled = () => {
    return (
      <div className="align-horizontal-center">
        <p>
          <FormattedMessage message={_t('You are already enrolled in {courseName}.')} courseName={course.name} />
        </p>
        <p>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <a href={course.homeLink}>{_t('Go to course home')}</a>
        </p>
      </div>
    );
  };

  const renderAlreadyPurchased = () => {
    const enrollInAlreadyPaidCourse = () => {
      checkSessionsV2Epic(course.id).then(() => {
        enrollInCourseForFreePromise(course.id).then(() => setDidEnroll(true));
      });
    };

    return (
      <div className="align-horizontal-center">
        {/* eslint-disable-next-line no-restricted-syntax */}
        <p>{_t('Please click the button below to access course materials.')}</p>
        <br />
        <p>
          <button type="button" className="primary" onClick={enrollInAlreadyPaidCourse}>
            {/* eslint-disable-next-line no-restricted-syntax */}
            {_t('Join Course')}
          </button>
        </p>
      </div>
    );
  };

  const renderCapstoneLocked = () => {
    return (
      <div className="align-horizontal-center">
        {_t('You can only access this Capstone after completing the courses in the Specialization')}
      </div>
    );
  };

  const renderRegionBlocked = () => {
    return (
      <div className="align-horizontal-center">
        <FormattedHTMLMessage
          message={_t(
            `We apologize for the inconvenience. This course is not available in your region.
          Click <a href="{policyUrl}">here</a> for more information.`
          )}
          policyUrl="https://learner.coursera.help/hc/articles/208280116-International-restrictions"
        />
      </div>
    );
  };

  const renderNoAvailableSession = () => {
    return (
      <p className="align-horizontal-center">
        {/* eslint-disable-next-line no-restricted-syntax */}
        {_t('There is no upcoming session for this course. Please check back later.')}
      </p>
    );
  };

  const renderSpecializationUpgradeRequired = () => {
    const newS12nId = upgradeS12nData?.Specialization?.queryLatestVersionByIds?.[0]?.id;
    return (
      <div className="s12n-upgrade-required">
        <p className="font-sm m-b-2 align-horizontal-center">
          {_t(
            'You are currently enrolled in an old version of the specialization. Upgrade the specialization to continue with your purchase.'
          )}
        </p>

        {s12nId && newS12nId && (
          <div className="align-horizontal-center">
            <Link
              trackingData={{
                button: {
                  linkURL: `/learn/specialization/upgrade/${s12nId}:${newS12nId}`,
                  linkName: 'upgrade_s12n_from_enrollment_error',
                  linkType: 'internal',
                  name: 'upgrade_s12n_from_enrollment_error' as ButtonName,
                },
              }}
              variant="standard"
              href={`/learn/specialization/upgrade/${s12nId}:${newS12nId}`}
            >
              {_t('View Upgrade')}
            </Link>
          </div>
        )}
      </div>
    );
  };

  const renderUnknownReasonCode = () => {
    return (
      <div className="align-horizontal-center">
        <p>
          <FormattedMessage
            message={_t('Sorry, we could not find any enrollment option for {courseName} at this time.')}
            courseName={course.name}
          />
        </p>
        <p>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <a href={course.link}>{_t('Go to course')}</a>
        </p>
      </div>
    );
  };

  // TODO(jnam) remove duplicate definitions between here and
  // bundles/naptimejs/resources/enrollmentAvailableChoices.v1.js
  switch (reasonCode) {
    case EnrollmentReasonCode.ENROLLED:
      return renderAlreadyEnrolled();
    case EnrollmentReasonCode.PURCHASED_SINGLE_COURSE:
    case EnrollmentReasonCode.SPECIALIZATION_BULK_PAID:
    case EnrollmentReasonCode.SPECIALIZATION_SUBSCRIBED:
      return renderAlreadyPurchased();
    case EnrollmentReasonCode.CAPSTONE_ACCESS_LOCKED:
      return renderCapstoneLocked();
    case EnrollmentReasonCode.REGION_BLOCKED:
      return renderRegionBlocked();
    case EnrollmentReasonCode.NO_AVAILABLE_SESSION:
      return renderNoAvailableSession();
    case EnrollmentReasonCode.SPECIALIZATION_UPGRADE_REQUIRED:
      return renderSpecializationUpgradeRequired();
    default:
      logger.error(`Unrecognized enrollment reason code: ${reasonCode}`);
      return renderUnknownReasonCode();
  }
};

export default EnrollmentReasonMessage;
