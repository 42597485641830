/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Divider, Grid, Typography2, breakpoints, useTheme } from '@coursera/cds-core';
import { ControllingIcon, OpenBookIcon } from '@coursera/cds-icons';

import { Heading } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
import DetailedObjectivesInformationLink from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/DetailedObjectivesInformationLink';
import SkillSetInformationCard from 'bundles/program-home/components/multiprogram/SkillSetInformationCard';

import _t from 'i18n!nls/program-home';

type Props = {
  skillSetLength?: number;
  totalSkillSetLength?: number;
  title?: string;
};

type DescriptionTextProps = {
  Icon: React.ReactElement;
  boldedText: string;
  descriptionText: string;
};

export function DescriptionText({ Icon, boldedText, descriptionText }: DescriptionTextProps) {
  const styles = {
    textContainer: css`
      display: flex;
    `,
    boldText: css`
      font-weight: 600;
    `,
  };
  return (
    <div css={styles.textContainer}>
      {Icon}
      <Typography2 component="p" variant="bodyPrimary">
        <span css={styles.boldText}>{boldedText}</span>
        {descriptionText}
      </Typography2>
    </div>
  );
}

export function SkillSetsTabDiscoveryHeader({ skillSetLength, totalSkillSetLength, title }: Props) {
  const theme = useTheme();
  const styles = {
    container: css`
      margin-bottom: theme.spacing(8);
      ${breakpoints.down('md')} {
        margin-bottom: theme.spacing(8);
      }

      .no-box-shadow-info {
        box-shadow: none;
      }
    `,
    header: css`
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: var(--cds-spacing-100);
      ${breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: var(--cds-spacing-150);
      }
    `,
    showingText: css`
      ${breakpoints.down('xs')} {
        margin-top: var(--cds-spacing-100);
      }
    `,
    description: css`
      margin: var(--cds-spacing-200) 0 var(--cds-spacing-100) 0;
      ${breakpoints.down('xs')} {
        margin-bottom: var(--cds-spacing-150);
      }
    `,
    icon: css`
      flex-shrink: 0;
      margin-top: var(--cds-spacing-100);
      margin-right: var(--cds-spacing-150);
    `,
    controllingIcon: css`
      transform: rotate(90deg);
    `,
    detailedObjectivesInformationLink: css`
      margin-bottom: theme.spacing(12);
      margin-left: calc(var(--cds-spacing-50) * -1);
    `,
  };

  const [helpIsExpanded, toggleHelpIsExpanded] = useState(false);

  const onToggleHelpIsExpanded = React.useCallback(() => {
    toggleHelpIsExpanded(!helpIsExpanded);
  }, [helpIsExpanded, toggleHelpIsExpanded]);

  const ShowingMessage =
    skillSetLength !== totalSkillSetLength ? (
      <FormattedMessage
        message={_t('Showing {numSkills} of {totalNumSkills, plural, =1 {# SkillSet} other {# SkillSets}}')}
        numSkills={skillSetLength}
        totalNumSkills={totalSkillSetLength}
      />
    ) : (
      <FormattedMessage
        message={_t('Showing {numSkills, plural, =1 {# SkillSet} other {# SkillSets}}')}
        numSkills={skillSetLength}
      />
    );
  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <Heading defaultLevel={2} variant="h1semibold">
          {title || _t('All SkillSets in this program')}
        </Heading>
        {skillSetLength ? (
          <Typography2 component="p" css={[styles.showingText]} variant="bodyPrimary">
            {ShowingMessage}
          </Typography2>
        ) : null}
      </div>
      <Divider color="dark" />
      <Grid container css={styles.description} spacing={16}>
        <Grid xs={12} sm={6} item>
          <DescriptionText
            Icon={<ControllingIcon size="large" css={[styles.icon, styles.controllingIcon]} />}
            boldedText={_t('Choose any SkillSet that aligns with your learning goals. ')}
            descriptionText={_t(
              'These SkillSets have been selected from Coursera’s library of pre-defined SkillSets or manually created by your organization to guide your learning journey.'
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <DescriptionText
            Icon={<OpenBookIcon size="large" css={styles.icon} />}
            boldedText={_t(
              'Enroll in personalized recommendations from the SkillSet to increase your proficiency level. '
            )}
            descriptionText={_t(
              'As your proficiency level increases, the recommendations will dynamically update across all SkillSets to match your level.'
            )}
          />
        </Grid>
      </Grid>
      <div css={styles.detailedObjectivesInformationLink}>
        <DetailedObjectivesInformationLink
          cardHtmlId="ExploreSkillsListInformationCard"
          isOpen={helpIsExpanded}
          onClick={onToggleHelpIsExpanded}
          enableSkillsDiscovery
          isCdsEnabled
        />
      </div>
      <SkillSetInformationCard
        rootClassName="no-box-shadow-info"
        id="ExploreSkillsListInformationCard"
        isOpen={helpIsExpanded}
      />
      <Divider color="light" hidden={helpIsExpanded} />
    </div>
  );
}
