/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';
import Media from 'react-media';

import { groupBy, keyBy, map, mapValues } from 'lodash';

import { Grid, PageGridContainer, useTheme } from '@coursera/cds-core';

import BrowseCollectionList, {
  OrgHomeBrowseCollectionList,
} from 'bundles/enterprise-collections/components/BrowseCollectionList';
import DesktopParitySearchCard, {
  DesktopParitySearchCardPlaceholder,
} from 'bundles/enterprise-collections/components/DesktopParitySearchCard';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import MobileParitySearchCard from 'bundles/enterprise-collections/components/MobileParitySearchCard';
import type { EnterpriseCardData } from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchCard';
import EnterpriseSearchCards from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchCards';
import { filterHiddenFilters } from 'bundles/enterprise-learner-search/utils/search';
import type { Product, ProductType } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import { useUserAgent } from 'bundles/page/contexts/UserAgentApiContext';
import SearchResultsPagination from 'bundles/search-common/components/SearchResultsPagination';
import { LEARNING_PRODUCTS_ENUM_MAP, SEARCH_MAIN_INDEX_ID, TABLET_SCREEN_WIDTH } from 'bundles/search-common/constants';
import { useSearch } from 'bundles/search-common/providers/SearchContext';
import type { SearchProductHit } from 'bundles/search-common/providers/searchTypes';

type Props = {
  domain?: { id: string };
  subdomain?: { id: string };
  programId?: string;
  onProductCardClick?: (product: Product, productType: ProductType, collectionId?: string | null) => void;
  thirdPartyOrganizationId?: string;
  isOrgHome?: boolean;
  userId?: number;
};

const useStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      padding: 0 12px;
      padding-bottom: 20px;
      margin: 0 auto;

      ul.rc-EnterpriseSearchCards {
        margin: 1rem 0 !important;
      }

      li.SearchHitWrapper {
        &:not(:last-child) {
          border-bottom: 1px solid var(--cds-color-grey-50) !important;
        }

        .card-content,
        button {
          background: none !important;
        }
      }
    `,
  };
};

export const ProgramHomeDomainSubdomainBodyPlaceholder = () => {
  const styles = useStyles();
  return (
    <div className="rc-ProgramHomeDomainSubdomainBody" css={styles.container}>
      <div className="rc-CollectionLists m-t-2 CollectionListPlaceholder" data-e2e="CollectionLists">
        <EnterpriseProductCardCollectionsPlaceholder />
        <EnterpriseProductCardCollectionsPlaceholder />
      </div>
    </div>
  );
};

export function ProgramHomeDomainSubdomainBody({
  domain,
  subdomain,
  programId,
  onProductCardClick,
  thirdPartyOrganizationId,
  isOrgHome,
  userId,
}: Props): JSX.Element {
  const { getSearchResults, setPage, isLoading } = useSearch();
  const searchData = getSearchResults(SEARCH_MAIN_INDEX_ID);
  const { elements, query, pagination, totalPages, facetFilters, source } = searchData || {};
  const userAgent = useUserAgent();
  const styles = useStyles();
  // keep filters and hits on states to prevent from empty on loading
  const [activeFilters, setActiveFilters] = useState<string[]>([...new Set(facetFilters?.flat())]);
  const contextType = subdomain ? 'SUBCATEGORY' : 'CATEGORY';
  const contextId = subdomain?.id || domain?.id;
  const userSelectedFilters = activeFilters?.filter(filterHiddenFilters);
  const haveAppliedFilters = (userSelectedFilters?.length || 0) > 0;

  const groupedFilters = groupBy(activeFilters, (filter) => filter.split(':')[0]);
  const keyedFilters = keyBy(groupedFilters, (filter) => filter[0]?.split(':')?.[0]);
  const filtersApplied = mapValues(keyedFilters, (group) => map(group, (filter) => filter.split(':')[1]));
  const eventingData = {
    searchIndex: source?.indexName || '',
    searchIndexPosition: 1,
    page: pagination?.cursor || undefined,
    searchQuery: query,
    filtersApplied,
  };

  useEffect(() => {
    if (!isLoading) {
      const newActiveFilters = [...new Set(facetFilters?.flat())];
      setActiveFilters(newActiveFilters);
    }
  }, [isLoading, facetFilters]);

  const renderSearchHit = (matches: boolean) => {
    const CardComponent = matches ? MobileParitySearchCard : DesktopParitySearchCard;
    return (hit: SearchProductHit, cardData?: EnterpriseCardData) => {
      const eventingV3Data = {
        algoliaIndexName: source?.indexName || '',
      };

      return (
        <Grid item xs={12} key={hit.id} component="li" className="SearchHitWrapper">
          <CardComponent
            hit={{
              ...hit,
              entityType: LEARNING_PRODUCTS_ENUM_MAP[hit.productType?.toUpperCase() || ''],
              objectID: hit.id,
            }}
            hitPosition={cardData?.hitPosition}
            isProgramHome
            eventingV3Data={eventingV3Data}
          />
        </Grid>
      );
    };
  };

  return (
    <PageGridContainer className="rc-ProgramHomeDomainSubdomainBody" css={styles.container}>
      <Grid item xs={12}>
        {haveAppliedFilters && (
          <Media query={{ maxWidth: TABLET_SCREEN_WIDTH }} defaultMatches={userAgent.isMobileBrowser}>
            {(matches) => (
              <React.Fragment>
                <EnterpriseSearchCards
                  hits={elements as SearchProductHit[]}
                  resultsPerPage={10}
                  eventingData={eventingData}
                  programId={programId}
                  thirdPartyOrganizationId={thirdPartyOrganizationId || ''}
                  renderSearchHit={renderSearchHit(matches)}
                  placeholder={<DesktopParitySearchCardPlaceholder />}
                  isLoading={isLoading}
                />
                {(totalPages || 0) > 1 && (
                  <SearchResultsPagination
                    // note: edge case when on last page, cursor will be null because it is actually nextCursor value
                    // we need to fallback to totalPages
                    paginationData={{ ...pagination, totalPages, page: Number(pagination?.cursor) || totalPages || 1 }}
                    currentQuery={query}
                    setPage={(pageNum: number) => setPage(SEARCH_MAIN_INDEX_ID, pageNum)}
                    eventingData={eventingData}
                  />
                )}
              </React.Fragment>
            )}
          </Media>
        )}
        {!haveAppliedFilters && !isOrgHome && programId ? (
          <BrowseCollectionList
            programId={programId}
            contextId={contextId}
            contextType={contextType}
            onProductCardClick={onProductCardClick}
          />
        ) : null}
        {!haveAppliedFilters && isOrgHome && thirdPartyOrganizationId && userId ? (
          <OrgHomeBrowseCollectionList
            contextId={contextId}
            contextType={contextType}
            onProductCardClick={onProductCardClick}
            thirdPartyOrganizationId={thirdPartyOrganizationId}
            userId={userId}
          />
        ) : null}
      </Grid>
    </PageGridContainer>
  );
}

export default ProgramHomeDomainSubdomainBody;
