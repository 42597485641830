/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo } from 'react';

import { Typography2 } from '@coursera/cds-core';

import ResizableContainer from 'bundles/authoring/common/components/ResizableContainer';
import EditorContainer from 'bundles/cml/editor/components/EditorContainer';
import StickyToolbar from 'bundles/cml/editor/components/toolbars/StickyToolbar';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';
import type { ToolbarPosition } from 'bundles/cml/editor/types/cmlEditorProps';

const styles = {
  label: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  scrollContainer: css`
    height: 100%;
    overflow: auto;
  `,
  resizableContent: css`
    height: 100%;
  `,
};

const TOOLBAR_HEIGHT = 53;
const BORDER_WIDTH = 1 * 2;
const MIN_HEIGHT = 104;
const MAX_HEIGHT = 320;

export type Props = {
  id: string;
  focused: boolean;
  resizable: boolean;
  readOnly?: boolean;
  label?: React.ReactNode;
  minHeight?: number;
  maxHeight?: number;
  borderColor?: string;
  footer?: { node: React.ReactNode; height: number };
  children: React.ReactNode;
  toolbarPosition?: ToolbarPosition;
};

const DEFAULT_BORDER_COLOR = 'var(--cds-color-neutral-primary-weak)';
const FOCUS_BORDER_COLOR = 'var(--cds-color-blue-700)';
const READ_ONLY_BORDER_COLOR = 'var(--cds-color-neutral-stroke-primary-weak)';

const ResizableCMLEditor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    id,
    focused,
    readOnly,
    minHeight = MIN_HEIGHT,
    maxHeight = MAX_HEIGHT,
    borderColor: userBorderColor,
    footer,
    children,
    toolbarPosition = 'bottom',
    label,
  } = props;

  const { tools, options } = useToolsContext();
  const minContentHeight =
    Math.max(minHeight, MIN_HEIGHT) - (readOnly ? 0 : TOOLBAR_HEIGHT) - BORDER_WIDTH - (footer?.height ?? 0);

  const borderColor = useMemo(() => {
    if (userBorderColor) {
      return userBorderColor;
    }

    if (readOnly) {
      return READ_ONLY_BORDER_COLOR;
    }

    return focused ? FOCUS_BORDER_COLOR : DEFAULT_BORDER_COLOR;
  }, [userBorderColor, focused, readOnly]);

  return (
    <div>
      {label && (
        <Typography2 css={styles.label} variant="subtitleMedium" component="label">
          {label}
        </Typography2>
      )}

      <ResizableContainer
        contentSelector={`[id="${id}"] .data-cml-editor-scroll-content`}
        resizable
        minHeight={Math.max(minHeight, MIN_HEIGHT)}
        initialHeight={maxHeight}
        borderColor={borderColor}
      >
        <div ref={ref} className="data-cml-editor-scroll-container" css={styles.scrollContainer}>
          <div className="data-cml-editor-scroll-content">
            <EditorContainer
              toolbar={!readOnly && <StickyToolbar customTools={tools} options={options} position={toolbarPosition} />}
              toolbarPosition={toolbarPosition}
              css={styles.resizableContent}
              style={{ minHeight: minContentHeight }}
            >
              {children}
            </EditorContainer>
            {footer?.node}
          </div>
        </div>
      </ResizableContainer>
    </div>
  );
});

export default React.memo(ResizableCMLEditor);
