import * as React from 'react';

import { Col, StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import type { LearningOutcome, PRODUCT_TYPE } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import type { ProductGlanceList } from 'bundles/enterprise-legacy-xdp/components/ProductGlance';
import ProductGlance from 'bundles/enterprise-legacy-xdp/components/ProductGlance';
import ProductReviewOverviewStatistics from 'bundles/enterprise-legacy-xdp/components/ProductReviewOverviewStatistics';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

export const renderToggleButtonContentFunction = ({ collapsed }: { collapsed: boolean }) => (
  <span>{collapsed ? _t('SHOW ALL') : _t('SHOW LESS')}</span>
);

export const renderToggleButtonWithoutCollapse = (context?: string) => {
  return ({ collapsed }: { collapsed: boolean }) =>
    collapsed ? (
      <div>
        <span className="screenreader-only">{_t('SHOW ALL #{context}', { context })}</span>
        <span aria-hidden="true">{_t('SHOW ALL')}</span>
      </div>
    ) : null;
};

export const renderReadMoreButtonWithoutCollapse = (context?: string) => {
  return ({ collapsed }: { collapsed: boolean }) =>
    collapsed ? (
      <div>
        <span className="screenreader-only">{_t('Read more #{context}', { context })}</span>
        <span aria-hidden="true">{_t('Read more')}</span>
      </div>
    ) : null;
};

export const renderToggleButtonContentWithContext = (context: string) => {
  return ({ collapsed }: { collapsed: boolean }) => (
    <div>
      <span className="screenreader-only">
        {collapsed ? _t('SHOW ALL #{context}', { context }) : _t('SHOW LESS #{context}', { context })}
      </span>
      <span aria-hidden="true">{collapsed ? _t('SHOW ALL') : _t('SHOW LESS')}</span>
    </div>
  );
};

const styles = StyleSheet.create({
  atGlanceMarginTop: {
    marginTop: '24px',
  },
  hideOnLarge: {
    [`@media (min-width: ${breakPoint.lg + 1}px)`]: {
      display: 'none',
    },
  },
  hideOnSmall: {
    [`@media (max-width: ${breakPoint.lg}px)`]: {
      display: 'none',
    },
  },
});

export const renderDesktopAtAGlanceItems = (
  glanceList: ProductGlanceList,
  productType: PRODUCT_TYPE,
  iconColor?: string,
  learningOutcomes?: LearningOutcome
) => {
  return (
    <Col xs={12} rootClassName={css('p-l-2 p-r-0', styles.atGlanceMarginTop, styles.hideOnSmall).className}>
      {learningOutcomes && (
        <ProductReviewOverviewStatistics
          productType={productType}
          iconColor={iconColor}
          isPositionedAtGlance={true}
          {...learningOutcomes}
        />
      )}
      <ProductGlance dataList={glanceList} iconColor={iconColor} />
    </Col>
  );
};

export const renderMobileAtAGlanceItems = (
  glanceList: ProductGlanceList,
  productType: PRODUCT_TYPE,
  iconColor?: string,
  learningOutcomes?: LearningOutcome
) => {
  return (
    <div>
      {learningOutcomes && (
        <div className={css(styles.hideOnLarge).className}>
          <ProductReviewOverviewStatistics
            productType={productType}
            iconColor={iconColor}
            isPositionedAtGlance={true}
            {...learningOutcomes}
          />
        </div>
      )}
      <ProductGlance
        dataList={glanceList}
        iconColor={iconColor}
        rootClassName={css('p-t-2', styles.hideOnLarge).className}
      />
    </div>
  );
};
