/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Grid, Tag } from '@coursera/cds-core';
import { SwapIcon } from '@coursera/cds-icons';
import { useTracker } from '@coursera/event-pulse/react';

import { CoachBrandLabel } from 'bundles/ai-coach-platform/components/branding/CoachBranding';
import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import {
  CloseIcon,
  CollapseIcon,
  ExpandIcon,
  NewChatIcon,
  SettingsIcon,
} from 'bundles/ai-coach-platform/components/icons/mui';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  onClose: () => void;
  showClose: boolean;
  showStartNewChat?: boolean;
  showSettings?: boolean;
  handleNewSession?: () => void;
  handleSettingsClick?: () => void;
  loading?: boolean;
  isCoachStudio?: boolean;
  showFullScreenButton?: boolean;
  toggleFullScreen?: () => void;
  isFullScreen?: boolean;
  showPositionToggle?: boolean;
  handlePositionToggleClick?: () => void;
};

/**
 * Default header component for Coach chat pattern
 */
const ChatHeader = (props: Props) => {
  const {
    onClose,
    handleSettingsClick,
    showSettings,
    showClose,
    showStartNewChat = true,
    handleNewSession,
    loading = false,
    isCoachStudio = false,
    showFullScreenButton = false,
    toggleFullScreen,
    isFullScreen = false,
    showPositionToggle,
    handlePositionToggleClick,
  } = props;
  const trackRetracked = useRetracked();

  const trackingData = useCoachTrackingContext()?.data;

  const track = useTracker();

  const handleCoachSettingsClick = () => {
    handleSettingsClick?.();

    track('interact_coach', {
      coachInteractionType: 'coach_settings_open',
      coach: {
        mode: trackingData?.mode,
      },
    });
  };

  const fullScreenButtonLabel = isFullScreen ? _t('Collapse') : _t('Expand');

  return (
    <Grid
      container
      className="ai-coach-chat-header"
      justifyContent="space-between"
      css={css`
        padding: 0 var(--cds-spacing-200);
        height: 54px;
        align-items: center;
      `}
    >
      <Grid
        item
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <CoachBrandLabel />
        {isCoachStudio && (
          <Tag
            variant="status"
            priority="primary"
            color="highlight1"
            css={css`
              margin-left: var(--cds-spacing-50);
            `}
          >
            STUDIO
          </Tag>
        )}
      </Grid>
      <Grid
        item
        css={css`
          display: flex;
          gap: 4px;
        `}
      >
        {showPositionToggle && (
          <CoachIconButton
            size="small"
            aria-label={_t('Swap the popup position between left and right')}
            tooltip={_t('Swap the popup position between left and right')}
            tooltipPlacement="bottom"
            onClick={() => {
              handlePositionToggleClick?.();
            }}
            data-testid="coach-swap-position"
            icon={
              <SwapIcon
                css={css`
                  transform: rotate(90deg);
                  color: var(--cds-color-grey-950);
                `}
              />
            }
            disabled={loading}
          />
        )}
        {showStartNewChat && (
          <CoachIconButton
            size="small"
            aria-label={_t('Start a new chat. This will clear your chat history so you can start fresh.')}
            tooltip={_t('Start a new chat. This will clear your chat history so you can start fresh.')}
            tooltipPlacement="bottom"
            onClick={() => {
              trackRetracked({
                trackingData: {
                  timestamp: Date.now(),
                },
                trackingName: 'ai_course_coach_chat_header_new_session_button',
                action: `click`,
              });
              handleNewSession?.();
            }}
            data-testid="coach-new-chat"
            icon={<NewChatIcon />}
            disabled={loading}
          />
        )}
        {showSettings && (
          <CoachIconButton
            size="small"
            icon={<SettingsIcon />}
            aria-label={_t('Coach settings')}
            tooltip={_t('Settings')}
            tooltipPlacement="bottom"
            data-testid="coach-settings"
            onClick={handleCoachSettingsClick}
            disabled={loading}
          />
        )}
        {showFullScreenButton && (
          <CoachIconButton
            size="small"
            aria-label={fullScreenButtonLabel}
            tooltip={fullScreenButtonLabel}
            tooltipPlacement="bottom"
            onClick={() => {
              toggleFullScreen?.();
            }}
            icon={isFullScreen ? <CollapseIcon /> : <ExpandIcon />}
          />
        )}
        {showClose && (
          <CoachIconButton
            size="small"
            icon={<CloseIcon />}
            aria-label={_t('Close chat')}
            tooltip={_t('Close chat')}
            tooltipPlacement="bottom"
            onClick={() => {
              trackRetracked({
                trackingData: {
                  timestamp: Date.now(),
                },
                trackingName: 'ai_course_coach_chat_header_close_button',
                action: `click`,
              });
              onClose();
            }}
            data-testid="coach-close"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChatHeader;
