import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { isRightToLeft } from 'js/lib/language';

import { Typography, Typography2 } from '@coursera/cds-core';
import { Box, StarRating, color } from '@coursera/coursera-ui';

import type { RatingsType, XDPRatingsType } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/__styles__/XDPRating';

const renderRatingCount = (
  ratingCount: number | null,
  isThemeDark?: boolean,
  shouldShowExpertiseNonControlVariant?: boolean,
  addAsteriskToRatingCount?: boolean
) => {
  if (!ratingCount) {
    return null;
  }

  return (
    <Typography2
      className={classNames({
        'm-r-1s': !shouldShowExpertiseNonControlVariant,
        'color-white': isThemeDark,
        'ratings-count-expertise-style': shouldShowExpertiseNonControlVariant,
      })}
      variant="bodyPrimary"
      component="div"
    >
      {!addAsteriskToRatingCount ? (
        <span data-test="ratings-count-without-asterisks">
          <FormattedMessage message={_t('{ratingsCount} ratings')} ratingsCount={ratingCount.toLocaleString()} />
        </span>
      ) : (
        <span data-test="ratings-count-with-asterisks">
          <FormattedMessage message={_t('{ratingsCount} ratings*')} ratingsCount={ratingCount.toLocaleString()} />
        </span>
      )}
    </Typography2>
  );
};

type Props = XDPRatingsType & RatingsType;

export function XDPRating({
  averageFiveStarRating: rating,
  ratingCount,
  commentCount,
  rootClassName,
  isThemeDark,
  starsColor,
  ratingColor = color.warning,
  shouldUnderlineStars,
  isSDPPage,
  a11yIdentifier,
  addAsteriskToRatingCount,
}: Props) {
  if (!rating || (!ratingCount && !commentCount)) {
    return null;
  }
  const renderRTL = isRightToLeft(_t.getLocale());
  const starRatingProps = {
    rating,
    color: starsColor || color.warning,
    enableColorOpacity: true,
    style: { cursor: 'inherit' },
    htmlAttributes: {
      'aria-hidden': true,
    },
    ...(renderRTL ? { dir: 'rtl' } : {}),
    a11yIdentifier,
  };

  return (
    <Box alignItems="center" flexWrap="nowrap" rootClassName={classNames('XDPRating', rootClassName)}>
      <>
        <TrackedDiv
          trackingName="banner_star_rating"
          trackClicks
          style={{ borderBottom: shouldUnderlineStars ? `2px solid ${starsColor}` : 'none' }}
          data-test="star-rating"
        >
          <StarRating {...starRatingProps} />
        </TrackedDiv>
        <Typography
          component="span"
          className={classNames('m-b-0 rating-text number-rating', {
            'm-l-1s m-r-1': !isSDPPage,
            'number-rating-expertise': isSDPPage,
          })}
          style={{ color: ratingColor }}
          data-test="number-star-rating"
          variant="h2semibold"
        >
          {rating.toFixed(1)}
          <div className="screenreader-only">{_t('stars')}</div>
        </Typography>
      </>
      {renderRatingCount(ratingCount, isThemeDark, isSDPPage, addAsteriskToRatingCount)}
    </Box>
  );
}
export default XDPRating;
