import _ from 'lodash';

import type {
  EnterpriseProductMetadata,
  EnterpriseProductMetadataConfiguration,
  EnterpriseProductMetadataFlags,
  InputField,
} from 'bundles/enterprise-legacy-learner-home/types/programCommon';

const cmlOpener = '<co-content>';
const startString = `${cmlOpener}<text>`;
const endString = '</text></co-content>';

const htmlEscape = (text: string): string =>
  text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\n/g, '</text><text>');

const htmlUnescape = (text: string): string =>
  text
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/<\/text><text>/g, '\n');

function testCMLOpened(cmlString: string): boolean {
  const regStr = new RegExp(cmlOpener, 'ig');
  return regStr.test(cmlString);
}

export function stripAllTagsFromString(string?: string): string {
  return string ? string.replace(/<[^>]+>/g, '') : '';
}

export function extractTextFromDescriptionCMLString(cmlString: string): string | undefined {
  const regStr = new RegExp('(?:' + startString + ')(.*?)(?:' + endString + ')', 'ig');
  const results = regStr.exec(cmlString);
  if (results && results.length > 1) {
    return htmlUnescape(results[1]);
  }
  return undefined;
}

export const stripAllTagsFromCMLString = _.flowRight(stripAllTagsFromString, extractTextFromDescriptionCMLString);

export function extractUnstyledTextFromCML(input: any): string | undefined {
  if (typeof input === 'object') {
    if (input.typeName === 'cml' && input.definition && input.definition.value) {
      return stripAllTagsFromCMLString(input.definition.value);
    }
    return undefined;
  } else if (testCMLOpened(input)) {
    return stripAllTagsFromCMLString(input);
  } else {
    return stripAllTagsFromString(input);
  }
}

export function wrapTextWithCMLString(text = ''): string {
  return `${startString}${htmlEscape(text)}${endString}`;
}

export function clamp(value: number, min: number, max: number): number {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

export function getIsFormValid(inputData: { [fieldName: string]: InputField }) {
  return _.every(inputData, (item) => !item.error);
}

// TODO(Audrey): figure \out why the CUI export didn't work. https://github.com/webedx-spark/coursera-ui/blob/master/src/utils/validation.js#L9
export type ValidationFunc = (x0: string | number) => string | boolean;
export function runValidations(value: string, validations: [ValidationFunc]) {
  let error = false;
  if (validations.length > 0) {
    // shortcircuit when first error is found
    validations.some((validation) => {
      // @ts-expect-error TSMIGRATION
      error = validation.apply(this, [value]);
      return error;
    });
  }
  return error;
}

export const getIdFromProductId = (productId: string) => {
  return productId.split(/~(.+)/)[1]?.replace('!', '');
};

export const getProductMetadata = (
  isCourse: boolean,
  id: string,
  productsMetadata?: EnterpriseProductMetadataConfiguration
): EnterpriseProductMetadataFlags | undefined => {
  return productsMetadata?.find(
    (metadata: EnterpriseProductMetadata | null) =>
      metadata?.metadataType?.[isCourse ? 'course' : 'specialization']?.[isCourse ? 'courseId' : 's12nId'] === id
  )?.metadataType?.[isCourse ? 'course' : 'specialization'];
};
