import {
  CheatingIncident_AppealRequestReason as CheatingIncidentAppealRequestReason,
  CheatingIncident_EventType as CheatingIncidentEventTypes,
  CheatingIncident_ItemType as CheatingIncidentItemTypes,
  CheatingIncident_LearnerServiceContactLogSource as CheatingIncidentLearnerServiceContactLogSources,
  CheatingIncident_CheatingIncidentRuling as CheatingIncidentRulings,
} from '__generated__/graphql-types';

import { HelpCenters, buildSalesforceArticleUrl } from 'bundles/common/utils/salesforceUrlBuilder';
import { buildLearnerHelpUrl } from 'bundles/common/utils/urlUtils';

export const RouteNames = {
  /* Base Paths */
  HONOR_CODE_DASHBOARD: 'honor_code_dashboard',

  /* incidents paths */
  INCIDENT_DETAIL: 'incident_detail',

  /* incident appeal paths */
  INCIDENT_APPEAL: 'incident_appeal',

  AUTO_PROCTOR_RESULTS: 'auto_proctor_results',
  SESSION_SCOPED_AUTO_PROCTOR_RESULTS: 'session_scoped_auto_proctor_results',
  COURSE_SCOPED_AUTO_PROCTOR_RESULTS: 'course_scoped_auto_proctor_results',

  SESSION_SCOPED_AUTO_PROCTOR_RESULTS_DETAIL: 'session_scoped_auto_proctor_results_detail',
  COURSE_SCOPED_AUTO_PROCTOR_RESULTS_DETAIL: 'course_scoped_auto_proctor_results_detail',
} as const;

export {
  CheatingIncidentRulings,
  CheatingIncidentItemTypes,
  CheatingIncidentEventTypes,
  CheatingIncidentLearnerServiceContactLogSources,
  CheatingIncidentAppealRequestReason,
};

export const HonorCodeHelpCenterUrl = buildLearnerHelpUrl('209818863');

export const HonorCodeUrl = buildSalesforceArticleUrl(
  HelpCenters.LEARNER,
  false,
  'en_US',
  '209818863-Coursera-Honor-Code'
);

export const HonorCodeCheckPlagiarismUrl = buildSalesforceArticleUrl(
  HelpCenters.LEARNER,
  false,
  'en_US',
  '360004031371-Check-your-written-assignments-for-plagiarism'
);
