import * as Sentry from '@sentry/react';
import type { Player } from 'video.js';
import type MediaError from 'video.js/dist/types/media-error';

export function handleVideoJsError(
  player: Player | null,
  sentryErrorSource: string,
  metadata?: { [key: string]: object }
) {
  const error = player?.error();
  if (error) {
    let videoJsFormattedError: Error | MediaError = error;

    if (!(error instanceof Error)) {
      // if this catches, it means that the error is not a stringifiable object
      videoJsFormattedError = new Error(`video.js: ${JSON.stringify(error)}`);
    }

    const errorTags = Object.keys(metadata ?? {}).reduce((acc, value) => {
      // Sentry tag character max limit is 200.
      acc[value] = JSON.stringify(metadata?.[value]).slice(0, 200);
      return acc;
    }, {} as { [key: string]: string });

    Sentry.captureException(videoJsFormattedError, {
      tags: { source: sentryErrorSource, errorType: 'media', ...errorTags },
    });
  }
}
