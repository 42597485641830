/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { ChangeEvent } from 'react';
import * as React from 'react';

import { typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/front-page';

type Props = {
  value: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const styles = {
  searchTextField: () => css`
    border: solid 1px var(--cds-color-grey-50);
    border-right: none;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    padding: 0 10px 0 24px;
    height: 60px;
    width: 100%;

    &:focus {
      outline: none;
    }

    ::placeholder {
      opacity: 1;
      color: var(--cds-color-neutral-primary-weak);
      ${typography2.bodyPrimary};
    }
  `,
};

const SearchBarLazyLoadInput: React.FC<Props> = ({ value, onBlur, onFocus, onChange }) => {
  return (
    <input
      type="text"
      name="query"
      placeholder={_t("Search 'python' or 'front-end developer'")}
      autoComplete="off"
      aria-label={_t("Search 'python' or 'front-end developer'")}
      css={styles.searchTextField}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
    />
  );
};

export default SearchBarLazyLoadInput;
