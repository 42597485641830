/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';
import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, Typography2 } from '@coursera/cds-core';
import { StarRating } from '@coursera/coursera-ui';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import type { TopReview } from 'bundles/reviews-common/types/reviewsSharedTypes';
import { ReviewComment } from 'bundles/reviews/components/ReviewComment';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const styles = {
  topReviewsListButton: () => css`
    display: block;
    max-width: 175px;
    white-space: nowrap;
    margin-top: var(--cds-spacing-400);
  `,

  topReviewsListItem: () => css`
    margin-top: var(--cds-spacing-300);
    max-width: 850px;
  `,

  topReviewsListItemInfo: () => css`
    margin-top: 0;
    margin-bottom: var(--cds-spacing-150);
  `,

  topReviewsListItemComment: () => css`
    margin: 0;
  `,
};

type PropsFromCaller = {
  productName: string;
  reviews: Array<TopReview>;
  numReviewsToDisplay: number;
  isSDP?: boolean;
  pathname?: string;
};

type PropsToComponent = PropsFromCaller;

export const TopReviewListItem = ({
  review: { averageFiveStarRating, authorName, comment, timestamp },
}: {
  review: TopReview;
}) => {
  if (!comment) {
    return null;
  }

  const starRatingProps = {
    rating: averageFiveStarRating,
    a11yIdentifier: authorName + timestamp,
  };

  return (
    <TrackedDiv css={styles.topReviewsListItem} trackingName="updated_reviews_module_review">
      <StarRating {...starRatingProps} />
      <div css={styles.topReviewsListItemInfo}>
        <Typography2 component="p" variant="bodySecondary">
          {_t('by #{authorName}', { authorName })}
        </Typography2>
        <Typography2 variant="bodySecondary" component="span">
          {formatDateTimeDisplay(timestamp, MED_DATE_ONLY_DISPLAY)}
        </Typography2>
      </div>
      <ReviewComment comment={comment} textVariant="body2" />
    </TrackedDiv>
  );
};

export const TopReviewsList = ({ reviews, numReviewsToDisplay, pathname, productName, isSDP }: PropsToComponent) => {
  if (!reviews?.length) {
    return null;
  }

  const reviewsPathname = pathname?.endsWith('/') ? pathname?.slice(0, -1) : pathname;

  return (
    <div>
      <Typography2 variant="subtitleMedium" component="h3" color="supportText">
        <FormattedHTMLMessage
          message={_t('TOP REVIEWS FROM {productName}')}
          productName={<span itemProp="itemReviewed">{productName.toUpperCase()}</span>}
        />
      </Typography2>
      {reviews.slice(0, numReviewsToDisplay).map((review) => (
        <TopReviewListItem key={review.reviewId} review={review} />
      ))}
      {
        // SDP doesn't have a dedicated reviews page.
        !isSDP && (
          <Button
            trackingName="reviews_page_link_button"
            css={styles.topReviewsListButton}
            component={TrackedLink2}
            variant="secondary"
            href={`${reviewsPathname}/reviews`}
            data-e2e="reviews-page-link"
          >
            {_t('View all reviews')}
          </Button>
        )
      }
    </div>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  connectToRouter(({ location: { pathname } }) => ({
    pathname,
  }))
)(TopReviewsList);
