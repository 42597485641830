import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';

import { Box, Pill, breakPoint } from '@coursera/coursera-ui';

import type { ReviewHighlight } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

export type Props = {
  highlights: Array<ReviewHighlight>;
};

export const HighlightsDesktop = ({ highlights }: Props) => (
  <Box flexDirection="column" rootClassName="border-bottom p-b-1">
    <span className="text-secondary m-b-1s">{_t('Highlights')}</span>
    <Box rootClassName="p-y-1" flex={1} flexWrap="wrap">
      {highlights.map(({ highlightText, reviewcount }) => (
        <Pill key={highlightText} rootClassName="m-b-1 m-r-1s" type="outline" size="lg">
          <Box alignItems="center" tag="span">
            <Box rootClassName="font-sm bold d-inline-block" flex={1}>
              {highlightText}
            </Box>
            {reviewcount > 0 && <span className="d-inline-block m-x-1s">({reviewcount})</span>}
          </Box>
        </Pill>
      ))}
    </Box>
  </Box>
);

export const HighlightsMobile = ({ highlights }: Props) => (
  <Box>
    <span className="text-secondary p-t-1 m-b-1s">{_t('Highlights')}</span>
    <Box rootClassName="p-a-1" flex={1} flexWrap="wrap">
      {highlights.map(({ highlightText, reviewcount }) => (
        <Pill key={highlightText} rootClassName="m-b-1 m-r-1s" type="outline" size="lg">
          <Box alignItems="center" tag="span">
            <Box rootClassName="font-sm bold d-inline-block" flex={1}>
              {highlightText}
            </Box>
            {reviewcount > 0 && (
              <span className="d-inline-block m-x-1s">
                <FormattedMessage message={_t('({numReviews} Reviews)')} numReviews={reviewcount} />
              </span>
            )}
          </Box>
        </Pill>
      ))}
    </Box>
  </Box>
);

export default function Highlights({ highlights }: Props) {
  return (
    <div className="Highlights">
      <Media query={{ maxWidth: breakPoint.lg }}>
        {(matches) =>
          matches ? <HighlightsDesktop highlights={highlights} /> : <HighlightsMobile highlights={highlights} />
        }
      </Media>
    </div>
  );
}
