import type * as React from 'react';
import { graphql } from 'react-apollo';

import { flatMap } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import type {
  EnterpriseSearchBarImpl,
  SearchScope,
} from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchBarV2';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import { ProgramSwitcherQueryText } from 'bundles/program-home/components/multiprogram/ProgramSwitcher';
import type {
  ProgramSwitcherQuery,
  ProgramSwitcherQueryVariables,
} from 'bundles/program-home/components/multiprogram/__generated__/ProgramSwitcherQuery';
import { org_coursera_program_membership_ProgramMembershipState as MembershipState } from 'bundles/program-home/components/multiprogram/__generated__/globalTypes';

import _t from 'i18n!nls/program-home';

type PropsFromCaller = {
  programSlug: string;
  programName: string;
  thirdPartyOrgSlug: string;
  thirdPartyOrganizationId: string;
  userId: number;
  programId: string;
  enableSkillsInSearchAndBrowse?: boolean;
  shouldShowShortFormContent?: boolean;
  isMobile?: boolean;
};

type PropsFromUnifiedSearchBar = React.ComponentPropsWithoutRef<
  typeof EnterpriseSearchBarImpl & { enableSkillsInSearchAndBrowse: boolean }
>;

export const withUnifiedSearchBarProps = compose<PropsFromUnifiedSearchBar, PropsFromCaller>(
  setDisplayName('UnifiedEnterpriseSearchBarV2'),
  graphql<PropsFromCaller, ProgramSwitcherQuery, ProgramSwitcherQueryVariables, PropsFromUnifiedSearchBar>(
    ProgramSwitcherQueryText,
    {
      // Protect against bad data fetching patterns...
      skip: ({ userId, thirdPartyOrgSlug, programSlug }) => !thirdPartyOrgSlug || !programSlug || !userId,
      options: ({ userId }) => ({
        variables: {
          // See above skip().

          userId: userId!.toString(),
        },
        // Query is executed by the page header, we're just piggy-backing off of it.
        fetchPolicy: 'cache-only',
      }),
      props: ({
        data,
        ownProps: {
          programSlug,
          programName,
          thirdPartyOrgSlug,
          userId,
          programId,
          thirdPartyOrganizationId,
          enableSkillsInSearchAndBrowse,
        },
      }) => {
        const scopes: Array<SearchScope & { key: string }> = filterExistsOrDefault(
          data?.ProgramMembershipsV2Resource?.byUser?.elements
        )
          .filter(
            (element) =>
              element.enterpriseProgram != null &&
              element.enterpriseProgram?.thirdPartyOrganizationId === thirdPartyOrganizationId &&
              element.membershipState === MembershipState.MEMBER
          )
          .map((element) => {
            const {
              id: programId0,
              metadata: { name: programName0, slug: programSlug0 },
              // See above filter().
            } = element.enterpriseProgram!;
            return {
              key: programSlug0,
              trackingData: { programSlug: programSlug0 },
              pathname: `/programs/${programSlug0}`,
              placeholder: _t("Search in '#{programName}'...", { programName: programName0 }),
              title: programName0,
              programIds: [programId0],
            };
          });

        const programIds = flatMap(scopes, (scope) => scope.programIds);

        if (!scopes.find(({ key }) => key === programSlug)) {
          // They aren't a member of the program, but we should show it anyway
          scopes.push({
            key: programSlug,
            trackingData: { programSlug },
            pathname: `/programs/${programSlug}`,
            placeholder: _t("Search in '#{programName}'...", { programName }),
            title: programName,
            programIds: [programId],
          });
        }

        scopes.sort((a, b) => a.placeholder.localeCompare(b.placeholder));

        if (userId && scopes.length > 1) {
          scopes.unshift({
            key: thirdPartyOrgSlug,
            trackingData: { thirdPartyOrgSlug },
            pathname: `/programs/all/${thirdPartyOrgSlug}`,
            placeholder: _t('Search in all programs you joined...'),
            title: _t('All Learning Programs'),
            programIds,
          });
        }

        const initialScopeIndex = Math.max(
          scopes.findIndex(({ key }) => key === programSlug),
          0
        );

        return { scopes, initialScopeIndex, enableSkillsInSearchAndBrowse };
      },
    }
  )
);
