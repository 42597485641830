/* @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo, useState } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { branch } from 'recompose';

import logger from 'js/app/loggerSingleton';
import requestCountry from 'js/lib/requestCountry';

import type { CSSProperties } from '@coursera/coursera-ui';
import { breakPoint } from '@coursera/coursera-ui';
import { SvgCheckV2 } from '@coursera/coursera-ui/svg';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import SubscriptionFooter from 'bundles/enroll/components/subscriptions/SubscriptionFooter';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import usePageData from 'bundles/enroll/data/usePageData';
import type { PrepaidProduct } from 'bundles/enroll/types/ProductInfoTypes';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import {
  getHoursPerWeekFromCourses,
  getTotalMonthsFromCourses,
} from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import paymentsExperiments from 'bundles/epic/clients/payments';
import { SPECIALIZATION_PREPAID } from 'bundles/payments/common/ProductType';
import Modal from 'bundles/phoenix/components/Modal';
import NonRecurringChoiceFooter from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoiceFooter';
import NonRecurringChoices from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoices';
import { NON_RECURRING_CHOICES } from 'bundles/s12n-enroll/components/non-recurring/utils/constants';
import { useNonRecurringChoiceData } from 'bundles/s12n-enroll/components/non-recurring/utils/nonRecurringChoiceDataUtils';
import {
  useS12nProductInfo,
  withS12nProductInfo,
} from 'bundles/s12n-enroll/components/non-recurring/withS12nProductInfo';
import type { PropsFromWithS12nProductInfo } from 'bundles/s12n-enroll/components/non-recurring/withS12nProductInfo';

import _t from 'i18n!nls/s12n-enroll';

const MODAL_TYPES = {
  ENROLL: 'ENROLL',
  ERROR: 'ERROR',
};

type PropsFromCaller = {
  s12nId: string;
  onSdp: boolean;
  courseId?: string;
  isFromS12nSelection?: boolean;
  onClose?: () => void;
};

type PropsToComponent = PropsFromCaller & PropsFromWithS12nProductInfo;

const styles: Record<string, CSSProperties> = {
  nonRecurringEnrollModal: {
    '.c-modal-content': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 !important',
      height: '75% !important',
      // Set max height to 650 to give users notice of more content to scroll down towards
      maxHeight: '650px !important',
      [`@media (max-width: 767px)`]: {
        maxWidth: '100% !important',
        width: '100% !important',
        top: '0 !important',
        maxHeight: 'initial !important',
        height: '100% !important',
      },
    },
  },
  closeButton: {
    color: '#fafafa !important',
  },
  modalContent: {
    padding: '30px 0 0 0',
  },
  overflowContainer: {
    overflow: 'scroll',
    [`@media (max-width: 767px)`]: {
      height: '100% !important',
      overflow: 'auto',
    },
  },
  titleContainer: {
    position: 'relative',
    padding: '40px 32px 32px',
    background: '#08274e',
  },
  title: {
    fontFamily: 'OpenSans',
    color: '#fff',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'center',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      textAlign: 'left',
    },
  },
  modalBodyContainer: {
    padding: '0 50px',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      padding: '0 30px',
    },
  },
  valuePropsTitle: {
    display: 'block',
    fontSize: '14px',
    marginBottom: '14px',
  },
  valueProps: {
    listStyle: 'none',
    paddingLeft: '0',
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      marginBottom: '10px',
    },
  },
  valuePropsEliminateScroll: {
    marginBottom: '10px',
  },
  valueProp: {
    width: '48%',
    marginBottom: '4px',
    display: 'flex',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      width: '100%',
    },
  },
  auditFooter: {
    width: '300px',
    margin: '16px auto 0',
  },
  courseCardContainer: {
    display: 'block',
    background: '#F5F7F8',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '25px',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      marginBottom: '10px',
    },
  },
  specializationCourseCountBadge: {
    background: '#231960',
    borderRadius: '4px',
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: '1px 4px',
  },
  desktopDisplay: {
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      display: 'none',
    },
  },
  mobileDisplay: {
    [`@media (min-width: ${breakPoint.md}px)`]: {
      display: 'none',
    },
  },
};

const getValueProps = (numOfCourses: number, partnerName: string) => {
  if (requestCountry.get() !== 'IN') {
    return [
      _t('Unlimited access to all #{numOfCourses} courses', { numOfCourses }),
      _t('Shareable certificate of completion from #{partnerName}', { partnerName }),
      _t('14 day refund period'),
    ];
  }
  return [
    _t('Unlimited access to all #{numOfCourses} courses', { numOfCourses }),
    _t('EMI payment options'),
    _t('Shareable certificate of completion from #{partnerName}', { partnerName }),
    _t('14 day refund period'),
  ];
};

const _handleContinue = (
  setIsEnrolling: (isEnrolling: boolean) => void,
  setEnrollmentError: (error: ApiError | undefined) => void,
  setActiveModal: (modalType: string) => void,
  enrollmentAvailableChoices: EnrollmentChoices,
  enrollmentChoice: keyof typeof EnrollmentChoiceTypes,
  s12nId: string,
  isFirstNonRecurringChoiceSelected?: boolean,
  courseId?: string,
  additionalData?: { paymentPassOption: PrepaidProduct },
  promoCode?: string
) => {
  setIsEnrolling(true);
  setEnrollmentError(undefined);

  const data = {
    s12nId,
    ...additionalData,
  };

  // productSkuId is not needed because this modal only shows prepaid, not subscription
  const options = {
    courseId,
    s12nId,
    data,
  };

  // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoice];
  submitEnrollmentPromise({
    handleSubmitPromise,
    options,
    promoCode: isFirstNonRecurringChoiceSelected ? promoCode : undefined,
  }).catch((error: ApiError) => {
    setEnrollmentError(error);
    setActiveModal(MODAL_TYPES.ERROR);
  });
};

export const NonRecurringEnrollModal: React.FunctionComponent<PropsToComponent> = ({
  productName,
  product,
  s12nName,
  partnerName,
  numOfCourses,
  courses,
  onSdp,
  s12nId,
  courseId,
  onClose,
  promotionDetails,
  promotionEligibilities,
}) => {
  const { enrollmentAvailableChoices } = usePageData();
  const { getEnrollModalTrackingRef, trackEnrollModalClose, trackEnrollModalContinue } = useEnrollModalEventing();

  const [activeModal, setActiveModal] = useState(MODAL_TYPES.ENROLL);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState<ApiError | undefined>(undefined);
  const [choiceType, setChoiceType] = useState(NON_RECURRING_CHOICES.PASS_TWO);

  const visibilityTrackingRef = getEnrollModalTrackingRef();

  const numOfHoursPerWeek = getHoursPerWeekFromCourses(courses);
  const numOfMonths = getTotalMonthsFromCourses(courses);

  const { prepaidOptions } = useNonRecurringChoiceData(s12nId);

  const promoCode = promotionEligibilities?.isEligible ? promotionEligibilities?.promoCodeId : undefined;

  const auditComponent = !onSdp ? (
    <div css={styles.auditFooter}>
      <SubscriptionFooter removeStyles />
    </div>
  ) : undefined;

  const specializationInfo = useMemo(() => {
    return (
      <p data-test="courseText">
        {onSdp
          ? _t('#{productName} is a #{numOfCourses}-course #{product}.', { productName, numOfCourses, product })
          : _t('#{productName} is part of the #{s12nName} certificate.', { productName, s12nName })}
      </p>
    );
  }, [productName, numOfCourses, s12nName, onSdp, product]);

  const valuePropsDiv = useMemo(() => {
    const valueProps = getValueProps(numOfCourses, partnerName);
    return (
      <div>
        <strong aria-hidden={true} css={styles.valuePropsTitle} id="value-props-description">
          {_t('This #{product} includes:', { product })}
        </strong>
        <ul css={styles.valueProps} aria-labelledby="value-props-description">
          {valueProps.map((valueProp) => (
            <li data-test="valueProp" key={valueProp} css={styles.valueProp}>
              <SvgCheckV2
                suppressTitle
                style={{
                  width: '18px',
                  minWidth: '18px',
                  height: '14px',
                  marginRight: '14px',
                  marginTop: '5px',
                  fill: '#2A73CC',
                }}
              />
              <p>{valueProp}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  }, [product, numOfCourses, partnerName]);

  if (!prepaidOptions || !enrollmentAvailableChoices) {
    logger.error('Unable to render enroll modal content');
    return null;
  }

  const handleClose = () => {
    if (onClose) {
      trackEnrollModalClose();
      onClose();
    }
  };

  const handleContinue = (paymentPassOption: PrepaidProduct) => {
    const enrollmentChoiceType =
      paymentPassOption.productType === SPECIALIZATION_PREPAID
        ? EnrollmentChoiceTypes.ENROLL_THROUGH_S12N_PREPAID
        : EnrollmentChoiceTypes.PURCHASE_THROUGH_COURSERA_PLUS;
    const ownershipDays = paymentPassOption.productProperties.ownershipDays;

    trackEnrollModalContinue(enrollmentChoiceType, { ownershipDays });

    _handleContinue(
      setIsEnrolling,
      setEnrollmentError,
      setActiveModal,
      enrollmentAvailableChoices,
      enrollmentChoiceType,
      s12nId,
      choiceType === NON_RECURRING_CHOICES.PASS_ONE,
      courseId,
      {
        paymentPassOption,
      },
      promoCode
    );
  };

  switch (activeModal) {
    case MODAL_TYPES.ENROLL: {
      return (
        <div data-test="rc-NonRecurringEnrollModal" css={styles.nonRecurringEnrollModal}>
          <CSSTransitionGroup transitionName="fade" transitionEnter={false} transitionLeaveTimeout={300}>
            <Modal
              trackingName="non_recurring_enroll_modal"
              data={{ id: s12nId }}
              key="NonRecurringEnrollModal"
              modalName={_t('Join this #{product}', { product })}
              allowClose={false}
            >
              <div css={styles.overflowContainer} ref={visibilityTrackingRef}>
                <div css={styles.titleContainer}>
                  {/* This is a manual duplicate of the Modal's close button except moved to be the first element
                  because it takes focus upon  opening the modal, allowing VO + Right Arrow to continue reading 
                  the modal contents sequentially. TODO: work with Design to replace with CDS Dialog */}
                  <div className="c-modal-x-out">
                    <button
                      data-test="closeButton"
                      aria-label={_t('close')}
                      className="nostyle"
                      css={styles.closeButton}
                      type="button"
                      onClick={handleClose}
                    >
                      ✕
                    </button>
                  </div>
                  <h2 css={styles.title}>
                    {onSdp
                      ? _t('Enroll in this #{numOfCourses}-course #{product}', { numOfCourses, product })
                      : _t('This course is part of a #{numOfCourses}-course #{product}', { numOfCourses, product })}
                  </h2>
                </div>
                <div css={styles.modalContent}>
                  <div css={styles.modalBodyContainer}>
                    {specializationInfo}
                    {valuePropsDiv}
                  </div>
                  <NonRecurringChoices
                    numOfCourses={numOfCourses}
                    numOfHoursPerWeek={numOfHoursPerWeek}
                    numOfMonths={numOfMonths}
                    choiceType={choiceType}
                    setChoiceType={setChoiceType}
                    isFullDiscount={!!promoCode && promotionDetails?.discountPercent === 100}
                    s12nId={s12nId}
                  />
                </div>
              </div>
              <NonRecurringChoiceFooter
                choiceType={choiceType}
                onButtonClick={handleContinue}
                isEnrolling={isEnrolling}
                auditComponent={auditComponent}
              />
            </Modal>
          </CSSTransitionGroup>
        </div>
      );
    }
    case MODAL_TYPES.ERROR:
      return <EnrollErrorModal error={enrollmentError} onClose={handleClose} isFinancialAid={false} />;
    default:
      logger.error('Unable to render NonRecurringEnrollModal');
      return null;
  }
};

const NonRecurringEnrollModalWithHook: React.FunctionComponent<PropsFromCaller> = (props) => {
  const { courseId } = props;
  const skip = !paymentsExperiments.preview('preloadEnrollModalEnabled');
  const s12nProductInfoProps = useS12nProductInfo({ courseId, skip });

  return <NonRecurringEnrollModal {...props} {...(s12nProductInfoProps as PropsFromWithS12nProductInfo)} />;
};

export default branch<PropsFromCaller>(
  () => !paymentsExperiments.preview('preloadEnrollModalEnabled'),
  withS12nProductInfo<PropsFromCaller>()
)(NonRecurringEnrollModalWithHook);
