/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import type {
  XdpV1_Org_Coursera_Xdp_Common_XdpCourseModule as WeekModules,
  XdpV1_Org_Coursera_Xdp_Common_XdpCourseWeek as Weeks,
} from '__generated__/graphql-types';

import { Button, Collapse } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { PRODUCTS } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import type { PRODUCT_TYPE } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import SyllabusContentRating from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/SyllabusContentRating';
import SyllabusWeek from 'bundles/enterprise-legacy-xdp/components/cdp/syllabus/SyllabusWeek';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })(Button);

type Props = {
  weeks: Weeks[];
  slug: string;
  activeCourseId: string;
  averageContentSatisfactionScore?: number;
  contentSatisfactionRatingsCount?: number;
  productType?: PRODUCT_TYPE;
};

type State = {
  isExpanded: boolean;
};

class Syllabus extends React.Component<Props, State> {
  state = {
    isExpanded: false,
  };

  toggleCollapse = () => {
    this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
  };

  renderCourseSyllabusHtmlOnly = () => {
    const { weeks, slug, activeCourseId } = this.props;
    if (weeks.length > 4) {
      return (
        <div className="syllabusHtmlOnly" style={{ display: 'none' }}>
          {weeks.slice(4).map((week, index) => (
            <SyllabusWeek
              key={index}
              courseId={activeCourseId}
              modules={week?.modules as Array<WeekModules>}
              sectionNumber={index + 5}
              slug={slug}
              isLast={index === weeks.length - 1}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  renderCourseSyllabusClient = (): JSX.Element => {
    const { weeks, slug, activeCourseId } = this.props;
    const { isExpanded } = this.state;
    if (weeks.length > 4) {
      return (
        <div data-test="syllabus-collapse" role="list" aria-label={_t('Syllabus')}>
          {weeks.slice(0, 4).map((week, index) => (
            <SyllabusWeek
              key={index}
              courseId={activeCourseId}
              modules={week?.modules as Array<WeekModules>}
              sectionNumber={index + 1}
              slug={slug}
              isLast={index === weeks.length - 1}
              role="listitem"
            />
          ))}
          <Collapse onChange={this.toggleCollapse}>
            <Collapse.Panel
              id="panel-syllabus-collapse"
              footer={
                <TrackedButton
                  rootClassName="m-t-1 d-block m-x-auto"
                  size="md"
                  trackingName={isExpanded ? 'syllabus_button_see_less' : 'syllabus_button_show_more'}
                >
                  {isExpanded ? _t('Show Less') : _t('Show More')}
                </TrackedButton>
              }
              bodyRole=""
            >
              {weeks.slice(4).map((week, index) => (
                <SyllabusWeek
                  key={index}
                  courseId={activeCourseId}
                  modules={week?.modules as Array<WeekModules>}
                  sectionNumber={index + 5}
                  slug={slug}
                  isLast={index === weeks.length - 1}
                  role="listitem"
                />
              ))}
            </Collapse.Panel>
          </Collapse>
        </div>
      );
    }
    return (
      <div data-test="syllabus-no-collapse" aria-label={_t('Syllabus')}>
        {weeks.map((week, index) => (
          <SyllabusWeek
            key={index}
            courseId={activeCourseId}
            modules={week?.modules as Array<WeekModules>}
            sectionNumber={index + 1}
            slug={slug}
            isLast={index === weeks.length - 1}
          />
        ))}
      </div>
    );
  };

  render() {
    const { weeks = [], averageContentSatisfactionScore, contentSatisfactionRatingsCount, productType } = this.props;
    const { isExpanded } = this.state;

    if (weeks.length === 0) {
      return null;
    }

    return (
      <div className="Syllabus">
        <div className="p-b-4">
          <h2 className="p-t-4 m-b-0 headline-4-text text-xs-center bold">
            {_t('Syllabus - What you will learn from this course')}
          </h2>
          {productType === PRODUCTS.course && (
            <SyllabusContentRating
              averageRating={averageContentSatisfactionScore}
              ratingsCount={contentSatisfactionRatingsCount}
            />
          )}
        </div>
        {this.renderCourseSyllabusClient()}
        {!isExpanded && this.renderCourseSyllabusHtmlOnly()}
      </div>
    );
  }
}

export default Syllabus;
