import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('choiceInput', [
  'root',
  'checked',
  'disabled',
  'focusVisible',
  'indeterminate',
]);

const styles = css`
  --input-fill: var(--cds-color-neutral-background-primary);
  --input-color: var(--cds-color-neutral-stroke-primary);

  fill: var(--input-fill);
  color: var(--input-color);
  padding: 0;

  & input + svg {
    fill: inherit;
  }

  &:hover,
  &:active,
  &.${classes.disabled}, &.${classes.checked}:hover {
    background-color: transparent;
  }

  &:hover,
  &.${classes.focusVisible} {
    --input-fill: var(--cds-color-interactive-background-primary-hover-weak);
    --input-color: var(--cds-color-interactive-primary-hover);
  }

  &:active {
    --input-fill: var(--cds-color-interactive-background-primary-pressed-weak);
    --input-color: var(--cds-color-interactive-primary-pressed);
  }

  &.${classes.checked}, &.${classes.indeterminate} {
    --input-color: var(--cds-color-interactive-primary);
    --input-fill: var(--cds-color-interactive-primary);

    /* Override MUI's default color */
    color: var(--input-color);

    &.${classes.focusVisible} {
      --input-fill: var(--cds-color-interactive-primary-hover);
      --input-color: var(--cds-color-interactive-primary-hover);
    }

    &:hover {
      --input-fill: var(--cds-color-interactive-primary-hover);
      --input-color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      --input-fill: var(--cds-color-interactive-primary-pressed);
      --input-color: var(--cds-color-interactive-primary-pressed);
    }
  }

  &.${classes.focusVisible} {
    svg {
      border-radius: var(--cds-border-radius-25);
      box-shadow: var(--cds-color-neutral-primary-invert) 0 0 0 2px,
        var(--cds-color-interactive-stroke-primary-focus) 0 0 0 3px,
        var(--cds-color-interactive-background-primary-hover-weak) 0 0 0 4px;
    }
  }

  &.${classes.disabled} {
    --input-fill: var(--cds-color-neutral-disabled);
    --input-color: var(--cds-color-neutral-disabled-strong);

    &.${classes.checked} {
      --input-fill: var(--cds-color-neutral-disabled-strong);
      --input-color: var(--cds-color-neutral-disabled-strong);
    }
  }
`;

export default styles;
