/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import type {
  AiCoach_ItemNavigationButton as AiCoachItemNavigationButton,
  AiCoach_RedirectLinkNavigationButton as AiCoachRedirectLinkNavigationButton,
} from '__generated__/graphql-types';
import moment from 'moment';

import { useRetracked } from 'js/lib/retracked';
import { useLocation, useNavigate } from 'js/lib/useRouter';
import { get as getUser } from 'js/lib/user';
import { humanizeLearningTime } from 'js/utils/DateTimeUtils';

import { Typography2 } from '@coursera/cds-core';

import { NavigationButtonContainer } from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/NavigationButtonContainer';
import { toggleChat } from 'bundles/ai-coach-platform/components/patterns/chat/store/actions';
import type {
  ExtendedAiCoachLectureItemMetadata,
  ExtendedAiCoachReadingItemMetadata,
  NavigationButtonItem,
} from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { generateCourseItemLink } from 'bundles/ai-coach-platform/components/patterns/chat/utils/recommendationsUtils';

type Props = { navigationButton: AiCoachItemNavigationButton | AiCoachRedirectLinkNavigationButton };

const styles = {
  itemNavigationButtonContainer: css`
    display: flex;
    flex-direction: column;
    gap: var(--cds-spacing-200);
    margin-top: var(--cds-spacing-200);
    margin-bottom: var(--cds-spacing-200);
  `,
  leadInText: css`
    color: var(--cds-color-grey-700);
  `,
};

export const NavigationButtons = (props: Props): React.ReactElement | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trackRetracked = useRetracked();
  const location = useLocation().pathname;
  const currentUser = getUser();

  const { navigationButton } = props;
  const navigationButtonType = navigationButton?.__typename;

  // Currently, we only support item navigation buttons. But eventually we may support redirect link navigation buttons as well.
  if (navigationButtonType === 'AiCoach_ItemNavigationButton') {
    const buttonLabel = navigationButton?.buttonLabel;
    const courseSlug = navigationButton.itemMetadata.course.slug;
    const itemSlug = navigationButton.itemMetadata.itemSlug;
    const itemId = navigationButton.itemMetadata.itemId;
    const title = navigationButton.itemMetadata.itemTitle;
    let itemType: NavigationButtonItem;
    let itemDuration = [''];

    switch (navigationButton.itemMetadata.__typename) {
      case 'AiCoach_ReadingItemMetadata':
        itemType = 'supplement';
        itemDuration = [
          humanizeLearningTime(
            moment.duration((navigationButton.itemMetadata as ExtendedAiCoachReadingItemMetadata)?.readingDuration)
          ) || '',
        ];
        break;
      case 'AiCoach_LectureItemMetadata':
        itemType = 'lecture';
        itemDuration = [
          humanizeLearningTime(
            moment.duration((navigationButton.itemMetadata as ExtendedAiCoachLectureItemMetadata).lectureDuration)
          ),
        ];
        break;
      default:
        itemType = null;
    }

    if (!itemType) {
      return null;
    }

    const handleNavButtonClick = () => {
      const courseItemLink = generateCourseItemLink({ itemType, courseSlug, itemSlug, itemId });

      const sharedEventingData = {
        userLocale: currentUser.locale,
        navMessage: navigationButton.navigationLeadingMessage,
        navButtonType: navigationButtonType,
      };

      // TODO: Add V3 eventing for navigation buttons
      trackRetracked({
        trackingData: {
          userId: currentUser.id,
          userLocale: sharedEventingData.userLocale,
          timestamp: Date.now(),
          navMessage: sharedEventingData.navMessage,
          navButtonType: sharedEventingData.navButtonType,
          navTargetUrl: courseItemLink,
          referrerUrl: location,
        },
        trackingName: 'ai_course_coach_recommended_navigation',
        action: `click`,
      });

      dispatch(toggleChat());
      navigate(courseItemLink, { replace: false });
    };

    return (
      <div css={styles.itemNavigationButtonContainer}>
        <Typography2 variant="bodyPrimary" component="p" css={styles.leadInText}>
          <strong>{navigationButton.navigationLeadingMessage}</strong>
        </Typography2>

        <NavigationButtonContainer
          itemId={itemId}
          title={title}
          itemType={itemType}
          itemDuration={itemDuration}
          buttonLabel={buttonLabel}
          handleNavButtonClick={handleNavButtonClick}
        />
      </div>
    );
  }

  return null;
};

export default NavigationButtons;
