/** @jsx jsx */
import { jsx } from '@emotion/react';

import { pure } from 'recompose';

import type { ButtonProps } from '@coursera/coursera-ui';
import { ApiButton, Button } from '@coursera/coursera-ui';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  btnAttributes?: ButtonProps;
  onClick: () => void;
  apiStatus: ApiStatus;
  canUnenroll?: boolean;
  isEnrolled?: boolean;
  renderNothingIfConditionNotMet?: boolean;
  initialBtnLabel?: JSX.Element | string;
};

const styles = {
  loading: {
    color: 'var(--cds-color-neutral-primary)',
    borderColor: 'var(--cds-color-neutral-primary)',
  },
};

const CourseUnenrollButton = ({
  btnAttributes = {},
  onClick,
  apiStatus,
  canUnenroll,
  isEnrolled,
  renderNothingIfConditionNotMet,
  initialBtnLabel = _t('Unenroll'),
}: Props) => {
  const renderUnenroll = isEnrolled && canUnenroll;
  const renderEnrolled = isEnrolled && !renderNothingIfConditionNotMet;
  if (renderUnenroll) {
    return (
      <ApiButton
        {...btnAttributes}
        style={apiStatus === 'API_IN_PROGRESS' && { ...styles.loading }}
        htmlAttributes={{
          ...btnAttributes?.htmlAttributes,
          'aria-live': 'polite',
          'aria-busy': apiStatus === 'API_IN_PROGRESS',
        }}
        iconAttributes={{
          suppressTitle: true,
        }}
        onClick={onClick}
        apiStatus={apiStatus}
        apiStatusAttributesConfig={{
          label: {
            API_BEFORE_SEND: initialBtnLabel,
            API_IN_PROGRESS: _t('Unenrolling...'),
            API_SUCCESS: _t('Unenrolled'),
            API_ERROR: _t('Failed'),
          },
        }}
      />
    );
  } else if (renderEnrolled) {
    return <Button {...btnAttributes} type="disabled" label={_t('Enrolled')} disabled={true} />;
  }
  return null;
};

export default pure(CourseUnenrollButton);
