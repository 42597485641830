import type {
  AiCoach_Action as AiCoachAction,
  AiCoach_CoachActionConfig as AiCoachCoachActionConfig,
  AiCoach_ItemNavigationButton as AiCoachItemNavigationButton,
  AiCoach_RedirectLinkNavigationButton as AiCoachRedirectLinkNavigationButton,
} from '__generated__/graphql-types';

import type { SenderType } from 'bundles/ai-coach-platform/components/patterns/chat/types';

import { MESSAGES_TYPES, MESSAGE_SENDER, getErrorMessageText } from '../constants';
import type { Feedback, MessageTypes as MessageI, MessageTypes, StructuredResponse } from '../store/types';

export const isClientMessage = (sender: SenderType | string) => sender === MESSAGE_SENDER.CLIENT;
export const isCoachMessage = (sender: SenderType | string) => sender === MESSAGE_SENDER.RESPONSE;

export const isTextMessage = (message: MessageTypes) => message.type === 'text';
export const isErrorMessage = (message: MessageTypes) => message.text === getErrorMessageText();

export function createNewMessage({
  text,
  sender,
  id,
  userFeedback,
  timestamp,
  action,
  navigationButtons,
  structuredResponse,
  recommendedActions,
}: {
  text: string;
  sender: string;
  id?: string;
  userFeedback?: Feedback | null;
  timestamp?: number;
  action?: AiCoachAction;
  navigationButtons?: (AiCoachItemNavigationButton | AiCoachRedirectLinkNavigationButton)[] | null;
  structuredResponse?: StructuredResponse;
  recommendedActions?: AiCoachCoachActionConfig[];
}): MessageI {
  return {
    type: MESSAGES_TYPES.TEXT,
    text,
    sender,
    timestamp: timestamp ? new Date(timestamp) : new Date(),
    showAvatar: true,
    customId: id,
    unread: sender === MESSAGE_SENDER.RESPONSE,
    userFeedback,
    action,
    navigationButtons,
    structuredResponse,
    recommendedActions,
  };
}
