/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useReducer } from 'react';

import {
  Button,
  Hidden,
  Pagination,
  SelectField,
  SelectOption,
  Typography,
  Typography2,
  useTheme,
} from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import SkillScoringInformationCard from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/SkillScoringInformationCard';
import { PlaceholderShimmer } from 'bundles/skills-common';

import _t from 'i18n!nls/program-home';

import { usePagination } from './CdsHooks';
import SkillCard, { SkillCardPlaceholder } from './SkillCard';
import type { LevelSetFiltersOptions, SortOptions } from './SkillsDashboardHooks';
import { useFilter, useSort } from './SkillsDashboardHooks';
import { withSkillsData } from './withSkillsData';
import type { DataFromSkills } from './withSkillsData';

const useStyles = () => {
  const { spacing, breakpoints } = useTheme();
  return {
    header: css`
      display: flex;
      justify-content: space-between;
      margin-bottom: ${spacing(24)};
    `,
    faqButtonIcon: css`
      margin-left: ${spacing(8)};
    `,
    filterByText: css`
      margin-top: ${spacing(12)};
    `,
    filterAndSort: css`
      display: flex;
      align-items: end;
      gap: ${spacing(8)};
      flex-wrap: wrap;
    `,
    select: css`
      width: 200px;

      & > div {
        /* the default CDS spacing between label and div is way too large */
        margin-top: ${spacing(4)} !important;
      }
      ${breakpoints.down('xs')} {
        width: 100%;
      }
    `,
    resetButton: css`
      margin-right: auto;
      ${breakpoints.down('xs')} {
        width: 100%;
      }
    `,
    entries: css`
      margin: ${spacing(12, 0)};
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: ${spacing(24, 32)};
    `,
    pagination: css`
      display: flex;
      justify-content: center;
    `,
    skillCard: css`
      flex: 1 1 50%;
      max-width: 48%;
      ${breakpoints.down('md')} {
        max-width: 100%;
      }
    `,
    emptySkills: css`
      flex: 1 1 auto;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: ${spacing(24)};
      gap: ${spacing(12)};
    `,
  };
};

type PropsFromParent = {
  programSlug: string;
};
type Props = DataFromSkills & PropsFromParent;

const SkillsDashboardPlaceholder = () => {
  const styles = useStyles();
  return (
    <div>
      <div css={styles.header}>
        <Typography variant="h1">{_t('All Skills')}</Typography>
        <PlaceholderShimmer width="300px" />
      </div>
      <div css={styles.filterAndSort}>
        <PlaceholderShimmer height="120px" width="100%" />
      </div>
      <div css={styles.entries}>
        {[...Array(24).keys()].map((i) => (
          <SkillCardPlaceholder css={styles.skillCard} key={i} />
        ))}
      </div>
      <PlaceholderShimmer />
    </div>
  );
};

const SkillsDashboardWithData: React.FunctionComponent<Props> = (props) => {
  const styles = useStyles();
  const [isFaqOpened, toggleFaqOpened] = useReducer((opened) => !opened, false);

  const filter = useFilter(props.skillProgress);
  const [subject, setSubject] = filter.subject;
  const [levelsetStatus, setLevelsetStatus] = filter.levelsetStatus;
  const sort = useSort();
  const [sortValue, setSortValue] = sort.select;
  const filteredElements = props.skillProgress.filter(filter.filter).sort(sort.sort);
  const page = usePagination({
    elements: filteredElements,
    defaultPageSize: 24,
  });
  const [currentPage, setCurrentPage] = page.currentPage;
  const [pageSize, setPageSize] = page.pageSize;

  const resetFilters = () => {
    setSubject('');
    setLevelsetStatus('ALL');
  };

  if (props.loadingSkills) {
    return <SkillsDashboardPlaceholder />;
  }

  return (
    <div>
      <div css={styles.header}>
        <Typography variant="h1">{_t('All Skills')}</Typography>
        <Button onClick={toggleFaqOpened} variant="ghost" aria-expanded={isFaqOpened}>
          {_t('How does scoring work?')}
          {isFaqOpened ? <ChevronUpIcon css={styles.faqButtonIcon} /> : <ChevronDownIcon css={styles.faqButtonIcon} />}
        </Button>
      </div>
      <SkillScoringInformationCard showTargetsFAQ={false} isOpen={isFaqOpened} />
      <div css={styles.filterByText}>
        <Typography2 variant="subtitleMedium" component="span" color="highlightBlue">
          {_t('Filter By')}
        </Typography2>
      </div>
      <div css={styles.filterAndSort}>
        <SelectField
          css={styles.select}
          label={_t('Subject')}
          value={subject}
          onChange={(e) => setSubject(e.target.value as string)}
        >
          {filter.subjectOptions.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectField>

        <SelectField
          css={styles.select}
          label={_t('LevelSet status')}
          value={levelsetStatus}
          onChange={(e) => setLevelsetStatus(e.target.value as LevelSetFiltersOptions)}
        >
          {filter.levelsetStatusOptions.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectField>
        <Hidden xsDown css={styles.resetButton}>
          <Button variant="ghost" onClick={resetFilters}>
            {_t('Reset')}
          </Button>
        </Hidden>
        <Hidden css={styles.resetButton} smUp>
          <Button fullWidth variant="secondary" onClick={resetFilters}>
            {_t('Reset Filters')}
          </Button>
        </Hidden>
        <SelectField
          css={styles.select}
          label={_t('Sort')}
          value={sortValue}
          onChange={(e) => setSortValue(e.target.value as SortOptions)}
        >
          {sort.options.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectField>
      </div>
      <div css={styles.entries}>
        {page.elements.map((skill, index) => (
          <SkillCard
            source="skills-dashboard"
            trackingName="skill_dashboard_skill_card"
            css={styles.skillCard}
            key={skill.skillId}
            skill={skill}
            size="large"
            programSlug={props.programSlug}
            trackingData={{
              order: index,
              filter: {
                subject,
                levelsetStatus,
              },
              sort: sortValue,
              currentPage,
              skillOrder: index,
              skill,
            }}
          />
        ))}
        {page.elements.length === 0 ? (
          <div css={styles.emptySkills} aria-live="polite">
            <Typography variant="h1semibold" component="h2">
              {_t('No matching skills')}
            </Typography>
            <Typography variant="h2semibold" component="span">
              {_t('There are no skill matches for this combination of filters.  ')}
            </Typography>
          </div>
        ) : (
          <div className="sr-only" aria-live="polite">
            {_t('#{count} matching skills.', { count: filteredElements.length })}
          </div>
        )}
      </div>
      <div css={styles.pagination}>
        <Pagination
          total={filteredElements.length}
          page={currentPage}
          onPageChange={(e, selectedPage) => {
            window.scrollTo(0, 0);
            setCurrentPage(selectedPage);
          }}
          pageSize={pageSize}
          onPageSizeChange={(e, size) => setPageSize(size)}
          pageSizeInputOptions={[
            {
              value: 24,
              label: '24',
            },
            {
              value: 48,
              label: '48',
            },
          ]}
        />
      </div>
    </div>
  );
};

const SkillsDashboard = withSkillsData(SkillsDashboardWithData);
export { SkillsDashboardWithData };
export default SkillsDashboard;
