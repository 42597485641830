import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import gql from 'graphql-tag';
import moment from 'moment';

import { Typography, Typography2 } from '@coursera/cds-core';
import { Box, Button, Col, ReadMore, Row, StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import type { CuiEnterpriseProgramSessionAssociationsQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourses_elements_session as PrivateSessionDates } from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/CuiEnterpriseProgramSessionAssociationsQuery';
import type { RatingsType } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import FixedSchedule from 'bundles/enterprise-legacy-xdp/components/FixedSchedule';
import GradientIcon from 'bundles/enterprise-legacy-xdp/components/GradientIcon';
import XDPRating from 'bundles/enterprise-legacy-xdp/components/XDPRating';
import { AutoEnrollBadge, CreditBadge, ExclusiveBadge } from 'bundles/enterprise-ui/components/Badges';
import withFragments from 'bundles/graphql/components/withFragments';

import _t from 'i18n!nls/coursera-ui';

const styles = StyleSheet.create({
  leftColumn: {
    display: 'inline-block',
    textAlign: 'center',
    [`@media (max-width: ${breakPoint.md}px)`]: {
      display: 'block',
    },
  },
  ratingRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const ShowMoreLessA11yStyle: React.CSSProperties = {
  textTransform: 'none',
};

export type Props = {
  id: string;
  name: string;
  slug?: string;
  description: string;
  index: number;
  workload: string;
  ratings?: RatingsType;
  material: {
    totalDuration?: number;
  };
  hideDivider?: boolean;
  onShowCDP: (id: string) => void;
  privateSessionDates?: PrivateSessionDates | null;
  coursesMetadata?: {
    courseId?: string;
    isSelectedForCredit?: boolean;
    isExclusive?: boolean;
    isAutoEnrollmentEnabled?: boolean;
  };
  isCampusWording?: boolean;
};

function AboutS12nCourseListItem({
  id,
  name,
  description,
  index,
  ratings,
  material: { totalDuration } = {},
  hideDivider,
  onShowCDP,
  privateSessionDates,
  coursesMetadata,
  isCampusWording,
}: Props) {
  return (
    <Row rootClassName="CourseItem p-b-3">
      <Col xs={12} md={2} lg={2} rootClassName="p-t-1">
        <div {...css(styles.leftColumn)}>
          <Typography2 component="strong" style={{ letterSpacing: 4 }} className="text-secondary text-uppercase">
            {_t('Course')}
          </Typography2>
          <Typography variant="d2" className="text-secondary d-block m-y-1" component="span">
            {index + 1}
          </Typography>
        </div>
      </Col>
      <Col xs={12} md={10} lg={10} rootClassName={classNames('p-b-3', { 'border-bottom': !hideDivider })}>
        {totalDuration && (
          <Box alignItems="center" rootClassName="m-b-2 ">
            <div className="m-r-1">
              <GradientIcon />
            </div>
            <Typography2 component="strong" className="text-secondary">
              <FormattedMessage
                message={_t('{numHours} hours to complete')}
                numHours={moment.duration(totalDuration).hours()}
              />
            </Typography2>
          </Box>
        )}
        <Button onClick={() => onShowCDP(id)} type="link" size="zero">
          <Typography
            variant="h1semibold"
            component="h3"
            className="m-b-1 text-xs-left"
            style={{ whiteSpace: 'pre-line' }}
          >
            {name}
          </Typography>
        </Button>
        <div {...css(styles.ratingRow)}>
          {coursesMetadata?.isSelectedForCredit && isCampusWording && <CreditBadge className="m-r-2 m-b-2" />}
          {coursesMetadata?.isExclusive && <ExclusiveBadge className="m-r-2 m-b-2" />}
          {coursesMetadata?.isAutoEnrollmentEnabled && <AutoEnrollBadge className="m-r-2 m-b-2" />}
          {ratings && <XDPRating rootClassName="m-b-2" {...ratings} ratingColor="#1f1f1f" />}
        </div>
        {privateSessionDates && (
          <div className="m-b-1">
            <FixedSchedule startsAt={privateSessionDates.startsAt} endsAt={privateSessionDates.endsAt} />
          </div>
        )}
        <Typography2 component="div">
          <ReadMore
            more={
              <span>
                <span aria-hidden>{_t('Show All')}</span>
                <span className="sr-only" style={ShowMoreLessA11yStyle}>
                  {_t('Show all: about #{courseName}', { courseName: name })}
                </span>
              </span>
            }
            less={
              <span>
                <span aria-hidden>{_t('Show Less')}</span>
                <span className="sr-only" style={ShowMoreLessA11yStyle}>
                  {_t('Show less: about #{courseName}', { courseName: name })}
                </span>
              </span>
            }
          >
            {description}
          </ReadMore>
        </Typography2>
      </Col>
    </Row>
  );
}

export default withFragments({
  course: gql`
    fragment AboutS12nCourseListItemFragment on XdpV1_org_coursera_xdp_sdp_SDPCourse {
      id
      name
      description
      slug
      # workload / estimatedWorkload
      ratings {
        averageFiveStarRating
        ratingCount
        commentCount
      }
    }
  `,
})(AboutS12nCourseListItem);
