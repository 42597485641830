/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { Button, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { BellIcon } from '@coursera/cds-icons';
import { useTracker } from '@coursera/event-pulse/react';

import withSingleTracked from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/notification-center';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

type Props = {
  active: boolean;
  unreadCount: number;
  onClick: () => void;
};

const styles = {
  button: css`
    border: none;
    outline: none;
    background: none;
    position: relative;
    margin: 0;
    padding: var(--cds-spacing-150);
    color: var(--cds-color-neutral-primary-weak);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--cds-color-interactive-background-primary-hover-weak);
    }
  `,
  icon: css`
    display: block;

    ${breakpoints.up('sm')} {
      width: 24px;
      height: 24px;
    }
  `,
  unreadCount: css`
    width: 20px;
    height: 20px;
    color: #fff;
    background-color: var(--cds-color-red-700);
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    border-radius: 50px;
    position: absolute;
    top: 6px;
    right: 14px;
  `,
};

const NotificationIcon: React.FC<Props> = ({ active = false, unreadCount, onClick }) => {
  const track = useTracker();

  /* Tracking only if unread notifications are present. This reduces event noise and doesn't track if bell icon renders intermittently for logged out users */
  useEffect(() => {
    if (unreadCount > 0) {
      track('view_notifications_icon', {
        totalUnreadNotifications: unreadCount,
      });
    }
  }, [unreadCount, track]);

  /* Click icon handler with tracking */
  const handleClick = () => {
    track('click_notifications_icon', {
      totalUnreadNotifications: unreadCount,
    });
    onClick();
  };

  return (
    <TrackedButton
      onClick={handleClick}
      trackingData={{ unreadCount, active }}
      className="rc-NotificationIcon"
      trackingName="notification_icon"
      css={styles.button}
      variant="ghost"
      aria-label={
        unreadCount > 0 ? _t('Notifications, #{unreadCount} new notifications', { unreadCount }) : _t('Notifications')
      }
      aria-expanded={active}
    >
      <BellIcon size="medium" css={styles.icon} color="support" />
      <div
        data-testid="unread-count"
        className="rc-NotificationIcon__UnreadCount"
        role="presentation"
        hidden={unreadCount <= 0}
        css={styles.unreadCount}
      >
        {unreadCount}
      </div>
    </TrackedButton>
  );
};

export default NotificationIcon;
