/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import EnterpriseGrowth from 'bundles/epic/data/defaults/EnterpriseGrowth.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  enableEnterpriseWebsiteContentPreview: boolean;
  enableEntWebFullStory: boolean;
  enableWESEmailFirstPage: boolean;
  enableEnterpriseXDPPromoUnits: boolean;
  enableC4BNavPromo: boolean;
  enableDriftChatbot: boolean;
  defaultExcludeMutiny: boolean;
  enableBusinessEmailValidation: boolean;
};

const NAMESPACE = 'EnterpriseGrowth';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([EnterpriseGrowth as $TSFixMe]);

const EnterpriseGrowthEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default EnterpriseGrowthEpicClient;
