/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { times } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import Retracked from 'js/lib/retracked';

import { Grid, breakpoints } from '@coursera/cds-core';

import SkillSetFilterResultsA11yAnnouncer from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFilterResultsA11yAnnouncer';
import type { FilterSelections } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersHOCDGS';
import type { SkillSetListQuery_EnterpriseTargetSkillProfileSummariesV1Resource_byProgram_elements as TargetSkillProficiencySummary } from 'bundles/program-home/components/enterprise-home/__generated__/SkillSetListQuery';
import { SkillSetCardV3, SkillSetCardV3Placeholder } from 'bundles/program-home/components/multiprogram/SkillSetCardV3';
import { getFilterTrackingData } from 'bundles/program-home/utils/ProgramHomeUtils';

type PropsFromCaller = {
  programId: string;
  programSlug: string;
  skillsets: TargetSkillProficiencySummary[];
  skillSetLength?: number;
  filterSelections?: FilterSelections;
  loading?: boolean;
};

type PlaceholderProps = {
  skillSetLength?: number;
};

export type Props = PropsFromCaller;

const styles = {
  skillSetsContainer: css`
    margin-bottom: var(--cds-spacing-300);
  `,
  gridItem: css`
    margin-bottom: var(--cds-spacing-300);

    ${breakpoints.down('sm')} {
      margin-bottom: var(--cds-spacing-200);
    }
    ${breakpoints.down('xs')} {
      margin-bottom: var(--cds-spacing-400);
    }
  `,
};

export function SkillSetsContainerV2Placeholder({ skillSetLength }: PlaceholderProps) {
  return (
    <div css={styles.skillSetsContainer}>
      <Grid container spacing={{ md: 24, sm: 16 }}>
        {times(skillSetLength || 4, (value) => (
          <Grid key={value} css={styles.gridItem} item xs={12} sm={6}>
            <SkillSetCardV3Placeholder />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export function SkillSetsContainerV2({ skillSetLength = 0, programSlug, filterSelections, skillsets, loading }: Props) {
  if (loading) return <SkillSetsContainerV2Placeholder />;
  if (skillSetLength) {
    const filterTrackingData = getFilterTrackingData(filterSelections);

    return (
      <div css={styles.skillSetsContainer}>
        <SkillSetFilterResultsA11yAnnouncer
          filteredSkillSetCount={skillSetLength}
          filterSelections={filterSelections}
          loading={loading} // We don't want to update the count until all skillsets are retrieved
        />
        <Grid container spacing={{ md: 24, sm: 16 }} data-e2e="SkillSetsContainerV2">
          {skillsets.map((skillWithCollection, idx) => (
            <Grid key={skillWithCollection.id} css={styles.gridItem} item xs={12} sm={6}>
              <SkillSetCardV3
                skillSetOrder={idx}
                skillSummary={skillWithCollection}
                programSlug={programSlug}
                filterTrackingData={filterTrackingData}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  return null;
}

export const enhance = compose<Props, PropsFromCaller>(
  setDisplayName('SkillSetsContainerV2'),
  Retracked.createTrackedContainer<PropsFromCaller>(({ programId }) => ({ programId }))
);

export default enhance(SkillSetsContainerV2);
