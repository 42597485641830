/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useNavigate } from 'js/lib/useRouter';

import { Button, Typography2, breakpoints } from '@coursera/cds-core';

import { redirectClientOrServer } from 'bundles/common/utils/urlUtils';
import EnrollmentChoiceModal from 'bundles/enterprise-legacy-learner-home/components/EnrollmentChoiceModal';
import AutoenrolledCourseList from 'bundles/enterprise-legacy-learner-home/components/modals/AutoenrolledCourseList';
import {
  AUTOENROLLED_COURSES_COOKIE_KEY,
  AUTOENROLLED_COURSES_COOKIE_PATH,
} from 'bundles/enterprise-legacy-learner-home/components/modals/constants';
import { TabName } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import { toProgramHomeTab } from 'bundles/enterprise-legacy-learner-home/links';
import type { ProductCardCourseFragmentFragment as Course } from 'bundles/enterprise-legacy-learner-home/queries/__generated__/ProductCoursesQuery';
import storageUtils from 'bundles/enterprise-legacy-learner-home/utils/storageUtils';
import { useIsUnifiedAppCheck } from 'bundles/unified-common/contexts/UnifiedAppCheckContext';

import _t from 'i18n!nls/program-home';

export type Props = {
  autoEnrolledCoursesWithData: Course[];
  setIsAutoenrolledCoursesModalDismissed: (isDimissed: boolean) => void;
  userId: number;
  programId: string;
  programSlug: string;
};

const styles = {
  section: css`
    margin: var(--cds-spacing-400) var(--cds-spacing-600) 0;
    ${breakpoints.down('xs')} {
      margin: var(--cds-spacing-300) var(--cds-spacing-200) 0;
    }
  `,
  text: css`
    border-bottom: 1px solid var(--cds-color-neutral-disabled);
    padding-bottom: var(--cds-spacing-300);
  `,
};

export const AutoenrolledCoursesModal = ({
  autoEnrolledCoursesWithData,
  setIsAutoenrolledCoursesModalDismissed,
  userId,
  programSlug,
  programId,
}: Props) => {
  const navigate = useNavigate();
  const isInUnifiedApp = useIsUnifiedAppCheck();
  const onDismissClick = () => {
    setIsAutoenrolledCoursesModalDismissed(true);
    const currentCookieValue = storageUtils.get(
      userId,
      AUTOENROLLED_COURSES_COOKIE_PATH,
      AUTOENROLLED_COURSES_COOKIE_KEY,
      []
    );

    storageUtils.set(userId, AUTOENROLLED_COURSES_COOKIE_PATH, AUTOENROLLED_COURSES_COOKIE_KEY, [
      ...autoEnrolledCoursesWithData.map((course) => course.id),
      ...currentCookieValue,
    ]);
  };

  const onActionClick = (e: React.MouseEvent) => {
    const viewCoursesHref = toProgramHomeTab(programSlug, TabName.MyCourses);
    e.preventDefault();
    onDismissClick();

    if (!isInUnifiedApp) {
      navigate(viewCoursesHref);
    } else {
      redirectClientOrServer(viewCoursesHref);
    }
  };

  return (
    <div data-e2e="AutoEnrollmentModal">
      <EnrollmentChoiceModal
        onClose={onDismissClick}
        headerTitle={_t('Welcome!')}
        button={
          <Button variant="primary" onClick={onActionClick} data-e2e="autoenrollment-modal-view-courses-button">
            {_t('View courses')}
          </Button>
        }
      >
        <div css={styles.section}>
          <Typography2 component="p" variant="bodyPrimary" css={styles.text}>
            {_t(
              'You’ve been automatically enrolled in the following courses by your organization. Find them in My Courses.'
            )}
          </Typography2>
          <AutoenrolledCourseList
            autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
            programId={programId}
            userId={userId}
          />
        </div>
      </EnrollmentChoiceModal>
    </div>
  );
};

export default AutoenrolledCoursesModal;
