import type { Coach } from '@coursera/event-pulse-types';
import { createTrackerStore } from '@coursera/event-pulse/react';

export type CoachTrackingData = {
  data: {
    courseId?: string;
    itemId?: string;
    degreeId?: string;
    mode: string;
    location?: Coach['location'];
  };
};

const [CoachTrackingDataProvider, useCoachTrackingContext] = createTrackerStore<CoachTrackingData>();

export { CoachTrackingDataProvider, useCoachTrackingContext };
