import * as React from 'react';

import { ProgramUserCredits_CoursesRemainingReason as CoursesRemainingReason } from '__generated__/graphql-types';

import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';

import { StyleSheet, color, css, font, spacing } from '@coursera/coursera-ui';

import type { SpecializationAndUserCredits_UserCreditsFragment as UserCredits } from 'bundles/enterprise-legacy-learner-home/queries/__generated__/SpecializationAndUserCreditsQuery';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const styles = StyleSheet.create({
  creditWrapper: {
    padding: `0.2rem ${spacing.md}`,
    border: `1px solid ${color.accent}`,
    borderRadius: '1.6rem',
    fontSize: font.sm,
  },
});

type InputProps = {
  programId?: string;
  isModalCredit?: boolean;
  userCredits?: UserCredits;
};

type Props = InputProps & {
  style?: React.CSSProperties;
  renderUnlimited?: boolean;
};

const AvailableCredit = ({ userCredits, style = {}, isModalCredit, renderUnlimited }: Props) => {
  if (!userCredits) {
    return null;
  }

  const { isLimited, coursesRemaining, totalAllocatedCourses, coursesRemainingReason } = userCredits;
  const hasFullCreditAvailable = coursesRemaining === totalAllocatedCourses;
  const numericCoursesRemaining = coursesRemaining ?? 0;

  const renderModalCredit = isLimited && isModalCredit;
  const renderFullCredit = !renderModalCredit && hasFullCreditAvailable && numericCoursesRemaining > 0;
  const renderNonFullCredit = !renderModalCredit && !hasFullCreditAvailable && numericCoursesRemaining > 0;

  // If limited by enrollment cap, don't display any limits until there are no more enrollments
  if (
    isLimited &&
    numericCoursesRemaining > 0 &&
    coursesRemainingReason === CoursesRemainingReason.ProgramUserCreditsEnrollmentCapLimit
  ) {
    return null;
  }

  return (
    <span style={style}>
      {renderModalCredit && (
        <span {...css(styles.creditWrapper)}>
          {coursesRemaining === 0 && <FormattedHTMLMessage message={_t('No more enrollments left')} />}
          {numericCoursesRemaining > 0 && (
            <FormattedHTMLMessage
              message={_t(
                `
                You may take
                <b>{totalLimitCount} {totalLimitCount, plural, =1 {course} other {courses}}</b>
              `
              )}
              totalLimitCount={coursesRemaining}
            />
          )}
        </span>
      )}
      {coursesRemaining === 0 && !renderModalCredit && (
        <FormattedHTMLMessage message={_t('No more enrollments left')} />
      )}
      {renderFullCredit && (
        <FormattedMessage
          message={_t(
            `
            Take up to {totalLimitCount}
            {totalLimitCount, plural, =1 {course} other {courses}}
          `
          )}
          totalLimitCount={totalAllocatedCourses}
        />
      )}
      {renderNonFullCredit && (
        <FormattedMessage
          message={_t(
            `
            Take {courseLimitCount} more
            {courseLimitCount, plural, =1 {course} other {courses}}
          `
          )}
          courseLimitCount={coursesRemaining}
        />
      )}
      {renderUnlimited && !isLimited && <span {...css(styles.creditWrapper)}>{_t('Take unlimited courses')}</span>}
    </span>
  );
};

export default AvailableCredit;

export const BaseComponent = AvailableCredit;
