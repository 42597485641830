/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import omit from 'lodash/omit';
import { compose } from 'recompose';

import API from 'js/lib/api';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';
import helpcenter from 'js/lib/courseraHelpcenter';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import { Button, TextField } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { spacing } from '@coursera/coursera-ui';
import type { ValidationResult } from '@coursera/coursera-ui/lib/utils/validation';
import { email, maxLength, runValidations } from '@coursera/coursera-ui/lib/utils/validation';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import type { PropsFromWithApiHandler } from 'bundles/coursera-ui/components/hocs/withApiHandler';
import withApiHandler from 'bundles/coursera-ui/components/hocs/withApiHandler';
import { API_SUCCESS } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import type {
  ProgramAddEmailModalQuery,
  ProgramAddEmailModalQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/components/modals/__generated__/ProgramAddEmailModalQuery';
import type { ApiError } from 'bundles/phoenix/components/ApiNotification';
import Modal from 'bundles/phoenix/components/Modal';
import { API_TAGS } from 'bundles/program-home/constants/ProgramHomeConstants';
import type ProgramLearnerApiManager from 'bundles/program-home/utils/ProgramLearnerApiManager';

import _t from 'i18n!nls/program-home';

type PropsFromCaller = {
  programId: string;
  programName: string;
  userId: number;
  apiManager: ProgramLearnerApiManager;
  thirdPartyOrganizationId: string;
};

type Props = PropsFromCaller & PropsFromWithApiHandler;

const styles = {
  modalContent: css`
    min-width: ${spacing.minWidth};
    max-width: '100%';
    text-align: 'left';
    background-color: 'red';
  `,
  header: css`
    width: '100%';
    color: 'red';
  `,
};

export const ProgramAddModalQueryText = gql`
  query ProgramAddEmailModalQuery($thirdPartyOrganizationId: String!) {
    GdprSettingsV1Resource {
      get(id: $thirdPartyOrganizationId) {
        id
        recoveryAccountOnHomePage
      }
    }
  }
`;

export function ProgramAddEmailModal({
  apiStatus,
  userId,
  apiManager,
  programId,
  programName,
  thirdPartyOrganizationId,
  handleApiPromise,
}: Props) {
  const [alternativeEmailField, setAlternativeEmailField] = useState<string>('');
  const [alternativeEmailFieldError, setAlternativeEmailFieldError] = useState<ValidationResult>('');
  const [apiError, setApiError] = useState<string>('');
  const [error, setError] = useState<string>('');

  const router = useRouter();
  const isAddEmailParamTrue = router.location.query.showAddEmailModal === 'true';

  const TrackedApiButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

  const { data } = useQuery<ProgramAddEmailModalQuery, ProgramAddEmailModalQueryVariables>(ProgramAddModalQueryText, {
    variables: {
      thirdPartyOrganizationId,
    },
  });

  const showAddEmailModal =
    data?.GdprSettingsV1Resource?.get?.recoveryAccountOnHomePage !== false && isAddEmailParamTrue;

  if (!showAddEmailModal) {
    return null;
  }

  function getTranslations() {
    return {
      header: _t('Create your personal account'),
      headerDone: _t('Check your email'),
      body: _t(
        `
        All the accomplishments you achieved with your company can be linked to your personal account.
        `
      ),
      bodyDone: _t(
        'An email has been sent to #{email}. Please follow the instructions in the link to set up your personal account.',
        { email: user.get().email_address }
      ),
      DEFAULT_ERROR_MSG: _t('An error has occured, please try again later'),
      helpCenterMessage: _t('Need Help? {helpCenterLink}'),
      helpCenterLink: _t('Visit our help center'),
      alternativeEmailLabel: _t('Personal Email Address'),
      alternativeEmailPlaceholder: _t('Enter personal email'),
      apiButtonLabel: {
        API_BEFORE_SEND: _t('Add Personal Email'),
        API_IN_PROGRESS: _t('Adding Personal Email...'),
        API_SUCCESS: _t('Added'),
        API_ERROR: _t('Failed to add'),
      },
    };
  }

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setAlternativeEmailField(value);
    setAlternativeEmailFieldError('');
    setError('');
  };

  const onTextFieldBlur = (): void => {
    const value = runValidations(alternativeEmailField, [email, maxLength(40)]);
    if (value) {
      setAlternativeEmailFieldError(value);
    }
  };

  const dismissAddEmailModal = () => {
    const newQuery = omit(router.location.query, 'showAddEmailModal');
    if (apiStatus === API_SUCCESS) {
      newQuery.showAddEmailModal = 'success';
    }
    router.push({ pathname: router.location.pathname, params: router.params, query: newQuery });
  };

  const sendPasswordResetEmail = async () => {
    const passwordResetAPI = API('/api/password/v2/requestReset', {
      type: 'rest',
    });

    await new Promise<void>((resolve) => {
      passwordResetAPI.post('', { data: { email: user.get().email_address } }).then(() => {
        resolve();
      });
    });
  };

  const onSubmitAlternativeEmail = (givenEmail: string) => {
    const apiPromise = apiManager.setAlternativeEmailPromise({
      email: givenEmail,
      userId,
    });
    setApiError('');

    handleApiPromise({
      apiPromise,
      apiTag: API_TAGS.SET_ALTERNATIVE_EMAIL,
      apiSuccessCallback: () => {
        setTimeout(dismissAddEmailModal, 7000);
        sendPasswordResetEmail();
      },
      apiErrorCallback: (response: ApiError) => {
        const message = (response && response.responseJSON) || {};
        const { errorCode, message: errorMessage, details } = message;
        setApiError(`${errorCode || ''} ${errorMessage || ''} ${details || ''}`);
      },
    });
  };

  const onButtonClick = (): void => {
    setError('');
    onSubmitAlternativeEmail(alternativeEmailField);
  };

  const isSucceeded = apiStatus === API_SUCCESS;

  const helpCenterLink = helpcenter.getEnterpriseHelpLink(user.isAuthenticatedUser());

  const _T = getTranslations();
  return (
    <div className="rc-ProgramNonBlockingModal" data-testid="program-add-email-modal">
      <Modal
        data-e2e="ProgramAddEmailModal"
        modalName="ProgramAddEmailModal"
        trackingName="program_add_email_modal"
        data={{ programId, programName }}
        handleClose={dismissAddEmailModal}
      >
        <div className="vertical-box align-items-absolute-center">
          <div css={styles.modalContent} data-e2e="ProgramAlternativeEmailModal">
            <h2 css={['m-b-1', styles.header]}>{isSucceeded ? _T.headerDone : _T.header}</h2>
            <p className="m-b-2">{isSucceeded ? _T.bodyDone : _T.body}</p>
            {(error || apiError) && (
              <div className="p-b-1">
                <ErrorMessage tag="div" error={error || apiError} defaultErrorMsg={_T.DEFAULT_ERROR_MSG} />
                <FormattedHTMLMessage
                  message={_T.helpCenterMessage}
                  helpCenterLink={<a href={helpCenterLink}> {_T.helpCenterLink} </a>}
                />
              </div>
            )}
            {!isSucceeded && (
              <TextField
                name="alternativeEmailField"
                data-testid="alternative-email-field"
                id="alternativeEmailField"
                fullWidth
                label={_T.alternativeEmailLabel}
                placeholder={_T.alternativeEmailPlaceholder}
                onChange={onTextFieldChange}
                onBlur={onTextFieldBlur}
                validationStatus={alternativeEmailFieldError ? 'error' : undefined}
                validationLabel={alternativeEmailFieldError || ''}
              />
            )}
            <div className="m-t-1 text-xs-right">
              <TrackedApiButton
                data-testid="add_alternative_email_button"
                trackingName="add_alternative_email_button"
                trackingData={{
                  programId,
                  programName,
                }}
                disabled={!!(error || alternativeEmailFieldError || !alternativeEmailField)}
                onClick={onButtonClick}
                loading={apiStatus === 'API_IN_PROGRESS'}
              >
                {_T.apiButtonLabel.API_BEFORE_SEND}
              </TrackedApiButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default compose<Props, PropsFromCaller>(
  withApiHandler({
    shouldResetToDefaultStatus: true,
    apiStatusResetTime: 8000,
  })
)(ProgramAddEmailModal);
