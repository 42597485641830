/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { CardMetadata } from '@coursera/cds-core';
import { ReadingIcon, VideoIcon } from '@coursera/cds-icons';

import type { NavigationButtonItem } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import toHumanReadableTypeName from 'bundles/ondemand/utils/toHumanReadableTypeName';

type Props = {
  itemDuration: string[];
  itemType: NavigationButtonItem;
};

const styles = {
  metadata: css`
    display: flex;
    flex-direction: row;
    gap: var(--cds-spacing-50);
    color: var(--cds-color-neutral-primary-weak);
  `,
};

export const NavigationMetadata = (props: Props): React.ReactElement | null => {
  const { itemDuration, itemType } = props;

  if (!itemType) {
    return null;
  }

  const itemTypeName = toHumanReadableTypeName(itemType);
  let itemIcon;

  switch (itemType) {
    case 'supplement':
      itemIcon = <ReadingIcon />;
      break;
    case 'lecture':
      itemIcon = <VideoIcon />;
      break;
    default:
      itemIcon = null;
  }

  return (
    <div css={styles.metadata}>
      {itemIcon}
      <CardMetadata metadata={[itemTypeName, ...itemDuration]} />
    </div>
  );
};

export default NavigationMetadata;
