/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';
import { compose, lifecycle, setDisplayName, withHandlers, withProps } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';

import { Button, InlineNotification, Provider, Typography, breakpoints, useTheme } from '@coursera/cds-core';

import type { CmlContent } from 'bundles/cml';
import { CML } from 'bundles/cml';
import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import type { PropsFromWithApiHandler } from 'bundles/coursera-ui/components/hocs/withApiHandler';
import withApiHandler from 'bundles/coursera-ui/components/hocs/withApiHandler';
import { extractUnstyledTextFromCML } from 'bundles/enterprise-legacy-learner-home/utils/programCommonUtils';
import type { InjectedNaptime, NaptimeError } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';
import EnterpriseNoticeAcceptanceLogsV1 from 'bundles/naptimejs/resources/enterpriseNoticeAcceptanceLogs.v1';
import EnterpriseNoticesV1 from 'bundles/naptimejs/resources/enterpriseNotices.v1';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import ProgramHomeWrapper from 'bundles/program-home/components/ProgramHomeWrapper';

import _t from 'i18n!nls/phoenix-cdp';

const DEFAULT_LANGUAGE_CODE = 'en';

type Props = {
  error?: string | NaptimeError;
  isAuthenticatedUser: boolean;
  notice: CmlContent;
  title?: CmlContent | string;
  onAcceptAgreement: () => void;
  onLoginBtnClick: () => void;
  programId: string;
  programSlug: string;
  programName: string;
  program: EnterpriseProgramsV1;
  router: InjectedRouter;
  thirdPartyOrganizationId: string;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  userId?: number;
  noticeId: string;
  languageCode?: string;
  naptime: InjectedNaptime;
  reloadProgramMembership: () => void;
  enableSkillsInSearchAndBrowse: boolean;
} & Omit<PropsFromWithApiHandler, 'error'>;

const useStyles = () => {
  const theme = useTheme();
  return {
    page: css`
      background-color: var(--cds-color-grey-25);
      min-height: 100vh;
    `,
    container: css`
      margin-left: auto;
      margin-right: auto;
      max-width: ${breakpoints.values.md}px;
      ${breakpoints.up('md')} {
        padding: var(--cds-spacing-200);
      }
    `,
    main: css`
      background-color: var(--cds-color-white-0);
      padding: 0 var(--cds-spacing-200);

      &::before,
      &::after {
        content: '';
        display: table;
      }

      &::before {
        margin-bottom: var(--cds-spacing-800);
      }

      &::after {
        margin-top: var(--cds-spacing-800);
      }
      ${breakpoints.down('xs')} {
        &::before {
          margin-bottom: var(--cds-spacing-300);
        }

        &::after {
          margin-top: var(--cds-spacing-300);
        }
      }

      /* Override CML styles */
      .rc-CML p {
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
      }
    `,
    title: css`
      text-align: center;
      margin-bottom: var(--cds-spacing-100);
    `,
    subtitle: css`
      text-align: center;
      margin-bottom: var(--cds-spacing-300);
    `,
    button: css`
      display: block;
      margin: var(--cds-spacing-300) auto 0;
    `,
  };
};

export function ProgramPreJoinCheck({
  apiStatus,
  error,
  notice,
  title,
  onAcceptAgreement,
  onLoginBtnClick,
  programId,
  program,
  programName,
  thirdPartyOrganizationId,
  thirdPartyOrganization,
  userId,
  enableSkillsInSearchAndBrowse,
}: Props) {
  const styles = useStyles();
  const displayTitle = extractUnstyledTextFromCML(title);
  let buttonLabel: string;
  let buttonOnClick: (() => void) | undefined = userId ? onAcceptAgreement : onLoginBtnClick;
  switch (apiStatus) {
    default:
    case 'API_BEFORE_SEND':
      buttonLabel = _t('Agree');
      break;
    case 'API_IN_PROGRESS':
      buttonLabel = _t('Processing...');
      buttonOnClick = undefined;
      break;
    case 'API_SUCCESS':
      buttonLabel = _t('Success');
      buttonOnClick = undefined;
      break;
    case 'API_ERROR':
      buttonLabel = _t('Failed');
      break;
  }
  return (
    <ProgramHomeWrapper
      className="rc-ProgramPreJoinCheck"
      thirdPartyOrganizationId={thirdPartyOrganizationId}
      thirdPartyOrganizationSlug={thirdPartyOrganization?.slug}
      program={program}
      programId={programId}
      programName={programName}
      isAuthenticatedUser={!!userId}
      data-e2e="ProgramPreJoinCheck"
      shouldHideSearch
      css={styles.page}
      enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
    >
      <div css={styles.container}>
        <main css={styles.main}>
          <Typography variant="d2" component="h1" css={styles.title} data-test="title">
            {displayTitle || _t('Privacy Notice')}
          </Typography>
          <Typography variant="h2" component="p" css={styles.subtitle}>
            {_t('Coursera')}
          </Typography>
          {error ? (
            <ErrorMessage error={error} tag={InlineNotification} htmlAttributes={{ severity: 'error' }} />
          ) : (
            <CML cml={notice} />
          )}
          <Button
            variant="primary"
            css={styles.button}
            onClick={buttonOnClick}
            data-e2e={userId ? undefined : 'LoginButton'}
            disabled={Boolean(error)}
          >
            {buttonLabel}
          </Button>
        </main>
      </div>
    </ProgramHomeWrapper>
  );
}

const ProgramPreJoinCheckHookProxy = (props: Props) => (
  <Provider locale={_t.getLocale()}>
    <ProgramPreJoinCheck {...props} />
  </Provider>
);

export default compose<Props, Partial<Props>>(
  setDisplayName('ProgramPreJoinCheckHOC'),
  withApiHandler({}),
  Naptime.createContainer<
    {
      notices: EnterpriseNoticesV1[];
    },
    {
      thirdPartyOrganizationId: string;
    }
  >(({ thirdPartyOrganizationId: id }) => ({
    notices: EnterpriseNoticesV1.finder('byOrganizationId', { params: { id }, fields: ['enterpriseNotices'] }),
  })),
  withProps<
    Partial<Props>,
    Partial<Props> & {
      notices: EnterpriseNoticesV1[];
    }
  >(({ languageCode = DEFAULT_LANGUAGE_CODE, notices: noticesAlt }) => {
    if (!noticesAlt) {
      return {
        error: _t('Error getting the Privacy Agreement'),
      };
    }
    if (noticesAlt.length === 0) {
      return {
        error: _t('The Privacy Agreement has not been created'),
      };
    }
    const notices = noticesAlt[0].enterpriseNotices;
    const notice = notices[languageCode] || _.first(_.values(notices));
    return {
      languageCode,
      noticeId: noticesAlt[0].id,
      notice: notice?.notice as CmlContent | undefined,
      title: notice?.title as CmlContent | undefined,
    };
  }),
  withHandlers<
    Props,
    {
      onLoginBtnClick: (props: Props) => void;
      onAcceptAgreement: (props: Props) => void;
    }
  >({
    onLoginBtnClick:
      ({ router }) =>
      () => {
        router.push({
          pathname: router.location.pathname,
          params: router.params,
          query: { ...router.location.query, authMode: 'login' },
        });
      },
    onAcceptAgreement:
      ({ handleApiPromise, naptime, noticeId, languageCode, reloadProgramMembership }) =>
      () => {
        handleApiPromise({
          apiPromiseFn: () =>
            naptime.executeMutation(
              EnterpriseNoticeAcceptanceLogsV1.create({
                noticeId,
                languageCode,
              })
            ),
          apiTag: '',
          apiSuccessCallback: () => {
            reloadProgramMembership();
          },
        });
      },
  }),
  lifecycle<Props, {}>({
    componentDidMount() {
      const { thirdPartyOrganization, router } = this.props;

      if (thirdPartyOrganization.loginMethod === 'SAML') {
        router.push({
          pathname: router.location.pathname,
          params: router.params,
          query: { ...router.location.query, authProvider: thirdPartyOrganization.slug },
        });
      }
    },
  })
)(ProgramPreJoinCheckHookProxy);
