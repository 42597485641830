import type { PremiumProductCollections_LearningProductFragment as PremiumProduct } from 'bundles/browse/components/Domain/queries/__generated__/getPremiumProductCollectionsByDomainQuery';
import { buildProgramAbsoluteUrl } from 'bundles/browse/components/Domain/withPremiumProductCollections';
import type { DiscoveryCollections_DiscoveryEntityFragment as DiscoveryEntity } from 'bundles/browse/components/__generated__/DiscoveryCollectionsQuery';
import type {
  DiscoveryCollectionRatingReviewEntity,
  DiscoveryCollectionRatingsReview,
} from 'bundles/browse/components/types/DiscoveryCollections';
import { filterUndefined } from 'bundles/common/utils/commonUtils';
import { CAROUSEL_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/cardTypes';
import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import {
  LEARNING_PRODUCTS,
  NUM_NO_RESULTS_RECS_PER_PRODUCT_TYPE,
  PRODUCT_TYPES,
} from 'bundles/search-common/constants';
import type { SearchProductCard, SearchProductHit } from 'bundles/search-common/providers/searchTypes';

type CombinedGraphqlResponse = {
  premiumProductCollection?: Array<PremiumProduct | null> | null;
  premiumProductsCollectionLoading?: boolean;
  discoveryCollections?: Array<DiscoveryCollectionRatingsReview | null> | null;
  discoveryCollectionsLoading?: boolean;
};

const getEntityTypeForDiscoveryItem = (entity: DiscoveryEntity) => {
  switch (entity.__typename) {
    case 'DiscoveryCollections_guidedProject':
      return PRODUCT_TYPES.GuidedProject;
    case 'DiscoveryCollections_professionalCertificate':
      return PRODUCT_TYPES.ProfessionalCertificate;
    case 'DiscoveryCollections_project':
      return PRODUCT_TYPES.Project;
    case 'DiscoveryCollections_specialization':
      return PRODUCT_TYPES.Specialization;
    default:
      return PRODUCT_TYPES.Course;
  }
};

const getProductCardForDiscoveryItem = (entity: DiscoveryCollectionRatingReviewEntity) => {
  return {
    productTypeAttributes: entity.productCard?.productTypeAttributes,
  } as SearchProductCard;
};

const castPremiumProductToSearchHit = (premiumProduct: PremiumProduct): SearchProductHit => {
  return {
    id: premiumProduct.id,
    name: premiumProduct.name,
    url: buildProgramAbsoluteUrl(premiumProduct.slug, LEARNING_PRODUCTS.Degree),
    productType: PRODUCT_TYPES.Degree,
    imageUrl: premiumProduct.imageUrl || undefined,
    partners: premiumProduct?.partners?.map((partner) => partner?.name).filter(filterUndefined),
    partnerLogos: premiumProduct?.partners?.map((partner) => partner?.logo).filter(filterUndefined),
    __typename: 'Search_ProductHit',
  };
};

const castDiscoveryEntityToSearchHit = (entity: DiscoveryCollectionRatingReviewEntity): SearchProductHit => {
  const showNoResultsSearch = GrowthDiscoveryEpicClient.preview('showUpdatedNoResultsPage');
  return {
    id: entity.id,
    name: entity.name,
    url: entity.url,
    productType: getEntityTypeForDiscoveryItem(entity),
    imageUrl: entity.imageUrl || undefined,
    partners: entity?.partners?.map((partner) => partner?.name).filter(filterUndefined),
    partnerLogos: entity?.partners?.map((partner) => partner?.logo).filter(filterUndefined),
    productCard: showNoResultsSearch ? getProductCardForDiscoveryItem(entity) : undefined,
    __typename: 'Search_ProductHit',
  };
};

export const combineDegreeAndDiscoveryData = ({
  premiumProductCollection,
  premiumProductsCollectionLoading,
  discoveryCollections,
  discoveryCollectionsLoading,
}: CombinedGraphqlResponse) => {
  const recommendationHits: SearchProductHit[] = [];

  if (
    !premiumProductsCollectionLoading &&
    premiumProductCollection &&
    !discoveryCollectionsLoading &&
    discoveryCollections
  ) {
    // Convert premium products into search hit items.
    premiumProductCollection?.slice(0, NUM_NO_RESULTS_RECS_PER_PRODUCT_TYPE).forEach((premiumProduct) => {
      if (premiumProduct) {
        recommendationHits.push(castPremiumProductToSearchHit(premiumProduct));
      }
    });

    // Convert discovery collection entities to search hit items.
    discoveryCollections[0]?.entities?.slice(0, NUM_NO_RESULTS_RECS_PER_PRODUCT_TYPE).forEach((entity) => {
      if (entity) {
        recommendationHits.push(castDiscoveryEntityToSearchHit(entity));
      }
    });

    return recommendationHits;
  } else {
    return undefined;
  }
};
export const formatDiscoveryData = (
  discoveryCollections: Array<DiscoveryCollectionRatingsReview | null> | null,
  discoveryCollectionsLoading: boolean
) => {
  const recommendationHits: SearchProductHit[] = [];

  if (!discoveryCollectionsLoading && discoveryCollections) {
    // Convert discovery collection entities to search hit items.
    discoveryCollections[0]?.entities?.slice(0, 24).forEach((entity) => {
      if (entity) {
        recommendationHits.push(castDiscoveryEntityToSearchHit(entity));
      }
    });

    return recommendationHits;
  } else {
    return undefined;
  }
};

export const getNoSearchResultsCollectionData = (cardPosition: number) => {
  if (cardPosition <= NUM_NO_RESULTS_RECS_PER_PRODUCT_TYPE - 1) {
    return {
      id: 'degree',
      model: 'degree',
      type: CAROUSEL_TYPES.RECONFIGURABLE_COMPONENTS,
      name: 'Earn Your Degree',
      section: 0,
    };
  } else {
    return {
      id: 'FULLY_PERSONALIZED:trendingByEnrollmentsNumericTag',
      model: 'FULLY_PERSONALIZED:trendingByEnrollmentsNumericTag',
      type: CAROUSEL_TYPES.FULLY_PERSONALIZED,
      name: 'Trending Courses',
      section: 0,
    };
  }
};
