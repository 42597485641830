import { css } from '@emotion/react';

import {
  getOutlineStyles,
  classes as inputClasses,
} from '@core/forms/Input/getInputCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

import constants from './constants';
import type { Props as SelectProps } from './Select';

export const classes = generateEmotionClasses('select', [
  'select',
  'opened',
  'focusVisible',
  'paper',
  'mobileMenu',
  'list',
  'icon',
  'valid',
  'invalid',
  'readOnly',
]);

export const getListStyles = (
  count: number,
  visibleCount = constants.DEFAULT_VISIBLE_ITEM_COUNT
) => {
  return css`
    padding: ${count > visibleCount
      ? 'var(--cds-spacing-150)'
      : 'var(--cds-spacing-200)'};
    max-height: ${constants.OPTION_HEIGHT * visibleCount +
    constants.OPTIONS_DIVIDER_HEIGHT * (visibleCount - 1)}px;
  `;
};

export const getDropdownCss = (props: SelectProps) => css`
  .${classes.paper} {
    overflow: hidden;
    box-sizing: border-box;
    margin-top: var(--cds-spacing-100);
    border: 1px solid var(--cds-color-interactive-primary-hover);

    /*! @noflip */
    padding-right: 0;
  }

  .${classes.list} {
    overflow: auto;

    ${getListStyles(
      Array.isArray(props.children) ? props.children.length : 1,
      props.visibleItemCount
    )}

    & > li + li {
      margin-top: var(--cds-spacing-50);
    }
  }

  .${classes.valid} {
    &.${classes.paper} {
      border-color: var(--cds-color-feedback-success-hover);
    }
  }

  .${classes.invalid} {
    &.${classes.paper} {
      border-color: var(--cds-color-feedback-error-hover);
    }
  }

  &[dir='rtl'] {
    .${classes.paper} {
      /*! @noflip */
      padding-left: 0;
    }
  }
`;

const getSelectCss = css`
  .${classes.select} {
    padding-right: 44px;

    &:focus {
      background: unset;
    }
  }

  .${classes.readOnly} {
    cursor: not-allowed;
  }

  .${classes.icon} {
    top: 50%;
    color: var(--cds-color-neutral-primary);
    right: var(--cds-spacing-150);
    left: auto;
    transform: translate(0, -50%);

    & svg {
      display: block;
    }
  }

  &.${classes.opened} {
    .${classes.select} {
      & > span {
        color: var(--cds-color-neutral-primary);
      }
    }

    .${inputClasses.notchedOutline} {
      border-color: var(--cds-color-interactive-primary-hover);
    }

    &.${inputClasses.valid} {
      .${inputClasses.notchedOutline} {
        border-color: var(--cds-color-feedback-success-hover);
      }
    }

    &.${inputClasses.invalid} {
      .${inputClasses.notchedOutline} {
        border-color: var(--cds-color-feedback-error-hover);
      }
    }
  }

  &.${classes.focusVisible} {
    ${getOutlineStyles}
  }
`;

export default getSelectCss;
