import * as React from 'react';

import _ from 'lodash';

import imgix from 'js/lib/imgix';

import { Box } from '@coursera/coursera-ui';

import type { Partner } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import { addColorHashIfNeeded } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  partners: Array<Partner>;
  tempDisplayPartnerLimit?: number;
  showBackground?: boolean;
};

export default function PartnerList({ partners, tempDisplayPartnerLimit = 5, showBackground }: Props) {
  if (_.isEmpty(partners)) {
    return null;
  }
  return (
    <div className="MiniPartnerList d-inline-block" style={{ verticalAlign: 'middle' }}>
      <span className="font-sm">{_t('Offered by ')}</span>
      <Box rootClassName="m-b-1s" justifyContent="start" alignItems="center" display="inline-flex" flexDirection="row">
        {partners.slice(0, tempDisplayPartnerLimit).map((partner, index) => {
          const {
            id,
            name,
            logo,
            rectangularLogo,
            squareLogo,
            primaryColor: primaryColorOrEmpty,
            secondaryColor: secondaryColorOrEmpty,
            productBrandingLogo,
          } = partner;

          const primaryColor = addColorHashIfNeeded(primaryColorOrEmpty ?? undefined);
          const secondaryColor = addColorHashIfNeeded(secondaryColorOrEmpty ?? undefined);
          const overrideLogo = productBrandingLogo?.replace(/^http:\/\//i, 'https://');
          const src = overrideLogo ?? rectangularLogo ?? logo ?? squareLogo ?? undefined;
          const height = overrideLogo ? 70 : 30;
          const maxWidth = 300;
          const processedImgSrc = (src && imgix.processImage(src, { height, fit: 'max' })) || src;
          return (
            <img
              className={`d-inline-block ${index > 1 ? 'm-l-1s' : ''}`}
              src={processedImgSrc}
              alt={name}
              title={name}
              key={id}
              style={{
                maxWidth,
                height,
                backgroundColor: (showBackground && (secondaryColor || primaryColor)) || 'transparent',
                padding: 5,
              }}
            />
          );
        })}
      </Box>
    </div>
  );
}
