import * as React from 'react';

import { compose } from 'recompose';

import connectToStores from 'js/lib/connectToStores';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import type UserAgentInfo from 'js/lib/useragent';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import { getCourseTypeFromCourseTypeMetadata } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';
import type { CourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';
import { PageQueryById } from 'bundles/enterprise-legacy-xdp/components/miniPDP/PageQuery';
import type {
  PdpPageQueryById_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember_cdpMetadata as CDPMetadata,
  PdpPageQueryById_XdpV1Resource_get_xdpMetadata_XdpV1_cdpMetadataMember as CDPMetadataMember,
  PdpPageQueryByIdVariables as PageQueryByIdVariables,
  PdpPageQueryById as PageQueryData,
  PdpPageQueryById_XdpV1Resource_get_xdpMetadata as XdpMetadata,
  PdpPageQueryById_XdpV1Resource as XdpV1Resource,
} from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/PdpPageQueryById';
import type { MiniPdpPageDataContext } from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/PageDataContext';
import PageDataContext from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/PageDataContext';
import BannerInfoBoxViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/BannerInfoBoxViewController';
import HowItWorksViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/HowItWorksViewController';
import PageViewController from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/controllers/PageViewController';
import { NotFoundError } from 'bundles/ssr/lib/errors/directives';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

type PropsFromCaller = {
  id: string;
};

type PropsFromGraphQL = {
  data: CDPMetadata;
  courseType: CourseType;
};

type PropsFromStores = {
  userAgent: UserAgentInfo;
};

type PropsToComponent = PropsFromCaller & PropsFromGraphQL & PropsFromStores;

const MiniPdpPageDataProvider: React.FC<PropsToComponent> = ({ children, data, courseType, userAgent }) => {
  const canEnrollWithFullDiscount = false;
  const providerData: MiniPdpPageDataContext = {
    data,
    userAgent,
    howItWorksViewController: new HowItWorksViewController(courseType),
    bannerInfoBoxViewController: new BannerInfoBoxViewController(courseType, canEnrollWithFullDiscount),
    pageViewController: new PageViewController(courseType),
  };
  return <PageDataContext.Provider value={providerData}>{children}</PageDataContext.Provider>;
};

function isCdpMetadata(data: XdpMetadata | undefined): data is CDPMetadataMember {
  return !!data;
}

function getCdpMetadata(data: XdpV1Resource | undefined): CDPMetadata | undefined {
  const metadata = data?.get?.xdpMetadata;
  if (isCdpMetadata(metadata)) {
    return metadata.cdpMetadata;
  }
  return undefined;
}

export default compose<PropsToComponent, PropsFromCaller>(
  connectToStores<PropsToComponent, PropsFromCaller>([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    userAgent: ApplicationStore.getUserAgent(),
  })),
  waitForGraphQL<PropsFromCaller, PageQueryData, PageQueryByIdVariables, PropsFromGraphQL>(PageQueryById, {
    options: ({ id }) => ({
      variables: {
        id: tupleToStringKey(['COURSE', id]),
      },
    }),
    props: ({ data }) => {
      const pageData = getCdpMetadata(data?.XdpV1Resource);
      if (!pageData) {
        throw new NotFoundError();
      }

      const courseType =
        pageData.courseTypeMetadata && getCourseTypeFromCourseTypeMetadata(pageData.courseTypeMetadata);
      switch (courseType) {
        case 'Project':
        case 'GuidedProject':
          return { data: pageData, courseType };
        case 'StandardCourse':
        default:
          // Occurs when the slug is not valid or is for a standard course
          throw new NotFoundError();
      }
    },
  })
)(MiniPdpPageDataProvider);
