import { graphql } from 'react-apollo';
import type { DataValue } from 'react-apollo';

import { unionBy } from 'lodash';

import {
  discoveryCollectionsToCollectionListItemStrucArray,
  filterBlacklistCollections,
} from 'bundles/enterprise-legacy-learner-home/components/single-program/withDiscoveryBrowseCollections';
import type { CollectionListItemStruct } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import fetchMoreErrorFilter from 'bundles/enterprise-legacy-learner-home/utils/fetchMoreErrorFilter';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import { DiscoveryBrowseCollectionsByOrganizationQuery } from 'bundles/program-home/components/enterprise-home/EnterpriseHomeQueries';
import type {
  DiscoveryBrowseCollectionsByOrganizationQueryVariables,
  DiscoveryBrowseCollectionsByOrganizationQuery as DiscoveryBrowseCollectionsQueryData,
} from 'bundles/program-home/components/enterprise-home/__generated__/DiscoveryBrowseCollectionsByOrganizationQuery';

type PropsFromWithState = {
  paginationError: boolean;
  setPaginationError: (val: boolean) => void;
};

type PropsForOrgHomeGraphql = {
  thirdPartyOrganizationId: string;
  userId: number;
  contextType: string;
  contextId: string;
  start: string;
  limit: number;
} & PropsFromWithState;

type PropsFromGraphql = {
  collections: Array<CollectionListItemStruct> | undefined;
  loading: boolean;
  error: boolean;
  loadMoreItems: () => void;
  isAllLoaded: boolean;
};

export function updateCollectionQuery(
  prevResult: DiscoveryBrowseCollectionsQueryData,
  newResult: DiscoveryBrowseCollectionsQueryData | undefined
): DiscoveryBrowseCollectionsQueryData {
  if (!newResult?.DiscoveryCollections?.queryEnterpriseCollectionsByOrganization) {
    return prevResult;
  }

  const productCollections = unionBy(
    prevResult.DiscoveryCollections?.queryEnterpriseCollectionsByOrganization?.productCollections,
    newResult.DiscoveryCollections?.queryEnterpriseCollectionsByOrganization?.productCollections,
    (productCollection) => productCollection?.id
  );

  return {
    DiscoveryCollections: {
      __typename: newResult.DiscoveryCollections.__typename,
      queryEnterpriseCollectionsByOrganization: {
        __typename: newResult.DiscoveryCollections.queryEnterpriseCollectionsByOrganization.__typename,
        next: newResult.DiscoveryCollections.queryEnterpriseCollectionsByOrganization.next,
        productCollections,
      },
    },
  };
}

export const loadMoreItems =
  (
    data:
      | Pick<
          DataValue<DiscoveryBrowseCollectionsQueryData, DiscoveryBrowseCollectionsByOrganizationQueryVariables>,
          'loading' | 'fetchMore' | 'DiscoveryCollections'
        >
      | undefined,
    setPaginationError: (val: boolean) => void
  ) =>
  () => {
    if (!data) {
      return;
    }

    const { loading, fetchMore } = data;
    if (loading) {
      return;
    }
    const start = data?.DiscoveryCollections?.queryEnterpriseCollectionsByOrganization?.next;
    if (!start) {
      return;
    }

    fetchMore({
      variables: {
        start,
      },
      updateQuery: (prevResult, { fetchMoreResult: newResult }) => {
        return updateCollectionQuery(prevResult, newResult);
      },
    })
      .catch(fetchMoreErrorFilter)
      .catch(() => {
        setPaginationError(true);
      });
  };

const withDiscoveryBrowseCollectionsByOrganization = graphql<
  PropsForOrgHomeGraphql,
  DiscoveryBrowseCollectionsQueryData,
  DiscoveryBrowseCollectionsByOrganizationQueryVariables,
  PropsFromGraphql
>(DiscoveryBrowseCollectionsByOrganizationQuery, {
  options: ({ thirdPartyOrganizationId, contextType, contextId, start, limit }) => {
    return {
      ssr: false,
      variables: {
        contextId,
        contextType,
        organizationId: thirdPartyOrganizationId,
        start: Number(start),
        limit,
      },
      context: { clientName: 'gatewayGql' },
    };
  },
  props: ({ data, ownProps }) => {
    const collections = discoveryCollectionsToCollectionListItemStrucArray(
      filterExistsOrDefault(data?.DiscoveryCollections?.queryEnterpriseCollectionsByOrganization?.productCollections)
    );
    const filteredCollections = filterBlacklistCollections(collections);
    const isAllLoaded = data?.DiscoveryCollections?.queryEnterpriseCollectionsByOrganization?.next === null;
    const loading = data?.loading ?? true;
    const error = Boolean(data?.error);
    return {
      collections: filteredCollections,
      loading,
      error,
      isAllLoaded,
      loadMoreItems: loadMoreItems(data, ownProps.setPaginationError),
    };
  },
});

export default withDiscoveryBrowseCollectionsByOrganization;
