import * as React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import Retracked from 'js/app/retracked';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { SvgThumbsDown, SvgThumbsDownFilled, SvgThumbsUp, SvgThumbsUpFilled } from '@coursera/coursera-ui/svg';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!./__styles__/MiniXDPVideoPlayer';

const getLikedStatus = (videoId: string) => {
  return localStorageEx.getItem(`rated_video_${videoId}`, String, null);
};

const setLikedStatus = (videoId: string, status: string | null) => {
  // We are using these buttons for tracking purposes only, so there's no BE
  localStorageEx.setItem(`rated_video_${videoId}`, status, String);
};

type VideoTrack = {
  label: string;
  languageCode: string;
  url: string;
};

export type VideoData = {
  courseId?: string;
  videoId: string;
  videoName: string;
  videoUrl: string;
  videoThumbnail?: string;
  tracks: { tracks: Array<VideoTrack> };
};

type Props = {
  videoData: VideoData;
  courseId: string;
  courseName: string;
};

type State = { likedStatus: string | null };

class MiniXDPVideoPlayer extends React.Component<Props, State> {
  declare videoPlayer: HTMLVideoElement | null | undefined;

  static contextTypes = {
    _eventData: PropTypes.object,
  };

  declare context: LegacyContextType<typeof MiniXDPVideoPlayer.contextTypes>;

  constructor(props: Props) {
    super(props);
    const {
      videoData: { videoId },
    } = this.props;
    const likedStatus = getLikedStatus(videoId);
    this.state = {
      likedStatus,
    };
  }

  render() {
    const { videoData, courseName, courseId } = this.props;
    const { likedStatus } = this.state;
    const { _eventData } = this.context;
    const { videoName, videoUrl, tracks, videoId } = videoData;

    return (
      videoData && (
        <div className="rc-MiniXDPVideoPlayer vertical-box">
          <video
            ref={(ref) => {
              this.videoPlayer = ref;
            }}
            muted
            onEnded={() => {
              const currentTime = this.videoPlayer ? this.videoPlayer.currentTime : 0;
              Retracked.trackComponent(
                _eventData,
                { videoName: videoData.videoName, currentTime, page: window.location.pathname },
                'short_form_video',
                'video_ended'
              );
            }}
            onTimeUpdate={_.throttle(() => {
              // Throttling to 1 event/s otherwise this would be firing something between every 20ms - 200ms depending on frame speeds
              const currentTime = this.videoPlayer ? this.videoPlayer.currentTime : 0;
              Retracked.trackComponent(
                _eventData,
                { videoName: videoData.videoName, currentTime, page: window.location.pathname },
                'short_form_video',
                'video_watched_for_another_second'
              );
            }, 1000)}
            controls
            autoPlay
            style={{ width: '100%' }}
          >
            <source src={videoUrl} type="video/webm" />
            {_t(
              'Your browser does not support the video playing feature. Please update to a new version or use a supported browser (We recommend Chrome or Firefox'
            )}
            {tracks &&
              tracks.tracks.map((track) => {
                return (
                  <track
                    key={track.languageCode}
                    kind="subtitles"
                    label={_t(track.label)}
                    src={track.url}
                    srcLang={track.languageCode}
                  />
                );
              })}
          </video>
          <div className="video-info-container horizontal-box">
            <div className="vertical-box">
              <h3 className="video-name">{videoName}</h3>
              <h5 className="video-description">
                <FormattedMessage
                  message={_t('This video is part of {courseName}')}
                  courseName={<strong>{courseName}</strong>}
                />
              </h5>
            </div>
            <div className="ratings horizontal-box">
              <TrackedButton
                data={{ courseId, courseName, videoName }}
                withVisibilityTracking={true}
                requireFullyVisible={true}
                className="thumbs-up nostyle"
                onClick={() => {
                  this.setState(() => {
                    setLikedStatus(videoId, 'LIKED');
                    return { likedStatus: 'LIKED' };
                  });
                }}
                data-e2e="video-thumbs-up"
                trackingName="rate_video_positive"
              >
                {likedStatus === 'LIKED' ? <SvgThumbsUpFilled color="#fff" /> : <SvgThumbsUp color="#fff" />}
              </TrackedButton>
              <TrackedButton
                data={{ courseId, courseName, videoName }}
                withVisibilityTracking={true}
                requireFullyVisible={true}
                onClick={() => {
                  this.setState(() => {
                    setLikedStatus(videoId, 'DISLIKED');
                    return { likedStatus: 'DISLIKED' };
                  });
                }}
                className="thumbs-down nostyle"
                data-e2e="video-thumbs-down"
                trackingName="rate_video_negative"
              >
                {likedStatus === 'DISLIKED' ? <SvgThumbsDownFilled color="#fff" /> : <SvgThumbsDown color="#fff" />}
              </TrackedButton>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default MiniXDPVideoPlayer;
