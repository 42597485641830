/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import { Typography } from '@coursera/cds-core';
import { Pill } from '@coursera/coursera-ui';

import { MIN_NUM_OF_SKILLS_TO_SHOW } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import { renderToggleButtonWithoutCollapse } from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import TogglableContent from 'bundles/page/components/TogglableContent';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const styles = {
  container: css`
    border-radius: 4px;
  `,
  skillsList: css`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  `,
};

type Props = {
  rootClassName?: string;
  sectionTitle?: string;
  skills?: Array<string>;
  titleTag?: React.ElementType;
  shouldShowBorder?: boolean;
  enableToggle?: boolean;
};

export default function Skills({
  skills = [],
  sectionTitle = _t('Skills you will gain'),
  titleTag = 'h2',
  rootClassName,
  shouldShowBorder = true,
  enableToggle,
}: Props) {
  const skillsList = (
    // safari loses semantics when list-style is none https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ul role="list" css={styles.skillsList}>
      {skills.map((skill) => (
        <li key={`${skill}-item`}>
          <Pill key={skill} size="lg" rootClassName="m-r-1s m-b-1s">
            {skill}
          </Pill>
        </li>
      ))}
    </ul>
  );
  if (skills.length < MIN_NUM_OF_SKILLS_TO_SHOW) return null;
  return (
    <div
      className={classNames('Skills', rootClassName, {
        'border-a': shouldShowBorder,
      })}
      css={styles.container}
    >
      <Typography component={titleTag} className="text-secondary text-uppercase m-b-2" variant="body1">
        {sectionTitle}
      </Typography>
      {enableToggle ? (
        <TogglableContent
          childrenWrapperTag="div"
          collapsedHeight={110}
          disableCollapseScroll
          hideShowLessBtn
          renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse('SKILLS')}
          toggleButtonType="link"
          trackingName="show_more_skills"
          useSmartCollapse
          ariaHidden={false}
        >
          {skillsList}
        </TogglableContent>
      ) : (
        skillsList
      )}
    </div>
  );
}
