/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { ArrowForwardIcon } from 'bundles/ai-coach-platform/components/icons/mui';

type Props = {
  buttonLabel?: string | null;
  handleNavButtonClick: () => void;
  'aria-describedby': string;
};

/**
 * Wrapper around CoachButton for navigation specific use cases.
 */
export const NavigationButton = (props: Props): React.ReactElement => {
  const { buttonLabel, handleNavButtonClick, 'aria-describedby': ariaDescribedBy } = props;

  return (
    <CoachButton
      aria-describedby={ariaDescribedBy}
      icon={<ArrowForwardIcon color="invert" />}
      size="small"
      fullWidth
      justifyContent="space-between"
      onClick={handleNavButtonClick}
    >
      {buttonLabel || ''}
    </CoachButton>
  );
};

export default NavigationButton;
