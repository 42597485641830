/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useSelector } from 'react-redux';

import { useTracker } from '@coursera/event-pulse/react';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { COACH_RECOMMENDED_ACTION_CLICK } from 'bundles/ai-coach-platform/components/patterns/chat/constants';
import type { GlobalState } from 'bundles/ai-coach-platform/components/patterns/chat/store/types';
import type { SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';
import sessionStorageEx from 'bundles/common/utils/sessionStorageEx';

type Props = {
  action: string;
  sendMessage: SendMessageFunc;
  actionMessage: string;
  actionNotClicked: boolean;
  isRecommendedAction: boolean;
};

export const RecommendedActionButton = ({
  actionMessage,
  action,
  sendMessage,
  actionNotClicked,
  isRecommendedAction,
}: Props): React.ReactElement | null => {
  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;
  const { isMessageLoading } = useSelector((state: GlobalState) => ({
    isMessageLoading: state.behavior.messageLoader,
  }));

  return (
    <CoachButton
      disabled={isMessageLoading}
      variant="secondary"
      size="small"
      onClick={() => {
        const isSetClickTimestamp = isRecommendedAction && actionNotClicked;

        if (isSetClickTimestamp) {
          const currentDate = new Date();
          sessionStorageEx.setItem(COACH_RECOMMENDED_ACTION_CLICK, currentDate.toISOString(), (value) => value);
        }
        sendMessage(actionMessage, action);

        if (trackingContext) {
          // v3 tracking
          track('interact_coach', {
            coachInteractionType: 'click_action',
            coach: {
              mode: trackingContext.mode,
              ...(trackingContext.location && { location: trackingContext.location }),
            },
            ...(trackingContext.courseId && {
              product: {
                type: 'course',
                id: trackingContext.courseId as string,
              },
            }),
            ...(trackingContext.itemId && {
              courseItem: {
                id: trackingContext.itemId as string,
              },
            }),
            coachAction: action,
          });
        }
      }}
    >
      {actionMessage}
    </CoachButton>
  );
};
