import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { isUserRightToLeft } from 'js/lib/language';

import { Typography2 } from '@coursera/cds-core';
import { StyleSheet, breakPoint, color, css, spacing } from '@coursera/coursera-ui';

import KeyboardArrowDown from 'bundles/coursera-ui/components/svg/coursera/common/KeyboardArrowDown';
import type { CardState } from 'bundles/enterprise-legacy-learner-home/components/CircleWidget';
import CircleWidget from 'bundles/enterprise-legacy-learner-home/components/CircleWidget';

import _t from 'i18n!nls/program-home';

export function getCardState({ isEnrolled, isCompleted }: { isEnrolled: boolean; isCompleted: boolean }): CardState {
  if (isCompleted) return 'green-cover';
  if (!isEnrolled) return 'gray-cover';
  return 'green';
}

const styles = StyleSheet.create({
  MultiCourseProductCirclesMenu: {
    paddingLeft: 136,
    paddingBottom: 0,
    borderBottom: `1px solid ${color.divider}`,
    [`@media (max-width: ${breakPoint.md}px)`]: {
      padding: '1.5rem 1.5rem 0 1.5rem',
    },
  },
  S12nCirclesMenuRTL: {
    paddingRight: 136,
    paddingBottom: 0,
    borderBottom: `1px solid ${color.divider}`,
    [`@media (max-width: ${breakPoint.md}px)`]: {
      padding: '1.5rem 1.5rem 0 1.5rem',
    },
  },
  countInfo: {
    minWidth: 96,
  },
  circleMenuContainer: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  menuItem: {
    listStyle: 'none',
    marginRight: spacing.md,
    cursor: 'pointer',
    width: 44,
    height: 78,
    float: 'left',
  },
  menuItemRTL: {
    listStyle: 'none',
    marginLeft: spacing.md,
    cursor: 'pointer',
    width: 44,
    height: 78,
    float: 'right',
  },
});

export type CircleMenuItem = {
  courseId: string;
  isActive: boolean;
  isCompleted: boolean;
  isEnrolled: boolean;
  isCapstone?: boolean;
  canUnenroll?: boolean;
};

const ArrowAlignment = {
  marginBottom: 34,
  flexBasis: 0,
};

const MultiCourseProductCirclesMenu: React.SFC<{
  courseCount?: number;
  name: string;
  capstoneProjectCount?: number;
  handleSelect: (courseId: string) => void;
  circleMenuData: CircleMenuItem[];
}> = ({ courseCount = 0, capstoneProjectCount = 0, circleMenuData, name, handleSelect }) => {
  const handleKeyBoardTabNavigation = (event: React.KeyboardEvent, index: number) => {
    event.preventDefault();
    let buttonElement;
    if (event.key === ' ') {
      handleSelect(circleMenuData[index].courseId);
    } else if (event.key === 'ArrowLeft') {
      if (index > 0) {
        buttonElement = document.getElementById(`circle-menu-item-${circleMenuData[index - 1].courseId}`)
          ?.firstElementChild as HTMLElement;
        buttonElement?.focus();
      } else {
        buttonElement = document.getElementById(
          `circle-menu-item-${circleMenuData[circleMenuData.length - 1].courseId}`
        )?.firstElementChild as HTMLElement;
        buttonElement?.focus();
      }
    } else if (event.key === 'ArrowRight') {
      if (index < circleMenuData.length - 1) {
        buttonElement = document.getElementById(`circle-menu-item-${circleMenuData[index + 1].courseId}`)
          ?.firstElementChild as HTMLElement;
        buttonElement?.focus();
      } else {
        buttonElement = document.getElementById(`circle-menu-item-${circleMenuData[0].courseId}`)
          ?.firstElementChild as HTMLElement;
        buttonElement?.focus();
      }
    }
  };
  return (
    <div
      {...css(
        'rc-MultiCourseProductCirclesMenu horizontal-box',
        isUserRightToLeft() ? styles.S12nCirclesMenuRTL : styles.MultiCourseProductCirclesMenu
      )}
      data-e2e="MultiCourseProductCirclesMenu"
    >
      {courseCount && (
        <Typography2
          component="div"
          variant="subtitleMedium"
          className="hidden-sm-down align-self-center m-r-1"
          style={ArrowAlignment}
        >
          <span className="text-nowrap">
            {courseCount > 0 && (
              <FormattedMessage
                message={_t('{courseCount, plural, =1 {# course} other {# courses}}')}
                courseCount={courseCount}
              />
            )}
            {courseCount > 0 && capstoneProjectCount > 0 && ' +'}
          </span>
          {courseCount > 0 && capstoneProjectCount > 0 && ' '}
          <span className="text-nowrap">
            {capstoneProjectCount > 0 && (
              <FormattedMessage
                message={_t('{capstoneProjectCount, plural, =1 {# capstone} other {# capstone}}')}
                capstoneProjectCount={capstoneProjectCount}
              />
            )}
          </span>
        </Typography2>
      )}
      <div {...css(styles.circleMenuContainer)} role="tablist" aria-label={_t('#{name} courses menu', { name })}>
        {circleMenuData.map(({ courseId, isActive, isCompleted, isEnrolled, isCapstone }, index) => (
          <div
            {...css(isUserRightToLeft() ? styles.menuItemRTL : styles.menuItem)}
            // eslint-disable-next-line react/no-array-index-key
            key={`MultiCourseProductCirclesMenu~${index}`}
            data-js={`menuItem~${courseId}`}
            data-e2e={`s12n-circles-menu-item~${courseId}`}
            role="none"
            id={`circle-menu-item-${courseId}`}
          >
            <button
              type="button"
              className="nostyle"
              role="tab"
              aria-selected={isActive}
              aria-label={_t('Course #{index}', { index: index + 1 })}
              onClick={() => handleSelect(courseId)}
              aria-controls={`menuItem~${courseId}`}
              tabIndex={isActive ? 0 : -1}
              onKeyUp={(event) => handleKeyBoardTabNavigation(event, index)}
            >
              <CircleWidget
                text={isCapstone ? 'C' : `${index + 1}`}
                cardState={getCardState({ isCompleted, isEnrolled })}
              />

              {isActive && (
                <span className="d-inline-block p-x-1s">
                  <KeyboardArrowDown color={color.secondaryText} hoverColor={color.primary} size={28} />
                </span>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiCourseProductCirclesMenu;
