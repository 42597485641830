import { compose, getDisplayName, setDisplayName } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import EnrollmentChoiceModalProductDescription from 'bundles/enterprise-legacy-learner-home/components/EnrollmentChoiceModalProductDescriptionWithProductIdAndType';
import { useRouterParams } from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';

type PropsFromRouter = {
  productId?: string;
  productType?: string;
};

const enhance = compose<PropsFromRouter, {}>(
  setDisplayName(getDisplayName(EnrollmentChoiceModalProductDescription)),
  connectToRouter((router) => {
    const routerParams = useRouterParams(router);
    const productId = routerParams?.productId;
    const productType = routerParams?.productType;
    return { productId, productType };
  })
);

export default enhance(EnrollmentChoiceModalProductDescription);
