import user from 'js/lib/user';

import type { PluginDetectionConfiguration } from 'bundles/academic-integrity/components/plugin-detector/detection-helpers';
import AcademicIntegrityExperiments from 'bundles/epic/clients/AcademicIntegrity';

export const isGibberishDeterrenceEnabled = (courseId: string): boolean => {
  return AcademicIntegrityExperiments.get('enableGibberishDeterrence', { course_id: courseId }) ?? false;
};

export const isPlagiarismDetectionEnabled = (courseId: string): boolean => {
  return AcademicIntegrityExperiments.get('enablePlagiarismDetection', { course_id: courseId }) ?? false;
};

export const isIntegrityPortalEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableIntegrityPortal') ?? false;
};

export const isC4CIntegrityPortalEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableC4CIntegrityPortal') ?? false;
};

export const isGibberishDeterrenceAuthoringEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableGibberishDeterrenceAuthoring') ?? false;
};

export const isPlagiarismDetectionAuthoringEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enablePlagiarismDetectionAuthoring') ?? false;
};

export const isPreventCopyingQuestionPromptEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('preventCopyingQuestionPrompt') ?? false;
};

export const excludeLearnerFromIdVerificationFingerprint = (): boolean => {
  const userId = user.get().id?.toString();
  const exclusionList = AcademicIntegrityExperiments.get('idVerificationFingerprintExclusionList') ?? [];
  if (exclusionList.length > 0) {
    return exclusionList.includes(userId);
  }
  return false;
};

export const isIdVerificationFingerprintEnabled = (courseId: string): boolean => {
  if (excludeLearnerFromIdVerificationFingerprint()) {
    return false;
  }
  return AcademicIntegrityExperiments.get('enableIdVerificationFingerprint', { course_id: courseId }) ?? false;
};

export const isFileUploadPlagiarismDetectionEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableFileUploadPlagiarismDetection') ?? false;
};

export const isFileUploadPlagiarismAuthoringEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableFileUploadPlagiarismAuthoring') ?? false;
};

export const isLockedBrowserEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableLockedBrowser') ?? false;
};

export const isVivaExamEnabled = (courseId: string, itemId: string): boolean => {
  const enabledAssignments = AcademicIntegrityExperiments.get('enableVivaExam') ?? {};
  if (enabledAssignments[courseId] && enabledAssignments[courseId][itemId]) {
    return true;
  }
  return false;
};

export const isAutoProctorEnabled = (courseId: string): boolean => {
  // eslint-disable-next-line camelcase
  return AcademicIntegrityExperiments.get('enableAutoProctor', { course_id: courseId }) ?? false;
};

export const isProctoringResultsAppEnabled = (): boolean => {
  return AcademicIntegrityExperiments.get('enableProctoringResultsAppEnabled') ?? false;
};

export const isVivaExamFeedbackEnabled = (courseId?: string): boolean => {
  if (courseId) {
    return AcademicIntegrityExperiments.get('enableVivaExamFeedback', { course_id: courseId }) ?? false;
  }
  return false;
};

export const getPluginConfiguration = (): PluginDetectionConfiguration => {
  return AcademicIntegrityExperiments.get('pluginDetectionConfiguration') ?? { enabled: false };
};
