/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';
import { branch, compose } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { StarRating } from '@coursera/coursera-ui';

import type { RatingsObject, ReviewHighlight } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import type {
  FetchProductOverviewQueryCDS as FetchProductOverviewQueryCDSData,
  FetchProductOverviewQueryCDSVariables,
} from 'bundles/enterprise-legacy-xdp/components/reviews/cds/__generated__/FetchProductOverviewQueryCDS';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import ReviewsRatingBarGraph from 'bundles/reviews-common/components/ReviewsRatingBarGraph';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const styles = {
  reviewsOverview: () => css`
    position: sticky;
    top: 180px;
    ${breakpoints.down('sm')} {
      margin-bottom: 48px;
    }

    h4.section-title {
      color: var(--cds-color-grey-600);
    }

    .bar-graph-container {
      margin-bottom: 0;
    }
  `,

  reviewsOverviewTotals: () => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: var(--cds-spacing-200);
  `,

  reviewsOverviewTotalsRating: () => css`
    margin: 0 var(--cds-spacing-150) 0 0;
  `,

  reviewsOverviewTotalsTotalReviews: () => css`
    display: block;
    margin: -6px 0 0 0;
  `,

  reviewsOverviewHighlights: () => css`
    margin-top: var(--cds-spacing-600);
  `,

  reviewsOverviewHighlightsRow: () => css`
    margin-top: var(--cds-spacing-150);
    font-size: 14px;
    line-height: 19px;
  `,

  reviewsOverviewHighlightsHighlightText: css`
    font-weight: 700;
  `,
};

type PropsFromCaller = {
  ratings: RatingsObject;
  reviewHighlights?: Array<ReviewHighlight>;
  isSDP?: boolean;
};

type PropsFromRouter = {
  pathname: string;
  courseSlug: string;
};

type PropsFromGraphql = {
  countsByStar?: {
    [starValue: string]: number;
  };
  totalReviewCount?: number;
};

type PropsToComponent = PropsFromCaller & PropsFromRouter & PropsFromGraphql;

export const ReviewsOverview = ({
  countsByStar,
  totalReviewCount,
  ratings,
  reviewHighlights,
  pathname,
}: PropsToComponent) => (
  <TrackedDiv css={styles.reviewsOverview} trackingName="updated_reviews_module_overview">
    <div css={styles.reviewsOverviewTotals}>
      <div css={styles.reviewsOverviewTotalsRating}>
        <Typography variant="d1semibold" component="div">
          {ratings?.averageFiveStarRating?.toFixed?.(1)}
        </Typography>
      </div>
      <div>
        <StarRating rating={ratings.averageFiveStarRating} />
        <TrackedA
          trackingName="reviews_overview_num_reviews_link"
          data-e2e="small-reviews-overview-reviews-page-link"
          href={pathname.startsWith('/') ? `${pathname}/reviews` : `/${pathname}/reviews`}
          css={styles.reviewsOverviewTotalsTotalReviews}
        >
          {ratings.commentCount ? (
            <Typography2 variant="bodyPrimary" component="span" color="supportText">
              <FormattedHTMLMessage
                message={_t('{numReviews} reviews')}
                numReviews={<span>{ratings.commentCount}</span>}
              />
            </Typography2>
          ) : (
            <Typography2 variant="bodyPrimary" component="span" color="supportText">
              <FormattedHTMLMessage
                message={_t('{numReviews} ratings')}
                numReviews={<span>{ratings.ratingCount}</span>}
              />
            </Typography2>
          )}
        </TrackedA>
      </div>
    </div>
    {countsByStar && totalReviewCount && (
      <ReviewsRatingBarGraph countsByStar={countsByStar} totalReviewCount={totalReviewCount} />
    )}
    {reviewHighlights?.length && (
      <div css={styles.reviewsOverviewHighlights}>
        <h4 className="section-title">{_t('HIGHLIGHTS')}</h4>
        {reviewHighlights.map((highlight) => (
          <TrackedDiv
            css={styles.reviewsOverviewHighlightsRow}
            trackingName="updated_reviews_module_overview_highlight"
          >
            <span css={styles.reviewsOverviewHighlightsHighlightText}>&quot;{highlight.highlightText}&quot;</span>
            {_t(' in #{total} reviews', { total: highlight.reviewcount })}
          </TrackedDiv>
        ))}
      </div>
    )}
  </TrackedDiv>
);

// Making a separate graphql query here as this resource it notably flaky and could take down the entire page query if included.
const ProductReviewOverviewQuery = gql`
  query FetchProductOverviewQueryCDS($courseSlug: String!) {
    XdpV1Resource {
      slug(productType: "COURSE", slug: $courseSlug) {
        elements {
          id
          fetchProductOverview {
            elements {
              id
              countsByStar
              totalReviewCount
            }
          }
        }
      }
    }
  }
`;

export default compose<PropsToComponent, PropsFromCaller>(
  connectToRouter(({ params: { slug }, location: { pathname } }) => ({
    courseSlug: slug,
    pathname,
  })),
  // ProductReviewOverview only works for courses.
  branch(
    ({ isSDP }: { isSDP: boolean }) => !isSDP,
    graphql<
      PropsFromRouter & PropsFromCaller,
      FetchProductOverviewQueryCDSData,
      FetchProductOverviewQueryCDSVariables,
      PropsFromGraphql
    >(ProductReviewOverviewQuery, {
      props: ({ data }) => {
        const reviewOverview = data?.XdpV1Resource?.slug?.elements?.[0]?.fetchProductOverview?.elements?.[0];
        return {
          totalReviewCount: reviewOverview?.totalReviewCount,
          countsByStar: reviewOverview?.countsByStar,
        };
      },
    })
  )
)(ReviewsOverview);
