import _t from 'i18n!nls/design-system';

const SlickActive = '.slick-slide.slick-active';
const SlickNotActive = '.slick-slide:not(.slick-active)';
const InteractiveElementSelectors = [
  'a',
  // program-home has buttons in some slides
  'button',
  // program-home has product cards that are divs with role="button" in most slides
  'div[role="button"]',
  // Coursera Plus landing pages introduced a non-interactive product card carousel. For this use case, there is no
  // anchor, and instead we need to enable/disable the tabbable element (a div that has the role="presentation" and
  // aria-label attributes).
  'div[role="presentation"][aria-label]',
];

const SlickNotActiveInteractiveElementSelector = InteractiveElementSelectors.map(
  (selector) => `${SlickNotActive} ${selector}`
).join(', ');

const SlickActiveInteractiveElementSelector = InteractiveElementSelectors.map(
  (selector) => `${SlickActive} ${selector}`
).join(', ');

export function updateSlickAccessibilityAttributes(carouselNode?: HTMLElement | null, title?: string | null): void {
  /*
    NOTE: https://github.com/kenwheeler/slick/issues/3268
    Due to the aforementioned issues with react-slick/slick-slider, we have
    to implement our own accessibility enhancements to our sliders.

    If the issue is ever resolved and we update libraries, there is a good chance
    that we'll need to remove this.
  */
  if (!carouselNode) {
    return;
  }

  const slickTrack = carouselNode.querySelectorAll('.slick-track');
  slickTrack.forEach((node) => {
    node.setAttribute('aria-label', title ?? _t('Slides'));
    node.setAttribute('role', 'group');
    node.setAttribute('aria-roledescription', 'Carousel');
    node.setAttribute('aria-live', 'polite');
    node.setAttribute('aria-atomic', 'true');
  });

  const parentDivsWithTabIndexAppliedBySlick = carouselNode.querySelectorAll('.slick-slide[tabindex]');
  if (parentDivsWithTabIndexAppliedBySlick?.length > 0) {
    const firstDataIndex = parentDivsWithTabIndexAppliedBySlick[0].getAttribute('data-index');
    parentDivsWithTabIndexAppliedBySlick.forEach((node, key) => {
      node.setAttribute('role', 'group');
      node.setAttribute('aria-roledescription', 'slide');
      // not key+1 when first item is the slick-slide with data-index="-1" which is never displayed
      if (firstDataIndex === '-1') {
        node.setAttribute('aria-label', _t('Slide #{key}', { key }));
      } else {
        node.setAttribute('aria-label', _t('Slide #{key}', { key: key + 1 }));
      }
    });
  }

  /*
   * react-slick adds tabindex="-1" to each child:
   * https://github.com/akiran/react-slick/blob/0.23.1/src/slider.js#L183
   *
   * react-slick also adds wrapper DIVs (for rows/slidesPerRow support), and each of these (the newChildren passed to
   * InnerSlider and subsequently Track) also get a tabindex="-1":
   * https://github.com/akiran/react-slick/blob/0.23.1/src/track.js#L124
   *
   * This causes screen readers to read "group" on these DIVs. But in our implementation, the children components we
   * send already have a role="presentation" with an aria-label or are anchors, so we don't need this on any of them.
   * (Note: we'll add back any necessary tabIndex attributes in the next section of this method.)
   */
  const divsWithTabIndexAppliedBySlick = carouselNode.querySelectorAll(
    '.slick-slide[tabindex], .slick-slide>div>[tabindex]'
  );

  divsWithTabIndexAppliedBySlick.forEach((node) => {
    node.removeAttribute('tabindex');
  });

  const interactiveElementsToDisable = carouselNode.querySelectorAll(SlickNotActiveInteractiveElementSelector);
  const interactiveElementsToEnable = carouselNode.querySelectorAll(SlickActiveInteractiveElementSelector);

  interactiveElementsToDisable.forEach((node) => {
    // not tabbable, and hidden from screen readers
    node.setAttribute('aria-hidden', 'true');
    node.setAttribute('tabindex', '-1');
  });

  interactiveElementsToEnable.forEach((node) => {
    // tabbable and visible to screen readers
    node.removeAttribute('aria-hidden');
    node.setAttribute('tabindex', '0');
  });
}
