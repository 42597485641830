import type React from 'react';

import { Radio as MuiRadio } from '@material-ui/core';

import clsx from 'clsx';

import getChoiceInputCss, {
  classes as choiceInputClasses,
} from '@core/forms/getChoiceInputCss';

import getRadioInputCss, { classes } from './getRadioInputCss';
import { default as RadioCheckedIcon } from './RadioCheckedIcon';
import { default as RadioUncheckedIcon } from './RadioUncheckedIcon';

export type Props = {
  /**
   * This prop controls if the radio appears checked or not.
   */
  checked?: boolean;
  /**
   * CSS class applied to the root element
   */
  className?: string;
  /**
   * The id of the `input` element.
   */
  id?: string;
  /**
   * If true, radio will be disabled.
   */
  disabled?: boolean;
  /**
   * Attributes applied to the input element.
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  /**
   * Ref that points to the `input` element node
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * Name attribute of the input element
   */
  name?: string;
  /**
   * Callback function when the state changes
   * @param {object} event - The react event object
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * If true, input element will be required
   */
  required?: boolean;
  /**
   * Value of the component
   */
  value?: string;
  /**
   * Classname for focus visible state
   */
  focusVisibleClassName?: string;

  /**
   * If `true`, focus visible state will not be applied.
   */
  disableFocusVisible?: boolean;
};

const RadioInput = (props: Props): React.ReactElement<Props> => {
  const { disableFocusVisible, focusVisibleClassName, ...rest } = props;

  return (
    <MuiRadio
      {...rest}
      disableRipple
      aria-disabled={undefined} // Need to override this property otherwise VO appends "one more item" to the label. This issue exists in pure MUI radio as well.
      checkedIcon={<RadioCheckedIcon />}
      classes={{
        root: choiceInputClasses.root,
        checked: choiceInputClasses.checked,
        disabled: clsx(choiceInputClasses.disabled, classes.disabled),
      }}
      css={[getChoiceInputCss, getRadioInputCss]}
      focusVisibleClassName={clsx(
        {
          [classes.focusVisible]: !disableFocusVisible,
          [choiceInputClasses.focusVisible]: !disableFocusVisible,
        },
        focusVisibleClassName
      )}
      icon={<RadioUncheckedIcon />}
    />
  );
};

export default RadioInput;
