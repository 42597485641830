import * as React from 'react';

import type { landingPageTypeChoices } from 'bundles/coursera-plus-landing-page/contentful/constants';
import type { DayValue } from 'bundles/coursera-plus-landing-page/contentful/dayValue';
import type { LandingPagePicked } from 'bundles/coursera-plus-landing-page/contentful/fetchContentful';
import type LandingPageController from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageController';
import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageDataProvider';
import type { SubscriptionTiersProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import type { PropsFromWithExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';

type ValueOf<T> = T[keyof T];
type LandingPageTypeChoicesType = ValueOf<typeof landingPageTypeChoices>;

export type Prices = {
  primaryPrice?: SubscriptionTiersProductPrice; // discounted, if there's a promotion
  primaryOriginalPrice?: SubscriptionTiersProductPrice;
  secondaryPrice?: SubscriptionTiersProductPrice;
};

export type LandingPageExtended = {
  landingPage: LandingPagePicked;
  expiry?: DayValue;
  primaryProductItemId: SubscriptionTiersProductVariant;
  secondaryProductItemId?: SubscriptionTiersProductVariant | undefined;
  userHasCourseraPlusFreeTrial: boolean;
  userHasCourseraLiteFreeTrial: boolean;
  isCourseraPlusOwner: boolean;
  isCourseraLiteOwner: boolean;
  prices: Prices;
  redirectedFromExpired: boolean;
  isCourseraLiteContentfulPage: boolean;
  landingPageType?: LandingPageTypeChoicesType | null;
  customProductCollectionAtTheTop?: string;
  customProductCollectionAtBottom?: string;
  isCourseraLiteLandingPageEnabled: boolean;
  hideRegionalPromotionBanner?: boolean;
} & PropsFromWithExistingCourseraLiteSubscription;

export type LandingPageDataContext = {
  pageController: LandingPageController;
};

const PageContext = React.createContext<LandingPageDataContext | undefined>(undefined);

export default PageContext;
