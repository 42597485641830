import _ from 'lodash';

import user from 'js/lib/user';

import sessionStorageEx from 'bundles/common/utils/sessionStorageEx';
import type { GroupedEnrolledProductsBySkillType } from 'bundles/enterprise-legacy-learner-home/components/available/MergedEnrolledProductsList';
import type { FilterSelections } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersHOC';
import storageUtils from 'bundles/enterprise-legacy-learner-home/utils/storageUtils';
import type {
  CourseStub as CourseType,
  ProductStub as ProductType,
  S12nStub as S12nType,
} from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

const styleGuideColors: { [key: string]: string } = {
  white: '#FFFFFF',
  gray: '#767676',
  green: '#1F8354',
};

const PROGRAM_AGE_VERIFICATION_KEY = 'programAgeVerification';

const _isHexaColor = (str: string) => {
  return /(^#?[0-9A-F]{6}$)|(^#?[0-9A-F]{3}$)/i.test(str);
};

// http://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
// Check if a color is dark or light, dark color, e.g. #0000FF will return true
/* eslint-disable no-bitwise */
export function getIsDarkThemeByColor(color: string): boolean {
  if (!color || !_isHexaColor(color)) {
    return false; // If giving color is not valid, default to light theme
  }
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma < 160;
}

// Used in CircleWidget, current states includes ['green', 'gray-cover', 'green-cover']
// Color is for circle border, textColor is for the circle text
export function getColorsByCardState(cardState: string) {
  const { green, white } = styleGuideColors;
  let color = green;
  let backgroundColor = white;
  let textColor = green;
  const stateInputs = cardState.split('-');

  if (!_.isEmpty(stateInputs)) {
    const inputColor = stateInputs[0];
    color = styleGuideColors[inputColor];
    textColor = styleGuideColors[inputColor];
    if (stateInputs[1] === 'cover') {
      backgroundColor = styleGuideColors[inputColor];
      textColor = white;
    }
  }
  return { color, backgroundColor, textColor };
}

type CourseState = {
  courseId: string;
};

export type ProductStub = {
  s12nId?: string;
  courseId?: string;
  s12nCourseStates?: Array<CourseState>;

  isCourse?: boolean;
  isEnrolled?: boolean;
  isCompleted?: boolean;
  isS12n?: boolean;
  completionTime?: number;
};

export type ProductMemoType = { [index: string]: ProductStub | CourseState };

// helper function to check if object has a constructor
function isConstructor<T, A>(obj: new (arg: A) => T) {
  return !!(obj && obj.prototype && obj.prototype.constructor.name);
}

export function mapArrayToType<T, A>(arr: A[], Type: new (arg: A) => T) {
  if (Array.isArray(arr) && isConstructor(Type)) {
    return arr.map((x) => new Type(x));
  }
  return arr;
}

const DEFAULT_SOCIAL_CAPTION = (shareLink: string | undefined) =>
  _t('Have you seen this? University & college students can learn for free on Coursera! #{link}', { link: shareLink });

export const getSocialCaptions = () => ({
  twitter: () => _t('Check this out, university & college students can learn for free on @Coursera!'),
  whatsapp: DEFAULT_SOCIAL_CAPTION,
  emailSubject: () => _t('Free Coursera for University & College Students'),
  emailBody: DEFAULT_SOCIAL_CAPTION,
  facebook: DEFAULT_SOCIAL_CAPTION,
  linkedin: DEFAULT_SOCIAL_CAPTION,
});

const DEFAULT_C4ER_SOCIAL_CAPTION = (shareLink: string | undefined) =>
  _t('My colleagues can learn for free #{link}', { link: shareLink });

export const getC4erSocialCaptions = () => ({
  twitter: () => _t('My colleagues can learn for free on @Coursera!'),
  whatsapp: DEFAULT_C4ER_SOCIAL_CAPTION,
  emailSubject: () => _t('My colleagues can learn for free'),
  emailBody: DEFAULT_C4ER_SOCIAL_CAPTION,
  facebook: DEFAULT_C4ER_SOCIAL_CAPTION,
  linkedin: DEFAULT_C4ER_SOCIAL_CAPTION,
});

type AgeVerification = {
  [programId: string]: boolean | undefined;
};

export const getIdFromProductId = (productId: string) => {
  return productId.split(/~(.+)/)[1].replace('!', '');
};

export function filterDuplicatedCourses(courses: Array<CourseType>, s12ns: Array<S12nType>): Array<CourseType> {
  // Since enrolledCourses no longer depends on specializations it's unaware if a course is part of a specialization or not. So we want to remove the duplicate courses if enrolledS12ns already contains them.
  const coursesInS12ns = _.flatMap(s12ns, (s12n) =>
    s12n.productState.definition.courseStates?.map((state) => state.courseId)
  );
  const coursesInS12nsSet = new Set(coursesInS12ns);
  return courses ? courses.filter((course) => !coursesInS12nsSet.has(course.productState.definition.courseId)) : [];
}

export function orderProducts(products: Array<ProductType>): Array<ProductType> {
  const rank = ({ isCompleted, isCourse }: ProductType) => {
    if (isCompleted) {
      return isCourse ? 3 : 2;
    } else {
      return isCourse ? 1 : 0;
    }
  };
  const sortBy = (first: ProductType, second: ProductType): number => {
    const firstRank = rank(first);
    const secondRank = rank(second);
    if (firstRank !== secondRank) {
      return firstRank < secondRank ? -1 : 1;
    } else {
      const firstLastUpdatedAt = first.productState.definition.lastUpdatedAt;
      const secondLastUpdatedAt = second.productState.definition.lastUpdatedAt;
      if (firstLastUpdatedAt && secondLastUpdatedAt) {
        return firstLastUpdatedAt > secondLastUpdatedAt ? -1 : 1;
      } else if (!firstLastUpdatedAt && secondLastUpdatedAt) {
        return -1;
      } else if (firstLastUpdatedAt && !secondLastUpdatedAt) {
        return 1;
      } else {
        return first.id > second.id ? 1 : -1;
      }
    }
  };
  return products.sort(sortBy);
}

export const groupEnrolledProductsBySkill = (
  enrolledProducts: Array<ProductType>
): GroupedEnrolledProductsBySkillType =>
  enrolledProducts.reduce<GroupedEnrolledProductsBySkillType>((acc, product) => {
    const tspId = product.productState?.definition?.enrolledTargetSkillProfileId;
    if (tspId && !acc[tspId]) {
      acc[tspId] = [product];
    } else if (tspId && acc[tspId]) {
      acc[tspId].push(product);
    }

    return acc;
  }, {});

export const setAgeVerification = (programId: string, isAuthenticatedUser: boolean, passed: boolean) => {
  if (isAuthenticatedUser) {
    storageUtils.set(user.get().id, programId, PROGRAM_AGE_VERIFICATION_KEY, passed);
  } else {
    const ageVerification = sessionStorageEx.getItem<AgeVerification>(PROGRAM_AGE_VERIFICATION_KEY, JSON.parse, {});
    ageVerification[programId] = passed;
    sessionStorageEx.setItem(PROGRAM_AGE_VERIFICATION_KEY, ageVerification, JSON.stringify);
  }
};

export const getAgeVerification = (programId: string, isAuthenticatedUser: boolean): boolean | undefined => {
  if (isAuthenticatedUser) {
    return storageUtils.get(user.get().id, programId, PROGRAM_AGE_VERIFICATION_KEY);
  } else {
    const ageVerification = sessionStorageEx.getItem<AgeVerification>(PROGRAM_AGE_VERIFICATION_KEY, JSON.parse, {});
    return ageVerification[programId];
  }
};

export const getFilterTrackingData = (filterSelections: FilterSelections | undefined) => {
  if (!filterSelections) return [];

  let skillSetGoal = '';
  let skillSetRole = '';
  let skillSetSkill = '';

  if (filterSelections?.goal) {
    skillSetGoal = `goal:${filterSelections?.goal}`;
  }
  if (filterSelections?.role) {
    skillSetRole = `role:${filterSelections?.role}`;
  }
  if (filterSelections?.skills) {
    skillSetSkill = `skill:${filterSelections.skills}`;
  }

  return [skillSetGoal, skillSetRole, skillSetSkill].filter((option) => option.length > 0);
};
