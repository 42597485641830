/**
 * This component generates a list of CollectionItem contained in a fragment.
 * Intented to be used with CollectionItemWithCarousel or other container component.
 */
import * as React from 'react';

import { useQuery } from '@apollo/client';
import type * as Types from '__generated__/graphql-types';
import { filter, keyBy, map } from 'lodash';

import Retracked from 'js/app/retracked';
import { useRetracked } from 'js/lib/retracked';

import { CardSection, StyleSheet, css } from '@coursera/coursera-ui';

import { getCollectionType } from 'bundles/collections-common/utils/collectionsUtils';
import type {
  ClipsQueryQuery,
  ClipsQueryQueryVariables,
} from 'bundles/enterprise-admin-program-catalog/api/__generated__/clipsQuery';
import clipsQuery from 'bundles/enterprise-admin-program-catalog/api/clipsQuery.graphql';
import type { ClipItem, CollectionType } from 'bundles/enterprise-collections/components/CollectionItem';
import { PRODUCT_TYPE, ProductCard } from 'bundles/enterprise-legacy-learner-home/components/ProductCard';
import type { ProductCardType } from 'bundles/enterprise-legacy-learner-home/components/ProductCardBase';
import ProductCardStateWrapper from 'bundles/enterprise-legacy-learner-home/components/ProductCardStateWrapper';
import type { S12nSessionDatesQuery_OnDemandCourseSessionsV1Resource_multiGet_elements as S12nSession } from 'bundles/enterprise-legacy-learner-home/components/single-program/__generated__/S12nSessionDatesQuery';
import type {
  EnterpriseProductMetadataConfiguration,
  EnterpriseProductMetadataFlags,
  Product,
  ProductType,
} from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import { getProductId } from 'bundles/enterprise-legacy-learner-home/utils/ProductUtils';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import {
  getIdFromProductId,
  getProductMetadata,
} from 'bundles/enterprise-legacy-learner-home/utils/programCommonUtils';

import _t from 'i18n!nls/enterprise-collections';

export type CollectionProduct = {
  id: string;
  isCourse: boolean;
  isClip?: boolean;
  product: Product;
};

type Props = {
  customSkillset?: boolean;
  collectionOrder?: number;
  collection: CollectionType;
  customCollection?: CollectionType;
  showSelection?: boolean;
  selectedProductIds?: string[];
  onProductCardClick?: (product: Product, productType: ProductType) => void;
  enterpriseProductConfigurations?: EnterpriseProductMetadataConfiguration;
  itemIdMappingFunction?: (id: string) => string;
  showClipStyleIfClip?: boolean;
  currentSlideNumber?: number;

  /**
   * `onSelect` will be called whenever any product is selected or deselected.
   * `isSelected` parameter indicates whether a selecting or a deselecting happened.
   *
   * To avoid passing both `selectedCourseIds` and `selectedS12nIds`, the unified `productId`
   * is used here.
   *
   * At this level, `productIds` could have only one productId, but it is declared as `string[]`
   * to keep consistent with `CollectionListItem`.
   */
  onSelect?: (productIds: string[], isSelected: boolean) => void;
  invertS12nSelection?: boolean;
  s12nSessions?: S12nSession[];
  productCardTrackingName?: string;
};

const styles = StyleSheet.create({
  courseNoteSection: {
    position: 'relative',
    // accounting for some margin set in the product card
    top: '-34px',
  },
  courseNoteText: {
    color: '#666',
    fontSize: '14px',
  },
});

const TrackedProductCard = Retracked.withVisibilityTracking(ProductCard);

export function mapClipItemToProduct(clip: ClipItem): Product {
  return {
    id: clip.id,
    name: clip.name,
    partners: { elements: clip.partners ?? [] },
    promoPhoto: clip.thumbnail,
    productVariant: 'clip',
    productType: PRODUCT_TYPE.CLIP,
    slug: `${clip.course.slug}/${clip.id.split('~')[1]}`,
    views: clip.views ?? 0,
    parentCourseName: clip.course.name,
    // @ts-expect-error Duration: string
    duration: clip.duration,
  };
}

// NOTE: this has to be defined as a function instead of a functional component
// otherrwise Carousel (in CollectionItemWithCarousel) will add an extra <div> wrapper which breaks the layout
export function getProductTypeFromProduct(productCardType: string | undefined, isCourse?: boolean): ProductCardType {
  let productType;

  if (productCardType) {
    productType = PRODUCT_TYPE[productCardType.toUpperCase()];
  } else if (isCourse) {
    productType = PRODUCT_TYPE.COURSE;
  } else {
    productType = PRODUCT_TYPE.S12N;
  }

  return productType;
}

export const getAllProducts = (
  { courses: nullableCourses, s12ns: nullableS12ns, items, clips }: CollectionType,
  itemIdMappingFunction?: (id: string) => string
): Array<CollectionProduct> => {
  // To interop with the silly GraphQL schema, allow lots of nulls in the type and then filter them out here. They're
  // only present when Assembler fails to join the edges.
  const courses = filterExistsOrDefault(nullableCourses?.elements);
  const s12ns = filterExistsOrDefault(nullableS12ns?.elements);

  if (courses.length === 0 && s12ns.length === 0 && (!clips?.length || clips.length < 1)) {
    return [];
  }

  let allProducts: Array<CollectionProduct> = [];

  if (items) {
    const productMap = keyBy(
      [
        ...map(courses, (product) => ({ id: product.id, product, isCourse: true, isClip: false })),
        ...map(s12ns, (product) => ({ id: product.id, product, isCourse: false, isClip: false })),
        ...map(clips, (clip) => ({ id: clip.id, product: mapClipItemToProduct(clip), isCourse: false, isClip: true })),
      ],
      (product) => product.id
    );
    const itemIds = map(items, (item) =>
      itemIdMappingFunction
        ? itemIdMappingFunction(item.productId)
        : item.clipId
        ? `${item.clipId.courseId}~${item.clipId.itemId}`
        : getIdFromProductId(item.productId)
    );

    allProducts = filter(
      map(itemIds, (itemId) => productMap[itemId]),
      Boolean
    );
  } else {
    const courseProducts = courses.map((product) => {
      return {
        isCourse: true,
        product,
        id: product.id,
        isClip: false,
      };
    });
    const s12nProducts = s12ns.map((product) => ({
      isCourse: false,
      product,
      id: product.id,
      isClip: false,
    }));
    allProducts = [...courseProducts, ...s12nProducts];
  }

  return allProducts;
};

function GetCollectionItemList({
  customSkillset,
  collectionOrder,
  collection,
  showSelection,
  customCollection,
  selectedProductIds,
  onSelect,
  invertS12nSelection,
  onProductCardClick,
  enterpriseProductConfigurations: productMetadata = [],
  s12nSessions,
  itemIdMappingFunction,
  showClipStyleIfClip,
  productCardTrackingName,
  currentSlideNumber,
}: Props) {
  const collectionInUse = customCollection ?? collection;
  const { trackId: collectionId, associatedSessions } = collectionInUse;
  const clipItems = (collection.items ?? [])
    .filter((item) => item.clipId)
    .map(({ clipId }) => ({ itemId: clipId?.itemId, courseId: clipId?.courseId })) as Types.Clip_CourseItemId[];

  const track = useRetracked();
  const {
    data: clipsData,
    loading: loadingClips,
    error: clipsError,
  } = useQuery<ClipsQueryQuery, ClipsQueryQueryVariables>(clipsQuery, {
    skip: clipItems.length === 0,
    variables: {
      ids: clipItems,
    },
    context: {
      clientName: 'gatewayGql',
    },
  });

  const allProducts: Array<CollectionProduct> = getAllProducts(
    {
      ...collectionInUse,
      // @ts-expect-error URL: string
      clips: collectionInUse?.clips ?? clipsData?.Clip?.queryClipItemsByIds,
    },
    itemIdMappingFunction
  );

  if (allProducts.length === 0) {
    return <h2>{_t('There are no courses or specializations in this collection.')}</h2>;
  }

  const onToggleCourseSelect = ({ isSelected = false, product }: { isSelected?: boolean; product: Product }) => {
    if (!showSelection || !onSelect) {
      return;
    }

    const productId = getProductId(product.id, true);
    onSelect([productId], isSelected);
  };

  const onToggleS12nSelect = ({ isSelected = false, product }: { isSelected?: boolean; product: Product }) => {
    if (!showSelection || !onSelect) {
      return;
    }

    const productId = getProductId(product.id, false);
    onSelect([productId], isSelected);
  };

  const getSessionDates = (isCourse: boolean, productId: string, productMetadata?: EnterpriseProductMetadataFlags) => {
    if (isCourse) {
      return associatedSessions?.elements?.find((session0) => productId === session0?.courseId);
    }

    return s12nSessions?.find((session) => session.id === productMetadata?.associatedSessionId);
  };
  return allProducts.map(({ product, isCourse, id, isClip }, index) => {
    const productState = { isS12n: !isCourse };
    const productId = getProductId(id, isCourse, product.productType);
    const isSelected = selectedProductIds?.includes(productId);
    const onToggleSelect = isCourse ? onToggleCourseSelect : onToggleS12nSelect;
    const trackingData = {
      customSkillset,
      collectionOrder,
      productCardOrder: index + 1,
      collectionId,
      productId,
    };
    const trackingName = productCardTrackingName ?? 'collection_product_card';
    const courseMetadata = getProductMetadata(isCourse, product.id, productMetadata);
    const session = getSessionDates(isCourse, product.id, courseMetadata);

    // Avoid TrackedDiv or similar because it introduces an extra VO-focusable <div> that adds screen reader
    // noise. Our role="text" workaround is banned by Lighthouse.
    const onProductCardClickWithTracking = (product0: Product, productType: ProductCardType) => {
      track({ trackingName, trackingData, action: 'click' });
      onProductCardClick?.(product0, productType);
    };

    const collectionType = getCollectionType(collectionId);
    const productType = getProductTypeFromProduct(product?.productType, isCourse);

    const eventingV3CarouselData = {
      id: collectionId ?? '',
      name: collection.title,
      model: collectionId ?? '',
      type: collectionType,
      section: collectionOrder,
      ...(currentSlideNumber && {
        pagination: {
          pageNumber: currentSlideNumber,
        },
      }),
    };

    const eventingV3ProductData = {
      id,
      name: product.name,
      ...(productType && {
        type: productType,
      }),
      slug: product.slug || '',
    };

    return (
      <div className="rc-CollectionItemWithCarousel-Card" key={id}>
        <TrackedProductCard
          product={product}
          productType={productType}
          onClick={onProductCardClickWithTracking}
          // TODO:
          // miniModalProps={{ id, isCourse, tag: 'div', collectionId }}
          htmlAttributes={{ 'data-e2e': isCourse ? 'CourseProductCard' : 'S12nProductCard' }}
          productStateElement={
            showSelection && (
              <ProductCardStateWrapper
                isS12n={!isCourse && !isClip}
                isNotSelected={!isSelected}
                isSelected={isSelected}
                onToggleSelect={onToggleSelect}
                product={product}
                productState={productState}
                invertS12nSelection={invertS12nSelection}
              />
            )
          }
          enterpriseProductMetadata={courseMetadata}
          startsAt={session?.startsAt}
          endsAt={session?.endsAt}
          trackingName={trackingName}
          data={trackingData}
          showClipStyleIfClip={showClipStyleIfClip}
          eventingV3Data={{
            productCard: {
              index: index ?? 0,
            },
            product: eventingV3ProductData,
            carousel: eventingV3CarouselData,
          }}
        >
          {isClip && product?.parentCourseName && showClipStyleIfClip && (
            <CardSection>
              <div {...css(styles.courseNoteSection)}>
                <b>From the course:</b>
                <span {...css(styles.courseNoteText)}> {product.parentCourseName}</span>
              </div>
            </CardSection>
          )}
        </TrackedProductCard>
      </div>
    );
  });
}

export default GetCollectionItemList;
