/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import user from 'js/lib/user';

import { breakpoints } from '@coursera/cds-core';

import AgePrompt, {
  AGE_VERIFICATION_STATUS,
} from 'bundles/enterprise-legacy-learner-home/components/age-verification/AgePrompt';
import type { ageVerificationStatusValues } from 'bundles/enterprise-legacy-learner-home/components/age-verification/AgePrompt';
import AgeVerificationFail from 'bundles/enterprise-legacy-learner-home/components/age-verification/AgeVerificationFail';
import AgeVerificationSuccess from 'bundles/enterprise-legacy-learner-home/components/age-verification/AgeVerificationSuccess';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import ProgramHomeWrapper from 'bundles/program-home/components/ProgramHomeWrapper';
import { getAgeVerification } from 'bundles/program-home/utils/ProgramHomeUtils';

type Props = {
  programId: string;
  thirdPartyOrganizationId: string;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  program: EnterpriseProgramsV1;
  programName: string;
  isAuthenticatedUser: boolean;
  setEnableAgeVerification: (val: boolean) => void;
  enableSkillsInSearchAndBrowse: boolean;
};

const styles = {
  page: css`
    background-color: var(--cds-color-neutral-disabled-weak);
    min-height: 100vh;
  `,
  container: css`
    margin-left: auto;
    margin-right: auto;
    max-width: ${breakpoints.values.md}px;
    ${breakpoints.up('md')} {
      padding: var(--cds-spacing-200);
    }
  `,
  main: css`
    background-color: var(--cds-color-neutral-background-primary);
    padding: 0 var(--cds-spacing-200);

    &::before,
    &::after {
      content: '';
      display: table;
    }

    &::before {
      margin-bottom: var(--cds-spacing-800);
    }

    &::after {
      margin-top: var(--cds-spacing-800);
    }
    ${breakpoints.down('xs')} {
      &::before {
        margin-bottom: var(--cds-spacing-300);
      }

      &::after {
        margin-top: var(--cds-spacing-300);
      }
    }
  `,
};

function AgeVerificationPage({
  programId,
  thirdPartyOrganizationId,
  thirdPartyOrganization,
  program,
  programName,
  isAuthenticatedUser,
  setEnableAgeVerification,
  enableSkillsInSearchAndBrowse,
}: Props): JSX.Element {
  const userId = user.get().id;

  const [ageVerificationState, setAgeVerificationState] = React.useState<ageVerificationStatusValues>();

  React.useEffect(() => {
    const ageVerification = getAgeVerification(programId, isAuthenticatedUser);
    if (ageVerification === undefined) {
      setAgeVerificationState(AGE_VERIFICATION_STATUS.NOT_ANSWERED);
    } else if (ageVerification === false) {
      setAgeVerificationState(AGE_VERIFICATION_STATUS.FAIL);
    } else {
      setAgeVerificationState(AGE_VERIFICATION_STATUS.SUCCESS);
    }
  }, [programId, isAuthenticatedUser]);

  return (
    <ProgramHomeWrapper
      className="AgeVerificationPage"
      thirdPartyOrganizationId={thirdPartyOrganizationId}
      thirdPartyOrganizationSlug={thirdPartyOrganization?.slug}
      program={program}
      programId={programId}
      programName={programName}
      isAuthenticatedUser={!!userId}
      shouldHideSearch
      css={styles.page}
      enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
    >
      <div css={styles.container}>
        <main css={styles.main}>
          {ageVerificationState === AGE_VERIFICATION_STATUS.NOT_ANSWERED && (
            <AgePrompt
              programId={programId}
              isAuthenticatedUser={isAuthenticatedUser}
              onContinueClick={setAgeVerificationState}
            />
          )}
          {ageVerificationState === AGE_VERIFICATION_STATUS.FAIL && <AgeVerificationFail programName={program.name} />}
          {ageVerificationState === AGE_VERIFICATION_STATUS.SUCCESS && (
            <AgeVerificationSuccess programName={program.name} setEnableAgeVerification={setEnableAgeVerification} />
          )}
        </main>
      </div>
    </ProgramHomeWrapper>
  );
}

// This proxy needs to exist otherwise it will throw at runtime with an error due to recompose using the functional component as a render function in a class component. This proxy creates a barrier that prevents that.
const AgeVerificationPageHookProxy = (props: Props) => <AgeVerificationPage {...props} />;

export default AgeVerificationPageHookProxy;
