import type {
  AiCoach_Action as AiCoachAction,
  AiCoach_CoachActionConfig as AiCoachCoachActionConfig,
  AiCoach_ItemNavigationButton as AiCoachItemNavigationButton,
  AiCoach_RedirectLinkNavigationButton as AiCoachRedirectLinkNavigationButton,
  AiCoach_SenderType as SenderTypes,
} from '__generated__/graphql-types';

import type { Feedback, StructuredResponse } from '../types';

export const TOGGLE_CHAT = 'BEHAVIOR/TOGGLE_CHAT';
export const OPEN_COACH_WIDGET = 'BEHAVIOR/OPEN_COACH_WIDGET';
export const CLOSE_COACH_WIDGET = 'BEHAVIOR/CLOSE_COACH_WIDGET';
export const TOGGLE_INPUT_DISABLED = 'BEHAVIOR/TOGGLE_INPUT_DISABLED';
export const ENABLE_MESSAGE_LOADER = 'BEHAVIOR/ENABLE_MSG_LOADER';
export const UPDATE_CHAT_HISTORY = 'MESSAGES/UPDATE_CHAT_HISTORY';
export const ADD_NEW_USER_MESSAGE = 'MESSAGES/ADD_NEW_USER_MESSAGE';
export const ADD_NEW_RESPONSE_MESSAGE = 'MESSAGES/ADD_NEW_RESPONSE_MESSAGE';

export const DROP_MESSAGES = 'MESSAGES/DROP_MESSAGES';
export const HIDE_AVATAR = 'MESSAGES/HIDE_AVATAR';
export const DELETE_MESSAGES = 'MESSAGES/DELETE_MESSAGES';
export const FEEDBACK_SET_LIKE = 'MESSAGE/FEEDBACK_SET_LIKE';
export const FEEDBACK_SET_UNLIKE = 'MESSAGE/FEEDBACK_SET_UNLIKE';
export const FEEDBACK_UNSET = 'MESSAGE/FEEDBACK_UNSET';
export const SET_USER_INPUT_TEXT = 'INPUT/SET_USER_INPUT_TEXT';
export const SET_CHAT_SESSION_ID = 'SESSION/SET_CHAT_SESSION_ID';
export const SET_CHAT_SESSION_STORAGE_KEY = 'SESSION/SET_CHAT_SESSION_STORAGE_KEY';
export const CLEAR_CHAT_SESSION = 'SESSION/CLEAR_CHAT_SESSION';
export const NEW_CHAT_SESSION = 'SESSION/NEW_CHAT_SESSION';

export interface ToggleChat {
  type: typeof TOGGLE_CHAT;
}

export interface OpenCoachWidget {
  type: typeof OPEN_COACH_WIDGET;
}

export interface CloseCoachWidget {
  type: typeof CLOSE_COACH_WIDGET;
}

export interface ToggleInputDisabled {
  type: typeof TOGGLE_INPUT_DISABLED;
}

export interface AddUserMessage {
  type: typeof ADD_NEW_USER_MESSAGE;
  text: string;
  id?: string;
  timestamp?: number;
  action?: AiCoachAction;
}

export interface AddResponseMessage {
  type: typeof ADD_NEW_RESPONSE_MESSAGE;
  text: string;
  id?: string;
  userFeedback?: Feedback | null;
  timestamp?: number;
  navigationButtons?: (AiCoachItemNavigationButton | AiCoachRedirectLinkNavigationButton)[] | null;
  structuredResponse?: StructuredResponse;
  recommendedActions?: AiCoachCoachActionConfig[];
}

export type ResponseMessage = {
  sender: SenderTypes;
  text: string;
  id?: string;
  userFeedback?: Feedback | null;
  timestamp?: number;
  navigationButtons?: (AiCoachItemNavigationButton | AiCoachRedirectLinkNavigationButton)[] | null;
  structuredResponse?: StructuredResponse;
  recommendedActions?: AiCoachCoachActionConfig[];
};

export type UserMessage = {
  sender: SenderTypes;
  text: string;
  id?: string;
  timestamp?: number;
  action?: AiCoachAction;
};

export interface UpdateChatHistory {
  type: typeof UPDATE_CHAT_HISTORY;
  messages: (ResponseMessage | UserMessage)[];
}

export interface EnableMsgLoader {
  type: typeof ENABLE_MESSAGE_LOADER;
  messageLoader: boolean;
}

export interface DropMessages {
  type: typeof DROP_MESSAGES;
}

export interface HideAvatar {
  type: typeof HIDE_AVATAR;
  index: number;
}

export interface DeleteMessages {
  type: typeof DELETE_MESSAGES;
  count: number;
  id?: string;
}

export interface FeedbackSetLike {
  type: typeof FEEDBACK_SET_LIKE;
  id?: string;
}
export interface FeedbackSetDisLike {
  type: typeof FEEDBACK_SET_UNLIKE;
  id?: string;
}
export interface FeedbackUnSet {
  type: typeof FEEDBACK_UNSET;
  id?: string;
}
export interface SetUserInputText {
  type: typeof SET_USER_INPUT_TEXT;
  inputText: string;
}

export interface SetChatSessionId {
  type: typeof SET_CHAT_SESSION_ID;
  chatSessionId?: string;
}

export interface SetChatSessionStorageKey {
  type: typeof SET_CHAT_SESSION_STORAGE_KEY;
  chatSessionStorageKey?: string;
}

export interface ClearChatSession {
  type: typeof CLEAR_CHAT_SESSION;
}

export interface NewChatSession {
  type: typeof NEW_CHAT_SESSION;
}

export type SessionActions = SetChatSessionId | SetChatSessionStorageKey | ClearChatSession | NewChatSession;

export type BehaviorActions = ToggleChat | OpenCoachWidget | CloseCoachWidget | ToggleInputDisabled | EnableMsgLoader;

export type MessagesActions =
  | UpdateChatHistory
  | AddUserMessage
  | AddResponseMessage
  | DropMessages
  | HideAvatar
  | DeleteMessages
  | FeedbackSetLike
  | FeedbackSetDisLike
  | FeedbackUnSet;

export type InputActions = SetUserInputText;
