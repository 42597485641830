import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('radioInput', [
  'focusVisible',
  'disabled',
  'checkedIconBg',
]);

const styles = css`
  &.${classes.disabled} {
    .${classes.checkedIconBg} {
      fill: var(--cds-color-neutral-disabled);
    }
  }

  &.${classes.focusVisible} {
    svg {
      border-radius: 50%;
    }
  }
`;

export default styles;
