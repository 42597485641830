/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { useRetracked } from 'js/lib/retracked';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import type { Props as CoachIconButtonProps } from 'bundles/ai-coach-platform/components/building-blocks/CoachIconButton';
import { MicrophoneIcon, MicrophoneOffIcon, StopIconFilled } from 'bundles/ai-coach-platform/components/icons/mui';
import useSpeechToText from 'bundles/ai-coach-platform/components/patterns/chat/utils/VoiceInput/useSpeechToText';
import type { SpeechRecognitionStatus } from 'bundles/ai-coach-platform/components/patterns/chat/utils/VoiceInput/useSpeechToText';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  root: css`
    position: relative;
    display: flex;
  `,
  // blob that appears when the user is speaking and
  // its size is a function of the speech volume
  speechBlob: ({
    size = 'small',
    speechVolume,
    animated,
  }: {
    size: CoachIconButtonProps['size'];
    speechVolume: number;
    animated: boolean;
  }) => css`
    position: absolute;
    background-color: var(--cds-color-blue-50);
    border-radius: 50%;
    width: ${size === 'small' ? '36px' : '48px'};
    height: ${size === 'small' ? '36px' : '48px'};
    z-index: 0;
    transform: scale(${1 + speechVolume * 0.5});
    transition: transform 0.1s ease-in-out;
    transform-origin: 'center';

    ${!animated ? `transform: none` : ''}
  `,
};

export type Props = {
  /**
   * Callback when voice input is transcribed to text and wheter it is interim or final
   */
  onTranscript: (transcript: string, isInterim: boolean) => void;

  /**
   * Number of seconds to automatically stop listening after there is no sound
   *
   * @default
   */
  stopAfterSeconds?: number;

  /**
   * Automatically adds punctuation to the final transcript.
   *
   * e.g. "hello world" -> "Hello world." or "how are you" -> "How are you?"
   *
   * Note: this only works for English language. This will be auto-disabled for other languages.
   *
   * @default true
   */
  enableAutomaticPunctuation?: boolean;

  /**
   * Callback when the listening status changes
   */
  onStatusChange?: (status: SpeechRecognitionStatus) => void;

  /**
   * Callback when listening starts
   */
  onStartListening?: () => void;

  /**
   * Callback when listening stops
   */
  onStopListening?: () => void;

  /**
   * Callback when there is some error in the speech recognition API
   */
  onError?: (error: string) => void;

  /**
   * Callback when the CTA is clicked
   */
  onClick?: (listening: boolean) => void;

  /**
   * size of the CTA
   * @default 'small'
   */
  size?: CoachIconButtonProps['size'];

  /**
   * Language code to use for speech recognition
   *
   */
  language?: string;

  /**
   * Overrides default supported user languages for speech recognition.
   * Use together with `language` to set a different language.
   *
   * e.g.
   * ```
   * <SpeechToTextButton
   *    language={user.get().locale}
   *    supportedLanguages={[ 'es-ES', 'en-US', 'fr-FR' ]}
   * />
   * ```
   */
  supportedLanguages?: Array<string>;

  /**
   * Whether to animate the speech blob when receiving voice input
   *
   * @default true
   */
  animated?: boolean;

  /**
   * Children render prop to pass additional info back up to parent, such as `clearTranscript` callback
   *
   * e.g.
   * ```
   * <SpeechToTextButton {...props}>
   *  {({ listening, clearTranscript, recognition }) => {
        clearRef.current = clearTranscript;
        setIsListening(listening);
      }}
   * </SpeechToTextButton>
   * ```
   * @default null
   */
  children?: ({
    listening,
    clearTranscript,
    recognition,
  }: {
    listening: boolean;
    clearTranscript: () => void;
    recognition: SpeechRecognition | null;
  }) => React.ReactNode;
};

/**
 * Reusable speech-to-text UI component using `useSpeechToText` hook
 */
const SpeechToTextButton = (props: Props) => {
  const {
    stopAfterSeconds = 4,
    onStatusChange,
    size = 'small',
    onStartListening,
    onStopListening,
    language,
    enableAutomaticPunctuation = true,
    supportedLanguages,
    animated = true,
    onError,
    onClick,
    children = null,
    onTranscript,
  } = props;

  const [speechVolume, setSpeechVolume] = React.useState(0);
  const audioContextRef = React.useRef<AudioContext | null>(null);
  const analyzerRef = React.useRef<AnalyserNode | null>(null);
  const dataArrayRef = React.useRef<Uint8Array | null>(null);
  const sourceRef = React.useRef<MediaStreamAudioSourceNode | null>(null);
  const animationFrameRef = React.useRef<number | null>(null);
  const streamRef = React.useRef<MediaStream | null>(null);

  // temporarily use eventing v2 for quickly getting insights on STT usage
  const trackRetracked = useRetracked();

  // detects volume of speech to determine animation of the speech blob
  const startVolumeAnimation = async () => {
    try {
      // permission from user to access microphone - this will already be granted from Web speech API
      // but this is needed to get the AudioContext for volume detection
      streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new AudioContext();

      // analyzer node created to get real time frequency and amplitude data from audio input
      analyzerRef.current = audioContextRef.current.createAnalyser();
      analyzerRef.current.fftSize = 256;
      const bufferLength = analyzerRef.current.frequencyBinCount;
      dataArrayRef.current = new Uint8Array(bufferLength);
      sourceRef.current = audioContextRef.current.createMediaStreamSource(streamRef.current);
      sourceRef.current.connect(analyzerRef.current);

      // calculate volume to animate speech blob
      const animateVolume = () => {
        if (dataArrayRef.current && analyzerRef.current) {
          animationFrameRef.current = requestAnimationFrame(animateVolume);
          analyzerRef.current.getByteFrequencyData(dataArrayRef.current);
          // volume is a floating point between 0 -> 1
          const currentVolume = dataArrayRef.current
            ? dataArrayRef.current.reduce((a, b) => a + b, 0) / bufferLength / 128
            : speechVolume;

          // max out at 0.3 to limit the blob size, which is calculated based on volume
          setSpeechVolume(Math.min(currentVolume, 0.3));
        }
      };

      animateVolume();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`[SpeechToTextButton.startVolumeAnimation]`, e);
    }
  };

  const stopVolumeAnimation = () => {
    if (sourceRef.current) {
      sourceRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (streamRef.current) {
      // this actually stops the mic recording, and visual indication in the browser
      streamRef.current.getTracks().forEach((track) => track.stop());
    }

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    setSpeechVolume(0);
  };

  const handleStartListening = () => {
    onStartListening?.();

    if (animated) {
      startVolumeAnimation();
    }
  };

  const handleStopListening = () => {
    onStopListening?.();

    if (animated) {
      stopVolumeAnimation();
    }
  };

  const handleError = useCallback(
    (error: string) => {
      onError?.(error);
    },
    [onError]
  );

  const handleSupportStatus = useCallback(
    (hasSupport: boolean) => {
      if (!hasSupport) {
        handleError('Your browser does not support the Web Speech API.');
      }

      trackRetracked({
        trackingData: {
          supported: hasSupport,
        },
        trackingName: 'ai_coach_platform_speech_to_text_support',
        action: 'view',
      });
    },
    [handleError, trackRetracked]
  );

  const handleTranscript = useCallback(
    (transcript: string, isInterim: boolean) => {
      onTranscript?.(transcript, isInterim);
    },
    [onTranscript]
  );

  const {
    startListening,
    stopListening,
    status,
    isSupportedBrowser,
    isSupportedLanguage,
    listening,
    error,
    clearTranscript,
    recognition,
  } = useSpeechToText({
    stopAfterSeconds,
    language,
    supportedLanguages,
    automaticPunctuation: enableAutomaticPunctuation,
    onStartListening: handleStartListening,
    onStopListening: handleStopListening,
    onError: handleError,
    onSupportStatus: handleSupportStatus,
    onTranscript: handleTranscript,
  });

  const handleButtonClick = () => {
    if (listening) {
      stopListening();

      trackRetracked({
        trackingData: {},
        trackingName: 'ai_coach_platform_speech_to_text_button_stop',
        action: 'click',
      });
    } else {
      startListening();

      trackRetracked({
        trackingData: {},
        trackingName: 'ai_coach_platform_speech_to_text_button_start',
        action: 'click',
      });
    }

    onClick?.(listening);
  };

  useEffect(() => {
    onStatusChange?.(status);
  }, [status, onStatusChange]);

  if (!isSupportedBrowser || !isSupportedLanguage) {
    return null;
  }

  if (error === 'not-allowed') {
    return (
      <CoachIconButton
        aria-label={_t('Allow microphone permission to use speech-to-text')}
        tooltip={_t('Allow microphone permission')}
        disabled
        icon={<MicrophoneOffIcon />}
        size={size}
      />
    );
  }

  return (
    <div className="coach-speech-to-text-button" css={styles.root}>
      {listening ? <div className="speech-blob" css={styles.speechBlob({ size, speechVolume, animated })} /> : null}
      <CoachIconButton
        aria-label={listening ? _t('Stop using microphone') : _t('Use your microphone')}
        tooltip={listening ? _t('Stop using microphone') : _t('Use microphone')}
        onClick={handleButtonClick}
        icon={listening ? <StopIconFilled fill="var(--cds-color-blue-600)" /> : <MicrophoneIcon />}
        size={size}
        css={css`
          --cds-color-interactive-background-primary-hover-weak: var(--cds-color-blue-50);
        `}
      />

      {/* render callback to pass data up to parent */}
      {typeof children === 'function' ? children({ listening, clearTranscript, recognition }) : null}
    </div>
  );
};

export default SpeechToTextButton;
