/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import NavigationButtons from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/NavigationButtons';
import RecommendedActions from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/RecommendedActions';
import type { SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';

import _t from 'i18n!nls/ai-coach-platform';

import type { MessageTypes } from '../store/types';

type Props = {
  message: MessageTypes;
  sendMessage: SendMessageFunc;
  showRecommendedActions: boolean;
};

/**
 * Renders navigation buttons based on the chat message.
 *
 * @param {Props} props - The properties object.
 * @param {MessageTypes} props.message - The message object containing navigation buttons.
 */
export const ChatRecommendations = (props: Props): React.ReactElement | null => {
  const {
    message: { navigationButtons, recommendedActions, timestamp },
    sendMessage,
    showRecommendedActions,
  } = props;

  return (
    <>
      {navigationButtons?.length ? (
        <div role="region" aria-label={_t('Navigation buttons')}>
          <NavigationButtons navigationButton={navigationButtons[0]} />
        </div>
      ) : null}
      {recommendedActions?.length && showRecommendedActions ? (
        <div role="region" aria-label={_t('Recommended actions')}>
          <RecommendedActions recommendedActions={recommendedActions} sendMessage={sendMessage} timestamp={timestamp} />
        </div>
      ) : null}
    </>
  );
};

export default ChatRecommendations;
