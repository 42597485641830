/** @jsx jsx */

/** @jsxFrag Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment } from 'react';

import { CardMetadata, CardWatchNow, ProductCard as CdsProductCard } from '@coursera/cds-core';
import { color, font, gradient } from '@coursera/coursera-ui';
import { SvgCircleWarning } from '@coursera/coursera-ui/svg';
import type { CarouselType } from '@coursera/event-pulse-types';

import { fade } from 'bundles/coursera-ui/utils/colorUtils';
import type { ProductCardType } from 'bundles/enterprise-legacy-learner-home/components/ProductCardBase';
import type {
  EnterpriseProductMetadataFlags,
  Product,
} from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import { PRODUCT_TYPE } from 'bundles/enterprise-legacy-learner-home/utils/ProductUtils';
import { getCourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseUtils';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import ProductCardImage from 'bundles/product-card/components/legacy/ProductCardImage';
import useGenAiBadges from 'bundles/product-card/hooks/useGenAiBadges';
import ClipsCardPreviewImage from 'bundles/program-personalized-tab/components/ClipsCardPreviewImage';
import { getClipsRecsMetadata, getFormattedViewCount } from 'bundles/program-personalized-tab/utils/displayUtils';
import { getS12nVariantLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const CONFIG = {
  fade: {
    overlay: 0.65,
    message: 0.85,
    icon: 0.5,
  },
  zIndex: {
    banner: 1,
    overlay: 2,
  },
};

export const temporaryStylesThatShouldBeAdoptedByCDSIfExperimentSucceeds = css`
  .cds-ProductCard-statusTagsOverlay {
    left: unset !important;
    right: 0;

    .cds-tag-status {
      border: 1px solid var(--cds-color-neutral-stroke-primary) !important;

      > span {
        color: var(--cds-color-neutral-primary);
      }
    }
  }
`;
const styles = {
  cardContainer: css`
    min-height: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    /*
    TrackedLink2's visibility tracking adds an extra div that interferes
    with the ProductCard styling. This applies style to the underlying link
    to ensure proper styling.
  */
    a.cds-CommonCard-titleLink {
      display: block;
      height: inherit;
    }
  `,
  recommendedBanner: css`
    position: absolute;
    width: 100%;
    text-align: center;
    color: ${color.white};
    font-size: ${font.sm};
    text-transform: uppercase;
    background: ${`linear-gradient(${gradient.primary.deg}deg, ${gradient.primary.start}, ${gradient.primary.end})`};
    z-index: ${CONFIG.zIndex.banner};
  `,
  unavailableOverlay: css`
    position: absolute;
    height: 100%;
    background-color: ${fade(color.white, CONFIG.fade.overlay)};
    cursor: default;
    z-index: ${CONFIG.zIndex.overlay};
  `,
  unavailableMessage: css`
    height: 144px;
    background-color: ${fade(color.border, CONFIG.fade.message)};
    text-align: center;
    color: ${color.white};
    padding: var(--cds-spacing-200);
  `,

  listCardContainer: css`
    /* intentionally unset min-height for list card that was default from CDS */
    min-height: unset;

    .cds-ProductCard-list {
      height: inherit;
      border: 2px solid var(--cds-color-neutral-stroke-primary-weak);

      /* intentionally unset min-width for list card that was default from CDS */
      min-width: unset;
    }
  `,

  footerWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  tempStyles: () => temporaryStylesThatShouldBeAdoptedByCDSIfExperimentSucceeds,
};
type Props = {
  product: Product;
  productType: ProductCardType;
  enterpriseProductMetadata?: EnterpriseProductMetadataFlags;
  showClipStyleIfClip?: boolean;
  label?: string | JSX.Element;
  feedbackButton?: React.ReactNode;
  htmlAttributes?: { [styleAttr: string]: string | number | React.RefObject<HTMLElement> | undefined };
  variant?: 'grid' | 'list';
  onClick?: (
    product: Product,
    productType: ProductCardType,
    enterpriseProductMetadata?: EnterpriseProductMetadataFlags
  ) => void;
  shouldShowNewBadge?: boolean;
  isRecommended?: boolean;
  isUnavailable?: boolean;
  customLinkProps?: {
    trackingName: string;
    withVisibilityTracking: boolean;
    requireFullyVisible?: boolean;
    data: Record<string, unknown>;
    refAlt?: React.RefObject<HTMLAnchorElement>;
  };
  eventingV3Data: {
    productCard: {
      index: number;
    };
    carousel?: {
      id: string;
      name: string;
      type: CarouselType;
      model?: string;
      section?: number;
    };
    product: {
      id: string;
      name: string;
      type: ProductCardType;
    };
  };
};

export const ProductCardCds = ({
  showClipStyleIfClip,
  product,
  eventingV3Data,
  onClick,
  variant = 'grid',
  productType,
  enterpriseProductMetadata,
  label,
  feedbackButton,
  shouldShowNewBadge,
  htmlAttributes,
  customLinkProps,
  isRecommended,
  isUnavailable,
}: Props) => {
  const track = useTracker();
  const productCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    eventingV3Data
  );
  const courseType = product?.courseTypeMetadata && getCourseType(product.courseTypeMetadata);
  const isS12n = productType === PRODUCT_TYPE.S12N;
  const s12nType = isS12n && getS12nVariantLabel(product.productVariant);

  const { name, partners, promoPhoto, views, duration, id, slug } = product;
  const filteredPartners = (partners?.elements || [])
    .filter((partner) => partner !== null)
    .map((item) => ({ name: item?.name as string, logoUrl: (item?.squareLogo as string) ?? undefined }));
  const handleClick = () => {
    track('click_product_card', eventingV3Data);

    onClick?.(product, productType, enterpriseProductMetadata);
  };
  const statusTags = [];
  const { badges } = useGenAiBadges(slug);

  if (shouldShowNewBadge) {
    statusTags.push(_t('New'));
  }
  statusTags.push(...badges);

  const getLabel = (typeLabel: string | JSX.Element | undefined) => {
    if (typeof typeLabel === 'string') {
      return typeLabel;
    } else if (typeof typeLabel?.props.children === 'string') {
      return typeLabel.props.children;
    } else {
      return undefined;
    }
  };

  return (
    <div
      {...htmlAttributes}
      role="group"
      data-product-type={courseType || s12nType}
      css={[
        styles.cardContainer,
        variant === 'list' && styles.listCardContainer,
        variant === 'grid' && styles.tempStyles,
      ]}
    >
      {isRecommended && (
        <div css={styles.recommendedBanner} className="p-a-1s">
          {_t('your recommendation')}
        </div>
      )}
      {isUnavailable && (
        <div css={styles.unavailableOverlay}>
          <div css={styles.unavailableMessage}>
            <SvgCircleWarning color={fade(color.disabledThemeDark, CONFIG.fade.icon)} suppressTitle />
            <p>{_t('Sorry, there are no more enrollments left.')}</p>
          </div>
        </div>
      )}
      <CdsProductCard
        ref={productCardRef}
        onClick={handleClick}
        variant={variant}
        productType={productType}
        // @ts-expect-error https://coursera.atlassian.net/browse/MERCH-742
        statusTags={statusTags}
        title={{
          name,
          linkProps: {
            target: '_blank',
            rel: 'noopener noreferrer',
            component: TrackedLink2,
            tabIndex: 0,
          },
          customLinkProps,
        }}
        partners={filteredPartners}
        renderPreviewImage={
          showClipStyleIfClip
            ? () => <ClipsCardPreviewImage imageUrl={promoPhoto} />
            : () => (
                <ProductCardImage
                  id={id}
                  slug={slug}
                  imageUrl={promoPhoto ?? undefined}
                  partnerLogos={filteredPartners.map((partner) => partner.logoUrl)}
                  enableFluidWidthForLazyImg
                />
              )
        }
        previewImageSrc={!showClipStyleIfClip ? promoPhoto ?? undefined : undefined}
        footer={
          <>
            {showClipStyleIfClip && <CardWatchNow watchNow={{ viewCount: getFormattedViewCount(views) }} />}
            <div css={styles.footerWrapper}>
              <CardMetadata
                metadata={showClipStyleIfClip ? getClipsRecsMetadata(duration?.toString()) : [getLabel(label)]}
              />
              {feedbackButton}
            </div>
          </>
        }
      />
    </div>
  );
};

export default ProductCardCds;
