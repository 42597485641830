import type { SocialCaptions } from 'bundles/sharing-common/types/sharingSharedTypes';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type GetSocialCaptionsArgs = {
  productName: string;
  partnerName: string;
  isFree: boolean;
};

// Get social caption messages for sharing links across the xdp
export default ({ productName, partnerName, isFree }: GetSocialCaptionsArgs): SocialCaptions => {
  return {
    twitter: () => {
      if (isFree) {
        return _t('Check out this free online course from #{partnerName}: #{productName} on @Coursera.', {
          productName,
          partnerName,
        });
      } else {
        return _t('Check out this great online course from #{partnerName}: #{productName} on @Coursera.', {
          productName,
          partnerName,
        });
      }
    },
    whatsapp: (shareLink) => {
      if (isFree) {
        return _t(
          'Hey! I found a free course I think you’ll like, #{productName} by #{partnerName} on Coursera, an online learning platform that partners with top universities and companies around the world. Check it out: #{shareLink}',
          {
            partnerName,
            productName,
            shareLink,
          }
        );
      } else {
        return _t(
          'Hey! I found a great course I think you’ll like by #{partnerName} on Coursera, an online learning platform that partners with top universities and companies around the world. Check it out: #{shareLink}',
          {
            partnerName,
            shareLink,
          }
        );
      }
    },
    emailSubject: () => {
      if (isFree) {
        return _t('Here’s a free online course I thought you might like');
      } else {
        return _t('I found a great course for you on Coursera!');
      }
    },
    emailBody: (shareLink) => {
      if (isFree) {
        return _t(
          'I thought you might like this free course from #{partnerName}: #{productName} on Coursera. #{shareLink}\n\n' +
            `Coursera also offers online courses and Professional Certificates from 190+ world-class universities and companies, including Yale, the University of Pennsylvania, Google, IBM, and more.\n\n` +
            `Let me know what you think! `,
          {
            productName,
            partnerName,
            shareLink,
          }
        );
      } else {
        return _t(
          'I thought you might like this course from #{partnerName}: #{productName} on Coursera. #{shareLink}\n\n' +
            `Coursera also offers online courses and Professional Certificates from 190+ world-class universities and companies, including Yale, the University of Pennsylvania, Google, IBM, and more.\n\n` +
            `Let me know what you think! `,
          {
            productName,
            partnerName,
            shareLink,
          }
        );
      }
    },
  };
};
