/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { type Descendant, Editor, Transforms } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';

import { useId } from '@coursera/cds-core';

import FluidCMLEditor from 'bundles/cml/editor/components/FluidCMLEditor';
import PagelessCMLEditor from 'bundles/cml/editor/components/PagelessCMLEditor';
import ResizableCMLEditor from 'bundles/cml/editor/components/ResizableCMLEditor';
import DeleteConfirmDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';
import { useCMLToSlate } from 'bundles/cml/editor/components/hooks/useCMLToSlate';
import { useFocusHandler } from 'bundles/cml/editor/components/hooks/useFocusHandler';
import { useKeyDownHandler } from 'bundles/cml/editor/components/hooks/useKeyDownHandler';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import {
  useRenderElement,
  useRenderPlaceholder,
  useRenderTextElement,
} from 'bundles/cml/editor/components/hooks/useRenderElement';
import { useSlateToCML } from 'bundles/cml/editor/components/hooks/useSlateToCML';
import FocusContextProvider from 'bundles/cml/editor/context/FocusContextProvider';
import StyleContextProvider from 'bundles/cml/editor/context/StyleContextProvider';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';
import type { Props as EditorProps, RenderModeResizable } from 'bundles/cml/editor/types/cmlEditorProps';
import { useVariableSubstitutions } from 'bundles/cml/editor/utils/variableSubstitutionUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { usePrevious } from 'bundles/cml/shared/hooks/usePrevious';

import _t from 'i18n!nls/cml';

export type Props = Omit<
  EditorProps,
  | 'initialCML'
  | 'onContentChange'
  | 'uploadOptions'
  | 'fillableBlankTags'
  | 'enableMarkdown'
  | 'isLearnerUpload'
  | 'enableMonospace'
  | 'enableWidgets'
  | 'enableAiWritingAssistant'
  | 'customTools'
  | 'debounceDuration'
> & {
  editor: Editor;
  cmlValue: string;
  className?: string;
  footer?: { node: React.ReactNode; height: number };
  children?: React.ReactNode;
  onChange?: (value: Descendant[]) => void;
};

const styles = {
  fullHeight: css`
    height: 100%;
  `,
};

const defaultRenderMode: RenderModeResizable = {
  typeName: 'resizable',
};

const CMLEditor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    editor,
    cmlValue,
    onChange = () => undefined,
    onFocus,
    onBlur,
    macros,
    renderMode = defaultRenderMode,
    placeholder = _t('Enter text here'),
    focusOnLoad: autoFocus,
    shouldFocus = false,
    ariaLabel = _t('Rich Text Editor, toolbar available, standard formatting hotkeys supported'),
    ariaRequired,
    ariaLabelledBy,
    ariaDescribedBy,
    ariaInvalid,
    contentId,
    readOnly = false,
    footer,
    toolbarPosition = 'bottom',
    borderColor,
    className,
    children,
  } = props;

  // Render mode booleans
  const resizable = renderMode?.typeName === 'resizable';
  const fluid = renderMode?.typeName === 'fluid';
  const pageless = renderMode?.typeName === 'pageless';

  const { tools } = useToolsContext();
  const staticEditor = useRef(editor).current;
  const id = useId();

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { focused: focusedOverride } = useFocusedContext();
  const { focused, setFocused, setFocusedOverride } = useFocusHandler(element, onFocus, onBlur);

  const handleVariableSubstitutions = useVariableSubstitutions(macros);

  const value = useCMLToSlate(staticEditor, focused, cmlValue);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const renderElement = useRenderElement();
  const renderLeaf = useRenderTextElement();
  const renderPlaceholder = useRenderPlaceholder();

  const keydownHandlerOptions = useMemo(() => ({ setDeleteConfirm }), []);
  const handleKeyDown = useKeyDownHandler(staticEditor, keydownHandlerOptions);
  const handleChange = useSlateToCML(staticEditor, onChange);

  // disable scrollIntoView if the editor doesn't have focus
  // to prevent Slate from scrolling to the previous selection
  // if the container is scrolled when the editor is not focused
  const handleScrollIntoView = focused ? undefined : () => undefined;

  useEffect(() => {
    if (value.length > 0) {
      Editor.normalize(staticEditor, { force: true });
    }
  }, [tools, value, staticEditor]);

  const focusEditor = useCallback(() => {
    if (!staticEditor.selection) {
      if (!staticEditor.children.length) {
        Transforms.insertNodes(staticEditor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] }, { at: [0] });
      }

      const start = Editor.start(staticEditor, [0]);
      staticEditor.selection = { anchor: start, focus: start };
    }
    ReactEditor.focus(staticEditor);
  }, [staticEditor]);

  useEffect(() => {
    if (autoFocus) {
      focusEditor();
    }
  }, [staticEditor, autoFocus, focusEditor]);

  const previousShouldFocus = usePrevious(shouldFocus);
  useEffect(() => {
    if (!previousShouldFocus && shouldFocus) {
      focusEditor();
    }
  }, [previousShouldFocus, shouldFocus, focusEditor]);

  const label = 'label' in renderMode && renderMode?.label;
  const fullHeight = pageless || fluid;

  const editableContent = (
    <Editable
      decorate={macros ? handleVariableSubstitutions : undefined}
      renderElement={renderElement}
      renderPlaceholder={renderPlaceholder}
      renderLeaf={renderLeaf}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onKeyDown={handleKeyDown}
      onFocus={setFocused}
      scrollSelectionIntoView={handleScrollIntoView}
      aria-label={ariaLabel ?? label}
      aria-required={ariaRequired}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      readOnly={readOnly}
      data-testid={contentId}
      aria-invalid={ariaInvalid}
      tabIndex={0}
    />
  );

  return (
    <div
      ref={setElement}
      id={id}
      data-testid={id}
      tabIndex={-1}
      className={`rc-CMLEditor slate-editor ${className}`}
      css={fullHeight && styles.fullHeight}
    >
      <FocusContextProvider focused={focused || focusedOverride} setFocused={setFocusedOverride}>
        <StyleContextProvider element={element} pageless={pageless}>
          <Slate editor={editor} value={value} onChange={handleChange}>
            {pageless && (
              <PagelessCMLEditor ref={ref} readOnly={readOnly} scrollingContainer={renderMode.scrollingContainer}>
                {editableContent}
              </PagelessCMLEditor>
            )}
            {resizable && (
              <ResizableCMLEditor
                ref={ref}
                id={id}
                label={label}
                resizable={resizable}
                readOnly={readOnly}
                focused={focused}
                minHeight={renderMode.minHeight}
                maxHeight={renderMode.maxHeight}
                borderColor={borderColor}
                footer={footer}
                toolbarPosition={toolbarPosition}
              >
                {editableContent}
              </ResizableCMLEditor>
            )}
            {fluid && (
              <FluidCMLEditor
                ref={ref}
                readOnly={readOnly}
                label={label}
                focused={focused}
                borderColor={borderColor}
                toolbarPosition={toolbarPosition}
              >
                {editableContent}
              </FluidCMLEditor>
            )}
          </Slate>
        </StyleContextProvider>
      </FocusContextProvider>
      {deleteConfirm && <DeleteConfirmDialog editor={staticEditor} setConfirm={setDeleteConfirm} />}
      {children}
    </div>
  );
});

CMLEditor.displayName = 'CMLEditor';

export default React.memo(CMLEditor);
