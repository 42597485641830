import { withProps } from 'recompose';

import type { OutputProps as PropsFromWithProgramsStartingLater } from 'bundles/enterprise-legacy-learner-home/components/available/withProgramsStartingLater';
import type { CourseStub, ProductStub, S12nStub } from 'bundles/program-home/types/Products';
import { filterDuplicatedCourses, orderProducts } from 'bundles/program-home/utils/ProgramHomeUtils';

export const withComputedProducts = withProps(
  ({
    futureProductIds,
    enrolledCourses,
    enrolledS12ns,
  }: PropsFromEnrolledCourses & PropsFromEnrolledS12ns & PropsFromWithProgramsStartingLater) => {
    const filteredCourseProducts = filterDuplicatedCourses(enrolledCourses, enrolledS12ns);
    const products = orderProducts([...(filteredCourseProducts || []), ...(enrolledS12ns || [])]);

    const unfilteredOrderedProducts = orderProducts([...(enrolledCourses || []), ...(enrolledS12ns || [])]);

    const isFutureProduct = (product: ProductStub) => futureProductIds.includes(product.id);

    return {
      products: products.filter((product) => !isFutureProduct(product)),
      futureProducts: products.filter(isFutureProduct),
      unfilteredOrderedPresentProducts: unfilteredOrderedProducts.filter((product) => !isFutureProduct(product)),
      unfilteredOrderedFutureProducts: unfilteredOrderedProducts.filter(isFutureProduct),
    };
  }
);

type PropsFromEnrolledCourses = {
  enrolledCourses: Array<CourseStub>;
};

type PropsFromEnrolledS12ns = {
  enrolledS12ns: Array<S12nStub>;
};
