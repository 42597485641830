/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link as ReactLink } from 'react-router';

import classNames from 'classnames';
import Uri from 'jsuri';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import { isRightToLeft } from 'js/lib/language';
import Retracked from 'js/lib/retracked';

import type { Theme } from '@coursera/cds-core';
import { Link, Typography, Typography2, typography2, withTheme } from '@coursera/cds-core';
import { ChevronNextIcon, ChevronPreviousIcon } from '@coursera/cds-icons';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { NotFoundError } from 'bundles/ssr/lib/errors/directives';

import _t from 'i18n!nls/page';

const styles = {
  paginationControls: css`
    margin-top: 16px;

    .pagination-controls-container {
      display: flex;
      align-items: center;

      & > .box {
        ${typography2.bodySecondary}
        margin-left: 6px;

        &:first-of-type {
          margin-left: 0;
        }
      }

      & > .current {
        &:focus {
          outline: auto 2px var(--cds-color-blue-700);
        }
      }
    }

    button {
      border: none;
      background-color: inherit;
    }

    &.large-style.cds {
      .pagination-controls-container {
        .number.box,
        .ellipsis {
          height: 36px;
          width: 36px;
          padding: 10px;
          margin: 0 6px 0 6px;
          border: none;
          font-size: 0.875rem;

          span {
            color: var(--cds-color-blue-700);
          }

          &.current {
            background: transparent;
            color: var(--cds-color-grey-975);
            font-weight: bold;

            span {
              text-decoration: underline;
            }
          }

          &:hover {
            text-decoration: none;
          }
        }

        .ellipsis {
          color: var(--cds-color-grey-300);
          margin: 0;
          padding: 6px 0 0 0;
          width: auto;
        }

        .box.arrow {
          background: var(--cds-color-blue-700);
          height: 48px;
          width: 36px;
          margin: 0 2px;
          border: none;
          border-radius: 2px;
          color: var(--cds-color-white-0);
          transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

          &:hover {
            background: var(--cds-color-blue-800);
            text-decoration: none;
          }

          &.arrow-disabled,
          &.arrow-disabled:hover {
            background: var(--cds-color-grey-50);
          }
        }
      }
    }
  `,
};

const COLLAPSE_THRESHOLD = 2;

type State = {
  componentDidMount: boolean;
};

type OnPageChangeFunc = (pageNum: number) => void;
type AlgoliaCreatePaginateLinkFunc = (pageNum: number) => string;

type RightArrowProps = {
  currentPage: number;
  pageCount: number;
  onPageChange?: OnPageChangeFunc;
  renderLink?: boolean;
  ariaLabel?: string;
  largeStyle?: boolean;
  algoliaCreatePaginateLink?: AlgoliaCreatePaginateLinkFunc;
};

export class RightArrow extends React.Component<RightArrowProps, State> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  declare context: LegacyContextType<typeof RightArrow.contextTypes>;

  state = {
    componentDidMount: false,
  };

  handleClick = () => {
    const { pageCount, currentPage, onPageChange } = this.props;
    if (currentPage !== pageCount && onPageChange) {
      onPageChange(currentPage + 1);
    }
  };

  componentDidMount() {
    this.setState(() => ({ componentDidMount: true }));
  }

  render() {
    const {
      renderLink,
      currentPage,
      pageCount,
      ariaLabel: ariaLabel0,
      largeStyle,
      algoliaCreatePaginateLink,
    } = this.props;
    const { componentDidMount } = this.state;
    const { router } = this.context;
    const disabled = currentPage === pageCount;
    const classes = classNames('label-text box arrow', {
      'arrow-disabled': disabled,
    });
    const isRtl = isRightToLeft(_t.getLocale());
    const ariaLabelDefault = isRtl ? _t('Previous Page') : _t('Next Page');
    const ariaLabel = ariaLabel0 || ariaLabelDefault;
    const dataE2e = isRtl ? 'pagination-controls-previous' : 'pagination-controls-next';
    const trackingName = isRtl ? 'pagination_left_arrow' : 'pagination_right_arrow';

    const icon = largeStyle ? (
      <ChevronNextIcon size="small" color={disabled ? 'default' : 'invert'} />
    ) : (
      <ChevronNextIcon size="small" color="interactive" />
    );

    if (!renderLink || disabled) {
      return (
        <TrackedButton
          data-e2e={dataE2e}
          trackingName={trackingName}
          onClick={this.handleClick}
          className={classes}
          disabled={disabled}
          aria-label={ariaLabel}
        >
          {icon}
        </TrackedButton>
      );
    } else if (algoliaCreatePaginateLink) {
      // need traditional anchor tags for link pagination with algolia to work
      const destinationUrl = new Uri()
        .setPath(router.location.pathname)
        .setQuery(algoliaCreatePaginateLink(currentPage + 1));

      return (
        <Link className={classes} href={destinationUrl.toString()} aria-label={ariaLabel}>
          {icon}
        </Link>
      );
    } else {
      const destinationUrl = new Uri().setPath(router.location.pathname).setQuery(router.location.search);
      if (destinationUrl.hasQueryParam('page')) {
        // replaceQueryParam underlying implementation relies on native decodeURIComponent function
        // decodeURIComponent will throw an error if the url is malformed
        // Ex: /directory/videos?param=&page=2 is not a valid url, because query param separator "&" is preceded by "=", and decodeURIComponent will throw an error.
        // Since this is a JS error, and we don't want the whole page to 500, we want to return 404 instead.
        try {
          destinationUrl.replaceQueryParam('page', currentPage + 1);
        } catch (err) {
          if (!componentDidMount) {
            throw new NotFoundError();
          }
        }
      } else {
        destinationUrl.addQueryParam('page', currentPage + 1);
      }

      return (
        <Link component={ReactLink} className={classes} to={destinationUrl.toString()} aria-label={ariaLabel}>
          {icon}
        </Link>
      );
    }
  }
}

type LeftArrowProps = Omit<RightArrowProps, 'pageCount'> & {
  ariaHidden?: boolean;
};

export class LeftArrow extends React.Component<LeftArrowProps, State> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  declare context: LegacyContextType<typeof LeftArrow.contextTypes>;

  static defaultProps = {
    ariaHidden: false,
  };

  state = {
    componentDidMount: false,
  };

  handleClick = () => {
    const { currentPage, onPageChange } = this.props;
    if (currentPage !== 1 && onPageChange) {
      onPageChange(currentPage - 1);
    }
  };

  componentDidMount() {
    this.setState(() => ({ componentDidMount: true }));
  }

  render() {
    const { renderLink, currentPage, ariaLabel: ariaLabel0, largeStyle, algoliaCreatePaginateLink } = this.props;
    const { componentDidMount } = this.state;
    const { router } = this.context;
    const disabled = currentPage === 1;
    const classes = classNames('label-text box arrow', {
      'arrow-disabled': disabled,
    });
    const isRtl = isRightToLeft(_t.getLocale());
    const dataE2e = isRtl ? 'pagination-controls-next' : 'pagination-controls-prev';
    const trackingName = isRtl ? 'pagination_right_arrow' : 'pagination_left_arrow';
    const ariaLabelDefault = isRtl ? _t('Next Page') : _t('Previous Page');
    const ariaLabel = ariaLabel0 || ariaLabelDefault;

    const icon = largeStyle ? (
      <ChevronPreviousIcon size="small" color={disabled ? 'default' : 'invert'} />
    ) : (
      <ChevronPreviousIcon size="small" color="interactive" />
    );
    const prevPageNumber = currentPage - 1;
    const firstPageNumber = 1;

    if (!renderLink || disabled) {
      return (
        <TrackedButton
          data-e2e={dataE2e}
          trackingName={trackingName}
          onClick={this.handleClick}
          className={classes}
          disabled={disabled}
          aria-label={ariaLabel}
        >
          {icon}
        </TrackedButton>
      );
    } else if (algoliaCreatePaginateLink) {
      // need traditional anchor tags for link pagination with algolia to work
      const destinationUrl = new Uri()
        .setPath(router.location.pathname)
        .setQuery(algoliaCreatePaginateLink(prevPageNumber));

      return (
        <Link className={classes} href={destinationUrl.toString()} aria-label={ariaLabel}>
          {icon}
        </Link>
      );
    } else {
      const destinationUrl = new Uri().setPath(router.location.pathname).setQuery(router.location.search);
      if (destinationUrl.hasQueryParam('page')) {
        try {
          if (prevPageNumber === firstPageNumber) {
            destinationUrl.deleteQueryParam('page');
          } else {
            destinationUrl.replaceQueryParam('page', prevPageNumber);
          }
        } catch (err) {
          if (!componentDidMount) {
            throw new NotFoundError();
          }
        }
      } else {
        destinationUrl.addQueryParam('page', prevPageNumber);
      }

      return (
        <Link component={ReactLink} className={classes} to={destinationUrl.toString()} aria-label={ariaLabel}>
          {icon}
        </Link>
      );
    }
  }
}

type NumberBoxProps = {
  num: number;
  isCurrent: boolean;
  onPageChange?: OnPageChangeFunc;
  renderLink?: boolean;
  algoliaCreatePaginateLink?: AlgoliaCreatePaginateLinkFunc;
};

class NumberBox extends React.Component<NumberBoxProps, State> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  declare context: LegacyContextType<typeof NumberBox.contextTypes>;

  state = {
    componentDidMount: false,
  };

  componentDidMount() {
    this.setState(() => ({ componentDidMount: true }));
  }

  handleClick = () => {
    const { isCurrent, onPageChange, num } = this.props;
    if (!isCurrent && onPageChange) {
      onPageChange(num);
    }
  };

  render() {
    const { router } = this.context;
    const { num, isCurrent, renderLink, algoliaCreatePaginateLink } = this.props;
    const { componentDidMount } = this.state;
    const classes = classNames('box number', { current: isCurrent });
    const ariaLabel = _t('Page ' + num);
    const firstPageNumber = 1;

    if (!renderLink) {
      const buttonAriaLabel = isCurrent ? _t('Selected page: ' + ariaLabel) : ariaLabel;
      return (
        <TrackedButton
          id={`pagination_number_box_${num}`}
          data-e2e="pagination-number-box"
          trackingName={`pagination_number_box_${num}`}
          data={{ pageNum: num }}
          onClick={this.handleClick}
          className={classes}
          aria-label={buttonAriaLabel}
        >
          <Typography variant={isCurrent ? 'h3bold' : 'body2'} component="span">
            {num}
          </Typography>
        </TrackedButton>
      );
    } else if (algoliaCreatePaginateLink) {
      // need traditional anchor tags for link pagination with algolia to work
      const destinationUrl = new Uri().setPath(router.location.pathname).setQuery(algoliaCreatePaginateLink(num));

      return (
        <Link
          className={classes}
          href={destinationUrl.toString()}
          aria-label={ariaLabel}
          style={isCurrent ? { pointerEvents: 'none' } : {}}
          typographyVariant={isCurrent ? 'subtitleMedium' : 'bodySecondary'}
        >
          {num}
        </Link>
      );
    } else {
      const destinationUrl = new Uri().setPath(router.location.pathname).setQuery(router.location.search);
      // Needs to be in try / catch b/c it will break if url has a malformed URL (cause of decodeURIComponent which is used in these helpers)
      try {
        if (num === firstPageNumber) {
          destinationUrl.deleteQueryParam('page');
        } else {
          destinationUrl.replaceQueryParam('page', num);
        }
      } catch (err) {
        if (!componentDidMount) {
          throw new NotFoundError();
        }
      }

      return (
        <Link
          component={ReactLink}
          className={classes}
          to={destinationUrl.toString()}
          aria-label={ariaLabel}
          style={isCurrent ? { pointerEvents: 'none' } : {}}
          typographyVariant={isCurrent ? 'subtitleMedium' : 'bodySecondary'}
        >
          {num}
        </Link>
      );
    }
  }
}

class Ellipsis extends React.Component {
  render() {
    return (
      <Typography2 className="ellipsis" variant="bodySecondary" component="span">
        …
      </Typography2>
    );
  }
}

type PaginationControlsProps = {
  currentPage: number;
  // maxPages controls how many numbers before we turn into ellipses.
  // e.g `currentPage = 1, maxPages = 3, pageCount = 10` then we have `1, 2, 3, ELIPSES , 10`
  maxPages?: number;
  onPageChange?: OnPageChangeFunc;
  pageCount?: number;
  centered?: boolean;
  renderLinks?: boolean;
  hideNumbers?: boolean;
  algoliaCreatePaginateLink?: AlgoliaCreatePaginateLinkFunc; // for use with algolia instant search to render links with their specific query parameters
  largeStyle?: boolean; // style components to match the coursera-ui component
  style?: React.CSSProperties;
  paginationControlsStyle?: React.CSSProperties;
  theme?: Theme;
};

class PaginationControls extends React.Component<PaginationControlsProps> {
  static defaultProps = {
    centered: false,
    pageCount: 1,
    style: {},
    paginationControlsStyle: {},
    renderLinks: false,
    hideNumbers: false,
    maxPages: 3,
  };

  componentWillMount() {
    const { currentPage } = this.props;
    if (currentPage < 1) {
      throw new Error('Current page cannot be zero or negative.');
    }
  }

  renderNumBox = (n: number) => {
    const { onPageChange, currentPage, renderLinks, algoliaCreatePaginateLink } = this.props;
    const num = n + 1;
    return (
      <NumberBox
        onPageChange={onPageChange}
        num={num}
        isCurrent={num === currentPage}
        key={num}
        renderLink={renderLinks}
        algoliaCreatePaginateLink={algoliaCreatePaginateLink}
      />
    );
  };

  render() {
    const {
      pageCount = 1,
      maxPages = 3,
      currentPage,
      onPageChange,
      renderLinks = false,
      hideNumbers = false,
      style = {},
      centered = false,
      largeStyle,
      paginationControlsStyle = {},
      algoliaCreatePaginateLink,
      theme,
    } = this.props;
    if (pageCount === 0 || !theme) {
      return null;
    }
    let body; // - Format: 1 2 3 4 5
    if (pageCount <= maxPages) {
      body = range(pageCount).map(this.renderNumBox); // - Format: 1 2 3 4 ... 10
    } else if (currentPage <= maxPages - COLLAPSE_THRESHOLD) {
      body = [
        range(maxPages - 1).map(this.renderNumBox),
        <Ellipsis key="elip" />,
        <NumberBox
          onPageChange={onPageChange}
          num={pageCount}
          isCurrent={false}
          key={pageCount}
          renderLink={renderLinks}
          algoliaCreatePaginateLink={algoliaCreatePaginateLink}
        />,
      ]; // - Format: 1 ... 7 8 9 10
    } else if (currentPage >= pageCount - (maxPages - 1 - COLLAPSE_THRESHOLD)) {
      body = [
        <NumberBox
          onPageChange={onPageChange}
          num={1}
          isCurrent={false}
          renderLink={renderLinks}
          algoliaCreatePaginateLink={algoliaCreatePaginateLink}
        />,
        <Ellipsis />,
        range(pageCount - (maxPages - 1), pageCount).map(this.renderNumBox),
      ]; // - Format: 1 ... 3 4 5 ... 10
    } else {
      const midSize = maxPages - COLLAPSE_THRESHOLD;
      const start = currentPage - Math.floor(midSize / 2);
      const end = currentPage + Math.ceil(midSize / 2);
      body = [
        <NumberBox
          onPageChange={onPageChange}
          num={1}
          isCurrent={false}
          key={1}
          renderLink={renderLinks}
          algoliaCreatePaginateLink={algoliaCreatePaginateLink}
        />,
        <Ellipsis key="elip1" />,
        range(start, end).map(this.renderNumBox),
        <Ellipsis key="elip2" />,
        <NumberBox
          onPageChange={onPageChange}
          num={pageCount}
          isCurrent={false}
          key={pageCount}
          renderLink={renderLinks}
          algoliaCreatePaginateLink={algoliaCreatePaginateLink}
        />,
      ];
    }
    const className = classNames('rc-PaginationControls', 'horizontal-box', {
      'align-items-right': !centered,
      'align-items-absolute-center': centered,
      'large-style cds': largeStyle,
    });
    return (
      <div data-e2e="pagination-controls" className={className} css={styles.paginationControls} style={{ ...style }}>
        <div
          role="navigation"
          className="pagination-controls-container"
          style={{ ...paginationControlsStyle }}
          aria-label="Page Navigation"
        >
          <LeftArrow
            onPageChange={onPageChange}
            currentPage={currentPage}
            renderLink={renderLinks}
            largeStyle={largeStyle}
            algoliaCreatePaginateLink={algoliaCreatePaginateLink}
          />
          {!hideNumbers && body}
          <RightArrow
            onPageChange={onPageChange}
            currentPage={currentPage}
            pageCount={pageCount}
            renderLink={renderLinks}
            largeStyle={largeStyle}
            algoliaCreatePaginateLink={algoliaCreatePaginateLink}
          />
        </div>
      </div>
    );
  }
}
const TrackedPaginationControls = Retracked.createTrackedContainer(
  ({ currentPage, maxPages, pageCount }: PaginationControlsProps) => ({
    currentPage,
    maxPages,
    pageCount,
  })
)(withTheme(PaginationControls));
export default TrackedPaginationControls;
