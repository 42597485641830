import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';
import useRouter, { useLocation } from 'js/lib/useRouter';

import { Button } from '@coursera/cds-core';

import { useTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/page-header';

export const HeaderMobileJoinForFreeButton = (): React.ReactElement => {
  // used only for navigation via `.push`
  const router = useRouter();
  // used for all other location data
  const location = useLocation();
  const trackV2 = useRetracked();
  const trackV3 = useTracker();

  const handleButtonClick = () => {
    trackV2({
      trackingData: {},
      trackingName: 'header_right_nav_button',
      action: 'click',
    });
    trackV3('click_button', {
      button: {
        name: 'signup',
      },
      pageSection: {
        sectionName: 'sticky_page_navigation',
      },
    });
    router.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        authMode: 'signup',
      },
    });
  };

  return (
    <Button size="small" style={{ marginLeft: '12px' }} onClick={handleButtonClick}>
      {_t('Join for free')}
    </Button>
  );
};

export default HeaderMobileJoinForFreeButton;
