/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';

const styles = {
  chatStyles: css`
    position: fixed;
    bottom: 0;
    top: 117px;
    right: 0;
    min-width: 300px;
    width: 100%;
    max-width: 432px;
    z-index: 1001;
    background: var(--cds-color-neutral-background-primary-weak);

    @media (max-width: 890px) {
      left: 0;
      max-width: 100%;
    }

    [data-testid='coursera-coach-widget'] {
      padding: 0;
    }
  `,
  childContainer: css`
    margin: 16px;
    position: relative;
    height: calc(100% - 32px);

    @media (max-width: 890px) {
      max-width: 100%;
      margin: 0;
      height: 100%;
    }
  `,
};

const ChatContainer = ({
  headerRef,
  footerRef,
  children,
}: {
  headerRef: React.RefObject<HTMLElement>;
  footerRef: React.RefObject<HTMLElement>;
  children: React.ReactNode;
}) => {
  const chatRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const headerHeight = headerRef.current?.getBoundingClientRect()?.height || 117;
    const currentFooterRef = footerRef.current;
    const updateHeaderPosition = () => {
      if (chatRef.current) {
        const footerTop = footerRef.current?.getBoundingClientRect()?.top || 0;
        chatRef.current.style.bottom = `${window.innerHeight - footerTop}px`;
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (chatRef.current) {
          if (entry.isIntersecting) {
            document.addEventListener('scroll', updateHeaderPosition);
          } else {
            document.removeEventListener('scroll', updateHeaderPosition);
            chatRef.current.style.top = `${headerHeight}px`;
            chatRef.current.style.bottom = `0px`;
          }
        }
      },
      { threshold: 0 }
    );

    currentFooterRef && observer.observe(currentFooterRef);

    return () => {
      currentFooterRef && observer.unobserve(currentFooterRef);
      document.removeEventListener('scroll', updateHeaderPosition);
    };
  }, [footerRef, headerRef]);

  return (
    <div ref={chatRef} css={styles.chatStyles}>
      <div css={styles.childContainer}>{children}</div>
    </div>
  );
};

export default ChatContainer;
