import * as React from 'react';
import { graphql } from 'react-apollo';

import type { EnterpriseSkills_Skillset as Skillset } from '__generated__/graphql-types';
import { branch, compose } from 'recompose';

import user from 'js/lib/user';

import { Grid, InlineNotification, PageGridContainer } from '@coursera/cds-core';

import SingleProgramSkillSets from 'bundles/enterprise-learner-onboarding/queries/SingleProgramSkillSetsQuery.graphql';
import type {
  SingleProgramSkillSetsQuery,
  SingleProgramSkillSetsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/SingleProgramSkillSetsQuery';
import HowItWorksInformationCard from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/HowItWorksInformationCard';
import { SingleProgramSkillsetWarningBanner } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramWarningBanners';
import { SkillSetFiltersContainer } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersContainer';
import SkillSetFiltersHOC from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersHOC';
import type { PropsFromSkillSetFiltersHOC } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersHOC';
import SkillSetFiltersHOCDGS from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersHOCDGS';
import SkillSetsContainerDGS from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetsContainerDGS';
import SkillSetsContainerV2 from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetsContainerV2';
import { SkillSetsTabDiscoveryHeader } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetsTabDiscoveryHeader';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import EnterpriseExperiments from 'bundles/epic/clients/Enterprise';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import { SkillSetListQuery } from 'bundles/program-home/components/enterprise-home/EnterpriseHomeQueries';
import type {
  SkillSetListQuery as SkillSetListQueryData,
  SkillSetListQueryVariables,
  SkillSetListQuery_EnterpriseTargetSkillProfileSummariesV1Resource_byProgram_elements as TargetSkillProficiencySummary,
} from 'bundles/program-home/components/enterprise-home/__generated__/SkillSetListQuery';
import useHasMultipleProgramProps from 'bundles/program-home/components/multiprogram/useHasMultipleProgramProps';

import _t from 'i18n!nls/program-home';

export type PropsFromCaller = {
  programId: string;
  programSlug: string;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  userSkillProfileStates?: UserSkillProfileStateType[];
  objectivesHelpCardId?: string;
  objectivesHelpIsExpanded?: boolean;
  skillSetLength?: number;
  toggleObjectivesHelpIsExpanded?: () => void;
  tutorialVideoAssetId?: string;
  tutorialVideoUrl?: string;
  showSkillSetRoleFilter: boolean;
  title?: string;
};

type PropsFromGraphql = {
  skillsets: TargetSkillProficiencySummary[];
  loading: boolean;
  error: boolean;
};

type PropsFromGraphql2 = {
  skillsetsDGS?: Skillset[];
  loading: boolean;
  error: boolean;
};

export type Props = PropsFromCaller & PropsFromGraphql & PropsFromGraphql2 & PropsFromSkillSetFiltersHOC;

export const SkillSetsTabContent: React.FC<Props> = ({
  thirdPartyOrganization,
  programId,
  userSkillProfileStates,
  objectivesHelpCardId,
  objectivesHelpIsExpanded,
  toggleObjectivesHelpIsExpanded,
  error,
  loading,
  skillsets,
  skillsetsDGS,
  programSlug,
  skillSetLength,
  tutorialVideoAssetId,
  tutorialVideoUrl,
  filterOptions,
  allOccupations,
  skillNames,
  filterSelections,
  isFilterSelected,
  showSkillSetRoleFilter,
  onRoleChange,
  onSkillsChange,
  onGoalChange,
  onFilterReset,
  onFilterInputChange,
  title,
}: Props) => {
  const userId = user.get().id;
  const isAuthenticatedUser = user.isAuthenticatedUser();

  const { hasMultipleProgramsInOrg } = useHasMultipleProgramProps({
    userId,
    thirdPartyOrganizationId: thirdPartyOrganization.id,
  });

  const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');

  return (
    <PageGridContainer className="rc-SkillSetsTabContent" data-testid="enterprise-skill-sets-dashboard">
      <Grid item xs={12}>
        <HowItWorksInformationCard
          rootClassName="m-b-3"
          id={objectivesHelpCardId}
          isOpen={objectivesHelpIsExpanded}
          onRequestClose={toggleObjectivesHelpIsExpanded}
          hasTSPs
          userAuthenticated={isAuthenticatedUser}
          tutorialVideoAssetId={tutorialVideoAssetId}
          tutorialVideoUrl={tutorialVideoUrl}
        />
        <SkillSetsTabDiscoveryHeader
          skillSetLength={enableSkillSetsByDefaultDGS ? skillsetsDGS?.length : skillsets.length}
          totalSkillSetLength={skillSetLength}
          title={title}
        />
        {error && (
          <InlineNotification severity="error">
            {_t('Sorry! Something went wrong. Please refresh the page.')}
          </InlineNotification>
        )}
        <>
          {isAuthenticatedUser && (
            <SkillSetFiltersContainer
              showSkillSetRoleFilter={showSkillSetRoleFilter}
              loading={loading}
              filterOptions={filterOptions}
              allOccupations={allOccupations}
              skillNames={skillNames}
              filterSelections={filterSelections}
              onRoleChange={onRoleChange}
              onSkillsChange={onSkillsChange}
              onGoalChange={onGoalChange}
              onFilterReset={onFilterReset}
              onFilterInputChange={onFilterInputChange}
              filterIdPrefix="SkillSetsTab-"
            />
          )}
          {enableSkillSetsByDefaultDGS ? (
            <SkillSetsContainerDGS
              skillsets={skillsetsDGS}
              programSlug={programSlug}
              programId={programId}
              skillSetLength={skillSetLength}
              filterSelections={filterSelections}
              userSkillProfileStates={userSkillProfileStates}
              isFilterSelected={isFilterSelected}
            />
          ) : (
            <SkillSetsContainerV2
              skillsets={skillsets}
              loading={loading}
              programSlug={programSlug}
              programId={programId}
              skillSetLength={skillSetLength}
              filterSelections={filterSelections}
            />
          )}
        </>
        {!!userId && hasMultipleProgramsInOrg && (
          <SingleProgramSkillsetWarningBanner
            userId={userId}
            thirdPartyOrganizationId={thirdPartyOrganization.id}
            thirdPartyOrgSlug={thirdPartyOrganization.slug}
          />
        )}
      </Grid>
    </PageGridContainer>
  );
};

export const enhance = compose<Props, PropsFromCaller>(
  graphql<PropsFromCaller, SkillSetListQueryData, SkillSetListQueryVariables, PropsFromGraphql>(SkillSetListQuery, {
    skip: () => {
      const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');
      return enableSkillSetsByDefaultDGS;
    },
    options: ({ programId }) => ({
      ssr: false,
      variables: {
        programId,
      },
    }),
    props: ({ data }): PropsFromGraphql => {
      const loading = data?.loading ?? true;
      const error = !!data?.error;
      const skillsets = filterExistsOrDefault(
        data?.EnterpriseTargetSkillProfileSummariesV1Resource?.byProgram?.elements
      );
      return { skillsets, loading, error };
    },
  }),
  graphql<PropsFromCaller, SingleProgramSkillSetsQuery, SingleProgramSkillSetsQueryVariables, PropsFromGraphql2>(
    SingleProgramSkillSets,
    {
      skip: () => {
        const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');
        return !enableSkillSetsByDefaultDGS;
      },
      options: ({ programId }) => ({
        variables: {
          programId,
          first: 999,
        },
        context: { clientName: 'gatewayGql' },
      }),
      props: ({ data }): PropsFromGraphql2 => {
        const loading = data?.loading ?? true;
        const error = Boolean(data?.error);
        const skillsets = filterExistsOrDefault(data?.EnterpriseSkillset?.queryByProgram?.edges?.map((_) => _.node));
        return { skillsetsDGS: skillsets, loading, error };
      },
    }
  ),
  branch(
    () => {
      const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');
      return enableSkillSetsByDefaultDGS;
    },
    SkillSetFiltersHOCDGS,
    SkillSetFiltersHOC
  )
);

export default enhance(SkillSetsTabContent);
