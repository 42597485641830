/** @jsx jsx */

/* eslint-disable camelcase */
import { jsx, useTheme } from '@emotion/react';

import * as React from 'react';

import type Maybe from 'graphql/tsutils/Maybe';
import { compose, setDisplayName } from 'recompose';

import Imgix from 'js/components/Imgix';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import Ratings from 'bundles/browse/components/ProductCard/Ratings';
// Save/Unsave Experiment
import SavingBookmark from 'bundles/browse/components/SavingBookmark';
import { SAVING_BOOKMARK_BLACK, getSavingProductType } from 'bundles/browse/components/utils/BookmarkUtils';
import type { SavedProductStringTypes } from 'bundles/browse/types/SavedProductQueries';
import type { withSaveDataForSearchCards } from 'bundles/browse/types/withSavedDataFromProducts';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import { getCleanIdFromObjectId } from 'bundles/enterprise-learner-search/utils/getCleanIdFromObjectId';
import { VIEWS_AND_ENROLLMENT_THRESHOLD } from 'bundles/enterprise-legacy-xdp/components/ProductMetrics';
import ProductDifficultyLabel from 'bundles/search-common/components/ProductDifficultyLabel';
import { GUIDED_PROJECT, RHYME_PROJECT, SELF_PACED_PROJECT } from 'bundles/search-common/constants/entityTypes';
// CDS
import { isSearchTheme } from 'bundles/search-common/searchTheme';
import numberRenders from 'bundles/teach-course/lib/numberRenders';

import _t from 'i18n!nls/search-common';

type PropsFromCaller = withSaveDataForSearchCards & {
  avgProductRating?: Maybe<number>;
  numProductRatings?: Maybe<number>;
  productDifficultyLevel?: Maybe<string | false>;
  enrollments?: Maybe<number>;
  tagline?: Maybe<string>;
  isPartOfCourseraPlus?: boolean;
  entityType?: string;
  id?: string;
  isSaved?: boolean;
  loadingSavedProducts?: boolean;
  showCourseraLitePill?: boolean;
};

type PropsToComponent = PropsFromCaller & PropsFromCourseraPlusProductOwnerships;

const ProductInfo = ({
  avgProductRating,
  productDifficultyLevel,
  enrollments,
  tagline,
  numProductRatings,
  isPartOfCourseraPlus,
  ownsCourseraPlus,
  entityType,
  id,
  isSaved,
  loadingSavedProducts,
  showCourseraLitePill,
}: PropsToComponent): JSX.Element => {
  const theme = useTheme();
  const resolvedID = getCleanIdFromObjectId(id || '') || '';

  if (!isSearchTheme(theme)) {
    throw new Error(
      'ProductInfo can only be used in the context of a SearchTheme. Wrap this component with a ThemeProvider.'
    );
  }
  const showEnrollments = enrollments && enrollments > VIEWS_AND_ENROLLMENT_THRESHOLD;

  const isEntityTypeExcludedFromCourseraLite =
    entityType && [GUIDED_PROJECT, RHYME_PROJECT, SELF_PACED_PROJECT].includes(entityType);
  const showCourseraLite = !ownsCourseraPlus && showCourseraLitePill && !isEntityTypeExcludedFromCourseraLite;

  return (
    <div className="rc-ProductInfo" css={theme.productInfo}>
      <div className="rating-enroll-wrapper">
        {!!avgProductRating && !!numProductRatings && numProductRatings > 5 && (
          <div className="info-item">
            <Ratings ratingCount={numProductRatings} averageFiveStarRating={Math.round(avgProductRating * 10) / 10} />
          </div>
        )}
        {!!showEnrollments && (
          <div className="enrollment info-item">
            <FormattedMessage
              message={_t('{numberOfStudents} students')}
              numberOfStudents={
                <span className="enrollment-number">{numberRenders.Formatters.largeNumber(enrollments)}</span>
              }
            />
          </div>
        )}
        {ownsCourseraPlus && isPartOfCourseraPlus && (
          <div className="info-item">
            <Imgix src={logo.PLUS_PILL_BLUE} alt={_t('Coursera Plus')} maxWidth={37} maxHeight={16} />
          </div>
        )}
        {showCourseraLite && isPartOfCourseraPlus && (
          <div className="info-item">
            <Imgix src={logo.LITE_PILL_BLUE} alt={_t('Coursera Plus')} maxWidth={37} maxHeight={16} />
          </div>
        )}
      </div>
      {tagline && <p className="tagline">{tagline}</p>}
      {productDifficultyLevel && <ProductDifficultyLabel productDifficultyLevel={productDifficultyLevel} />}
      {id && entityType && !loadingSavedProducts && (
        <SavingBookmark
          saved={isSaved}
          product={{
            product_id: resolvedID,
            product_type: getSavingProductType(entityType) as SavedProductStringTypes,
          }}
          color={SAVING_BOOKMARK_BLACK}
          shouldShowText={true}
          shouldUseSmallSize={true}
        />
      )}
    </div>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  setDisplayName('ProductInfo'),
  withCourseraPlusProductOwnerships<PropsFromCaller>()
)(ProductInfo);
