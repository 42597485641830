/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { TextField } from '@coursera/cds-core';
import type { TextFieldProps } from '@coursera/cds-core';

import _t from 'i18n!nls/authoring';

const MAX_LENGTH = 125;

const styles = {
  textField: css`
    label {
      margin-bottom: 0;
    }
  `,
};

type Props = {
  defaultValue: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  hasServerError?: boolean;
  descriptionLength: number;
  multiline?: boolean;
  itemType?: string;
  autoFocus?: boolean;
  inputProps?: TextFieldProps['inputProps'];
};

const AssetDescriptionEditor = ({
  defaultValue,
  handleChange,
  hasServerError = false,
  descriptionLength,
  itemType = 'asset',
  autoFocus = true,
  inputProps,
  ...otherProps
}: Props) => {
  const lengthValidationLabel = descriptionLength >= MAX_LENGTH ? _t('Maximum number of characters is 125') : '';
  const lengthValidationStatus = descriptionLength >= MAX_LENGTH ? 'error' : undefined;

  return (
    <TextField
      autoFocus={autoFocus}
      fullWidth
      label={_t('Accessibility description')}
      supportText={_t(`Add a short description of this #{itemType} for learners using assistive technologies`, {
        itemType,
      })}
      placeholder={_t('Add a short description ...')}
      defaultValue={defaultValue}
      maxLength={MAX_LENGTH}
      onChange={handleChange}
      validationStatus={hasServerError ? 'error' : lengthValidationStatus}
      validationLabel={hasServerError ? _t('Update Failed') : lengthValidationLabel}
      css={styles.textField}
      data-test="accessibility-desc-textField"
      inputProps={{ ...inputProps, required: false }}
      {...otherProps}
    />
  );
};

export default AssetDescriptionEditor;
