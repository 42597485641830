/** @jsx jsx */

/** @jsxFrag */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import useRouter from 'js/lib/useRouter';

import type { ProductCardProps } from '@coursera/cds-core';
import {
  CardCategory,
  CardClips,
  CardEnrollmentDate,
  CardMetadata,
  CardSkills,
  CardWatchNow,
  ProductCard,
  Tag,
  Typography2,
} from '@coursera/cds-core';
import type { ProductType } from '@coursera/event-pulse-types';

import { getCleanIdFromObjectId } from 'bundles/enterprise-learner-search/utils/getCleanIdFromObjectId';
import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import { doesEnterpriseSearchOpenInNewWindow } from 'bundles/enterprise-legacy-learner-home/epicFeatureFlags';
import getDurationFormatted from 'bundles/enterprise-legacy-learner-home/utils/getDurationFormatted';
import { launchMiniModal } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import { SearchResultsBadges } from 'bundles/product-card/components/Badges';
import { temporaryStylesThatShouldBeAdoptedByCDSIfExperimentSucceeds } from 'bundles/product-card/components/ProductCardV2';
import ProductCardReviews from 'bundles/product-card/components/legacy/ProductCardReviews';
import {
  capitalizeAndTranslateEachWordInEntityType,
  extractEventId,
  eventingV3ProductType as getEventingV3ProductType,
  getImagePropOverrides,
} from 'bundles/product-card/components/utils/productCardV2Utils';
import useGenAiBadges from 'bundles/product-card/hooks/useGenAiBadges';
import { getNumberOfReviews } from 'bundles/product-card/utils/productCardUtils';
import { SearchCardImage } from 'bundles/search-common/components/search-cards/SearchCard';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import type { SearchIndexHit } from 'bundles/search-common/types';
import { isPathwaySlug } from 'bundles/search-common/utils/experimentUtils';
import { getPartnersFromHit } from 'bundles/search-common/utils/utils';
import numberRenders from 'bundles/teach-course/lib/numberRenders';

import _t from 'i18n!nls/program-home';

export type EnterpriseCardData = {
  objectID: string;
  hitPosition: number;
  query?: string;
  queryID: string;
  listIndex: number;
  hasNoResults: boolean;
  filtersApplied: Record<string, string[]>;
  indexName?: string;
  indexPosition?: number;
};

const styles = {
  cardWrapper: css`
    cursor: pointer;

    .product-reviews {
      margin-bottom: 0;
    }
  `,
  tempStyles: temporaryStylesThatShouldBeAdoptedByCDSIfExperimentSucceeds,
};
const getVideoMetadataFromHit = (hit: SearchIndexHit): string[] => {
  let section1 = hit.entityType === 'VIDEO' ? _t('Video') : _t('Lesson');
  if (hit.videosInLesson) {
    section1 += _t(' (#{videosInLesson} videos)', { videosInLesson: hit.videosInLesson });
  }
  const section2 = getDurationFormatted(hit.durationMs ?? 0);
  return [section1, section2];
};

type EnterpriseSearchCardProps = {
  isSFC?: boolean;
  urlToUse?: string;
  trackingName?: string;
  cardData?: EnterpriseCardData;
  hit: SearchIndexHit;
  variant?: ProductCardProps['variant'];
  adminContent?: ProductCardProps['adminContent'];
  onClick?: () => void;
};

const EnterpriseSearchCard = ({
  isSFC,
  urlToUse,
  trackingName,
  cardData,
  hit,
  variant,
  adminContent,
  onClick,
}: EnterpriseSearchCardProps) => {
  const router = useRouter();

  const imgProps = React.useMemo(() => {
    return {
      ...getImagePropOverrides(hit.partners?.map((name) => ({ name, id: name })) ?? [], hit.imageUrl ?? ''),
    };
  }, [hit.imageUrl, hit.partners]);
  const track = useTracker();
  const isPathwayContent = GrowthDiscoveryEpicClient.get('useDynamicDegreePathway');

  const cardMetadata: string[] = (
    isSFC
      ? getVideoMetadataFromHit(hit)
      : [
          hit.productDifficultyLevel ?? '',
          capitalizeAndTranslateEachWordInEntityType(hit.entityType) ?? '',
          hit.productDuration ?? '',
        ]
  ).filter((val) => !!val);

  const cardCategory = hit.isPartOfCourseraPlus && !adminContent && (
    <div css={{ display: 'flex', gap: 4, alignItems: 'baseline' }}>
      <Tag priority="primary" variant="category" color="highlight1">
        PLUS
      </Tag>
      <Typography2 component="p" variant="bodySecondary" color="supportText">
        {_t('Included with Coursera Plus')}
      </Typography2>
    </div>
  );

  const getHitSlug = (fullUrl?: string | null) => {
    if (!fullUrl) {
      return undefined;
    }
    const regexToMatchAfterLastSlashAndBeforeQuestionMark = /\/([^/]+)\?/;
    const match = fullUrl.match(regexToMatchAfterLastSlashAndBeforeQuestionMark);

    return match?.[1];
  };

  const cardRating = !isSFC && (
    <ProductCardReviews avgProductRating={hit.avgProductRating} numProductRatings={hit.numProductRatings} />
  );

  const cardWatchNow = {
    viewCount: numberRenders.Formatters.largeNumber(hit.completions ?? 0),
  };

  const a11yRating = hit.avgProductRating ? `${hit.avgProductRating.toFixed(1)} ${_t('stars')}` : '';
  const a11yNumOfReviews =
    hit.numProductRatings && hit.numProductRatings >= 5
      ? `${_t('by')} ${getNumberOfReviews(hit.numProductRatings)} ${_t('reviews')}`
      : '';
  const a11ySkills = hit.skills?.length ? `${_t('provide skills')} ${hit.skills.slice(0, 3).join(', ')} etc...` : '';

  const cardProps: ProductCardProps = {
    variant: variant ?? 'grid',
    adminContent,
    productType: hit.entityType ?? '',
    'aria-label': _t(
      '#{title} #{productType} by #{partners}, #{rating} #{reviews}, #{skills}, #{productDifficultyLevel} level, #{productDuration}.',
      {
        title: hit.name,
        productType: hit.entityType,
        partners: hit.partners?.join(', '),
        rating: a11yRating,
        reviews: a11yNumOfReviews,
        skills: a11ySkills,
        productDifficultyLevel: hit.productDifficultyLevel,
        productDuration: hit.productDuration,
      }
    ),
    renderPreviewImage: () => (
      <SearchCardImage
        {...{
          hit,
          hitPosition: cardData?.hitPosition,
          enableFluidWidthForLazyImg: true,
          searchCardImageProps: imgProps,
        }}
      />
    ),
    partners: getPartnersFromHit(hit),
    title: {
      name: hit.name ?? '',
      linkProps: {
        href: urlToUse ?? '', // fallback to `''` to keep it as a valid link which is clickable by users/screenreaders
        target: urlToUse ? '_blank' : undefined,
        rel: urlToUse ? 'noopener noreferrer' : undefined,
        component: TrackedLink2,
      },
      customLinkProps: {
        trackingName,
        data: cardData,
        withVisibilityTracking: true,
        requireFullyVisible: true,
        'data-testId': 'card-name',
      },
    },

    body: isSFC
      ? /* 
          The below logic is in place to support standalone clips' requirement which has no course name or lesson name:
          if there is no course name, don't show any "breadcrumb" (i.e. "Lesson:", etc)
        */
        hit.parentCourseName && (
          <CardClips clipInfo={{ courseName: hit.parentCourseName, lessonName: hit?.parentLessonName }} />
        )
      : hit?.skills && hit.skills.length > 0 && <CardSkills skills={hit.skills} />,
    footer: (
      <>
        {hit.session && !adminContent && <CardEnrollmentDate>{hit.session}</CardEnrollmentDate>}
        <CardCategory>{cardCategory}</CardCategory>
        <SearchResultsBadges
          shouldShowThePathwayBadge={
            !isSFC && isPathwayContent ? hit.isPathwayContent : isPathwaySlug(getHitSlug(urlToUse) ?? '')
          }
          ratingElement={cardRating}
        />
        {isSFC && <CardWatchNow watchNow={cardWatchNow} data-testid="card-product-info" />}
        <CardMetadata metadata={cardMetadata} data-testid="card-product-info" />
      </>
    ),
  };

  const eventingProductType = getEventingV3ProductType(hit.entityType) as ProductType;

  const eventingV3ProductCardData = {
    productCard: {
      index: cardData?.hitPosition ?? 0,
      ...(cardData?.indexName && {
        algoliaIndexName: cardData?.indexName,
      }),
    },
    product: {
      id: extractEventId(hit?.id, eventingProductType, true) ?? '',
      name: hit?.name,
      ...(eventingProductType && {
        type: eventingProductType,
      }),
    },
  };

  const handleProductCardClick = (id: string, entityType: string) => {
    const productId = getCleanIdFromObjectId(id || '');
    const isCourse = [
      LEARNING_PRODUCTS.Course,
      LEARNING_PRODUCTS.GuidedProject,
      'GUIDED PROJECT',
      LEARNING_PRODUCTS.Project,
    ].includes(entityType);

    // this experiment has ended and its code paths needs to be removed but I don't know
    // which path we should keep
    if (doesEnterpriseSearchOpenInNewWindow()) {
      const urlParams = new URLSearchParams();
      urlParams.append('productId', productId);
      urlParams.append('productType', isCourse ? PRODUCT_TYPES.COURSE : PRODUCT_TYPES.SPECIALIZATION);
      urlParams.append('showMiniModal', 'true');
      window.open(`${router.location.pathname}?${urlParams.toString()}`);
    } else {
      launchMiniModal(router, productId, isCourse, '');
    }
  };

  const productCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    eventingV3ProductCardData
  );
  const slug = hit.objectUrl?.substring(hit.objectUrl?.lastIndexOf('/') + 1);
  const { badges: statusTags } = useGenAiBadges(slug);

  return (
    <div ref={productCardRef}>
      <ProductCard
        {...cardProps}
        tabIndex={urlToUse ? undefined : 0}
        onClick={(event) => {
          track('click_product_card', eventingV3ProductCardData);
          if (urlToUse) return;

          if (onClick) {
            event.preventDefault();
            event.stopPropagation();
            onClick();
          } else {
            event.preventDefault();
            handleProductCardClick(hit.id ?? '', hit.entityType ?? '');
          }
        }}
        css={[styles.cardWrapper, styles.tempStyles]}
        data-testid="SearchCard"
        // @ts-expect-error https://coursera.atlassian.net/browse/MERCH-742
        statusTags={statusTags}
      />
    </div>
  );
};

export default EnterpriseSearchCard;
