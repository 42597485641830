/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import EditorContainer from 'bundles/cml/editor/components/EditorContainer';
import HoveringToolbar from 'bundles/cml/editor/components/toolbars/HoveringToolbar';
import PagelessToolbar from 'bundles/cml/editor/components/toolbars/PagelessToolbar';
import { useToolsContext } from 'bundles/cml/editor/context/toolsContext';

const styles = {
  pagelessContent: css`
    height: 100%;
    padding: var(--cds-spacing-50);

    [data-slate-editor='true'] {
      padding-bottom: var(--cds-spacing-300);
    }
  `,
};

export type Props = {
  readOnly: boolean;
  scrollingContainer?: HTMLElement | null;
  children: React.ReactNode;
};

const PagelessCMLEditor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { readOnly, children, scrollingContainer } = props;
  const { tools, options } = useToolsContext();

  return (
    <EditorContainer
      ref={ref}
      toolbar={
        !readOnly && (
          <React.Fragment>
            <PagelessToolbar customTools={tools} options={options} scrollingContainer={scrollingContainer} />
            <HoveringToolbar customTools={tools} options={options} />
          </React.Fragment>
        )
      }
      toolbarPosition="bottom"
      css={styles.pagelessContent}
    >
      {children}
    </EditorContainer>
  );
});

export default React.memo(PagelessCMLEditor);
