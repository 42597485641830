// This component lazy loads in the mega menu content, for pages that rely on mega menu links for SEO, use MegaMenuWrapper

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';
import { compose } from 'recompose';

import { isUserRightToLeft } from 'js/lib/language';

import { disableScrolling } from 'bundles/browse/utils';
import { isMetaNavEnabled } from 'bundles/ent-website/components/MetaNavigation';
import { MD_SCREEN_BREAKPOINT_PX } from 'bundles/page-header/components/mobile/constants';
import ExploreButtonRedesign from 'bundles/page-header/components/redesign-experiment/ExploreButton';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import withHasPageLoaded from 'bundles/page/utils/withHasPageLoaded';
import withHasPageScrolled from 'bundles/page/utils/withHasPageScrolled';

import _t from 'i18n!nls/page';

import 'css!bundles/page-header/components/desktop/mega-menu/__styles__/MegaMenuWrapper';

type PropsFromCaller = {
  // Lazy Loadable component from createLoadableComponent. Expecting either MegaMenu or MegaMenuContent from 'bundles/browse/components/PageHeader'.
  // This allows us to pass in data directly if we have it (MegaMenuContent) or fetch it (MegaMenu).
  LoadableMegaMenu: React.ComponentType<any>;
  renderMenuOnServer?: boolean;
  delayLoadUntilScroll?: boolean;
};

type PropsToComponent = PropsFromCaller & {
  hasPageScrolled?: boolean;
  hasPageLoaded?: boolean;
};

type State = {
  menuIsOpen: boolean;
  menuHasLoaded: boolean;
  anchorElementForDropDown?: HTMLElement | null;
  isUsingKeyboard: boolean;
};

const styles = {
  preUnificationWrapper: css`
    /* displays the Explore button and Search bar */
    padding: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 16px;

    .mega-menu-overlay--lazy-loading {
      top: 65px !important;
    }
  `,
  megaMenuInGrid: css`
    margin-right: 0;
    margin-left: 0;
  `,
  preUnificationMegaMenuContainer: css`
    /* align MegaMenu with bottom of Explore button */
    top: 0 !important;

    @media (max-width: 1430px) {
      left: 5% !important;
    }

    .mega-menu-overlay--lazy-loading {
      top: -12px;
    }

    > div[class*='loadingContainer'] {
      margin-top: 0;
    }

    .rc-MegaMenuContent > div {
      margin-top: 0;
    }
  `,
};

class MegaMenuWrapperLite extends React.Component<PropsToComponent, State> {
  declare cursorIsOnMenuOrExploreButton: boolean | undefined;

  state: State = {
    menuIsOpen: false,
    // Once menu has been loaded once we don't want to remove it from the DOM (it's hidden via CSS) or it breaks some onClick events in menu
    menuHasLoaded: false,
    anchorElementForDropDown: null,
    // comment TBA
    isUsingKeyboard: false,
  };

  exploreButtonRef: HTMLButtonElement | null = null;

  setAnchorElement = (anchorElementForDropDown: HTMLElement) => {
    this.setState(() => ({ anchorElementForDropDown }));
  };

  setExploreButtonRef = (buttonRef: HTMLButtonElement | null) => {
    this.exploreButtonRef = buttonRef;
  };

  openMenu = () => {
    const menuIsOpen = true;
    this.cursorIsOnMenuOrExploreButton = menuIsOpen;
    disableScrolling(menuIsOpen);
    this.setState(() => ({ menuIsOpen, menuHasLoaded: true }));
  };

  openMenuUsingKeyboard = () => {
    const menuIsOpen = true;
    this.cursorIsOnMenuOrExploreButton = menuIsOpen;
    disableScrolling(menuIsOpen);
    this.setState(() => ({ menuIsOpen, menuHasLoaded: true, isUsingKeyboard: true }));
  };

  closeMenu = () => {
    const menuIsOpen = false;
    this.cursorIsOnMenuOrExploreButton = menuIsOpen;
    setTimeout(() => {
      if (!this.cursorIsOnMenuOrExploreButton) {
        disableScrolling(menuIsOpen);
        this.setState(() => ({ menuIsOpen, isUsingKeyboard: false }));
        this.exploreButtonRef?.focus();
      }
    }, 100);
  };

  render() {
    const { hasPageScrolled, hasPageLoaded, LoadableMegaMenu, renderMenuOnServer, delayLoadUntilScroll } = this.props;
    const { menuIsOpen, anchorElementForDropDown, menuHasLoaded, isUsingKeyboard } = this.state;

    const anchorBoundingRect = anchorElementForDropDown?.getBoundingClientRect();
    let containerStyle: React.CSSProperties | undefined;
    if (anchorBoundingRect) {
      if (isUserRightToLeft()) {
        containerStyle = { right: window?.innerWidth - anchorBoundingRect.x - anchorBoundingRect.width - 50 };
      } else {
        containerStyle = { left: anchorBoundingRect.x - anchorBoundingRect.width - 50 };
      }
    }
    const overlayClassNames = classNames('mega-menu-overlay', 'mega-menu-overlay--lazy-loading', {
      'is-active': menuIsOpen,
    });

    // This covers a few conditions that will trigger the mega menu content to start loading.
    // The mega menu shows/hides via css so once we show it once this will always be true (via menuHasLoaded).
    // The other conditions will trigger loading in certain stages of the page life cycle to improve TTI.
    const shouldLoadMegaMenu =
      // Start loading mega menu after initial page load if not waiting for scroll event.
      (hasPageLoaded && window.innerWidth >= MD_SCREEN_BREAKPOINT_PX && !delayLoadUntilScroll) ||
      // Start loading mega menu only after user has scrolled (so doesn't interfere with TTI).
      (hasPageScrolled && delayLoadUntilScroll) ||
      menuIsOpen ||
      menuHasLoaded ||
      // If menu needs to be in SSR (like for front-page).
      renderMenuOnServer;

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader, hasUnifiedMetaNav, isComposablePageHeader }) => (
          <div
            className={classNames('rc-MegaMenuWrapper rc-MegaMenuWrapperLite', {
              menuIsOpen,
              'rc-MegaMenuWrapper--MetaNav': isMetaNavEnabled({ forceOverride: !!hasUnifiedMetaNav }),
            })}
            data-e2e="megamenu-dropdown"
            css={{
              ...((isSimplifiedPageHeader &&
                !isMetaNavEnabled({ forceOverride: hasUnifiedMetaNav }) &&
                styles.preUnificationWrapper) ||
                {}),
              ...((isComposablePageHeader && styles.megaMenuInGrid) || {}),
            }}
          >
            <div>
              <ExploreButtonRedesign
                menuIsOpen={menuIsOpen}
                openMenu={this.openMenu}
                openMenuUsingKeyboard={this.openMenuUsingKeyboard}
                closeMenu={this.closeMenu}
                setAnchorElement={this.setAnchorElement}
                setExploreButtonRef={this.setExploreButtonRef}
              />
              {shouldLoadMegaMenu && (
                <div className={overlayClassNames} aria-hidden={!menuIsOpen}>
                  <nav
                    className="mega-menu-container"
                    onMouseEnter={this.openMenu}
                    onMouseLeave={this.closeMenu}
                    style={containerStyle}
                    css={isSimplifiedPageHeader && styles.preUnificationMegaMenuContainer}
                    aria-label={_t('Main Menu')}
                  >
                    <LoadableMegaMenu
                      menuIsOpen={menuIsOpen}
                      isUsingKeyboard={isUsingKeyboard}
                      openMenu={this.openMenu}
                      closeMenu={this.closeMenu}
                      anchorElement={anchorElementForDropDown}
                      renderMenuOnServer={renderMenuOnServer}
                      renderContentsOnly
                      showLoadingState
                    />
                  </nav>
                </div>
              )}
            </div>
          </div>
        )}
      </PageHeaderContext.Consumer>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(withHasPageScrolled, withHasPageLoaded)(MegaMenuWrapperLite);
