import { css } from '@emotion/react';

import { classes as buttonBaseClasses } from '@core/ButtonBase/getButtonBaseCss';
import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('Chip', [
  'root',
  'selected',
  'deletable',
  'deleteIcon',
]);

const getChipCss = css`
  &.${classes.root} {
    ${typography.actionSecondary};
    display: inline-flex;
    align-items: center;
    min-height: 32px;
    min-width: 44px;
    padding: var(--cds-spacing-50) var(--cds-spacing-150);
    color: var(--cds-color-neutral-primary);
    border: 1px solid var(--cds-color-neutral-stroke-primary);
    border-radius: var(--cds-border-radius-400);
    background-color: var(--cds-color-neutral-background-primary);

    &:hover {
      color: var(--cds-color-interactive-primary-hover);
      border-color: var(--cds-color-interactive-primary-hover);
      background-color: var(
        --cds-color-interactive-background-primary-hover-weak
      );
    }

    &:active {
      color: var(--cds-color-interactive-primary-pressed);
      border-color: var(--cds-color-interactive-primary-pressed);
      background-color: var(
        --cds-color-interactive-background-primary-pressed-weak
      );
    }

    &.${buttonBaseClasses.focusVisible}::after {
      border-radius: var(--cds-border-radius-400);
    }
  }

  &.${classes.selected} {
    color: var(--cds-color-neutral-primary-invert);
    background-color: var(--cds-color-neutral-primary-weak);

    &:hover {
      color: var(--cds-color-neutral-primary-invert);
      border-color: var(--cds-color-interactive-primary-hover);
      background-color: var(--cds-color-interactive-primary-hover);
    }

    &:active {
      color: var(--cds-color-neutral-primary-invert);
      border-color: var(--cds-color-interactive-primary-pressed);
      background-color: var(--cds-color-interactive-primary-pressed);
    }
  }

  &.${classes.deletable} {
    padding: 0 var(--cds-spacing-100) 0 var(--cds-spacing-150);
    color: var(--cds-color-neutral-primary);

    .${classes.deleteIcon} {
      box-sizing: content-box;
      height: 16px;
      width: 16px;
      margin-left: var(--cds-spacing-50);
      color: var(--cds-color-neutral-primary-weak);
    }

    &:hover .${classes.deleteIcon} {
      color: var(--cds-color-interactive-primary-hover);
    }

    &:active .${classes.deleteIcon} {
      color: var(--cds-color-interactive-primary-pressed);
    }
  }
`;

export default getChipCss;
