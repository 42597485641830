import * as React from 'react';

import { pure } from 'recompose';

import cookie from 'js/lib/cookie';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Box, Card, CardBlock, StyleSheet, color, css, font, spacing } from '@coursera/coursera-ui';

import SvgButton from 'bundles/coursera-ui/components/basic/SvgButton';
import AboutS12nCourseList from 'bundles/coursera-ui/components/rich/MiniXDP/AboutS12nCourseList';
import MiniCDPContainer from 'bundles/coursera-ui/components/rich/MiniXDP/MiniCDPContainer';
import type { CuiEnterpriseProgramSessionAssociationsQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourses_elements as CourseSession } from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/CuiEnterpriseProgramSessionAssociationsQuery';
import NavigationChevronLeft from 'bundles/coursera-ui/components/svg/coursera/common/NavigationChevronLeft';
import type { EnterpriseProductMetadataFlags } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import type { EnterpriseProductConfiguration } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import MiniPartnerList from 'bundles/enterprise-legacy-xdp/components/partners/MiniPartnerList';
import AboutS12n from 'bundles/enterprise-legacy-xdp/components/sdp/AboutS12n';
import {
  ExternalCertificateS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import { getS12nVariantProCertLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import UserDataProvider from 'bundles/unified-description-page-common/providers/user/UserDataProvider';
import { setOrUpdateRecentlyViewedXDP } from 'bundles/unified-description-page-common/utils/setOrUpdateRecentlyViewedXDP';

import _t from 'i18n!nls/coursera-ui';

const MODAL_PADDING = 28; // Set by Modal component

const styles = StyleSheet.create({
  MiniSDP: {
    minHeight: 80,
    minWidth: spacing.minWidth,
    scrollBehavior: 'smooth',
  },
  MiniSDPwithCDP: {
    minHeight: 80,
    minWidth: spacing.minWidth,
    scrollBehavior: 'smooth',
  },
  miniCdpContainer: {
    padding: MODAL_PADDING,
  },
  banner: {
    position: 'relative',
    color: color.white,
    background: '#046082',
    width: '100vw',
    marginLeft: 'calc(50% - 50vw)',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      marginLeft: 0,
    },
  },
  bannerText: {
    padding: '48px',
    backgroundSize: 'cover',
    position: 'relative',
    width: '1200px',
  },
  courseInfoContainer: {
    padding: 0,
  },
  lgPadding: {
    padding: spacing.lg,
  },
  titleContainer: {
    opacity: 1,
    filter: 'alpha(opacity=100)',
  },
  titleAndPartner: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  partnerContainer: {
    color: color.white,
    fontSize: font.xs,
    '@media (max-width: 600px)': {
      alignItems: 'start',
    },
  },
  description: {
    whiteSpace: 'pre-line',
  },
  content: {
    maxWidth: 1200,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export type Props = {
  id: string;
  s12n: $TSFixMe;
  actionElement?: JSX.Element;
  htmlAttributes: $TSFixMe;
  // If provided, we'll display the MiniCDP by default.
  // Useful when we want to persist the MiniCDP route within a MiniSDP.
  activeCourseId?: string;
  showDegreeBanner: boolean;
  s12nMiniCDPActionElementFn: (x0: { courseId: string }) => JSX.Element;
  privateS12nSessionDates?: (CourseSession | null)[];
  enableCurriculumBuilder?: boolean;
  productMetadata?: EnterpriseProductMetadataFlags[];
  isC4C: boolean;
  hasMultipleAvailabilities?: boolean;
  isEnterpriseAdminView?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  hasLabAssignments?: boolean;
};

type State = {
  showMiniCDP: boolean;
  activeCourseId?: string;
};

class MiniSDP extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { activeCourseId } = props;

    this.state = {
      showMiniCDP: !!activeCourseId,
      activeCourseId,
    };
  }

  componentDidMount() {
    this.scrollTop();
    const id = this.props.id;
    const sessionId = cookie.get('__204u');
    if (sessionId && id) {
      setOrUpdateRecentlyViewedXDP(id, sessionId);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { showMiniCDP } = this.state;
    if (showMiniCDP !== prevState.showMiniCDP) {
      this.scrollTop();
    }
  }

  onShowCDP = (activeCourseId: string) => {
    this.setState(() => ({
      showMiniCDP: true,
      activeCourseId,
    }));
  };

  onDismissCDP = () => {
    this.setState(() => ({
      showMiniCDP: false,
      activeCourseId: undefined,
    }));
  };

  // force modal to scroll to top
  scrollTop = () => {
    setTimeout(() => {
      if (this._div && this._div.parentElement) {
        this._div.parentElement.scrollTop = 0;
      }
    }, 200);
  };

  getPrivateSessionDates = (activeCourseId: string) => {
    const { privateS12nSessionDates } = this.props;
    const session = privateS12nSessionDates?.find(
      (courseSession: CourseSession | null) => courseSession?.courseId === activeCourseId
    )?.session;
    return session ? { startsAt: session.startsAt, endsAt: session.endsAt } : undefined;
  };

  getCourseMetadata = (courseId: string) => {
    const { productMetadata } = this.props;
    return productMetadata?.find((metadata) => metadata?.courseId === courseId) ?? undefined;
  };

  _div: HTMLElement | null = null;

  render() {
    const {
      s12n: {
        id,
        name,
        description,
        partners,
        courses,
        courseCount,
        skills,
        cmlDescription,
        level,
        primaryLanguages,
        subtitleLanguages,
        partnerIncentives: learnerIncentives,
        productVariant,
      },
      actionElement,
      htmlAttributes,
      s12nMiniCDPActionElementFn,
      privateS12nSessionDates,
      productMetadata,
      isC4C,
      isEnterpriseAdminView,
      enableCurriculumBuilder,
      hasMultipleAvailabilities,
      enterpriseProductConfiguration,
      hasLabAssignments,
    } = this.props;
    const { showMiniCDP, activeCourseId } = this.state;
    const partner = partners[0];
    const partnerColor = (partner || {}).primaryColor;
    const primaryColor = '#000000';
    const s12nVariantLabel = getS12nVariantProCertLabel(productVariant);

    return (
      <UserDataProvider>
        <div
          {...css(showMiniCDP ? styles.MiniSDPwithCDP : styles.MiniSDP)}
          {...htmlAttributes}
          ref={(ref) => {
            this._div = ref;
          }}
        >
          {showMiniCDP && activeCourseId && (
            <div {...css(styles.miniCdpContainer)} data-e2e="CDPinsideSDP">
              <MiniCDPContainer
                id={activeCourseId}
                privateSessionDates={this.getPrivateSessionDates(activeCourseId)}
                enterpriseProductConfiguration={this.getCourseMetadata(activeCourseId)}
                tag="div"
                actionElement={s12nMiniCDPActionElementFn?.({ courseId: activeCourseId })}
                topNavigationElement={
                  <Box style={{ padding: '0 2.5rem' }} alignItems="center">
                    <SvgButton
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      type="link"
                      data-js="DismissCDPBtn"
                      onClick={this.onDismissCDP}
                      label={_t('Back to #{s12nName}', { s12nName: name })}
                      style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'pre-line', textAlign: 'left' }}
                      svgElement={<NavigationChevronLeft color={color.primary} />}
                      htmlAttributes={{ 'data-e2e': 'CDPBackButton' }}
                    />
                  </Box>
                }
                learnerIncentives={learnerIncentives}
              />
            </div>
          )}
          {!showMiniCDP && (
            <Card>
              <CardBlock isFullBleed>
                <div {...css(styles.contentContainer)}>
                  <div {...css(styles.content)}>
                    <div {...css(styles.courseInfoContainer)}>
                      <div {...css(styles.banner)}>
                        <div {...css(styles.bannerText)}>
                          <div {...css(styles.titleAndPartner)}>
                            <div {...css('vertical-box', styles.titleContainer)}>
                              <span className="d-block font-sm text-uppercase">
                                {productVariant === ProfessionalCertificateS12n ||
                                productVariant === ExternalCertificateS12n ? (
                                  s12nVariantLabel
                                ) : (
                                  <FormattedMessage
                                    message={_t('{courseCount} course {specialization}')}
                                    courseCount={courseCount}
                                    specialization={s12nVariantLabel}
                                    data-test="s12n-course-count"
                                  />
                                )}
                              </span>
                              <h2 id="programMiniModalName" aria-live="polite" aria-busy={!name} className="m-r-1">
                                {name}
                              </h2>
                            </div>
                            <Box rootClassName={styles.partnerContainer} alignItems="center" justifyContent="start">
                              {partners && partners.length > 0 && (
                                <MiniPartnerList partners={partners} tempDisplayPartnerLimit={1} />
                              )}
                            </Box>
                          </div>
                        </div>
                      </div>
                      {actionElement && <div className="actionElementContainer m-y-1 p-x-3">{actionElement}</div>}
                      <div className="p-x-3 m-t-1">
                        <AboutS12n
                          isMiniSDP={true}
                          description={description}
                          cmlDescription={cmlDescription}
                          level={level}
                          skills={skills}
                          primaryColor={primaryColor || partnerColor}
                          primaryLanguages={primaryLanguages}
                          subtitleLanguages={subtitleLanguages}
                          languageLineThreshold={2}
                          s12nId={id}
                          showProductMetrics={false}
                          courses={courses}
                          learnerIncentives={learnerIncentives ?? []}
                          hasPrivateSessionDates={privateS12nSessionDates && privateS12nSessionDates?.length > 0}
                          productVariant={productVariant}
                          enterpriseProductConfiguration={
                            productMetadata?.find((meta) => meta?.s12nId === id) || enterpriseProductConfiguration
                          }
                          isC4C={isC4C}
                          isEnterpriseAdminView={isEnterpriseAdminView}
                          enableCurriculumBuilder={enableCurriculumBuilder}
                          hasMultipleAvailabilities={hasMultipleAvailabilities}
                          showCourseraLabsGlance={hasLabAssignments}
                        />
                      </div>
                    </div>
                    <div className="p-t-1 p-b-2 p-x-3 border-top">
                      <AboutS12nCourseList
                        courses={courses}
                        s12nProductVariant={productVariant}
                        onShowCDP={this.onShowCDP}
                        privateSessionDates={privateS12nSessionDates}
                        coursesMetadata={productMetadata}
                      />
                    </div>
                  </div>
                </div>
              </CardBlock>
            </Card>
          )}
        </div>
      </UserDataProvider>
    );
  }
}

export default pure(MiniSDP);
export const BaseComponent = MiniSDP;
