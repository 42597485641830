/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import { useTheme } from '@coursera/cds-core';

import { DomainSubdomainQuery } from 'bundles/browse/components/Queries';
import type {
  DomainSubdomainQuery_DomainsV1Resource_slug_elements as Domain,
  DomainSubdomainQuery as DomainSubdomainQueryData,
  DomainSubdomainQueryVariables,
  DomainSubdomainQuery_SubdomainsV1Resource_slug_elements as Subdomain,
} from 'bundles/browse/components/__generated__/DomainSubdomainQuery';
import ProgramHomeDomainSubdomainBody, {
  ProgramHomeDomainSubdomainBodyPlaceholder,
} from 'bundles/enterprise-collections/components/ProgramHomeDomainSubdomainBody';
import ProgramHomeDomainSubdomainHeader, {
  ProgramHomeDomainSubdomainHeaderPlaceholder,
} from 'bundles/enterprise-collections/components/ProgramHomeDomainSubdomainHeader';
import type { Product, ProductType } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import NotFound from 'bundles/phoenix/components/NotFound';
import SimpleCourseraMetatags from 'bundles/seo/components/SimpleCourseraMetatags';

type InputProps = {
  isWESContract: boolean;
  isC4cv: boolean;
  isUpswell: boolean;
  programSlug: string;
  programId: string;
  onProductCardClick?: (product: Product, productType: ProductType, collectionId?: string | null) => void;
  thirdPartyOrgSlug: string;
  programName: string;
};

type HOCProps = InputProps & {
  domainSlug: string;
  subdomainSlug?: string;
  program: any;
  thirdPartyOrganizationId: string;
};

type Props = HOCProps & {
  domain?: Domain;
  subdomain?: Subdomain;
  loading: boolean;
  items?: Array<{
    label: string;
    attribute: string;
    currentRefinement: string | Record<string, any>;
    items: Array<any>;
    value: () => void;
  }>;
  userAgent?: any;
  thirdPartyOrganizationId: string;
};

type TitleProps = {
  domainName: string;
  subdomainName?: string;
};

export function ProgramHomeDomainSubdomainContainerPlaceholder() {
  return (
    <main>
      <ProgramHomeDomainSubdomainHeaderPlaceholder />
      <ProgramHomeDomainSubdomainBodyPlaceholder />
    </main>
  );
}

export const NotFoundContainer = () => {
  const theme = useTheme();
  const styles = {
    container: css`
      /* Align with page footer. */
      max-width: 1170px;
      padding: 0 15px;
      margin: var(--cds-spacing-600) auto;
    `,
  };
  return (
    <main css={styles.container}>
      <NotFound />
    </main>
  );
};

export function createDomainFilter(domainId: string | undefined, subdomainId: string | undefined) {
  const domainFilter = domainId && `categoryId:${domainId}`;
  const subdomainFilter = subdomainId && `subcategoryId:${subdomainId}`;
  return [domainFilter, subdomainFilter].filter(Boolean).join(' AND ') || undefined;
}

const ProgramMetaTitle = ({ domainName, subdomainName }: TitleProps) => {
  const metaTitle = `${domainName} ${subdomainName ? ' - ' + subdomainName : ''}`;
  return <SimpleCourseraMetatags title={metaTitle} />;
};

export const ProgramHomeDomainSubdomainContainer = ({
  domain,
  subdomain,
  programSlug,
  loading,
  thirdPartyOrgSlug,
  thirdPartyOrganizationId,
  programName,
  programId,
  ...props
}: Props) => {
  if (loading) return <ProgramHomeDomainSubdomainContainerPlaceholder />;
  if (!domain) return <NotFoundContainer />;

  return (
    <main data-testid="program-home-domain-subdomain-container">
      <ProgramMetaTitle domainName={domain.name} subdomainName={subdomain?.name} />
      <ProgramHomeDomainSubdomainHeader
        domain={domain}
        subdomain={subdomain}
        programSlug={programSlug}
        thirdPartyOrgSlug={thirdPartyOrgSlug}
        thirdPartyOrganizationId={thirdPartyOrganizationId}
        programName={programName}
      />
      <ProgramHomeDomainSubdomainBody
        domain={domain}
        subdomain={subdomain}
        thirdPartyOrganizationId={thirdPartyOrganizationId}
        programId={programId}
        {...props}
      />
    </main>
  );
};

const withData = graphql<HOCProps, DomainSubdomainQueryData, DomainSubdomainQueryVariables, Props>(
  DomainSubdomainQuery,
  {
    options: ({ domainSlug, subdomainSlug }) => ({
      variables: {
        domainSlug: domainSlug || '',
        subdomainSlug: subdomainSlug || '',
        isDomainSlugDefined: typeof domainSlug === 'string',
        isSubdomainSlugDefined: typeof subdomainSlug === 'string',
      },
    }),

    skip: ({ domainSlug, subdomainSlug }) => !domainSlug && !subdomainSlug,
    props: ({ data, ownProps }) => ({
      subdomain: data?.SubdomainsV1Resource?.slug?.elements?.[0] ?? undefined,
      domain: data?.DomainsV1Resource?.slug?.elements?.[0] ?? undefined,
      loading: data?.loading ?? true,
      ...ownProps,
    }),
  }
);

export default withData(ProgramHomeDomainSubdomainContainer);
