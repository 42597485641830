import type {
  XdpV1_Org_Coursera_Xdp_Sdp_SdpCourse as Course,
  XdpV1_Org_Coursera_Xdp_Common_XdpCourseWeek as CourseWeek,
  DescriptionPage_Partner as DescriptionPagePartner,
} from '__generated__/graphql-types';
import moment from 'moment';

import { languageCodeToName } from 'js/lib/language';

import type { CmlContent, CmlDefinition } from 'bundles/cml';
import type { DifficultyLevel, Domain, Partner } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import {
  getTotalMonthsFromCourses,
  getTotalWeeksFromCourses,
} from 'bundles/enterprise-legacy-learner-home/utils/productDuration';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

export { getTotalMonthsFromCourses, getTotalWeeksFromCourses };

export type Product = {
  id: string;
  title: string;
  s12nProductVariant?: S12nProductVariant;
  subtitle: string | null;
  imageSrc: string;
  logoSrc: string | null;
  label: string;
  slug: string;
};

export function getBreadcrumbsFromDomain(domain: Domain | undefined) {
  const routes = [{ path: '/browse', name: _t('Browse') }];

  if (domain && domain.domainId && domain.domainName) {
    routes.push({ path: `/browse/${domain.domainId}`, name: domain.domainName });
  }

  if (domain && domain.subdomainId && domain.subdomainName) {
    routes.push({ path: `/browse/${domain.domainId}/${domain.subdomainId}`, name: domain.subdomainName });
  }
  return routes;
}

export function getHoursFromDuration(duration: number) {
  return Math.floor(moment.duration(duration).asHours());
}

export function getTotalHoursFromCDPWeeks(weeks: Array<CourseWeek>): number {
  function getTotalDurationFromWeek(week: CourseWeek): number {
    return week.modules.reduce((moduleDuration, module) => {
      return moduleDuration + (module?.totalDuration || 0);
    }, 0);
  }

  const totalDuration = weeks.reduce((weekDuration, week) => {
    return weekDuration + getTotalDurationFromWeek(week);
  }, 0);

  return getHoursFromDuration(totalDuration);
}

export function getTotalHoursFromSDPWeeks(weeks: Array<CourseWeek>): number {
  function getTotalDurationFromWeek(week: CourseWeek): number {
    return week.modules.reduce((moduleDuration, module) => {
      return moduleDuration + (module?.totalDuration || 0);
    }, 0);
  }

  const totalDuration = weeks.reduce((weekDuration, week) => {
    return weekDuration + getTotalDurationFromWeek(week);
  }, 0);

  return getHoursFromDuration(totalDuration);
}

// Only use this function if the hoursPerWeekOverride field from xdpMetadataResource is NOT defined
export function getHoursPerWeekFromCourses(courses: Array<Course>) {
  const totalS12nHours = courses.reduce((s12nHours, course) => {
    const weeks = course?.material?.weeks;
    return s12nHours + (weeks ? getTotalHoursFromSDPWeeks(weeks as Array<CourseWeek>) : 0);
  }, 0);
  const totalWeeks = getTotalWeeksFromCourses(courses);

  return Math.round(totalS12nHours / totalWeeks);
}

export function isAppliedProjectManagementCert(s12nId: string) {
  return s12nId === 'kLWnFWsyEeeVdhKUpvOPZg';
}

export function getNaptimeCMLShape(cml: { dtdId: string; value: string }): CmlContent {
  return {
    typeName: 'cml',
    definition: {
      value: cml.value,
      dtdId: cml.dtdId,
    },
  };
}

export function getCMLShape(cml: CmlDefinition): CmlContent {
  return {
    typeName: 'cml',
    definition: { ...cml },
  };
}

export function generateInstructorImageAltValue(instructorName: string | null) {
  return instructorName ? _t('Image of instructor, #{instructorName}', { instructorName }) : _t('Image of instructor');
}

export function addColorHashIfNeeded(color?: string) {
  if (typeof color === 'string' && !color.startsWith('#')) {
    return '#' + color;
  }
  return color;
}

// TODO: This is a duplicate of code in static/bundles/collections/components/CollectionItem.jsx
// Is there a good shared place for these funcs?
export const DIFFICULTY_LEVEL_TO_ICON = {
  BEGINNER: 'SvgLevelBeginner',
  INTERMEDIATE: 'SvgLevelIntermediate',
  ADVANCED: 'SvgLevelAdvanced',
};

export function getDifficultyLevelString(level: DifficultyLevel) {
  // TODO: This means we call _t() 3 times every time this function is called.
  // But we can't put those calls at the module top level because it will cause SSR errors:
  // https://coursera.atlassian.net/wiki/spaces/EN/pages/48169658/Development+Warnings+and+Errors
  // Is this worth addressing performance-wise?
  // In OOP we would make this a class instance variable
  const DIFFICULTY_LEVEL_TO_STRING = {
    BEGINNER: _t('Beginner Level'),
    INTERMEDIATE: _t('Intermediate Level'),
    ADVANCED: _t('Advanced Level'),
  };
  return DIFFICULTY_LEVEL_TO_STRING[level];
}

export function getLanguageString(languagesArray: Array<string>) {
  return languagesArray.map(languageCodeToName).join(', ');
}

export const hexCodeRegex = /#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})/;

export const getSEODescription = (
  description: string,
  partners?: Array<Partner | DescriptionPagePartner> | null,
  enableCoBranding?: boolean
): string => {
  if (partners && partners?.length > 1 && enableCoBranding) {
    return _t(`Offered by #{firstPartnerName} and #{secondPartnerName}. #{description}`, {
      firstPartnerName: partners[0].name,
      secondPartnerName: partners[1].name,
      description,
    });
  } else if (partners?.length === 1) {
    return _t(`Offered by #{firstPartnerName}. #{description}`, {
      firstPartnerName: partners[0].name,
      description,
    });
  } else {
    return description;
  }
};
