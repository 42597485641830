import type { CourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

class BannerInfoBoxViewController {
  declare productTitle: string;

  constructor(courseType: CourseType, canEnrollWithFullDiscount: boolean) {
    switch (courseType) {
      case 'GuidedProject':
        this.productTitle = canEnrollWithFullDiscount ? _t('Free Guided Project') : _t('Guided Project');
        break;
      case 'Project':
        this.productTitle = canEnrollWithFullDiscount ? _t('Free Project') : _t('Project');
        break;
      case 'StandardCourse':
      default:
        this.productTitle = '';
    }
  }
}

export default BannerInfoBoxViewController;
