/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Link, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import type { LinkProps } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import SkillCard, {
  SkillCardPlaceholder,
} from 'bundles/enterprise-legacy-learner-home/components/myLearning/skillsDashboard/SkillCard';
import { withSkillsData } from 'bundles/enterprise-legacy-learner-home/components/myLearning/skillsDashboard/withSkillsData';
import type { DataFromSkills } from 'bundles/enterprise-legacy-learner-home/components/myLearning/skillsDashboard/withSkillsData';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { isSkillUpdatedHome } from 'bundles/skills-common/private/skillsInsightsEnabled';

import _t from 'i18n!nls/program-home';

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps>(Link);

const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--cds-spacing-300);
  `,
  skills: css`
    margin: var(--cds-spacing-150) 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--cds-spacing-300) var(--cds-spacing-400);
  `,
  skill: css`
    flex: 1 1 33%;
    max-width: 31%;
    ${breakpoints.down('md')} {
      max-width: 100%;
    }
  `,
  viewAllLink: css`
    display: flex;
    align-items: center;
    gap: var(--cds-spacing-150);
  `,
};
type PropsForHeader = {
  programSlug: string;
};
const SkillFocusHeader = ({ programSlug }: PropsForHeader) => {
  return (
    <div css={styles.header}>
      <Typography variant="h1semibold" component="h2">
        {_t('Narrow your focus with skills')}
      </Typography>
      <TrackedDiv
        trackingName="program_home_skill_view_all"
        withVisibilityTracking
        requireFullyVisible={true}
        trackClicks={false}
      >
        <TrackedLink
          {...Link.defaultProps}
          css={styles.viewAllLink}
          typographyVariant="subtitleMedium"
          trackingName="view_all_skills"
          variant="quiet"
          href={`/programs/${programSlug}?currentTab=MY_LEARNING&myLearningTab=SKILLS_DASHBOARD`}
        >
          {_t('View all Skills')}
          <ArrowNextIcon />
        </TrackedLink>
      </TrackedDiv>
    </div>
  );
};

type Props = DataFromSkills & PropsForHeader;

const SkillFocusPlaceholder = (props: PropsForHeader) => {
  return (
    <div>
      <SkillFocusHeader {...props} />
      <Typography2 component="span">{_t('Build deep proficiency with expertly matched recommendations.')}</Typography2>
      <div css={styles.skills}>
        {[0, 1, 2].map((i) => (
          <SkillCardPlaceholder css={styles.skill} key={i} />
        ))}
      </div>
    </div>
  );
};

const SkillFocusWithData: React.FunctionComponent<Props> = (props) => {
  if (props.loadingSkills) {
    return <SkillFocusPlaceholder programSlug={props.programSlug} />;
  }

  return (
    <div className="rc-SkillsFocus">
      <SkillFocusHeader programSlug={props.programSlug} />
      <Typography2 component="span">{_t('Build deep proficiency with expertly matched recommendations.')}</Typography2>
      <div css={styles.skills}>
        {props.skillProgress.map((skill, order) => (
          <SkillCard
            hideLinks={isSkillUpdatedHome()}
            source="program-home"
            trackingName="program_home_skill_card"
            key={skill.skillId}
            css={styles.skill}
            size="small"
            skill={skill}
            programSlug={props.programSlug}
            trackingData={{
              skill,
              order,
              pageSection: {
                sectionName: 'cta_banner',
                index: 2,
              },
            }}
          />
        ))}
      </div>
    </div>
  );
};

const SkillsFocus = withSkillsData(SkillFocusWithData);
export { SkillFocusWithData };
export default SkillsFocus;
