import * as React from 'react';
import { graphql } from 'react-apollo';

import { compose } from 'recompose';

import waitFor from 'js/lib/waitFor';

import { Card, StyleSheet, color, css, spacing } from '@coursera/coursera-ui';

import type { CircleMenuItem } from 'bundles/enterprise-legacy-learner-home/components/multiCourseProductCard/MultiCourseProductCirclesMenu';
import MultiCourseProductEnrolledCard from 'bundles/enterprise-legacy-learner-home/components/multiCourseProductCard/MultiCourseProductEnrolledCard';
import S12nCardSpecialization from 'bundles/program-home/queries/S12nCardSpecializationQuery.graphql';
import type {
  S12nCardSpecializationQuery,
  S12nCardSpecializationQueryVariables,
  S12nCardSpecialization_SpecializationFragment as Specialization,
} from 'bundles/program-home/queries/__generated__/S12nCardSpecializationQuery';
import S12nNotEnrolledCard from 'bundles/s12n-common/components/s12nCard/S12nNotEnrolledCard';

const MIN_CARD_HEIGHT = 112;

const styles = StyleSheet.create({
  S12nCard: {
    position: 'relative',
    backgroundColor: color.white,
    marginBottom: spacing.md,
    overflow: 'hidden',
  },
});

type PropsFromCaller = {
  programId: string;
  isS12nEnrolled: boolean;
  canUnenroll: boolean;
  s12nId: string;
  userId: number;
  circleMenuData?: CircleMenuItem[];
};

type PropsFromGraphQL = {
  s12n: Specialization;
};

export type PropsToComponent = PropsFromCaller & PropsFromGraphQL;

export const S12nCard: React.SFC<PropsToComponent> = ({
  canUnenroll = true,
  isS12nEnrolled,
  programId,
  s12n,
  s12nId,
  userId,
  circleMenuData,
}) => {
  if (!s12n) {
    return null;
  }

  const cardStyles = {
    minHeight: MIN_CARD_HEIGHT,
    marginBottom: spacing.md,
    padding: isS12nEnrolled ? 0 : spacing.md,
    marginTop: '2px',
    marginLeft: '2px',
    marginRight: '2px',
    boxShadow: '0px 1px 4px rgba(49, 49, 49, 0.24)',
    border: '1px solid #EAEAEA',
  };
  const courseCount = s12n.courseCount - s12n.capstoneCourses.length;

  return (
    <Card
      tagAttributes={{
        xsMinWidth: true,
      }}
      isInteractive={true}
      style={cardStyles}
    >
      <div {...css('m-b-1', styles.S12nCard)} data-e2e={`S12nCard~${s12nId}`}>
        {!isS12nEnrolled && <S12nNotEnrolledCard s12n={s12n} programId={programId} />}
        {isS12nEnrolled && userId && (
          <MultiCourseProductEnrolledCard
            id={s12n.id}
            name={s12n.name}
            logo={s12n.cardImageUrl}
            slug={s12n.slug}
            productVariant={s12n.productVariant}
            description={s12n.descriptionAsPlainText}
            partners={s12n.partners}
            programId={programId}
            userId={userId}
            circleMenuData={circleMenuData}
            courseCount={courseCount || 0}
            firstCourseId={s12n?.courses[0]?.id}
            capstoneCourses={s12n?.capstoneCourses?.map((capstone) => capstone.id) ?? []}
            itemType="S12N"
            canUnenroll={canUnenroll}
          />
        )}
      </div>
    </Card>
  );
};

const S12nCardWithData = compose<PropsToComponent, PropsFromCaller>(
  graphql<
    PropsFromCaller,
    S12nCardSpecializationQuery,
    S12nCardSpecializationQueryVariables,
    Partial<PropsFromGraphQL>
  >(S12nCardSpecialization, {
    options: ({ s12nId }) => ({
      variables: {
        id: s12nId,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }),
    props: ({ data }) => ({
      s12n: data?.Specialization?.queryById ?? undefined,
    }),
  }),
  // Mimic the old blocking behavior of Naptime.createContainer for OnDemandSpecializationsV1 (now using the GraphQL
  // Gateway via the `graphql` HOC above) without blocking the remaining Naptime calls, i.e. don't use `waitForGraphQL`
  // above, so that both the GraphQL and Naptime calls can run simultaneously.
  waitFor<PropsToComponent>(({ s12n }) => !!s12n)
)(S12nCard);

export default S12nCardWithData;
