import config from 'js/app/config';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

export const MOBILE_BREAKPOINT_PX = 768;
export const EXP_TOGGLABLE_CONTENT_HEIGHT_PX = 212;

export const PRODUCTS = {
  course: 'COURSE',
  specialization: 'SPECIALIZATION',
} as const;

export const MIN_NUM_OF_SKILLS_TO_SHOW = 2;

export const XDP_CERTIFICATE_IMAGES = {
  [PRODUCTS.course]: `${config.url.resource_assets}growth/xdp/certificateCDP.jpg`,
  [PRODUCTS.specialization]: `${config.url.resource_assets}growth/xdp/certificateSDP.jpg`,
} as const;

export const XDP_EXPERTISE_ICONS = {
  instructor: `${config.url.resource_assets}xdp/expertise/icons/SvgInstructor.svg`,
  learners: `${config.url.resource_assets}xdp/expertise/icons/SvgMembers.svg`,
  courseBook: `${config.url.resource_assets}xdp/expertise/icons/SvgBook.svg`,
} as const;

export const XDP_META_DESC_LENGTH_LIMIT = 160;
export const getXdpMetaDescriptionSuffix = () => _t('Enroll for free.');

export const productTypesTranslated = () => ({
  get COURSE() {
    return _t('course');
  },
  get SPECIALIZATION() {
    return _t('specialization');
  },
});

export const getDefaultBackgroundLevelCml = () => ({
  get BEGINNER() {
    return _t('No prior experience required.');
  },
  get INTERMEDIATE() {
    return _t('Some related experience required.');
  },
  get ADVANCED() {
    return _t('Designed for those already in the industry.');
  },
});

export const navItemsEnum = {
  about: 'about',
  instructorsTop: 'instructors-top',
  howItWorks: 'howItWorks',
  courses: 'courses',
  instructors: 'instructors',
  enroll: 'enroll',
  faq: 'faq',
  syllabus: 'syllabus',
  reviews: 'reviews',
  courseraPlus: 'courseraPlus',
};

export const FCNDPartners = new Set(['boulder', 'illinois']);

// Temporary solution. Not all courses are available as FCND.
// FCND query source - https://tools.coursera.org/mega/queryExecutionLog/QUERY~c8Ze8DCEEe2enUOUgM-VWQ~1~1662758140510#table
// TODO: https://coursera.atlassian.net/browse/DEGROW-1025
export const FCND_COURSES = new Set([
  'corporate-strategy',
  'managing-organization',
  'designing-organization',
  'strategy-business',
  'financial-accounting-advanced',
  'financial-accounting-basics',
  'infonomics-1',
  'infonomics-2',
  'project-initiation-planning',
  'project-execution-control',
  'digital-marketing-revolution',
  'marketing-digital',
  'digital-analytics',
  'marketing-plan',
  'marketing-analytics',
  'marketing-channels',
  'marketing-management',
  'accounting-for-managers',
  'marketing-management-two',
  'process-improvement',
  'operations-management',
  'managerial-accounting-business-decisions',
  'entrepreneurship-1',
  'creativity-toolkit-1',
  'strategic-innovation-building-and-sustaining-innovative-organizations',
  'creativity-toolkit-2',
  'strategic-innovation-innovation-at-the-frontier',
  'entrepreneurship-2',
  'global-strategy',
  'cultural-psychology-globalization',
  'business-ethics',
  'subsistence-marketplaces-1',
  'global-strategy-two',
  'sustainable-business-enterprises',
  'business-analytics-r',
  'intro-business-analytics',
  'current-modecontrol',
  'averagedswitchmodelingandsimulation',
  'modeling-and-control-of-single-phase-rectifiers-and-inverters',
  'inputfilterdesign',
  'techniques-of-design-oriented-analysis',
  'pressure-force-motion-humidity-sensors',
  'sensor-manufacturing-process-control',
  'sensors-circuit-interface',
  'motors-circuits-design',
  'magnetics-for-power-electronic-converters-v2',
  'power-electronics',
  'converter-control',
  'converter-circuits',
  'battery-state-of-charge',
  'battery-state-of-health',
  'equivalent-circuit-cell-model-simulation',
  'battery-management-systems',
  'battery-pack-balancing-power-estimation',
  'm2m-iot-interface-design-embedded-systems',
  'rapid-prototyping-embedded-interface',
  'ux-interface-design-embedded-systems',
  'nanophotonics-detectors',
  'displays',
  'leds-semiconductor-lasers',
  'power-electronics-capstone',
  'current-control',
  'software-architecture-for-big-data-fundamentals',
  'data-mining-pipeline',
  'data-mining-methods',
  'supervised-text-classification-for-marketing-analytics',
  'network-analysis-for-marketing-analytics',
  'regression-and-classification',
  'dynamic-programming-greedy-algorithms',
  'trees-graphs-basics',
  'algorithms-searching-sorting-indexing',
  'introduction-high-performance-computing',
  'generalized-linear-models-and-nonparametric-regression',
  'anova-and-experimental-design',
  'modern-regression-analysis-in-r',
  'advanced-topics-future-trends-database-technologies',
  'relational-database-design',
  'the-structured-query-language-sql',
  'probability-theory-foundation-for-data-science',
  'statistical-inference-for-estimation-in-data-science',
  'intro-accounting-data-analytics-visual',
  'accounting-data-analytics-python',
  'machine-learning-accounting-python',
  'federal-taxation-business',
  'federal-taxation-individuals',
  'asset-measurement-disclosure',
  'accounting-information-system',
  'electronic-converters',
  'transistor-field-effect-transistor-bipolar-junction-transistor',
  'semiconductor-physics',
  'diode-pn-junction-metal-semiconductor-contact',
  'industrial-iot-project-planning-machine-learning',
  'modeling-debugging-embedded-systems',
  'industrial-iot-markets-security',
  'fpga-hardware-description-languages',
  'capstone-fpga-design',
  'fpga-softcore-proccessors-ip',
  'intro-fpga-design-embedded-systems',
  'introduction-to-power-semiconductor-switches',
  'optical-efficiency-and-resolution',
  'design-high-performance-optical-systems',
  'first-order-optical-system-design',
  'real-time-mission-critical-systems-design',
  'real-time-project-embedded-systems',
  'real-time-embedded-theory-analysis',
  'real-time-embedded-systems-concepts-practices',
  'theory-of-angular-momentum',
  'approximation-methods',
  'foundations-quantum-mechanics',
  'investments-applications',
  'investments-fundamentals',
  'corporate-finance-measure-success',
  'corporate-finance-two',
  'applying-data-analytics-accounting',
  'applying-data-analytics-business-in-marketing',
  'machine-learning-algorithms-r-business-analytics',
  'tools-exploratory-data-analysis-business',
  'business-statistics',
  'firm-level-economics-markets',
  'business-data',
  'country-level-economics',
  'firm-level-economics',
  'macroeconomic-factors',
  'fundamentals-of-data-visualization',
  'cybersecurity-for-data-science',
  'ethical-issues-data-science',
  'data-science-as-a-field',
  'unsupervised-algorithms-in-machine-learning',
  'introduction-to-deep-learning-boulder',
  'measurement-systems-analysis',
  'managing-describing-analyzing-data',
  'accounting-data-analytics-capstone',
  'accounting-analysis-2-equity',
  'accounting-analysis-2-liabilities',
  'global-challenges-business-capstone',
  'strategic-leadership-capstone',
  'taxation-business-entities-part-1',
  'taxation-business-entities-part-2',
  'us-federal-tax-capstone',
  'accounting-for-ma-2',
  'accounting-for-ma-1',
  'advanced-financial-reporting',
  'applying-data-analytics-business-in-finance',
  'auditing-part1-conceptual-foundations',
  'auditing-part2-the-practice',
  'banking-and-financial-institutions',
  'beyond-financials-insights-analysis-valuations',
  'central-banks-monetary-policies',
  'corporate-commercial-law-part1',
  'corporate-commercial-law-part2',
  'mergers-acquisitions-ma-deal',
  'mergers-acquisitions-valuation-pricing',
  'financial-statements-sec-filings-ratio-analysis',
  'multistate-taxation',
  'predictive-analytics-data-mining',
  'ethics',
  'us-international-tax',
  'business-analytics-executive-overview',
  'financial-statement-ratio-analysis-accountants',
  'forecasting-financial-statements-business-valuation-accountants',
  'introduction-to-finance-the-basics',
  'introduction-to-finance-the-role-of-financial-markets',
]);

export const FCND_S12NS = new Set([
  'strategic-leadership',
  'financial-accounting',
  'analytics-information',
  'business-value-project-management',
  'digital-marketing',
  'value-chain-management',
  'innovation-creativity-entrepreneurship',
  'global-challenges-business',
  'business-data-management-communication',
  'modeling-and-control-of-power-electronics',
  'embedding-sensors-motors',
  'power-electronics',
  'algorithms-for-battery-management-systems',
  'embedded-interface-design',
  'active-optical-devices',
  'software-architecture-big-data',
  'data-mining-foundations-practice',
  'text-marketing-analytics',
  'statistical-learning-for-data-science',
  'boulder-data-structures-algorithms',
  'high-performance-parallel-computing',
  'statistical-modeling-for-data-science-applications',
  'databases-for-data-scientists',
  'statistical-inference-for-data-science-applications',
  'accounting-data-analytics',
  'united-states-federal-taxation',
  'financial-reporting',
  'semiconductor-devices',
  'developing-industrial-iot',
  'fpga-design',
  'power-semiconductor-devices',
  'optical-engineering',
  'real-time-embedded-systems',
  'quantum-mechanics-for-engineers',
  'financial-management',
  'analytics',
  'managerial-economics-business-analysis',
  'vital-skills-for-data-science',
  'machine-learnin-theory-and-hands-on-practice-with-pythong-cu',
  'data-science-methods-for-quality-improvement',
]);
