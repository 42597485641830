/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import { Button, Typography, Typography2, useTheme } from '@coursera/cds-core';
import { SpinnerIcon } from '@coursera/cds-icons';

import EnterpriseExperiments from 'bundles/epic/clients/Enterprise';
import { IdTypes } from 'bundles/product-features';
import { ProductFeaturesQuery } from 'bundles/program-home/productFeatureFlags';
import SsoButton from 'bundles/user-account/components/SsoButton';

import _t from 'i18n!nls/program-home';

type Props = {
  programId: string;
  programName: string;
  onBrowse: (callback: () => void) => void;
  thirdPartyOrganizationId: string;
};

const ProgramDenyModal = ({ programName, programId, onBrowse, thirdPartyOrganizationId }: Props) => {
  const theme = useTheme();
  const styles = {
    loader: css`
      margin: var(--cds-spacing-800) 0;
    `,
    text: css`
      margin-bottom: var(--cds-spacing-200);
    `,
    customMessage: css`
      white-space: pre-wrap;
    `,
    buttons: css`
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      gap: var(--cds-spacing-200);
      margin-top: var(--cds-spacing-200);
      align-self: stretch;

      > .c-SsoButton {
        flex: 0 1 0;
      }
    `,
    ssoButton: css`
      flex: 0 1 auto;
    `,
  };

  const router = useRouter();
  const { authProvider: ssoProvider } = router.location.query;

  const handleBrowse = React.useCallback(() => {
    onBrowse(() => redirect.setLocation('/browse'));
  }, [onBrowse]);

  const whitelistOrganizationProgramsForSSOLogin = EnterpriseExperiments.get(
    'whitelistOrganizationProgramsForSSOLogin'
  );
  const isWhitelistOrganizationProgramsForSSOLogin =
    whitelistOrganizationProgramsForSSOLogin?.[thirdPartyOrganizationId]?.includes(programId);

  return (
    <>
      <Typography variant="h1" component="h2" css={styles.text}>
        {_t('Hi, #{email}', { email: user.get().email_address })}
      </Typography>
      <ProductFeaturesQuery idType={IdTypes.Program} id={programId}>
        {({ loading, features }) => {
          if (loading) {
            return <SpinnerIcon aria-busy title={_t('Loading...')} />;
          }

          const customMessage = features.get('enterprise', 'programDenyCustomMessage');
          if (customMessage) {
            return (
              <Typography2 component="p" variant="bodyPrimary" css={[styles.text, styles.customMessage]}>
                {customMessage}
              </Typography2>
            );
          }

          return (
            <>
              <Typography2 component="p" variant="bodyPrimary" css={styles.text}>
                {_t("It looks like you haven't joined #{programName}.", { programName })}
              </Typography2>
              <Typography2 component="p" variant="bodyPrimary" css={styles.text}>
                <FormattedMessage
                  message={_t(
                    "If you believe this is an error, confirm you are signed in with using the same email you used to join the program. For more information, visit our {helpCenterLink} or contact your program's administrator."
                  )}
                  helpCenterLink={<a href="http://learner.coursera.help">{_t('Help Center')}</a>}
                />
              </Typography2>
            </>
          );
        }}
      </ProductFeaturesQuery>
      <div css={styles.buttons}>
        {ssoProvider && isWhitelistOrganizationProgramsForSSOLogin && <SsoButton provider={ssoProvider} />}
        <Button variant="primary" onClick={handleBrowse} data-e2e="browse-button" css={styles.ssoButton}>
          {_t('Browse Coursera')}
        </Button>
      </div>
    </>
  );
};

export default ProgramDenyModal;
