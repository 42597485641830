/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';

import { Dialog, Typography2, useTheme } from '@coursera/cds-core';
import { SpinnerIcon } from '@coursera/cds-icons';

import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import ProgramActionButtonContainer from 'bundles/enterprise-legacy-learner-home/components/programActionButton/ProgramActionButtonContainer';
import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import useEnterpriseBadgeUnenrollMutation from 'bundles/program-home/queryHooks/useEnterpriseBadgeUnenrollMutation';
import { useEnterpriseUserEnrolledProducts } from 'bundles/unified-home-common/contexts/enterprise/EnterpriseUserEnrolledProductsContext';

import _t from 'i18n!nls/program-home';

type PropsFromCaller = {
  itemId: string;
  programId: string;
  thirdPartyOrganizationId?: string;
  isCourse: boolean;
  itemType: string;
  open: boolean;
  name: string;
  userId: number;
  handleClose: () => void;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

type Props = PropsFromCaller;

const useStyles = () => {
  const theme = useTheme();
  return {
    cancelButton: css`
      margin-left: var(--cds-spacing-300);
    `,
  };
};

const { HeadingGroup, Content, Actions } = Dialog;

export const UnenrollConfirmationModal: React.FC<Props> = ({
  handleClose,
  refetchEnterpriseBadgeCollections,
  itemId,
  programId,
  thirdPartyOrganizationId,
  isCourse,
  itemType,
  open,
  name,
  userId,
}) => {
  const styles = useStyles();
  const { refetchEnterpriseEnrolledProducts } = useEnterpriseUserEnrolledProducts();

  const supportingText = `Unenroll from ${name}`;
  let description = '';
  let trackingName = '';
  let dataE2EString = '';
  let productTypePropertyName = '';
  let cancelButtonName = '';

  switch (itemType) {
    case 'COURSE':
      description = 'course-unenroll-confirmation-dialog-content';
      trackingName = 'unenroll_modal_display';
      dataE2EString = `CourseUnenrollConfirmationModal~${itemId}`;
      productTypePropertyName = 'courseId';
      cancelButtonName = 'unenroll_course_cancel_button';
      break;
    case 'S12N':
      description = 's12n-unenroll-confirmation-dialog-content';
      trackingName = 's12n_unenroll_modal_display';
      dataE2EString = `S12nUnenrollConfirmationModal~${itemId}`;
      productTypePropertyName = 's12nId';
      cancelButtonName = 'unenroll_s12n_cancel_button';
      break;
    case 'BADGE':
      description = 'badge-unenroll-confirmation-dialog-content';
      trackingName = 'badge_unenroll_modal_display';
      dataE2EString = `BadgeUnenrollConfirmationModal~${itemId}`;
      productTypePropertyName = 'badgeId';
      cancelButtonName = 'unenroll_badge_cancel_button';
      break;
    default:
      break;
  }

  const [isEnrolling, setIsEnrolling] = React.useState(false);
  const unenroll = useEnterpriseBadgeUnenrollMutation();

  const handleEnroll = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (isEnrolling) return;

    try {
      setIsEnrolling(true);
      await unenroll({
        user_id: userId,
        badge_template_id: itemId,
        request_origin: 'REQUEST_ORIGIN_USER',
      });

      if (refetchEnterpriseBadgeCollections) {
        refetchEnterpriseBadgeCollections();
      }

      setIsEnrolling(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      handleClose();
    } catch (error) {
      setIsEnrolling(false);
    }
  };

  return (
    <Dialog open={open} aria-describedby={description} width="medium" onClose={handleClose}>
      <HeadingGroup supportText={supportingText}>{_t('Are you sure you want to Unenroll?')}</HeadingGroup>
      <Content>
        <TrackedDiv
          trackingName={trackingName}
          trackClicks={false}
          withVisibilityTracking
          data-e2e={dataE2EString}
          data={{ [productTypePropertyName]: itemId }}
        >
          <Typography2 component="p" variant="bodyPrimary">
            {_t('Unenrolling:')}
          </Typography2>
          {(itemType === 'S12N' || itemType === 'COURSE') && (
            <div>
              <ul>
                <li>
                  <Typography2 component="p" variant="bodyPrimary">
                    {_t("Will not remove any Certificates you've already earned.")}
                  </Typography2>
                </li>
                <li>
                  <Typography2 component="p" variant="bodyPrimary">
                    {_t("Will remove you from courses you've enrolled in but have not completed.")}
                  </Typography2>
                </li>
              </ul>
            </div>
          )}
          {itemType === 'BADGE' && (
            <div>
              <ul>
                <li>
                  <Typography2 component="p" variant="bodyPrimary">
                    {_t("Will not remove any certificates you've already earned.")}
                  </Typography2>
                </li>
              </ul>
            </div>
          )}
        </TrackedDiv>
      </Content>
      <Actions>
        {(itemType === 'S12N' || itemType === 'COURSE') && (
          <ProgramActionButtonContainer
            thirdPartyOrganizationId={thirdPartyOrganizationId}
            onActionSuccess={async () => {
              await refetchEnterpriseEnrolledProducts?.();

              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              handleClose();
            }}
            programId={programId}
            productId={itemId}
            productType={isCourse ? PRODUCT_TYPES.COURSE : PRODUCT_TYPES.SPECIALIZATION}
            actionType="UNENROLL"
            initialBtnLabel={_t('Unenroll')}
            btnAttributes={{
              type: 'primary',
              htmlAttributes: {
                'data-e2e': `ProgramActionButtonContainer~${itemId}`,
              },
            }}
            trackingInfo={{
              trackingData: { [productTypePropertyName]: itemId },
              trackingName: isCourse ? 'unenroll_course_confirm_button' : 'unenroll_s12n_confirm_button',
            }}
          />
        )}
        {itemType === 'BADGE' && (
          <TrackedCdsButton
            variant="primary"
            onClick={handleEnroll}
            iconPosition="before"
            icon={isEnrolling ? <SpinnerIcon size="medium" /> : undefined}
            trackingData={{ [productTypePropertyName]: itemId }}
            trackingName="unenroll_badge_confirm_button"
          >
            {_t('Unenroll')}
          </TrackedCdsButton>
        )}
        <TrackedCdsButton
          variant="secondary"
          onClick={handleClose}
          css={styles.cancelButton}
          trackingData={{ [productTypePropertyName]: itemId }}
          trackingName={cancelButtonName}
        >
          {_t('Cancel')}
        </TrackedCdsButton>
      </Actions>
    </Dialog>
  );
};

export default UnenrollConfirmationModal;
