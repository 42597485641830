/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import URI from 'jsuri';
import { branch, compose } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';

import { Button } from '@coursera/cds-core';

import type { ThirdPartyOrganizations_SsoInformationFragment as OrganizationTypeFromQuery } from 'bundles/authentication/queries/__generated__/UserThirdPartyOrganizationsQuery';
import Separator from 'bundles/authentication/shared/Separator';
import { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import API from 'bundles/phoenix/lib/apiWrapper';
import withThirdPartyOrgNameData from 'bundles/user-account/components/hoc/withThirdPartyOrgNameData';

import _t from 'i18n!nls/user-account';

type PropsFromCaller = {
  provider: string;
  showFooter?: boolean;
  signupMode?: boolean;
  useGeneralSSOLoginText?: boolean;
};

type Organization = Omit<OrganizationTypeFromQuery, 'supportsDegreeSSOLogin'> &
  Partial<Pick<OrganizationTypeFromQuery, 'supportsDegreeSSOLogin'>>;

type Props = PropsFromCaller & { organizations?: (Organization | null)[] };

type State = {
  loginUrl?: string;
};

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
};

class SsoButton extends React.Component<Props, State> {
  state: State = {
    loginUrl: undefined,
  };

  declare _isMounted?: boolean;

  componentDidMount() {
    this._isMounted = true;

    const { organizations } = this.props;
    const organization = organizations?.[0];
    if (organization) {
      const searchParams = new URLSearchParams();
      searchParams.append('action', 'initiateLoginFlow');
      searchParams.append('id', organization.id);
      searchParams.append('returnTo', redirect.getPathname() + redirect.getQueryParams());

      // todo(dguo): switch to naptime mutations when available
      API('/api/thirdPartyOrganizations.v1')
        .post(`?${searchParams.toString()}`)
        .then((res: string) => {
          if (this._isMounted) {
            this.setState({ loginUrl: res });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initiateFlow = () => {
    const { loginUrl } = this.state;
    if (loginUrl) {
      window.location.href = loginUrl;
    }
  };

  renderButton(organization: Organization) {
    const { signupMode, useGeneralSSOLoginText } = this.props;
    const { loginUrl } = this.state;

    if (loginUrl) {
      if (signupMode) {
        return (
          <TrackedCdsButton trackingName="sso_btn_click" onClick={this.initiateFlow} fullWidth>
            <FormattedMessage message={_t('Sign up with {organizationName}')} organizationName={organization.name} />
          </TrackedCdsButton>
        );
      } else {
        // TODO: remove this once our SSO initiateLoginFlow is flexible to support both GET/POST
        // currently this is only for slug=honeywell-test, honeywell
        const FORM_POST_IDS = ['nHKjZ-lRSFmIkfg4BsFSTw', 'gaczNyNBRxqP1TBtQom3Eg'];
        if (FORM_POST_IDS.includes(organization.id)) {
          return this.renderPostForm(organization);
        }

        return (
          <TrackedCdsButton trackingName="sso_btn_click" onClick={this.initiateFlow} fullWidth>
            {useGeneralSSOLoginText ? (
              _t('Log in with SSO')
            ) : (
              <FormattedMessage message={_t('Log in with {organizationName}')} organizationName={organization.name} />
            )}
          </TrackedCdsButton>
        );
      }
    } else if (!organization) {
      return (
        <TrackedCdsButton trackingName="sso_btn_click" onClick={this.initiateFlow} disabled={true} fullWidth>
          {_t('SSO Provider Not Found')}
        </TrackedCdsButton>
      );
    } else {
      return (
        <Button onClick={this.initiateFlow} disabled={true} fullWidth>
          {_t('Loading...')}
        </Button>
      );
    }
  }

  renderPostForm(organization: Organization) {
    const { loginUrl } = this.state;
    const uri = new URI(loginUrl);
    const samlRequest = uri.getQueryParamValue('SAMLRequest');
    const relayState = uri.getQueryParamValue('target');
    const partnerSpId = uri.getQueryParamValue('PartnerSpId');

    uri
      .deleteQueryParam('SAMLRequest')
      .deleteQueryParam('SigAlg')
      .deleteQueryParam('proiderId')
      .deleteQueryParam('shire')
      .deleteQueryParam('target')
      .deleteQueryParam('signature')
      .addQueryParam('PartnerSpId', partnerSpId);

    const formattedUrl = uri.toString();

    return (
      <form method="POST" action={formattedUrl} className="nostyle">
        <input type="hidden" name="SAMLRequest" value={samlRequest} />
        <input type="hidden" name="RelayState" value={relayState} />
        <TrackedCdsButton trackingName="sso_btn_click" fullWidth>
          <FormattedMessage message={_t('Log in with {organizationName}')} organizationName={organization.name} />
        </TrackedCdsButton>
      </form>
    );
  }

  render() {
    const { organizations, showFooter } = this.props;
    const organization = organizations?.[0];

    if (organization) {
      return (
        <div className="c-SsoButton w-100" css={styles.container}>
          {this.renderButton(organization)}
          {showFooter && <Separator text={_t('or')} />}
        </div>
      );
    }
    return null;
  }
}

export default compose<Props, Props>(
  // In some places where this is used, we already have organizations data. If not, fetch it.
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '<T extends Record<string, unknow... Remove this comment to see the full error message
  branch(({ organizations }: Props) => !organizations, withThirdPartyOrgNameData())
)(SsoButton);
