import * as React from 'react';

import { pure } from 'recompose';

import type { ApiStatus, ButtonProps } from '@coursera/coursera-ui';
import { ApiButton, Button, color } from '@coursera/coursera-ui';
import { SvgBookmark } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  btnAttributes?: ButtonProps;
  onClick: () => void;
  apiStatus: ApiStatus;
  canSelect?: boolean;
  isSelected?: boolean;
  renderNothingIfConditionNotMet?: boolean;
  initialBtnLabel?: JSX.Element | string;
};

const S12nSelectButton = ({
  btnAttributes = {},
  onClick,
  apiStatus,
  isSelected,
  canSelect,
  renderNothingIfConditionNotMet,
  initialBtnLabel = _t('Save for Later'),
}: Props) => {
  const renderSelect = !isSelected && canSelect;
  const renderSelected = isSelected && !renderNothingIfConditionNotMet;

  if (renderSelect) {
    return (
      <ApiButton
        {...btnAttributes}
        iconAttributes={{
          suppressTitle: true,
        }}
        onClick={onClick}
        apiStatus={apiStatus}
        apiStatusAttributesConfig={{
          label: {
            API_BEFORE_SEND: initialBtnLabel,
            API_IN_PROGRESS: _t('Saving...'),
            API_SUCCESS: _t('Saved'),
            API_ERROR: _t('Failed'),
          },
        }}
      >
        <SvgBookmark color={color.primary} hoverColor={color.darkPrimary} htmlAttributes={{ 'aria-hidden': true }} />
      </ApiButton>
    );
  } else if (renderSelected) {
    return <Button {...btnAttributes} type="disabled" label={_t('Selected')} disabled={true} />;
  }
  return null;
};

export default pure(S12nSelectButton);
