/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useRef, useState } from 'react';

import classNames from 'classnames';

import { MenuList } from '@coursera/cds-core';
import { ChevronNextIcon } from '@coursera/cds-icons';

import type { MenuProps } from './Menu';
import { MenuContext } from './utils';

type MenuItemProps = {
  label: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  autoFocus?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
  children?: (props: MenuProps) => React.ReactNode;
};

const menuItemClasses = {
  activeSubMenu: 'cds-menu-item-active-sub-menu',
  selectedChildren: 'cds-menu-item-selected-sub-item',
  icon: 'cds-menu-item-icon',
};

const styles = css`
  &.${menuItemClasses.activeSubMenu}, &.${menuItemClasses.selectedChildren} {
    background-color: var(--cds-color-interactive-background-primary-hover-weak);
  }

  .${menuItemClasses.icon} {
    align-self: center;
  }
`;

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { label, children, onClick, selected = false, className = 'cds-menu-item', disabled, ...rest } = props;
  const { className: menuClassName, container, onClose } = useContext(MenuContext);
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(selected);

  const hasSubMenu = !!children;

  const hasActiveMenu = hasSubMenu && open;

  const hasSelectedChildren = hasSubMenu && selected;

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }

    if (!hasSubMenu) {
      onClose(event, 'click');
    } else {
      setOpen(true);
    }
  };

  const handleMouseEnter = () => {
    if (hasSubMenu) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (hasSubMenu) {
      setOpen(false);
    }
  };

  const subMenuProps: Omit<MenuProps, 'children'> = {
    open,
    onClose: () => setOpen(false),
    anchorEl: ref.current || undefined,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    // 16px(padding) + 4px(gap)
    offset: { x: '20px' },

    css: css`
      pointer-events: none;
    `,
    className: menuClassName,
    container,
  };

  return (
    // @ts-expect-error -- use onFocusVisible to prevent focus visible being propagated to the parent menu item
    <MenuList.Item
      ref={ref}
      role={hasSubMenu ? 'menuitem' : 'menuitemradio'}
      checked={selected}
      aria-haspopup={hasSubMenu ? 'menu' : undefined}
      aria-expanded={hasSubMenu ? open || false : undefined}
      css={styles}
      className={classNames(className, {
        [menuItemClasses.activeSubMenu]: hasActiveMenu,
        [menuItemClasses.selectedChildren]: hasSelectedChildren,
      })}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
      // Because we render menu inside menu item
      onFocusVisible={(e: $TSFixMe) => e.stopPropagation()}
      suffix={
        hasSubMenu ? (
          <div className={menuItemClasses.icon}>
            <ChevronNextIcon display="block" size="small" />
          </div>
        ) : undefined
      }
      {...rest}
    >
      {label}
      {children && children(subMenuProps)}
    </MenuList.Item>
  );
};

export default MenuItem;
