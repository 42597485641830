import { getIsCourseraLiteLandingPageEnabled } from 'bundles/coursera-plus-landing-page/contentful/constants';
import type { DayValue } from 'bundles/coursera-plus-landing-page/contentful/dayValue';
import { dayValueFromDateString, isDayPassed } from 'bundles/coursera-plus-landing-page/contentful/dayValue';
import type { LandingPagePicked } from 'bundles/coursera-plus-landing-page/contentful/fetchContentful';
import type {
  PropsToLandingPage,
  SubscriptionTiersProductPrice,
} from 'bundles/coursera-plus-landing-page/contentfulData/LandingPageDataProvider';
import type { LandingPageExtended } from 'bundles/coursera-plus-landing-page/contentfulData/PageContext';
import {
  removePurchaseTrackerCookie,
  setPurchaseTrackerCookie,
} from 'bundles/coursera-plus-landing-page/purchaseTracker/purchaseTrackerCookies';
import type { SubscriptionTiersProductVariant } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import type SubscriptionTrialsV1 from 'bundles/naptimejs/resources/subscriptionTrials.v1';

export const PAGE_EXPIRY_ERROR = 'Page expired';

class LandingPageController {
  declare landingPageExtended: LandingPageExtended;

  private static doesUserHaveFreeTrial = (
    subscriptionTrials: Array<SubscriptionTrialsV1>,
    isMockNoFreeTrial: boolean
  ): boolean => {
    if (isMockNoFreeTrial) {
      return false;
    }
    if (Array.isArray(subscriptionTrials) && subscriptionTrials.length > 0) {
      return false;
    }
    return true;
  };

  private static getPriceFromBackendResult = (
    productId: SubscriptionTiersProductVariant,
    priceResults?: SubscriptionTiersProductPrice[]
  ): SubscriptionTiersProductPrice => {
    if (!Array.isArray(priceResults)) {
      throw new Error('something is wrong with the prices');
    }
    const priceResult = priceResults.find((result) => result.productItemId === productId);
    if (!priceResult) {
      throw new Error('fishy price');
    }
    return { ...priceResult, amount: Number(priceResult.formattedFinalAmount.amount) } as SubscriptionTiersProductPrice;
  };

  private static getSecondaryPrice = (
    secondaryId?: SubscriptionTiersProductVariant,
    retrievedPrices?: SubscriptionTiersProductPrice[]
  ): { secondaryPrice?: SubscriptionTiersProductPrice } => {
    if (!secondaryId) {
      return { secondaryPrice: undefined };
    }
    return {
      secondaryPrice: LandingPageController.getPriceFromBackendResult(secondaryId, retrievedPrices),
    };
  };

  private static getPrimaryPrice = (
    primaryId: SubscriptionTiersProductVariant,
    retrievedPromotion?: SubscriptionTiersProductPrice,
    retrievedPrices?: SubscriptionTiersProductPrice[]
  ): { primaryPrice: SubscriptionTiersProductPrice; primaryOriginalPrice: SubscriptionTiersProductPrice } => {
    if (retrievedPromotion) {
      return {
        primaryPrice: {
          ...retrievedPromotion,
          amount: Number(retrievedPromotion.formattedFinalAmount.amount),
        } as SubscriptionTiersProductPrice,
        primaryOriginalPrice: {
          ...retrievedPromotion,
          amount: Number(retrievedPromotion.amount),
        } as SubscriptionTiersProductPrice,
      };
    }

    const priceFromBackend = LandingPageController.getPriceFromBackendResult(primaryId, retrievedPrices);
    return { primaryPrice: priceFromBackend, primaryOriginalPrice: priceFromBackend };
  };

  private static getPrices = (
    retrievedPromotion: SubscriptionTiersProductPrice | undefined,
    retrievedPrices: SubscriptionTiersProductPrice[] | undefined,
    primaryId: SubscriptionTiersProductVariant,
    secondaryId?: SubscriptionTiersProductVariant
  ): {
    primaryPrice: SubscriptionTiersProductPrice;
    primaryOriginalPrice: SubscriptionTiersProductPrice;
    secondaryPrice?: SubscriptionTiersProductPrice;
  } => {
    const { primaryPrice, primaryOriginalPrice } = LandingPageController.getPrimaryPrice(
      primaryId,
      retrievedPromotion,
      retrievedPrices
    );
    const { secondaryPrice } = LandingPageController.getSecondaryPrice(secondaryId, retrievedPrices);
    return { primaryPrice, primaryOriginalPrice, secondaryPrice };
  };

  static getCheckedContext = (props: PropsToLandingPage, pageExpiry?: DayValue): LandingPageExtended => {
    const {
      landingPage,
      primaryId,
      secondaryId,
      subscriptionTrials,
      liteTierFreeTrials,
      isMockNoFreeTrial,
      ownsCourseraPlus,
      ownsCourseraLite,
      promotion,
      prices,
      redirectedFromExpired,
      openCourseMemberships,
      existingCourseraLiteSubscription,
      hideRegionalPromotionBanner,
    } = props;

    const { promotionCode, activatePurchaseTracker, isCourseraLiteLandingPage } = landingPage;

    if (!primaryId) {
      throw new Error('Please select at least one Coursera Plus product for this page.');
    }

    if (primaryId === secondaryId) {
      throw new Error('Primary and secondary products cannot be the same');
    }

    removePurchaseTrackerCookie();

    let promotionRetrieved: SubscriptionTiersProductPrice | undefined;
    if (promotionCode) {
      const missingPromotionMessage = `Missing promotion for promotion code "${promotionCode}"`;
      if (!promotion) {
        throw new Error(missingPromotionMessage);
      }
      promotionRetrieved = promotion[0];
      if (!promotionRetrieved) {
        throw new Error(missingPromotionMessage);
      }
      const { promotionInfo, amount, formattedFinalAmount, currencyCode } = promotionRetrieved;
      // This is kind of raw due to the hastily created endpoint; more refined error messages expected after
      // https://coursera.atlassian.net/browse/CON-4581
      // so for now we check for the validity with making sure that the discounted price
      // is different from the undiscounted one

      if (!promotionInfo || Number(amount) === Number(formattedFinalAmount.amount)) {
        throw new Error(
          `The discount has not been applied for the currency ${currencyCode} with the promotion code "${promotionCode}". This may be because the promotion is not yet live in Promo Tools (it has a future start date) or discount amounts for ${currencyCode} have not been added`
        );
      }
      if (activatePurchaseTracker) {
        setPurchaseTrackerCookie(promotionCode);
      }
    }
    const contextPrices = LandingPageController.getPrices(promotionRetrieved, prices, primaryId, secondaryId);
    const isCourseraLiteLandingPageEnabled =
      isCourseraLiteLandingPage && getIsCourseraLiteLandingPageEnabled(openCourseMemberships);
    const { customProductCollectionAtTheTop, customProductCollectionAtBottom } = landingPage ?? {};

    return {
      landingPage: landingPage as LandingPagePicked,
      expiry: pageExpiry,
      primaryProductItemId: primaryId,
      secondaryProductItemId: secondaryId,
      userHasCourseraPlusFreeTrial: LandingPageController.doesUserHaveFreeTrial(
        subscriptionTrials,
        !!isMockNoFreeTrial
      ),
      userHasCourseraLiteFreeTrial: LandingPageController.doesUserHaveFreeTrial(
        liteTierFreeTrials,
        !!isMockNoFreeTrial
      ),
      isCourseraPlusOwner: !!ownsCourseraPlus,
      isCourseraLiteOwner: !!ownsCourseraLite,
      prices: contextPrices,
      redirectedFromExpired,
      // isCourseraLiteContentfulPage is the value straight from the Contentful query
      isCourseraLiteContentfulPage: Boolean(isCourseraLiteLandingPage),
      // isCourseraLiteLandingPageEnabled includes all eligibility checks for landing page
      isCourseraLiteLandingPageEnabled: Boolean(isCourseraLiteLandingPageEnabled),
      landingPageType: landingPage.landingPageType,
      customProductCollectionAtTheTop: customProductCollectionAtTheTop || undefined,
      customProductCollectionAtBottom: customProductCollectionAtBottom || undefined,
      existingCourseraLiteSubscription,
      hideRegionalPromotionBanner,
    };
  };

  constructor(rawData: PropsToLandingPage) {
    const { landingPage } = rawData;
    const { pageExpiry } = landingPage;

    const expiry = pageExpiry ? dayValueFromDateString(pageExpiry) : undefined;
    if (expiry && isDayPassed(expiry)) {
      throw new Error(PAGE_EXPIRY_ERROR);
    }
    this.landingPageExtended = LandingPageController.getCheckedContext(rawData);
  }
}

export default LandingPageController;
