/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useQuery } from '@apollo/client';

import user from 'js/lib/user';

import { Typography2 } from '@coursera/cds-core';

import Collection from 'bundles/enterprise-collections/components/Collection';
import CollectionTitle from 'bundles/enterprise-collections/components/CollectionTitle';
import { Heading } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
import CatalogSection from 'bundles/enterprise-legacy-learner-home/components/single-program/CatalogSection';
import { CollectionsView } from 'bundles/enterprise-legacy-learner-home/components/single-program/CuratedCollections';
import AssignedContentQuery from 'bundles/program-home/queries/AssignedContent.graphql';
import type {
  AssignedContentQueryVariables,
  AssignedContentQuery as AssignedContentType,
} from 'bundles/program-home/queries/__generated__/AssignedContent';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

const styles = {
  description: css`
    margin-top: var(--cds-spacing-200);
  `,
};

type Props = {
  programId: string;
  headingLevel: number;
  onProductCardClick: OnProductCardClick;
  isSingleProgram?: boolean;
};

const ConditionalCatalogSectionWrapper: React.FC<{ isSingleProgram: boolean | undefined; children: JSX.Element }> = ({
  isSingleProgram,
  children,
}) => (isSingleProgram ? <CatalogSection>{children}</CatalogSection> : children);

export const AssignedCourses: React.FC<Props> = ({ programId, isSingleProgram, headingLevel, onProductCardClick }) => {
  const { data, loading } = useQuery<AssignedContentType, AssignedContentQueryVariables>(AssignedContentQuery, {
    variables: {
      userId: String(user.get().id),
      programIds: [programId],
      limit: 100,
    },
    ssr: false,
    context: { clientName: 'gatewayGql' },
    skip: !user.get().id,
  });

  if (loading) {
    return (
      <CatalogSection>
        <CollectionsView.Placeholder length={1} />
      </CatalogSection>
    );
  }
  const assignments = data?.EnterpriseContentAssignment?.getAssignmentsByUserAndPrograms?.elements;

  if (!assignments || assignments.length === 0) {
    return null;
  }

  return (
    <ConditionalCatalogSectionWrapper isSingleProgram={isSingleProgram}>
      <div>
        <Heading defaultLevel={headingLevel} className="Collection-Title">
          <CollectionTitle title={_t('Assigned to you')} />
        </Heading>
        <Typography2 component="p" css={styles.description}>
          {_t('The following content has been assigned to you by your organization')}
        </Typography2>
        <Collection
          id="assigned-courses"
          collection={{
            id: 'assigned-courses',
            description: {},
            s12ns: {
              elements: assignments.map(({ content }) => {
                if (content.__typename === 'Specialization_Specialization') {
                  const { id, name, partners, cardImageUrl } = content;
                  return {
                    id,
                    name,
                    promoPhoto: cardImageUrl,
                    partners: { elements: partners },
                  };
                }
                return null;
              }),
            },
            title: '',
            courses: {
              elements: assignments.map(({ content }) => {
                if (content.__typename === 'Course_Course') {
                  const { id, name, partners, promoPhotoUrl } = content;
                  return {
                    id,
                    name,
                    promoPhoto: promoPhotoUrl,
                    partners: { elements: partners },
                  };
                }
                return null;
              }),
            },
          }}
          onProductCardClick={onProductCardClick}
          disableDescription={true}
          productCardTrackingName="assigned_product_card"
        />
      </div>
    </ConditionalCatalogSectionWrapper>
  );
};

export default AssignedCourses;
