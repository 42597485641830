import * as React from 'react';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type SvgGuidedProjectsProps = React.SVGProps<SVGSVGElement>;

const SvgGuidedProjectsRtl: React.FC<SvgGuidedProjectsProps> = (props) => (
  <svg width={60} height={62} viewBox="0 0 60 62" role="presentation" {...props}>
    <title id="SvgGuidedProjectsRtl-Title">{_t('Guided projects')}</title>
    <defs>
      <path
        d="M3 32.25h36V9.75H3v22.5zm17-1.5h17.5v-18.5H20v18.5zm3-13.5h11.5v-1.5H23v1.5zm4 4h7.5v-1.5H27v1.5zm-8.5-9v18.5h-14v-18.5h14zM8.18 17.634v6.876l5.446-3.438-5.446-3.438z"
        id="SvgGuidedProjectsRtl_a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32.413 1.614L58.14 18.177a4 4 0 011.81 3.813h.037v19.022h-.107a4 4 0 01-1.74 2.492L31.918 60.386a4 4 0 01-4.33 0L1.858 43.823a3.99 3.99 0 01-1.796-2.81H.012V21.988h.014a4 4 0 011.833-3.494l26.223-16.88a4 4 0 014.33 0z"
        fillOpacity=".1"
        fill="#2A73CC"
      />
      <g opacity=".75" transform="translate(9 10)">
        <mask id="SvgGuidedProjectsRtl_b" fill="#fff">
          <use xlinkHref="#SvgGuidedProjectsRtl_a" />
        </mask>
        <use fill="#000" xlinkHref="#SvgGuidedProjectsRtl_a" />
        <g mask="url(#SvgGuidedProjectsRtl_b)">
          <path fill="#1F1F1F" d="M3 3h36v36H3z" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgGuidedProjectsRtl;
