/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography, Typography2, breakpoints, useTheme } from '@coursera/cds-core';

import { CML } from 'bundles/cml';
import type { EnterpriseProductConfiguration, Partner } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import { getNaptimeCMLShape } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import FixedSchedule from 'bundles/enterprise-legacy-xdp/components/FixedSchedule';
import type { ProductGlanceList } from 'bundles/enterprise-legacy-xdp/components/cds/ProductGlance';
import ProductGlance from 'bundles/enterprise-legacy-xdp/components/cds/ProductGlance';
import AboutProject from 'bundles/enterprise-legacy-xdp/components/miniPDP/AboutProject';
import { LEVEL_TO_ICON } from 'bundles/enterprise-legacy-xdp/components/miniPDP/BannerInfoBox';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import PdpSkills from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSkills';
import type { AboutProject as AboutProjectType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/AboutProject';
import type { Skills as PdpSkillsType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/Skills';
import type { BannerInfoBox_cmlRecommendedBackground as BannerInfoBoxType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/BannerInfoBox';
import type { org_coursera_ondemand_common_DifficultyLevel as DifficultyLevel } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/globalTypes';
import LearnerIncentive, { shouldShowLearnerIncentive } from 'bundles/learner-incentives/components/LearnerIncentive';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const sectionStyles = () => css`
  padding-top: var(--cds-spacing-600);
  padding-bottom: 0;

  ${breakpoints.down('lg')} {
    padding-top: var(--cds-spacing-300);
    padding-bottom: 0;
  }
`;

const styles = {
  container: css`
    margin: 0 auto;
    max-width: 1152px;
  `,
  learnerIncentive: () => css`
    margin-top: var(--cds-spacing-400);
    margin-bottom: var(--cds-spacing-300);
  `,
};

type Props = Omit<AboutProjectType, '__typename'> &
  Omit<PdpSkillsType, '__typename'> & {
    canEnrollWithFullDiscount?: boolean;
    cmlRecommendedBackground?: BannerInfoBoxType | null;
    level?: DifficultyLevel | null;
    learnerIncentives: Array<LearnerIncentiveType>;
    partners: Array<Partner>;
    privateSessionDates?: { startsAt: number; endsAt: number };
    enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  };

const AboutProjectWrapper: React.FunctionComponent<Props> = ({
  description,
  cmlRecommendedBackground,
  canEnrollWithFullDiscount,
  skills,
  level,
  learnerIncentives,
  partners,
  privateSessionDates,
  enterpriseProductConfiguration,
}) => {
  const shouldShowRecommendedBackground = cmlRecommendedBackground && canEnrollWithFullDiscount && level;
  let recommendedBackground: ProductGlanceList = [];

  const theme = useTheme();

  if (cmlRecommendedBackground && level) {
    recommendedBackground = [
      {
        title: _t('Requirements'),
        subtitle: <CML cml={getNaptimeCMLShape(cmlRecommendedBackground?.cml)} />,
        icon: LEVEL_TO_ICON[level],
      },
    ];
  }

  return (
    <PdpSection css={sectionStyles}>
      <div
        css={css`
          margin-top: calc(var(--cds-spacing-300) * -1);
          margin-left: var(--cds-spacing-150);
        `}
      >
        {privateSessionDates && enterpriseProductConfiguration?.isSelectedForCredit && (
          <Grid container spacing={24} css={styles.container}>
            <Grid item md={8} lg={9}>
              {privateSessionDates && (
                <div className="m-b-3 p-t-1s">
                  <FixedSchedule startsAt={privateSessionDates.startsAt} endsAt={privateSessionDates.endsAt} />
                </div>
              )}
            </Grid>
            <Grid item md={4} lg={3}>
              {enterpriseProductConfiguration?.isSelectedForCredit && (
                <div>
                  <Typography component="h2" variant="h2semibold">
                    {_t('Offered For Credit')}
                  </Typography>
                  <Typography2 component="p" variant="bodyPrimary">
                    {_t('Your institution will award credit for completing this guided project.')}
                  </Typography2>
                </div>
              )}
            </Grid>
          </Grid>
        )}
        <Grid css={styles.container} container spacing={24}>
          <Grid item md={8} lg={9}>
            {/* The container styling only makes sense in the context of this particular layout,
              so it's not part of the <AboutProject> styling */}
            <div>
              <AboutProject description={description} />
              {shouldShowLearnerIncentive(learnerIncentives) && (
                <div css={styles.learnerIncentive}>
                  <LearnerIncentive learnerIncentives={learnerIncentives} additionalInfo={{ partner: partners[0] }} />
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={4} lg={3}>
            {shouldShowRecommendedBackground && <ProductGlance dataList={recommendedBackground} />}
            <PdpSkills skills={skills} />
          </Grid>
        </Grid>
      </div>
    </PdpSection>
  );
};

export default AboutProjectWrapper;
