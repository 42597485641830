import * as React from 'react';

import { SvgEye } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  slug: string;
};

const StaffPreviewLink = (props: Props) => {
  const { slug } = props;

  const courseHomePath = `/learn/${slug}/home/welcome`;

  return (
    <div className="rc-StaffPreviewLink admin-banner-link">
      <div className="staff-link body-1-text">
        <a href={courseHomePath} className="primary horizontal-box">
          <SvgEye size={24} color="#2a73cc" />
          <span>{_t('Preview Course Materials')}</span>
        </a>
      </div>
    </div>
  );
};

export default StaffPreviewLink;
