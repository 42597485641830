import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('checkboxAndRadio', [
  'label',
  'icon',
  'input',
  'checked',
  'indeterminate',
  'disabled',
  'labelText',
  'labelContent',
  'labelSuffix',
  'supportText',
  'focusVisible',
  'hasIcon',
  'success',
  'error',
  'nonInteractiveSpace',
]);

const getRadioAndCheckboxCss = css`
  --support-text-color: var(--cds-color-neutral-primary-weak);
  --support-text-offset: calc(var(--cds-spacing-300) + var(--cds-spacing-150));
  --input-choice-fill: var(--cds-color-neutral-background-primary);
  --input-choice-fill--hover: var(
    --cds-color-interactive-background-primary-hover-weak
  );
  --input-choice-fill--active: var(
    --cds-color-interactive-background-primary-pressed-weak
  );
  --input-choice-color: var(--cds-color-neutral-stroke-primary);
  --input-choice-color--hover: var(--cds-color-interactive-primary-hover);
  --input-choice-color--active: var(--cds-color-interactive-primary-pressed);
  --label-text-color: var(--cds-color-neutral-primary);
  --label-text-color--hover: var(--cds-color-interactive-primary-hover);
  --label-text-color--active: var(--cds-color-interactive-primary-pressed);

  .${classes.label} {
    display: flex;
    align-items: flex-start;
    gap: var(--cds-spacing-150);
    padding: var(--cds-spacing-100) 0;
    cursor: pointer;
    word-break: break-word;

    .${classes.input} {
      --input-fill: var(--input-choice-fill);
      --input-color: var(--input-choice-color);
    }

    /* Interactive states  */
    &:hover,
    &:has(.${classes.focusVisible}) {
      --label-text-color: var(--label-text-color--hover);
      --input-choice-fill: var(--input-choice-fill--hover);
      --input-choice-color: var(--input-choice-color--hover);

      .${classes.input} {
        --input-fill: var(--input-choice-fill);
        --input-color: var(--input-choice-color);
      }
    }

    &:active {
      --label-text-color: var(--label-text-color--active);
      --input-choice-fill: var(--input-choice-fill--active);
      --input-choice-color: var(--input-choice-color--active);

      .${classes.input} {
        --input-fill: var(--input-choice-fill);
        --input-color: var(--input-choice-color);
      }
    }
  }

  .${classes.icon} {
    width: 24px;
    height: 24px;
  }

  .${classes.labelText} {
    display: flex;
    width: 100%;
    color: var(--label-text-color);
  }

  .${classes.labelContent} {
    flex: 1;
  }

  .${classes.labelSuffix} {
    padding-left: var(--cds-spacing-100);
    margin-left: auto;
    white-space: nowrap;
  }

  .${classes.supportText} {
    margin-top: calc(var(--cds-spacing-50) * -1);
    margin-bottom: var(--cds-spacing-100);
    margin-left: var(--support-text-offset);
    color: var(--support-text-color);

    &.${classes.hasIcon} {
      margin-left: calc(var(--support-text-offset) * 2);
    }
  }

  .${classes.nonInteractiveSpace} {
    margin-bottom: var(--cds-spacing-100);
    margin-left: calc(var(--cds-spacing-300) + var(--cds-spacing-150));
  }

  /* Checked/Indeterminate state */
  &.${classes.checked}, &.${classes.indeterminate} {
    --label-text-color: var(--cds-color-interactive-primary);
    --label-text-color--hover: var(--cds-color-interactive-primary-hover);
    --input-choice-color: var(--cds-color-interactive-primary);
    --input-choice-fill: var(--cds-color-interactive-primary);
    --input-choice-fill--hover: var(--cds-color-interactive-primary-hover);
    --input-choice-color--hover: var(--cds-color-interactive-primary-hover);
    --input-choice-fill--active: var(--cds-color-interactive-primary-pressed);
    --input-choice-color--active: var(--cds-color-interactive-primary-pressed);
  }

  /* Success state */
  &.${classes.success} {
    --label-text-color: var(--cds-color-feedback-success);
    --label-text-color--active: var(--cds-color-feedback-success-hover);
    --label-text-color--hover: var(--cds-color-feedback-success-hover);
    --input-choice-color: var(--cds-color-feedback-success);
    --input-choice-fill--hover: var(
      --cds-color-feedback-background-success-weak
    );
    --input-choice-color--hover: var(--cds-color-feedback-success-hover);
    --input-choice-fill--active: var(
      --cds-color-feedback-background-success-weak
    );
    --input-choice-color--active: var(--cds-color-feedback-success-hover);

    /* Checked/Indeterminate state */
    &.${classes.checked}, &.${classes.indeterminate} {
      --input-choice-fill: var(--cds-color-feedback-success);
      --input-choice-fill--hover: var(--cds-color-feedback-success-hover);
      --input-choice-fill--active: var(--cds-color-feedback-success-hover);
    }
  }

  /* Error state */
  &.${classes.error} {
    --label-text-color: var(--cds-color-feedback-error);
    --label-text-color--active: var(--cds-color-feedback-error-hover);
    --label-text-color--hover: var(--cds-color-feedback-error-hover);
    --input-choice-color: var(--cds-color-feedback-error);
    --input-choice-fill--hover: var(--cds-color-feedback-background-error-weak);
    --input-choice-color--hover: var(--cds-color-feedback-error-hover);
    --input-choice-fill--active: var(
      --cds-color-feedback-background-error-weak
    );
    --input-choice-color--active: var(--cds-color-feedback-error-hover);

    /* Checked/Indeterminate state */
    &.${classes.checked}, &.${classes.indeterminate} {
      --input-choice-fill: var(--cds-color-feedback-error);
      --input-choice-fill--hover: var(--cds-color-feedback-error-hover);
      --input-choice-fill--active: var(--cds-color-feedback-error-hover);
    }
  }

  /* Disabled state */
  &.${classes.disabled} {
    --support-text-color: var(--cds-color-neutral-disabled-strong);
    --label-text-color: var(--cds-color-neutral-disabled-strong);
    --label-text-color--active: var(--cds-color-neutral-disabled-strong);
    --label-text-color--hover: var(--cds-color-neutral-disabled-strong);
    --input-choice-color: var(--cds-color-neutral-disabled-strong);
    --input-choice-color--hover: var(--cds-color-neutral-disabled-strong);
    --input-choice-color--active: var(--cds-color-neutral-disabled-strong);
    --input-choice-fill: var(--cds-color-neutral-disabled);
    --input-choice-fill--hover: var(--cds-color-neutral-disabled);
    --input-choice-fill--active: var(--cds-color-neutral-disabled);

    /* Checked/Indeterminate state */
    &.${classes.checked}, &.${classes.indeterminate} {
      --input-choice-fill: var(--cds-color-neutral-disabled-strong);
      --input-choice-fill--hover: var(--cds-color-neutral-disabled-strong);
      --input-choice-fill--active: var(--cds-color-neutral-disabled-strong);
    }

    /* Default state */
    .${classes.label} {
      cursor: default;
    }
  }
`;

export default getRadioAndCheckboxCss;
