import * as React from 'react';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type SvgGuidedProjectsProps = React.SVGProps<SVGSVGElement>;

const SvgGuidedProjects: React.FC<SvgGuidedProjectsProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="60"
    height="62"
    viewBox="0 0 60 62"
    role="presentation"
    {...props}
  >
    <title id="SvgGuidedProjects-Title">{_t('Guided projects')}</title>
    <defs>
      <path
        id="SvgGuidedProjects_a"
        d="M3 32.25h36V9.75H3zm17-1.5h17.5v-18.5H20zm3-13.5h11.5v-1.5H23zm4 4h7.5v-1.5H27zm-8.5-9v18.5h-14v-18.5zm-4.874 5.384L8.18 21.072l5.446 3.438z"
      />
      <mask id="SvgGuidedProjects_b" fill="#fff">
        <use fillRule="evenodd" xlinkHref="#SvgGuidedProjects_a" />
      </mask>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
      <rect width="60" height="60" opacity="0.8" rx="15" />
      <path
        fill="#2a73cc"
        fillOpacity="0.1"
        d="M32.413.614L58.14 17.177a4 4 0 011.81 3.813h.037v19.022h-.107a4 4 0 01-1.74 2.492L31.918 59.386a4 4 0 01-4.33 0L1.858 42.823a3.99 3.99 0 01-1.796-2.81H.012V20.988h.014a4 4 0 011.833-3.494L28.082.616a4 4 0 014.33 0z"
      />
      <g opacity="0.75" transform="matrix(-1 0 0 1 51 9)">
        <use fill="#000" xlinkHref="#SvgGuidedProjects_a" />
        <g fill="#1f1f1f" mask="url(#SvgGuidedProjects_b)">
          <path d="M3 3h36v36H3z" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgGuidedProjects;
