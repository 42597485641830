import { graphql, withApollo } from 'react-apollo';

import type ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import user from 'js/lib/user';

import { withProductFeatures } from 'bundles/content-curation/features';
import type {
  ProgramProductEnterpriseMetadataMultiGetQuery_ProgramProductMetadataV1Resource_multiGet_elements_metadataType_ProgramProductMetadataV1_courseMember as CourseMember,
  ProgramProductEnterpriseMetadataMultiGetQuery as ProgramProductEnterpriseMetadataMultiGetQueryData,
  ProgramProductEnterpriseMetadataMultiGetQueryVariables,
  ProgramProductEnterpriseMetadataMultiGetQuery_ProgramProductMetadataV1Resource_multiGet_elements_metadataType_ProgramProductMetadataV1_specializationMember as S12nMember,
} from 'bundles/enterprise-legacy-learner-home/components/__generated__/ProgramProductEnterpriseMetadataMultiGetQuery';
import { IdTypes } from 'bundles/product-features';

type PropsFromCaller = {
  programId: string;
  products: { id: string; offeringType: string }[];
  client: ApolloClient<any>;
  thirdPartyOrganizationId: string;
};

type PropsFromWithProductFeatures = {
  allowOrgForCurriculumBuilder?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

export type EnterpriseProductMetadataFlags = {
  courseId?: string;
  s12nId?: string;
  hasMultipleOfferings?: boolean;
  isExclusive?: boolean;
  isRecommendedForCredit?: boolean;
  isSelectedForCredit?: boolean;
};

export const LOCAL_ENTERPRISE_PRODUCT_METADATA_TYPENAME = 'LocalEnterpriseProductMetadata';

export type PrivateSession = { productId: string; startsAt: number; endsAt: number };

export type Props = {
  enterpriseProductMetadata?: (EnterpriseProductMetadataFlags | null)[];
  privateSessions?: (PrivateSession | null)[];
};

export const ProgramCourseMetadataFragment = gql`
  fragment ProgramCourseMetadataFragment on ProgramProductMetadataV1_org_coursera_enterprise_curriculumbuilder_ProgramCourseMetadata {
    isExclusive
    isSelectedForCredit
    courseId
  }
`;

export const ProgramSpecializationMetadataFragment = gql`
  fragment ProgramSpecializationMetadataFragment on ProgramProductMetadataV1_org_coursera_enterprise_curriculumbuilder_ProgramSpecializationMetadata {
    isSelectedForCredit
    s12nId
  }
`;

export const ProgramProductEnterpriseMetadataMultiGetQuery = gql`
  query ProgramProductEnterpriseMetadataMultiGetQuery(
    $formattedProductIds: [String!]!
    $productIds: [String!]!
    $skipS12nMetadata: Boolean!
    $programId: String!
  ) {
    ProgramProductMetadataV1Resource {
      multiGet(ids: $formattedProductIds) {
        elements {
          id
          metadataType {
            ... on ProgramProductMetadataV1_courseMember {
              course {
                isExclusive
                isSelectedForCredit
                courseId
              }
            }

            ... on ProgramProductMetadataV1_specializationMember {
              specialization {
                s12nId
                isSelectedForCredit
                associatedSessionId
              }
            }
          }
        }
      }
    }

    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramAndCourses(courseIds: $productIds, programId: $programId) {
        elements {
          id
          courseId
          session {
            id
            startsAt
            endsAt
          }
        }
      }
    }

    EnterpriseProgramSessionAssociationsV1Resource {
      byProgramsAndSpecializations(s12nIds: $productIds, programIds: [$programId]) @skip(if: $skipS12nMetadata) {
        elements {
          id
          baseS12nId
          session {
            id
            startsAt
            endsAt
          }
        }
      }
    }
  }
`;

const withEnterpriseProgramMetadata = compose<Props, PropsFromCaller>(
  withApollo,
  withProductFeatures<PropsFromWithProductFeatures, PropsFromCaller>(
    ({ thirdPartyOrganizationId }) => ({ idType: IdTypes.Organization, id: thirdPartyOrganizationId }),
    ({ features }) => ({
      allowOrgForCurriculumBuilder: features.get('enterprise', 'allowOrgForCurriculumBuilder'),
      allowOrgForSpecializationConfiguration: features.get('enterprise', 'allowOrgForSpecializationConfiguration'),
    })
  ),
  graphql<
    PropsFromCaller & PropsFromWithProductFeatures,
    ProgramProductEnterpriseMetadataMultiGetQueryData,
    ProgramProductEnterpriseMetadataMultiGetQueryVariables,
    Props
  >(ProgramProductEnterpriseMetadataMultiGetQuery, {
    skip: ({ products, allowOrgForCurriculumBuilder }) =>
      !products || !allowOrgForCurriculumBuilder || !user?.get()?.id,
    options: ({ programId, products, allowOrgForSpecializationConfiguration }) => {
      const formattedProductIds = products.reduce((acc, product) => {
        if (product.offeringType === 'COURSE') {
          return [`${programId}~VerifiedCertificate~${product.id}`, ...acc];
        } else if (
          allowOrgForSpecializationConfiguration &&
          (product.offeringType === 'SPECIALIZATION' || product.offeringType === 'PROFESSIONAL CERTIFICATE')
        ) {
          return [`${programId}~Specialization~${product.id}`, ...acc];
        } else {
          return acc;
        }
      }, [] as string[]);
      return {
        variables: {
          formattedProductIds,
          productIds: products.map((product) => product.id),
          skipS12nMetadata: !allowOrgForSpecializationConfiguration,
          programId,
        },
      };
    },
    props: ({ data, ownProps }) => {
      const { client, allowOrgForSpecializationConfiguration } = ownProps;
      const coursePrivateSessions: (PrivateSession | null)[] =
        data?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramAndCourses?.elements?.map((session) =>
          session && session.session
            ? { productId: session.courseId, startsAt: session.session?.startsAt, endsAt: session.session?.endsAt }
            : null
        ) ?? [];

      const s12nPrivateSessions: (PrivateSession | null)[] =
        data?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramsAndSpecializations?.elements?.map((session) =>
          session && session.baseS12nId && session.session
            ? { productId: session.baseS12nId, startsAt: session.session.startsAt, endsAt: session.session.endsAt }
            : null
        ) ?? [];

      const privateSessions = coursePrivateSessions.concat(s12nPrivateSessions);
      const programProductMetadata = data?.ProgramProductMetadataV1Resource?.multiGet?.elements;

      const enterpriseProductMetadata = programProductMetadata?.map((metadata) => {
        const productMetadata = metadata?.metadataType;
        if (!productMetadata || !metadata?.id) return null;

        if ('course' in productMetadata && productMetadata.course) {
          client?.writeFragment({
            id: `${LOCAL_ENTERPRISE_PRODUCT_METADATA_TYPENAME}:${metadata.id}`,
            fragment: ProgramCourseMetadataFragment,
            data: productMetadata.course,
          });

          return (productMetadata as CourseMember)?.course;
        } else if (
          allowOrgForSpecializationConfiguration &&
          'specialization' in productMetadata &&
          productMetadata.specialization
        ) {
          client?.writeFragment({
            id: `${LOCAL_ENTERPRISE_PRODUCT_METADATA_TYPENAME}:${metadata.id}`,
            fragment: ProgramSpecializationMetadataFragment,
            data: productMetadata.specialization,
          });
          return (productMetadata as S12nMember)?.specialization;
        } else {
          return null;
        }
      });

      return { enterpriseProductMetadata, privateSessions };
    },
  })
);

export default withEnterpriseProgramMetadata;
