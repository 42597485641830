/** @jsx jsx */
import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

const cdsToCMLStyles = (theme: Theme) => {
  const codeStyle = css`
    pre,
    code {
      border: 1px solid var(--cds-color-grey-200);
      border-radius: 0;
      font-size: 0.875rem;
      line-height: 16px;
      margin-bottom: var(--cds-spacing-200);
      overflow-y: hidden;
      padding: 0;
    }

    code {
      position: relative;
      display: block;
      margin: 0 0 10px;
      word-break: break-all;
      word-wrap: break-word;
      background-color: var(--cds-color-grey-50);
    }
  `;

  const monospaceStyle = css`
    var {
      background-color: var(--cds-color-grey-50);
      font-family: Courier, 'Courier New', monospace;
      font-size: 0.95em;
      font-style: normal;
      font-weight: bold;
    }
  `;

  const figureStyle = css`
    figure {
      margin: 0 !important;

      &.selected {
        outline: 2px solid var(--cds-color-green-500);
      }

      ::selection {
        background: none;
      }

      img {
        max-width: 100%;
      }
    }
  `;

  const imgStyle = css`
    img {
      max-width: 100%;
      margin: 0 0 var(--cds-spacing-300) 0;

      &.cml-image-small {
        width: 180px;
      }

      &.cml-image-fullWidth {
        width: 100%;
      }
    }
  `;

  const listStyle = css`
    ul {
      list-style-type: disc;
    }

    /* aria-level targeting is used for "fake lists", i.e cases where the list is not
   structurally nested but we need to visually show the nesting e.g. MS Word copy-pasted lists.
*/
    ul ul,
    ul > li[aria-level='2'] {
      list-style-type: circle !important;
    }

    ul ul ul,
    ul > li[aria-level='3'] {
      list-style-type: square !important;
    }

    ul ul ul ul,
    ul > li[aria-level='4'] {
      list-style-type: disc !important;
    }

    ul ul ul ul ul,
    ul > li[aria-level='5'] {
      list-style-type: circle !important;
    }

    ol ol,
    ol > li[aria-level='2'] {
      list-style-type: lower-alpha !important;
    }

    ol ol ol,
    ol > li[aria-level='3'] {
      list-style-type: lower-roman !important;
    }

    ol ol ol ol,
    ol > li[aria-level='4'] {
      list-style-type: decimal !important;
    }

    ol ol ol ol ol,
    ol > li[aria-level='5'] {
      list-style-type: lower-alpha !important;
    }

    // handle list ordering for "fake lists"
    // aria-posinset represents the actual position of the item in the list
    ol li[aria-posinset]::marker {
      content: attr(aria-posinset) '. ';
    }

    ul,
    ol {
      ${theme.typography?.body1}
      padding-left: var(--cds-spacing-400);
      margin-left: 0;

      li {
        margin-bottom: var(--cds-spacing-100);
        padding-left: var(--cds-spacing-100);

        ul,
        ol {
          margin-top: var(--cds-spacing-100);
        }

        // fake nested lists for cases like MS Word copy-paste
        // where lists are not structurally nested
        &[aria-level='2'] {
          margin-left: 32px !important;
        }

        &[aria-level='3'] {
          margin-left: 64px !important;
        }

        &[aria-level='4'] {
          margin-left: 96px !important;
        }

        &[aria-level='5'] {
          margin-left: 128px !important;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  `;

  const tableStyle = css`
    table {
      margin: 0 0 var(--cds-spacing-300) 0;
      width: 100%;

      th,
      td {
        ${theme.typography?.body2}
        padding: var(--cds-spacing-50) var(--cds-spacing-200);
        text-align: left;
        vertical-align: top;
      }

      th,
      thead td {
        border: 1px solid var(--cds-color-grey-700);
      }

      thead td p,
      thead th p {
        font-weight: bold;
      }

      td {
        border: 1px solid var(--cds-color-grey-50);
      }

      p {
        margin-bottom: 0;
      }
    }
  `;

  const cdsFonts = css`
    strong {
      font-family: unset;
    }

    h1,
    h1[data-heading-variant='h1semibold'] {
      ${theme.typography?.h1semibold}
      margin: var(--cds-spacing-400) 0 var(--cds-spacing-300) 0;
    }

    h1[data-heading-variant='h1regular'] {
      ${theme.typography?.h1}
    }

    h2,
    h2[data-heading-variant='h2semibold'] {
      ${theme.typography?.h2semibold}
      margin: var(--cds-spacing-400) 0 var(--cds-spacing-200) 0;
    }

    h2[data-heading-variant='h2regular'] {
      ${theme.typography?.h2}
    }

    h3,
    h3[data-heading-variant='h3bold'] {
      ${theme.typography?.h3bold}
      margin: var(--cds-spacing-400) 0 var(--cds-spacing-150) 0;
    }

    h3[data-heading-variant='h3semibold'] {
      ${theme.typography?.h3semibold}
    }

    h4,
    h4[data-heading-variant='h4bold'] {
      ${theme.typography?.h4bold}
      margin: var(--cds-spacing-300) 0 var(--cds-spacing-100) 0;
    }

    > *:first-child {
      &,
      h1,
      h2,
      h3,
      h4 {
        margin-top: 0 !important;
      }
    }

    p,
    p[data-text-variant='body1'] {
      ${theme.typography?.body1}
      margin-bottom: var(--cds-spacing-200);
      min-height: 24px;
    }

    p[data-text-variant='body2'] {
      ${theme.typography?.body2}
    }

    a {
      color: var(--cds-color-blue-700);
      text-decoration: underline;

      &:hover {
        color: var(--cds-color-blue-800);
      }
    }
  `;

  const cmlAssets = css`
    .cml-asset {
      width: 100%;
      margin-bottom: var(--cds-spacing-150);

      .cml-asset-link {
        width: 100%;
        padding: var(--cds-spacing-150);
        display: block;
        color: var(--cds-color-neutral-primary-weak);
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        text-overflow: ellipsis;
        border: 1px solid var(--cds-color-grey-200);

        &:hover {
          cursor: pointer;
          border-color: var(--cds-color-grey-200);
        }
      }

      a {
        text-decoration: none;
      }

      .asset-container {
        border: 1px solid var(--cds-color-grey-300);
        justify-content: space-between;
        display: flex;
        ${breakpoints?.down('xs')} {
          flex-wrap: wrap;
        }

        /* This is done in order to have margin when the flex elements wrap. */
        padding: var(--cds-spacing-100) var(--cds-spacing-300) var(--cds-spacing-300) var(--cds-spacing-300);

        > * {
          margin-top: var(--cds-spacing-200);
          align-items: center;
        }
      }

      .asset-link-title {
        margin-right: var(--cds-spacing-100);
        margin-left: var(--cds-spacing-400);
        padding-left: var(--cds-spacing-100);
        white-space: nowrap;
      }

      .asset-info {
        margin-left: var(--cds-spacing-200);

        .asset-name {
          ${theme.typography?.h3bold}
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
        }

        .asset-extension {
          ${theme.typography?.body2}
          color: var(--cds-color-grey-600);
          margin-top: var(--cds-spacing-100);
        }
      }

      .asset-icon-file {
        width: var(--cds-spacing-300);
        min-width: var(--cds-spacing-300);
      }

      &.cml-asset-video {
        width: auto;
      }

      &.cml-asset-audio {
        width: auto;
        min-width: 300px;
      }
    }

    .cml-asset-link {
      text-decoration: initial;
      color: initial;
    }
  `;

  const globalMapping = css`
    word-wrap: break-word;

    .show-soft-breaks {
      white-space: pre-wrap; /* preserves newlines/soft-breaks */
    }

    /* Don't let the last child have a bottom margin */
    > *:last-child,
    [data-slate-editor='true'] > *:last-child {
      margin-bottom: 0 !important;
    }

    .ace_scroller .ace_content .ace_layer .ace_print-margin {
      background: var(--cds-color-blue-100);
    }

    /* for usage, see "display" prop jsdoc in RenderableHtml.tsx */
    .displayInlineBlock .cmlToHtml-content-container.hasAssetBlock,
    .displayInlineBlock .cmlToHtml-content-container.hasCodeBlock {
      display: inline-block;
    }
  `;

  const math = css`
    .katex-display,
    .MathJax_Display {
      margin: 0;
    }

    .MathJax {
      .math {
        > span {
          font-size: 120% !important;
        }
      }
    }
  `;

  return [
    cdsFonts,
    cmlAssets,
    monospaceStyle,
    codeStyle,
    figureStyle,
    imgStyle,
    listStyle,
    tableStyle,
    globalMapping,
    math,
  ];
};

export { cdsToCMLStyles };
