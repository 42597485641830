import * as React from 'react';

import { Typography } from '@coursera/cds-core';
import { Box, Row, StyleSheet, css, gradient } from '@coursera/coursera-ui';
import type { Svg } from '@coursera/coursera-ui/svg';
import { SvgBriefcase, SvgDirectionSigns, SvgMoney, SvgUser } from '@coursera/coursera-ui/svg';

import { PRODUCTS, productTypesTranslated } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import type { LearningOutcome, PRODUCT_TYPE } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import GradientIcon from 'bundles/enterprise-legacy-xdp/components/GradientIcon';
import IconInCircle from 'bundles/enterprise-legacy-xdp/components/IconInCircle';
import {
  GLANCE_ICON_BORDER_SIZE,
  GLANCE_ICON_SVG_SIZE,
} from 'bundles/enterprise-legacy-xdp/components/__styles__/shared';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/__styles__/ProductReviewOverviewStatistics';

const ICON_SIZE = 60;
const LEARNING_OUTCOME_ICON_SVG_SIZE = 27;

export type ReviewStatistic = {
  title: string;
  percentage: number;
};

const ALL_ICONS: { [key: string]: Svg } = {
  SvgDirectionSigns,
  SvgBriefcase,
  SvgMoney,
};

const styles = StyleSheet.create({
  atGlanceBackground: {
    backgroundColor: '#F8F8F8',
    padding: '20px 25px 10px 25px',
    margin: '10px 0',
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    [`@media (max-width: 400px)`]: {
      textAlign: 'right',
      flexDirection: 'column',
    },
  },
  iconWrapper: {
    height: ICON_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  },
});

type Props = {
  productType: PRODUCT_TYPE;
  iconColor?: string;
};

export default function ProductReviewOverviewStatisticsDelegator({
  careerOutcomeNewCareer,
  tangibleCareerOutcome,
  careerOutcomePayIncrease,
  productType,
  iconColor,
  isPositionedAtGlance,
}: LearningOutcome & Props) {
  if (productType === PRODUCTS.specialization) {
    return (
      <ProductReviewOverviewStatisticsV2
        careerOutcomeNewCareer={careerOutcomeNewCareer}
        tangibleCareerOutcome={tangibleCareerOutcome}
        careerOutcomePayIncrease={careerOutcomePayIncrease}
        productType={productType}
        iconColor={iconColor}
      />
    );
  }
  return (
    <ProductReviewOverviewStatisticsV1
      careerOutcomeNewCareer={careerOutcomeNewCareer}
      tangibleCareerOutcome={tangibleCareerOutcome}
      careerOutcomePayIncrease={careerOutcomePayIncrease}
      isPositionedAtGlance={isPositionedAtGlance}
    />
  );
}

type ProductReviewOverviewStat = {
  iconName: string;
  iconTitle: string;
  title: string;
  percentage: number;
  gradientObject: {
    start: string;
    end: string;
    deg: number;
  };
};

// The design currently displayed on CDPs for Learning Outcome statistics
export function ProductReviewOverviewStatisticsV1({
  careerOutcomeNewCareer,
  tangibleCareerOutcome,
  careerOutcomePayIncrease,
  isPositionedAtGlance = false,
}: LearningOutcome) {
  const dataList: ProductReviewOverviewStat[] = [];
  if (careerOutcomeNewCareer) {
    dataList.push({
      iconName: 'SvgDirectionSigns',
      iconTitle: _t('Career direction'),
      title: _t('started a new career after completing these courses'),
      percentage: careerOutcomeNewCareer,
      gradientObject: gradient.sea,
    });
  }
  if (tangibleCareerOutcome) {
    dataList.push({
      iconName: 'SvgBriefcase',
      iconTitle: _t('Career Benefit'),
      title: _t('got a tangible career benefit from this course'),
      percentage: tangibleCareerOutcome,
      gradientObject: gradient.blueberry,
    });
  }
  if (careerOutcomePayIncrease) {
    dataList.push({
      iconName: 'SvgMoney',
      iconTitle: _t('Career promotion'),
      title: _t('got a pay increase or promotion'),
      percentage: careerOutcomePayIncrease,
      gradientObject: gradient.kiwi,
    });
  }
  const dataListWithIcons = dataList.map((item) => ({
    ...item,
    IconTag: ALL_ICONS[item.iconName],
  }));

  if (dataListWithIcons.length === 0) return null;

  return (
    <div {...css(isPositionedAtGlance ? styles.atGlanceBackground : 'hidden-xs-down')}>
      {isPositionedAtGlance && (
        <Row>
          <Box alignItems="center" rootClassName="m-b-1">
            <IconInCircle
              Svg={SvgUser}
              iconColor="#20589D"
              iconSize={GLANCE_ICON_SVG_SIZE}
              circleSize={GLANCE_ICON_BORDER_SIZE}
              aria-hidden="true"
            />
            <h3 style={{ marginLeft: '20px' }} className="body-2-text m-b-0">
              {_t(
                isPositionedAtGlance ? 'Learner Career Outcomes' : 'Career outcomes for learners who took this course:'
              )}
            </h3>
          </Box>
        </Row>
      )}
      {dataListWithIcons.map(({ percentage, title, IconTag, gradientObject, iconTitle }) => (
        <Row key={title}>
          <Box alignItems="center" rootClassName="m-b-1">
            <div {...css(styles.iconWrapper)}>
              <GradientIcon
                size={isPositionedAtGlance ? GLANCE_ICON_BORDER_SIZE : ICON_SIZE}
                icon={IconTag}
                bgGradient={gradientObject}
                title={iconTitle || title}
              />
            </div>
            <div {...css(styles.textWrapper)}>
              <Typography
                className="m-x-1 m-b-0"
                style={{ minWidth: '70px', textAlign: 'right' }}
                variant="h1"
                component="h2"
              >
                {percentage}%
              </Typography>
              <Box className="font-sm" style={{ textAlign: 'center' }} flex={1}>
                {title}
              </Box>
            </div>
          </Box>
        </Row>
      ))}
    </div>
  );
}

type TitleV2Props = {
  title: string;
  icon: Svg;
  iconColor?: string;
};

export const TitleV2: React.FunctionComponent<TitleV2Props> = ({ title, icon, iconColor }) => (
  <Row>
    <Box alignItems="center" rootClassName="LearnerOutcomes__icon m-b-1">
      <IconInCircle
        Svg={icon}
        iconColor={iconColor || '#20589D'}
        iconSize={LEARNING_OUTCOME_ICON_SVG_SIZE}
        circleSize={GLANCE_ICON_BORDER_SIZE}
        aria-hidden="true"
      />
      <div className="LearnerOutcomes__title m-b-0">{title}</div>
    </Box>
  </Row>
);

// This is a redesign of Learning Outcome statistics that is rolled out on SDPs.
// #growth-acquisition plans to run the test the redesign on CDPs too https://coursera.atlassian.net/browse/GR-21721
export function ProductReviewOverviewStatisticsV2({
  careerOutcomeNewCareer,
  tangibleCareerOutcome,
  careerOutcomePayIncrease,
  productType,
  iconColor,
}: LearningOutcome & Props) {
  const dataList: ReviewStatistic[] = [];
  if (careerOutcomeNewCareer) {
    dataList.push({
      title: _t('Started a new career after completing this #{product}.', {
        product: productTypesTranslated()[productType],
      }),
      percentage: careerOutcomeNewCareer,
    });
  }
  if (tangibleCareerOutcome) {
    dataList.push({
      title: _t('Got a tangible career benefit from this #{product}.', {
        product: productTypesTranslated()[productType],
      }),
      percentage: tangibleCareerOutcome,
    });
  }
  if (careerOutcomePayIncrease) {
    dataList.push({
      title: _t('Got a pay increase or promotion.'),
      percentage: careerOutcomePayIncrease,
    });
  }

  if (dataList.length === 0) return null;

  return (
    <div className="LearnerOutcomes__container m-b-2 m-t-3">
      <TitleV2 title={_t('Learner Career Outcomes')} icon={SvgDirectionSigns} iconColor={iconColor} />
      {dataList.map(({ percentage, title }) => (
        <Row key={title}>
          <Box alignItems="center" rootClassName="m-b-1">
            <div className="LearnerOutcomes__text-wrapper">
              <div className="m-l-1s m-r-1 m-b-0 LearnerOutcomes__percent">{_t('#{percentage}%', { percentage })}</div>
              <Box className="LearnerOutcomes__text font-sm" flex={1}>
                {title}
              </Box>
            </div>
          </Box>
        </Row>
      ))}
    </div>
  );
}
