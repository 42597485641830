import * as React from 'react';

import Imgix from 'js/components/Imgix';

import DefaultCompanyLogo from 'bundles/enterprise-legacy-learner-home/components/DefaultCompanyLogo';
import DefaultOrganizationLogo from 'bundles/enterprise-legacy-learner-home/components/DefaultOrganizationLogo';

export type ThirdPartyOrganization = {
  rectangularLogo?: string | null;
  squareLogo?: string | null;
  name?: string | null;
  iconColor?: string | null;
  primaryColor?: string | null;
};

type Props = {
  thirdPartyOrganization?: ThirdPartyOrganization;
  preferRectangle?: boolean;
  logoHeight?: number;
  logoWidth?: number;
  defaultSize?: number;
};

const ThirdPartyOrganizationLogo = ({
  thirdPartyOrganization,
  preferRectangle = true,
  logoHeight = 32,
  logoWidth = 32,
  defaultSize = 32,
}: Props) => {
  const showThirdPartyOrganizationLogo = !!thirdPartyOrganization;
  const thirdPartyOrganizationLogo = preferRectangle
    ? (thirdPartyOrganization || {}).rectangularLogo || (thirdPartyOrganization || {}).squareLogo
    : (thirdPartyOrganization || {}).squareLogo || (thirdPartyOrganization || {}).rectangularLogo;

  if (showThirdPartyOrganizationLogo && thirdPartyOrganizationLogo) {
    return (
      <Imgix
        src={thirdPartyOrganizationLogo}
        maxWidth={logoWidth}
        maxHeight={logoHeight}
        alt={thirdPartyOrganization?.name ?? ''}
      />
    );
  } else if (thirdPartyOrganization) {
    return <DefaultOrganizationLogo size={defaultSize} thirdPartyOrganization={thirdPartyOrganization} />;
  } else {
    return <DefaultCompanyLogo size={defaultSize} />;
  }
};

export default ThirdPartyOrganizationLogo;
