// useSavedCoursesData.ts
import { useQuery } from '@apollo/client';

import ProductCoursesQuery from 'bundles/enterprise-legacy-learner-home/queries/ProductCoursesQuery.graphql';
import type {
  ProductCoursesQuery as ProductCoursesQueryType,
  ProductCoursesQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/queries/__generated__/ProductCoursesQuery';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import type {
  SavedProductsQuery_ProgramCurriculumProductsV1Resource_selected_elements_productState_ProgramCurriculumProductsV1_programCourseWithStateMember_programCourseWithState as CourseProductState,
  SavedProductsQuery_ProgramCurriculumProductsV1Resource_selected_elements_productState_ProgramCurriculumProductsV1_programS12nWithStateMember_programS12nWithState as S12nProductState,
} from 'bundles/program-home/components/enterprise-home/__generated__/SavedProductsQuery';
import type ProgramCurriculumProduct from 'bundles/program-home/components/enterprise-home/models/ProgramCurriculumProduct';
import SavedContainerS12nQuery from 'bundles/program-home/queries/SavedContainerS12nQuery.graphql';
import type {
  SavedContainerS12nQuery as ProductsContainerS12nQueryType,
  SavedContainerS12nQueryVariables as ProductsContainerS12nQueryVariablesType,
} from 'bundles/program-home/queries/__generated__/SavedContainerS12nQuery';

type ProductStateEnhanced = (CourseProductState | S12nProductState) & { isS12n: boolean };

export const useSavedCourseAndS12n = (savedProducts: Array<ProgramCurriculumProduct>) => {
  const productStateObj: Record<
    string,
    Omit<
      ProductStateEnhanced,
      '__typename' | 'reasonsForState' | 'enrolledAt' | 'actions' | 'lastUpdatedAt' | 'enrolledTargetSkillProfileId'
    >
  > = {};
  const s12nIds: string[] = [];
  const courseIds: string[] = [];
  const displayOrderIds: string[] = [];
  // Map each productState to its id and add id to corresponding list
  savedProducts.forEach((state) => {
    if (state.isS12n && state.s12nId) {
      s12nIds.push(state.s12nId);
      displayOrderIds.push(state.s12nId);
      productStateObj[state.s12nId] = state;
    } else if (state.isCourse && state.courseId) {
      courseIds.push(state.courseId);
      displayOrderIds.push(state.courseId);
      productStateObj[state.courseId] = state;
    }
  });

  const { data: courseData } = useQuery<ProductCoursesQueryType, ProductCoursesQueryVariables>(ProductCoursesQuery, {
    variables: { courseIds },
    skip: !courseIds || courseIds.length === 0,
    context: { clientName: 'gatewayGql' },
  });
  const courses = filterExistsOrDefault(courseData?.Course?.queryByIds).filter(({ id }) => courseIds.includes(id));

  const { data: s12nData } = useQuery<ProductsContainerS12nQueryType, ProductsContainerS12nQueryVariablesType>(
    SavedContainerS12nQuery,
    {
      context: { clientName: 'gatewayGql' },
      variables: { s12nIds },
      skip: !s12nIds || s12nIds.length === 0,
    }
  );

  const s12ns = filterExistsOrDefault(s12nData?.Specialization?.queryByIds).filter(({ id }) => s12nIds.includes(id));

  return { courses, s12ns, displayOrderIds, productStateObj };
};
