import * as React from 'react';

import _ from 'lodash';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';

import { Typography2 } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/coursera-ui';
import { Button, StyleSheet, css } from '@coursera/coursera-ui';
import { SvgaAlarmClock, SvgaStack } from '@coursera/coursera-ui/svg';

import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseS12nPhotoWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseS12nPhoto';
import TogglableContent from 'bundles/course-cards/components/course-card/enterprise/TogglableContent';
import withLogin from 'bundles/coursera-ui/components/hocs/withLogin';
import CourseInfoRow from 'bundles/coursera-ui/components/rich/CourseInfoRow';
import StartDateButton from 'bundles/enterprise-legacy-learner-home/components/StartDateButton';
import ProgramActionButtonContainer from 'bundles/enterprise-legacy-learner-home/components/programActionButton/ProgramActionButtonContainer';
import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import withMiniModal, { DESCRIPTION_PAGE_PATHS } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import { getCourseProductUrl } from 'bundles/unified-description-page-common/utils';

import _t from 'i18n!nls/course-cards';

const ViewDetailsBtnWithMiniModal = withMiniModal<ButtonProps>()(Button);
const ProgramActionButtonWithLogin = withLogin(ProgramActionButtonContainer);

const CONFIG = {
  defaultIconSize: 44,
};

const styles = StyleSheet.create({
  CourseNotEnrolledCard: {
    minHeight: 104,
  },
});

type Props = {
  course: CoursesV1;
  isPartOfS12n?: boolean;
  programId: string;
  hideAdditionalInfo?: boolean;
};

const CourseNotEnrolledCard = ({
  course: { id, name, photoUrl, slug, description, workload, isPreEnroll, isLaunched, isDraft, partnerIds },
  isPartOfS12n,
  programId,
  hideAdditionalInfo,
}: Props): JSX.Element => {
  // We are allowing enrollment for draft courses now
  const shouldRenderEnrollBtn = isLaunched || isDraft || isPreEnroll;
  const btnLabelText = isPreEnroll ? _t('Pre-enroll') : _t('Enroll');
  const miniModalProps = {
    id,
    isCourse: true,
    tag: undefined,
    unifiedDescriptionPageProps: { slug, productPagePath: DESCRIPTION_PAGE_PATHS.course },
  };
  const btnLabel = <StartDateButton courseId={id} />;

  const onActionSuccess = () => {
    const baseHomeUrl = getCourseProductUrl(slug, false);
    redirect.setLocation(`${baseHomeUrl}/home`);
  };

  return (
    <div
      {...css('rc-CourseNotEnrolledCard horizontal-box wrap', styles.CourseNotEnrolledCard)}
      data-e2e={`CourseNotEnrolledCard~${id}`}
    >
      <CourseS12nPhotoWithModal
        imgixClassName="course-logo"
        imageSrc={photoUrl}
        slug={slug}
        id={id}
        title={name}
        miniModalProps={miniModalProps}
      />
      <div className="flex-1 vertical-box">
        <CourseAndPartnerNameWithModal
          courseId={id}
          courseName={name}
          partnerIds={partnerIds}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
        />
        {isPartOfS12n && (
          <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
            {_t('Not Started')}
          </Typography2>
        )}
        {description && (
          <TogglableContent moreLessButtonContextLabel={_t('About #{courseName}', { courseName: name })}>
            <Typography2 component="p" variant="bodySecondary">
              {description}
            </Typography2>
          </TogglableContent>
        )}
        {!hideAdditionalInfo && (
          <>
            <div className="horizontal-box info-blocks wrap">
              <CourseInfoRow
                icon={<SvgaStack suppressTitle size={CONFIG.defaultIconSize} />}
                title={<FormattedMessage message={_t('{count, plural, =1 {# Course} other {# Courses}}')} count={1} />}
              />
              {!_.isEmpty(workload) && (
                <CourseInfoRow
                  icon={<SvgaAlarmClock suppressTitle size={CONFIG.defaultIconSize} />}
                  title={_t('Time Needed')}
                  subtitle={workload}
                />
              )}
            </div>
            <div className="horizontal-box align-items-right align-items-vertical-center">
              <ViewDetailsBtnWithMiniModal
                miniModalProps={miniModalProps}
                type="link"
                size="sm"
                label={_t('View Details')}
                htmlAttributes={{
                  'aria-label': `${_t('View Details')}: ${name}`,
                }}
              />
              {shouldRenderEnrollBtn && (
                <ProgramActionButtonWithLogin
                  loggedoutBtnAttributes={{
                    label: btnLabelText,
                    size: 'sm',
                  }}
                  loginHashHistoryReplacement={`autoEnroll/course/${id}`}
                  programId={programId}
                  productId={id}
                  onActionSuccess={onActionSuccess}
                  renderNothingIfConditionNotMet
                  productType={PRODUCT_TYPES.COURSE}
                  actionType="ENROLL"
                  initialBtnLabel={btnLabel}
                  btnAttributes={{
                    type: 'primary',
                    size: 'sm',
                    htmlAttributes: {
                      'data-e2e': `ProgramActionButtonContainer~${id}`,
                    },
                  }}
                  trackingInfo={{
                    trackingData: { courseId: id },
                    trackingName: 'course_enroll_button',
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CourseNotEnrolledCard;
