import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import moment from 'moment';

import { Typography2 } from '@coursera/cds-core';
import { Box, gradient } from '@coursera/coursera-ui';
import { SvgReading } from '@coursera/coursera-ui/svg';

import GradientIcon from 'bundles/enterprise-legacy-xdp/components/GradientIcon';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

export type OnDemandLearningMaterials = {
  totalQuizzesCount?: number | null;
  totalVideoLectureCount?: number | null;
  totalReadingCount?: number | null;
  totalLectureDuration?: number | null;
};

export default function LearnerMaterialSummary({
  totalVideoLectureCount,
  totalLectureDuration,
  totalReadingCount,
  totalQuizzesCount,
}: OnDemandLearningMaterials) {
  return (
    <Box rootClassName="p-y-1" alignItems="center">
      <div className="m-r-1">
        <GradientIcon icon={SvgReading} bgGradient={gradient.sea} />
      </div>
      <Typography2 className="m-x-1s text-secondary" variant="bodyPrimary" component="div">
        <FormattedMessage
          message="{numVideos}{numReadings}{numQuizzes}"
          numVideos={
            <FormattedMessage
              message={
                totalVideoLectureCount
                  ? _t('{videosCount} {videosCount, plural, =1 { video} other { videos}} {duration}')
                  : ''
              }
              videosCount={totalVideoLectureCount}
              duration={
                !!totalLectureDuration && (
                  <FormattedMessage
                    message={totalLectureDuration ? _t('(Total {totalVideoDuration} min)') : ''}
                    totalVideoDuration={Math.round(moment.duration(totalLectureDuration).asMinutes())}
                  />
                )
              }
            />
          }
          numReadings={
            <FormattedMessage
              message={
                totalReadingCount
                  ? _t(
                      '{videosCount, plural, =0 { } other { ,}} {readingsCount} {readingsCount, plural, =1 { reading} other { readings}}'
                    )
                  : ''
              }
              videosCount={totalVideoLectureCount}
              readingsCount={totalReadingCount}
            />
          }
          numQuizzes={
            !!totalVideoLectureCount &&
            !!totalReadingCount && (
              <FormattedMessage
                message={
                  totalQuizzesCount
                    ? _t(
                        '{videosAndReadingsCount, plural, =0 { } other { ,}} {quizzesCount} {quizzesCount, plural, =1 { quiz} other { quizzes}}'
                      )
                    : ''
                }
                videosAndReadingsCount={totalVideoLectureCount + totalReadingCount}
                quizzesCount={totalQuizzesCount}
              />
            )
          }
        />
      </Typography2>
    </Box>
  );
}
