/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import payments from 'bundles/epic/data/defaults/payments.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  courseraPlusGatewayCertificates: [
    'fq9UWMbTEeqpthJ2RmWGow',
    'Z-5wCcbTEeqeNBKhfgCLyw',
    'kr43OcbTEeqeNBKhfgCLyw',
    'child~L8zv1y16EeiOGQoLfyCjcg',
    'FLG-SnYMEeuJvRIq2L0SHw',
    'uvWXX6NMEem8VwqbwgpIcA',
    'QE4tKYGmEem2fgrSf7QW9A'
  ];
  removeFinancialAidLink: boolean;
  removeFinancialAidLinkIndia: boolean;
  disablePreEnrollment: boolean;
  useDGSProductOwnership: boolean;
  requireEmailVerification: boolean;
  preloadEnrollModalEnabled: boolean;
  ownableProductsGraphqlEnabled: boolean;
  hideAuditFromIndustryPartnerCourseInS12n: boolean;
  finaidCeilingDiscountPercentage: 100 | 75;
  finaidCeilingDiscountPercentageIndia: 100 | 90;
  finaidCeilingDiscountPercentageNonUSDeveloped: 100 | 75;
  finaidCeilingVariant: 'optOut' | 'A';
  finaidCeilingVariantV2: 'optOut' | 'A';
  finaidCeilingVariantV3: 'optOut' | 'control' | 'A';
  finaidCeilingDiscountPercentageV3RegionA: 100 | 90;
  finaidCeilingDiscountPercentageV3RegionB: 100 | 75;
  routeToFinancialAidApplicationV3: boolean;
  blockCertInFreeTrialPhase2Enabled: boolean;
  combineFreeTrialAndCheckoutFullStoryEnabled: boolean;
};

const NAMESPACE = 'payments';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([payments as $TSFixMe]);

const paymentsEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default paymentsEpicClient;
