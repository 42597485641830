/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';

import { Container } from '@coursera/coursera-ui';

import type { CourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';
import InstructorList, { getTitle } from 'bundles/enterprise-legacy-xdp/components/instructors/cds/InstructorList';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import type { Instructors as ComponentProps } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/Instructors';
import withFragments from 'bundles/graphql/components/withFragments';

type InnerProps = {
  id: string;
  noLink?: boolean;
  courseType?: CourseType;
};

type Props = InnerProps & Omit<ComponentProps, '__typename'>;

const stylesOverride = () => css`
  .pdp-section-header {
    margin-left: var(--cds-spacing-150);
  }
`;

export const InstructorListWrapper: React.FunctionComponent<Props> = ({
  id,
  instructors,
  averageInstructorRating,
  instructorRatingCount,
  noLink = false,
  courseType,
}) => {
  if (instructors?.length < 1 || courseType === 'Project') {
    return null;
  }

  return (
    <PdpSection css={stylesOverride} title={getTitle(instructors)}>
      <Container isFluid>
        <InstructorList
          id={id}
          instructors={instructors}
          averageInstructorRating={averageInstructorRating || undefined}
          instructorRatingCount={instructorRatingCount || undefined}
          noLink={noLink}
        />
      </Container>
    </PdpSection>
  );
};

export default withFragments({
  Instructors: gql`
    fragment Instructors on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      averageInstructorRating
      instructorRatingCount
      instructors {
        id
        photo
        fullName
        bio
        title
        shortName
        department
        learnersReached
        coursesTaught
        isTopInstructor
      }
    }
  `,
})(InstructorListWrapper);
