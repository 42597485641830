import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { compose } from 'recompose';

import connectToStores from 'js/lib/connectToStores';
import type UserAgentInfo from 'js/lib/useragent';

import { Col, Row } from '@coursera/coursera-ui';

import { CML } from 'bundles/cml';
import type { GraphQLCML } from 'bundles/collections-tool/types/collectionTypesSU';
import { PRODUCTS } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import type {
  Course,
  Degree,
  DifficultyLevel,
  EnterpriseProductConfiguration,
  LearningOutcome,
  Partner,
} from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import getS12nGlanceList from 'bundles/enterprise-legacy-learner-home/utils/getS12nGlanceList';
import hideEnterpriseUpsell from 'bundles/enterprise-legacy-learner-home/utils/hideEnterpriseUpsell';
import { addColorHashIfNeeded, getCMLShape } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import LearningObjectivesAndSkills from 'bundles/enterprise-legacy-xdp/components/LearningObjectivesAndSkills';
import ProductMetrics, { ProductMetricsRenderType } from 'bundles/enterprise-legacy-xdp/components/ProductMetrics';
import {
  renderDesktopAtAGlanceItems,
  renderMobileAtAGlanceItems,
} from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import EnterprisePromoUnitSidebar from 'bundles/enterprise-legacy-xdp/components/experiments/enterprise-promo-units/EnterprisePromoUnitSidebar';
import AboutSectionTogglableContent from 'bundles/enterprise-legacy-xdp/components/sdp/AboutSectionTogglableContent';
import AppliedLearningProject from 'bundles/enterprise-legacy-xdp/components/sdp/AppliedLearningProject';
import EnterpriseGrowthExperiments from 'bundles/epic/clients/EnterpriseGrowth';
import LearnerIncentive, { shouldShowLearnerIncentive } from 'bundles/learner-incentives/components/LearnerIncentive';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import { SPECIALIZATION } from 'bundles/payments/common/ProductType';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import { ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';
import { getS12nVariantLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';
import UserContext from 'bundles/unified-description-page-common/contexts/UserContext';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

// eslint-disable-next-line
import 'css!@coursera/coursera-ui/css/coursera-ui-no-font-face.min.css';
import 'css!bundles/enterprise-legacy-xdp/components/__styles__/AboutX';

type InputProps = {
  isMiniSDP?: boolean;
  description?: string;
  cmlDescription?: { cml: { dtdId: string; value: string } };
  htmlDescription?: string;
  degree?: Degree;
  skills?: Array<string>;
  level?: DifficultyLevel;
  avgLearningMonthsAdjusted?: number;
  avgLearningHoursPerWeek?: number;
  primaryColor?: string;
  cmlProjectsOverview?: GraphQLCML;
  htmlRecommendedBackground?: string;
  htmlProjectsOverview?: string;
  cmlRecommendedBackground?: GraphQLCML;
  cmlLearningObjectives?: Array<GraphQLCML>;
  s12nAndCourseLearningObjectives?: Array<GraphQLCML>;
  htmlS12nAndCourseLearningObjectives?: Array<string>;
  s12nAndCourseSkills?: Array<string>;
  primaryLanguages: Array<string>;
  subtitleLanguages: Array<string>;
  languageLineThreshold?: number;
  partners?: Array<Partner>;
  s12nId: string;
  showProductMetrics: boolean;
  learningOutcomes?: LearningOutcome;
  courses: Array<Course>;
  learnerIncentives: Array<LearnerIncentiveType>;
  totalEnrollmentCount?: number | null;
  pageViewsInLastMonthCount?: number | null;
  hasPrivateSessionDates?: boolean;
  productVariant?: S12nProductVariant;
  isEnterpriseAdminView?: boolean;
  enableCurriculumBuilder?: boolean;
  hasMultipleAvailabilities?: boolean;
  isC4C?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  showCourseraLabsGlance?: boolean;
};

type PropsFromNaptimePrice = {
  s12nDerivative?: S12nDerivativesV1;
};

type PropsFromStore = {
  userAgent: UserAgentInfo;
};

type Props = InputProps & PropsFromStore & PropsFromNaptimePrice;

type State = {
  mounted: boolean;
};

export class AboutS12n extends React.Component<Props, State> {
  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState(() => ({
      mounted: true,
    }));
  }

  render() {
    const {
      description: descriptionAlt,
      cmlDescription,
      s12nAndCourseLearningObjectives = [],
      htmlS12nAndCourseLearningObjectives = [],
      s12nAndCourseSkills,
      htmlProjectsOverview,
      cmlProjectsOverview,
      userAgent,
      avgLearningMonthsAdjusted,
      avgLearningHoursPerWeek,
      level,
      primaryColor,
      htmlRecommendedBackground,
      cmlRecommendedBackground,
      primaryLanguages = [],
      subtitleLanguages = [],
      languageLineThreshold = 12,
      s12nId,
      showProductMetrics,
      learningOutcomes,
      courses,
      learnerIncentives,
      totalEnrollmentCount,
      pageViewsInLastMonthCount,
      hasPrivateSessionDates,
      s12nDerivative,
      productVariant,
      isEnterpriseAdminView,
      enableCurriculumBuilder,
      hasMultipleAvailabilities,
      isC4C,
      enterpriseProductConfiguration,
      showCourseraLabsGlance,
    } = this.props;

    const { mounted } = this.state;

    const iconColor = addColorHashIfNeeded(primaryColor);
    const description =
      (mounted && cmlDescription?.cml && (
        <CML
          className="override-cml-headers"
          cml={getCMLShape(cmlDescription.cml)}
          shouldRenderMath={false}
          shouldRenderCode={false}
          shouldRenderAssets={false}
          isCdsEnabled
        />
      )) ||
      descriptionAlt;

    const backgroundLevelHtml: string | undefined = htmlRecommendedBackground;

    const glanceList = getS12nGlanceList({
      avgLearningMonthsAdjusted,
      avgLearningHoursPerWeek,
      level,
      backgroundLevelCml: cmlRecommendedBackground?.cml ? getCMLShape(cmlRecommendedBackground.cml) : undefined,
      backgroundLevelHtml,
      languageLineThreshold,
      primaryLanguages,
      subtitleLanguages,
      showDefaultBackgroundLevel: true,
      hasPrivateSessionDates,
      s12nDerivative,
      isEnterpriseAdminView,
      enableCurriculumBuilder,
      hasMultipleAvailabilities,
      enterpriseProductConfiguration,
      isC4C,
      showCourseraLabsGlance,
    });

    const skillsAndLearningObjExist = s12nAndCourseLearningObjectives.length > 0 && !!s12nAndCourseSkills;

    const aboutTitle = (
      <FormattedMessage message={_t('About this {program}')} program={getS12nVariantLabel(productVariant)} />
    );

    const enableEnterpriseXDPPromoUnits =
      EnterpriseGrowthExperiments.get('enableEnterpriseXDPPromoUnits') && !isEnterpriseAdminView;

    return (
      <UserContext.Consumer>
        {(userData) => {
          const shouldHideEnterpriseUpsell = hideEnterpriseUpsell(userData);

          return (
            <div className="AboutS12n" data-e2e="about-s12n">
              <Row rootClassName="text-xs-left">
                <Col rootClassName="m-t-2" xs={12} md={8}>
                  <LearningObjectivesAndSkills
                    cmlLearningObjectives={s12nAndCourseLearningObjectives}
                    htmlLearningObjectives={htmlS12nAndCourseLearningObjectives}
                    skills={s12nAndCourseSkills}
                  />
                  {shouldShowLearnerIncentive(learnerIncentives) && (
                    <div style={{ marginBottom: '24px' }}>
                      <LearnerIncentive
                        additionalInfo={{ courseCount: courses.length }}
                        learnerIncentives={learnerIncentives}
                      />
                    </div>
                  )}
                  <h2 className="m-r-1 m-b-0 bold headline-4-text" style={{ verticalAlign: 'middle' }}>
                    {aboutTitle}
                  </h2>
                  {showProductMetrics && (
                    <ProductMetrics
                      totalEnrollmentCount={totalEnrollmentCount}
                      pageViewsInLastMonthCount={pageViewsInLastMonthCount}
                      productItemId={s12nId}
                      productType={SPECIALIZATION}
                      productMetricsRenderType={ProductMetricsRenderType.VIEWS_WITH_TEXT_ONLY}
                    />
                  )}
                  <div data-e2e="description" className="m-t-1 m-b-3 description">
                    <AboutSectionTogglableContent
                      renderedContentClassName="description"
                      userAgent={userAgent}
                      trackingName="description"
                    >
                      {description}
                    </AboutSectionTogglableContent>
                  </div>
                  <AppliedLearningProject
                    userAgent={userAgent}
                    skillsAndLearningObjExist={skillsAndLearningObjExist}
                    cmlProjectsOverview={cmlProjectsOverview}
                    htmlProjectsOverview={htmlProjectsOverview}
                  />
                  {renderMobileAtAGlanceItems(glanceList, PRODUCTS.specialization, iconColor, learningOutcomes)}
                  {enableEnterpriseXDPPromoUnits &&
                    !shouldHideEnterpriseUpsell &&
                    productVariant !== ProfessionalCertificateS12n && (
                      <EnterprisePromoUnitSidebar
                        isMobile
                        isS12n
                        primaryLanguages={primaryLanguages}
                        productId={s12nId}
                        iconColor={iconColor}
                      />
                    )}
                </Col>
                <Col md={4}>
                  {renderDesktopAtAGlanceItems(glanceList, PRODUCTS.specialization, iconColor, learningOutcomes)}
                  {enableEnterpriseXDPPromoUnits &&
                    !shouldHideEnterpriseUpsell &&
                    productVariant !== ProfessionalCertificateS12n && (
                      <EnterprisePromoUnitSidebar
                        isS12n
                        primaryLanguages={primaryLanguages}
                        productId={s12nId}
                        iconColor={iconColor}
                      />
                    )}
                </Col>
              </Row>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default compose<Props, InputProps>(
  connectToStores([ApplicationStoreClass], (ApplicationStore, props) => ({
    ...props,
    userAgent: ApplicationStore.getUserAgent(),
  }))
)(AboutS12n);
