import * as React from 'react';
import type { FunctionComponent } from 'react';
import Media from 'react-media';

import cx from 'classnames';

import user from 'js/lib/user';

import { useCoachEpicEnabledState } from 'bundles/ai-course-coach/utils/productFeatureFlags';
import Help from 'bundles/common/components/Help';
import EnterpriseSearchBarV2, {
  UnifiedEnterpriseSearchBar,
} from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchBarV2';
import MobileEnterpriseSearchBar, {
  UnifiedMobileEnterpriseSearchBar,
} from 'bundles/enterprise-learner-search/components/search/MobileEnterpriseSearchBar';
import PageNavigation from 'bundles/enterprise-legacy-learner-home/components/single-program/PageNavigation';
import type { TabName as TabNameType } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader, { PAGE_HEADER_MOBILE_BREAKPOINT } from 'bundles/page-header/components/PageHeader';
import useSimplifiedNav from 'bundles/page-header/hooks/useSimplifiedNav';
import useUserAgent from 'bundles/page/hooks/useUserAgent';
import HorizontalDomainsMenu from 'bundles/program-home/components/multiprogram/HorizontalDomainsMenu';
import SimpleCourseraMetatags from 'bundles/seo/components/SimpleCourseraMetatags';

import 'css!./__styles__/ProgramHomeWrapper';

type Props = {
  isAuthenticatedUser: boolean;
  pageTitle?: string;
  programId: string;
  programName: string;
  program: EnterpriseProgramsV1;
  enableSkillsInSearchAndBrowse: boolean;
  thirdPartyOrganizationId: string;
  thirdPartyOrganizationSlug: string;
  className?: string;
  shouldRenderDomainsMenu?: boolean;
  shouldHideSearch?: boolean;
  'data-e2e'?: string;
  shouldShowShortFormContent?: boolean;
  tabNames?: TabNameType[];
};

const ProgramHomeWrapper: FunctionComponent<Props> = ({
  children,
  pageTitle,
  programId,
  program,
  programName,
  thirdPartyOrganizationId,
  thirdPartyOrganizationSlug,
  className,
  shouldRenderDomainsMenu = false,
  shouldHideSearch = false,
  'data-e2e': e2eDataAttribute,
  enableSkillsInSearchAndBrowse,
  shouldShowShortFormContent,
  tabNames,
}) => {
  const userId = user.get().id;

  /*
  We use useShouldShowCoachQuery to determine if we should render the help button. Note, this is not the global help button.
  useChatWidgetSelector doesn't handle this case because it doesn't differentiate between "loading" and "don't show any of the buttons", this is why useShouldShowCoachQuery is used instead.
  */
  const { showEmbeddedCoach } = useCoachEpicEnabledState();
  const isCoachEnabled = showEmbeddedCoach;

  const renderHelpButton = !isCoachEnabled;

  let desktopSearchBar: JSX.Element | null;
  let mobileSearchBar: JSX.Element | null;

  const isSimplifiedNav = useSimplifiedNav();
  const userAgent = useUserAgent();

  const pageNavigation =
    tabNames && tabNames.length > 0 ? (
      <PageNavigation links={tabNames} programSlug={program?.metadata?.slug} />
    ) : undefined;

  if (shouldHideSearch) {
    desktopSearchBar = null;
    mobileSearchBar = null;
  } else if (userId) {
    desktopSearchBar = (
      <UnifiedEnterpriseSearchBar
        programSlug={program?.metadata?.slug}
        programName={program?.metadata?.name}
        thirdPartyOrgSlug={thirdPartyOrganizationSlug}
        thirdPartyOrganizationId={thirdPartyOrganizationId}
        userId={userId}
        programId={programId}
        enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        shouldShowShortFormContent={shouldShowShortFormContent}
      />
    );
    mobileSearchBar = (
      <UnifiedMobileEnterpriseSearchBar
        programSlug={program?.metadata?.slug}
        programName={program?.metadata?.name}
        thirdPartyOrgSlug={thirdPartyOrganizationSlug}
        thirdPartyOrganizationId={thirdPartyOrganizationId}
        userId={userId}
        programId={programId}
        enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        shouldShowShortFormContent={shouldShowShortFormContent}
      />
    );
  } else {
    desktopSearchBar = (
      <EnterpriseSearchBarV2
        programIds={[programId]}
        enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        shouldShowShortFormContent={shouldShowShortFormContent}
        programName={programName}
      />
    );
    mobileSearchBar = (
      <MobileEnterpriseSearchBar
        programIds={[programId]}
        enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        shouldShowShortFormContent={shouldShowShortFormContent}
        programName={programName}
      />
    );
  }

  const wrapperClassName = cx('rc-ProgramHomeWrapper', className);

  return (
    <div className={wrapperClassName} data-e2e={e2eDataAttribute} data-testid="program-home-wrapper">
      <SimpleCourseraMetatags disableCourseraSuffix={Boolean(pageTitle)} title={pageTitle || programName} />
      <Media query={{ maxWidth: `${PAGE_HEADER_MOBILE_BREAKPOINT}px` }} defaultMatches={userAgent?.isMobile}>
        {(matches) => {
          return (
            <PageHeader
              showLanguagesDropdown
              hideSearch={!isSimplifiedNav}
              hideMetaNav
              thirdPartyOrganizationId={thirdPartyOrganizationId}
              hideEnterprise
              showGDPRBanner
              isEnterprise
              programId={programId}
              logoWrapper="div"
              injectedSearchBar={matches && isSimplifiedNav ? mobileSearchBar : desktopSearchBar}
              subNavigationLinks={pageNavigation}
            />
          );
        }}
      </Media>
      {!isSimplifiedNav && mobileSearchBar}
      {shouldRenderDomainsMenu && (
        <HorizontalDomainsMenu
          programId={programId}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
          enableSkillsInSearchAndBrowse={enableSkillsInSearchAndBrowse}
        />
      )}
      {children}
      <PageFooter />
      {renderHelpButton && <Help style={{ minWidth: 0, bottom: 12, right: 12 }} isEnterprise />}
    </div>
  );
};

export default ProgramHomeWrapper;
