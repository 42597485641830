import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { times } from 'lodash';

import useRouter from 'js/lib/useRouter';

import type { ButtonProps, LinkProps } from '@coursera/cds-core';
import { Button, InlineNotification, Link, Typography, Typography2 } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import { Box } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import Collection from 'bundles/enterprise-collections/components/Collection';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import { Heading, Section } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
import CatalogSection from 'bundles/enterprise-legacy-learner-home/components/single-program/CatalogSection';
import { COLLECTION_TYPE } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramConstants';
import { toViewAll } from 'bundles/enterprise-legacy-learner-home/components/single-program/links';
import { useDiscoveryBrowseCollectionsData } from 'bundles/enterprise-legacy-learner-home/components/single-program/withDiscoveryBrowseCollections';
import type {
  CollectionListItemStruct,
  Product,
  ProductType,
} from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';
import getCatalogBrowseCollections from 'bundles/program-home/utils/getCatalogBrowseCollection';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/BrowseCollections';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Link
);

const DefaultLength = 3;

export type PropsFromCaller = {
  programId: string;
  onProductCardClick: OnProductCardClick;
  isAuthenticatedUser?: boolean;
  placeholderLength?: number;
  length?: number;
  disableDescription?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

export type PropsForCollectionsView = PropsFromCaller & {
  collections: Array<CollectionListItemStruct> | undefined;
  loading: boolean;
  error: boolean;
};

export function CollectionsView({
  onProductCardClick,
  collections,
  loading,
  error,
  programId,
  length = DefaultLength,
  placeholderLength = length,
  disableDescription,
  allowOrgForSpecializationConfiguration,
}: PropsForCollectionsView): JSX.Element | null {
  const handleProductCardClick =
    (collectionTrackingId: string | null) => (product: Product, productType: ProductType) => {
      onProductCardClick(product, productType, collectionTrackingId, programId);
    };

  if (error) {
    return (
      <InlineNotification severity="error" data-testid="inline-notification">
        {_t('Sorry! Something went wrong. Please refresh the page.')}
      </InlineNotification>
    );
  } else if (loading) {
    return <CollectionsView.Placeholder length={placeholderLength} />;
  } else if (!collections?.length) {
    return null;
  }

  // Slice to length because the View All page changes the cache in Apollo. That is a problem for another day.
  const collectionElements = collections
    ?.slice(0, length)
    .map((collection, idx) => (
      <Collection
        key={collection.id}
        id={collection.id}
        collectionOrder={idx + 1}
        collection={collection}
        onProductCardClick={handleProductCardClick(collection.collectionTrackingId)}
        disableDescription={disableDescription}
        allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
      />
    ));

  return <div data-testid="collections-view">{collectionElements}</div>;
}

type PropsForPlaceholder = {
  length: number;
};

CollectionsView.Placeholder = ({ length }: PropsForPlaceholder) => {
  return (
    <div data-testid="collections-view-placeholder">
      {times(length, (key) => (
        <EnterpriseProductCardCollectionsPlaceholder key={key} />
      ))}
    </div>
  );
};

export type PropsForOrgHomeBrowseCollections = {
  programSlug: string;
  programName: string;
} & Omit<PropsFromCaller, 'isAuthenticatedUser' | 'disableDescription'> & {
    collections: Array<CollectionListItemStruct> | undefined;
    loading: boolean;
    error: boolean;
  };

export function OrgHomeBrowseCollections({
  onProductCardClick,
  collections,
  loading,
  error,
  programId,
  programSlug,
  programName,
  length = DefaultLength,
  placeholderLength = length,
  allowOrgForSpecializationConfiguration,
}: PropsForOrgHomeBrowseCollections) {
  const title = _t('Recommended by Coursera');

  if (!error && !loading && !collections?.length) {
    return null;
  }

  return (
    <div className="rc-BrowseCollections" data-testid="org-home-browse-collections">
      <Box className="header-box">
        <Heading defaultLevel={3} className="title">
          {title}
        </Heading>
        <div className="view-all-title-link">
          <TrackedButton
            {...Button.defaultProps}
            component={ReactRouterLink}
            variant="ghost"
            className="coursera-recommendations-link"
            icon={<ArrowNextIcon size="medium" />}
            iconPosition="after"
            size="medium"
            trackingData={{ programSlug }}
            trackingName="browse_collections_title_view_all_arrow_link"
            to={toViewAll(programSlug, COLLECTION_TYPE.BROWSE)}
            aria-label={_t('View all: #{title} in #{programName}', { title, programName })}
          >
            {_t('View all')}
          </TrackedButton>
        </div>
      </Box>
      <Section initialLevel={4}>
        <CollectionsView
          onProductCardClick={onProductCardClick}
          collections={collections}
          loading={loading}
          error={error}
          programId={programId}
          length={length}
          placeholderLength={placeholderLength}
          disableDescription
          allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
        />
      </Section>
    </div>
  );
}

export function BrowseCollections({
  programId,
  length = DefaultLength,
  isAuthenticatedUser,
  onProductCardClick,
}: PropsFromCaller): JSX.Element | null {
  const { params } = useRouter();
  const programSlug = params.programSlug;
  const { contextId, contextType, start, limit } = getCatalogBrowseCollections(Boolean(isAuthenticatedUser), 4);
  const { collections, loading, error } = useDiscoveryBrowseCollectionsData({
    programId,
    contextId,
    contextType,
    limit,
    start,
  });

  if (!loading && !error && collections?.length === 0) {
    return null;
  }

  return (
    <CatalogSection>
      <div className="rc-BrowseCollections" data-testid="browse-collections">
        <Box className="header-box">
          <Heading defaultLevel={2} className="title">
            {_t('Recommended by Coursera')}
          </Heading>
          <div className="view-all-title-link">
            <TrackedButton
              {...Button.defaultProps}
              component={ReactRouterLink}
              variant="ghost"
              className="coursera-recommendations-link"
              icon={<ArrowNextIcon size="medium" />}
              iconPosition="after"
              size="medium"
              trackingData={{ programSlug }}
              trackingName="browse_collections_title_view_all_arrow_link"
              to={toViewAll(programSlug, COLLECTION_TYPE.BROWSE)}
              aria-label={_t('View all: Recommendations from Coursera')}
            >
              {_t('View all')}
            </TrackedButton>
          </div>
        </Box>
        <Section initialLevel={3}>
          <Typography2 component="p" className="description">
            {_t(
              'Enroll in popular courses based on enrollment data and ratings by learners from a variety of industries across Coursera.'
            )}
          </Typography2>
          <CollectionsView
            programId={programId}
            length={DefaultLength}
            isAuthenticatedUser={isAuthenticatedUser}
            onProductCardClick={onProductCardClick}
            collections={collections}
            loading={loading}
            error={error}
          />
          <Typography component="div" variant="h2semibold">
            {_t('Want to see more? ')}
            <TrackedLink
              {...Link.defaultProps}
              className="collection-footer-link"
              component={ReactRouterLink}
              variant="quiet"
              trackingData={{ programSlug }}
              trackingName="browse_collections_footer_view_all_arrow_link"
              to={toViewAll(programSlug, COLLECTION_TYPE.BROWSE)}
              data-testid="view-all-recs-link"
            >
              {_t('View all recommendations from Coursera')}{' '}
              <ArrowNextIcon size="medium" style={{ verticalAlign: 'middle' }} />
            </TrackedLink>
          </Typography>
        </Section>
      </div>
    </CatalogSection>
  );
}

export default BrowseCollections;
