import * as React from 'react';
import { graphql } from 'react-apollo';

import { useQuery } from '@apollo/client';
import moment from 'moment';
import { compose, lifecycle, withHandlers, withStateHandlers } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';

import { StyleSheet, TabPanel } from '@coursera/coursera-ui';
import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import { getIsCALearnerDefaultTab } from 'bundles/enterprise-career-academy/utils/helpers';
import QueryOnboardingResponseByUserId from 'bundles/enterprise-learner-onboarding/queries/QueryOnboardingResponseByUserId.graphql';
import type {
  QueryOnboardingResponseByUserIdQuery,
  QueryOnboardingResponseByUserIdQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/QueryOnboardingResponseByUserId';
import { handleRedirect } from 'bundles/enterprise-learner-onboarding/utils';
import HowItWorksInformationCard from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/HowItWorksInformationCard';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import MyLearningPage from 'bundles/enterprise-legacy-learner-home/components/myLearning/MyLearningPage';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import BrowseCatalogTabContent from 'bundles/enterprise-legacy-learner-home/components/single-program/BrowseCatalogTabContent';
import MyCoursesTabContent from 'bundles/enterprise-legacy-learner-home/components/single-program/MyCoursesTabContent';
import type {
  AcademicDisciplineNameQueryVariables,
  AcademicDisciplineSummariesResult,
} from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramGraphqlQueries';
import { AcademicDisciplineNameQuery } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramGraphqlQueries';
import SkillSetsTabContent from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetsTabContent';
import type { PropsFromWithCatalogCollectionProps } from 'bundles/enterprise-legacy-learner-home/components/single-program/withCatalogCollectionProps';
import type { InjectedNaptime } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import EnterpriseSettingsV1 from 'bundles/naptimejs/resources/enterpriseSettings.v1';
import type { EnterpriseSetting } from 'bundles/naptimejs/resources/enterpriseSettings.v1';
import type ProgramBrowsingExperienceV1 from 'bundles/naptimejs/resources/programBrowsingExperiences.v1';
import type ProgramMembershipsV2 from 'bundles/naptimejs/resources/programMemberships.v2';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import type {
  PropsFromRefetchUserSkillProfileStates,
  PropsFromSaveSkillSetToggle,
} from 'bundles/program-home/components/ProgramHomeApp';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import {
  DEFAULT_SKILL_NAME,
  SKILLS_IDS_FOR_CLIPS_ALLOW_LIST,
} from 'bundles/program-home/constants/skillCollectionsConstants';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';
import mapExists from 'bundles/program-home/utils/mapExists';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import PersonalizedTabRoot from 'bundles/program-personalized-tab/pages/enterprise/PersonalizedTabRoot';
import {
  EnterpriseCADirectorNotConfigurable,
  redirectToUnifiedCA,
} from 'bundles/unified-career-academy/components/modules/EnterpriseCADirectorNotConfigurable';
import EnterpriseOnboardingHeaderProvider from 'bundles/unified-common/providers/EnterpriseOnboardingHeaderProvider';

import 'css!./__styles__/SingleProgramTabs';

type PropsFromCaller = {
  programMembership?: ProgramMembershipsV2;
  program: EnterpriseProgramsV1;
  userId: number;
  onProductCardClick: OnProductCardClick;
  isProgramDetailsView: boolean;
  isAuthenticatedUser: boolean;
  isProgramMember: boolean;
  isProgramAdmin: boolean;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  naptime: InjectedNaptime;
  refetchUserSkillProfileStates?: () => void;
  userSkillProfileStates?: UserSkillProfileStateType[];
  userSkillProfileStatesLoading?: boolean;
  tutorialVideoAssetId?: string;
  tutorialVideoUrl?: string;
  userEmails: UserEmail[] | undefined;
  browsingExperienceType?: ProgramBrowsingExperienceV1;
  shouldShowEulaBanner: boolean;
  shouldHideCourseraRecommendations: boolean;
  showSkillSetRoleFilter: boolean;
  shouldShowSponsoredByMessage: boolean;
  isAllowlistedC4er: boolean;
  programCollectionSectionDescription: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  enableCurriculumBuilder?: boolean;
  enableAcademicDisciplines?: boolean;
  enableSkillsDashboard: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
  isGwGProgram?: boolean;
  forceEnableProgramSkillSetFilters: boolean | null;
  isCareerAcademyLimitedCatalogProgram?: boolean;
  enableEnterpriseCustomBadges: boolean;
  isLevelSetsEnabled: boolean;
  enablePersonalizedRecommendationsForOrg: boolean;
  tabNames: TabName[];
  isCareerAcademy: boolean;
  enableCareerAcademyTabToggle: boolean;
  isCareerAcademyFreemium: boolean;
  isMyLearningTabEnabled: boolean;
  hasEnabledPersonalizedRecommendations: boolean;
  showPersonalizedRecommendations: boolean;
  shouldShowShortFormContent?: boolean;
} & PropsFromSaveSkillSetToggle &
  PropsFromWithCatalogCollectionProps &
  PropsFromRefetchUserSkillProfileStates;

type PropsFromRouter = {
  router: InjectedRouter;
  selectedTab: TabName;
  hasSpecifiedInitialTab: boolean;
};

type PropsFromHandlers = {
  onTabChange: (_: unknown, selectedIndex: number) => void;
  syncTabQuery: () => void;
};

type PropsFromHandlersSkillSets = {
  showSkillSets: () => void;
  showCatalog: () => void;
};

type StateForInformationCards = {
  catalogHelpCardId: string;
  catalogHelpIsExpanded: boolean;
  objectivesHelpCardId: string;
  objectivesHelpIsExpanded: boolean;
};

type StateHandlersForInformationCards = {
  toggleCatalogHelpIsExpanded: () => StateForInformationCards;
  toggleObjectivesHelpIsExpanded: () => StateForInformationCards;
};

type PropsFromGraphql = {
  academicDisciplineName?: string;
};

type PropsFromNaptime = {
  programSettings?: EnterpriseSetting[];
  naptime: InjectedNaptime;
};

type Props = PropsFromCaller &
  PropsFromWithCatalogCollectionProps &
  PropsFromRouter &
  PropsFromHandlers &
  PropsFromHandlersSkillSets &
  StateForInformationCards &
  StateHandlersForInformationCards &
  PropsFromGraphql &
  PropsFromNaptime;

const styles = StyleSheet.create({
  infoCard: {
    margin: '0px auto 48px',
    maxWidth: '1200px',
  },
});

export enum TabName {
  Catalog = 'CATALOG',
  Recommendations = 'RECOMMENDATIONS',
  MyCourses = 'MY_COURSES',
  SkillSets = 'SKILLSETS',
  CareerAcademy = 'EXPLORE_CAREERS',
  MyLearning = 'MY_LEARNING',
}

export function SingleProgramTabs({
  tabNames,
  selectedTab,
  onTabChange,
  program,
  userId,
  onProductCardClick,
  hasTSPs,
  hasCuratedCollections,
  hasBrowseCollections,
  catalogCollectionCount,
  catalogCollectionLength,
  catalogCollectionShouldLoadMore,
  isAuthenticatedUser,
  isProgramAdmin,
  isProgramMember,
  showSkillSets,
  thirdPartyOrganization,
  userSkillProfileStates,
  userSkillProfileStatesLoading,
  catalogHelpCardId,
  catalogHelpIsExpanded,
  toggleCatalogHelpIsExpanded,
  objectivesHelpCardId,
  objectivesHelpIsExpanded,
  toggleObjectivesHelpIsExpanded,
  tutorialVideoAssetId,
  tutorialVideoUrl,
  onSkillSetSaveToggle,
  showCatalog,
  userEmails,
  shouldShowEulaBanner,
  isAllowlistedC4er,
  programCollectionSectionDescription,
  programRecommendationsAvailableCoursesCopy,
  enableCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  academicDisciplineName,
  isGwGProgram,
  isCareerAcademyLimitedCatalogProgram,
  isCareerAcademyFreemium,
  enableEnterpriseCustomBadges,
  showSkillSetRoleFilter,
  enableSkillsDashboard,
  isCareerAcademy,
  programSettings,
  naptime,
  hasSpecifiedInitialTab,
  enableCareerAcademyTabToggle,
  isMyLearningTabEnabled,
  router,
  programMembership,
  hasEnabledPersonalizedRecommendations,
  showPersonalizedRecommendations,
  shouldHideCourseraRecommendations,
  isInDeepBrowseMode,
  shouldShowShortFormContent,
}: Props): JSX.Element {
  const tabIds: Array<string> = [];

  const { data: userOnboardingData, loading: onboardingDataLoading } = useQuery<
    QueryOnboardingResponseByUserIdQuery,
    QueryOnboardingResponseByUserIdQueryVariables
  >(QueryOnboardingResponseByUserId, {
    variables: { userId: String(userId) },
    context: { clientName: 'gatewayGql' },
    fetchPolicy: 'network-only',
    ssr: false,
  });

  const hasCompletedOnboarding = userOnboardingData?.LearnerOnboarding?.queryResponseByUserId?.isCompleted ?? true;
  const occupationSkills = userOnboardingData?.LearnerOnboarding?.queryResponseByUserId.occupationSkills;

  // use top 3 role-derived skills for clips collection
  let onboardingSkillNames = occupationSkills?.length
    ? occupationSkills
        .filter((skill) => SKILLS_IDS_FOR_CLIPS_ALLOW_LIST.includes(skill.id))
        .map((skill) => skill.name)
        .slice(0, 3)
    : [DEFAULT_SKILL_NAME];
  // don't show skill clips recs if they haven't completed onboarding
  if (!hasCompletedOnboarding) onboardingSkillNames = [];

  const onboardingRemindTime = userOnboardingData?.LearnerOnboarding?.queryResponseByUserId.remindMeByTime;
  const shouldRemindOnboarding = !onboardingRemindTime || moment(onboardingRemindTime).diff(Date.now(), 'days') < 0;
  const showLearnerOnboarding =
    (isProgramMember || programMembership?.isInvited) &&
    !hasCompletedOnboarding &&
    shouldRemindOnboarding &&
    hasEnabledPersonalizedRecommendations &&
    isInDeepBrowseMode;

  if (showLearnerOnboarding) {
    handleRedirect(`/programs/${program.slug}/onboarding`, router);
  }

  React.useEffect(() => {
    if (isCareerAcademy && enableCareerAcademyTabToggle && !hasSpecifiedInitialTab) {
      const isCALearnerDefaultTab = getIsCALearnerDefaultTab(programSettings);

      if (isCALearnerDefaultTab) {
        redirectToUnifiedCA(router, program.slug);
      }
    }

    // Using a query selector to make sure the tab panels are not focusable to prevent
    // a11y issues. TabPanel CUI component doesn't allow ref or htmlAttributes
    tabIds.forEach((tabId) => {
      document?.querySelector(`[id="${tabId}~panel"]`)?.setAttribute('tabindex', '-1');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIds]);

  const myCoursesTabContent = (
    <MyCoursesTabContent
      programId={program.id}
      programSlug={program.metadata.slug}
      programName={program.metadata.name}
      userId={userId}
      onProductCardClick={onProductCardClick}
      naptime={naptime}
      userSkillProfileStates={userSkillProfileStates}
      userSkillProfileStatesLoading={userSkillProfileStatesLoading}
      onSkillSetSaveToggle={onSkillSetSaveToggle}
      showCatalog={showCatalog}
      userEmails={userEmails}
      thirdPartyOrganization={thirdPartyOrganization}
      shouldShowEulaBanner={shouldShowEulaBanner}
      enableCurriculumBuilder={enableCurriculumBuilder}
      allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
      isCareerAcademyLimitedCatalogProgram={isCareerAcademyLimitedCatalogProgram}
    />
  );

  return (
    <div className="rc-SingleProgramTabs">
      <div className="tabpanel-container">
        {mapExists(tabNames, (tabName) => {
          const shouldShow = tabName === selectedTab;
          const tabId = `SingleProgramTabs-${tabName}`;

          tabIds.push(tabId);

          if (!shouldShow) {
            return (
              <TabPanel key={tabName} shouldShow={shouldShow} tabId={tabId}>
                {null}
              </TabPanel>
            );
          }
          switch (tabName) {
            case TabName.CareerAcademy:
              return (
                <TabPanel key={tabName} shouldShow={shouldShow} tabId={tabId}>
                  <EnterpriseCADirectorNotConfigurable programSlug={program.slug} />
                </TabPanel>
              );
            case TabName.Recommendations:
              return (
                <TabPanel key={tabName} shouldShow={shouldShow} tabId={tabId}>
                  <PersonalizedTabRoot program={program} />
                </TabPanel>
              );
            case TabName.Catalog:
              return (
                <TabPanel key={tabName} shouldShow={shouldShow} tabId={tabId}>
                  <EnterpriseOnboardingHeaderProvider
                    programId={program.id}
                    thirdPartyOrganizationId={thirdPartyOrganization.id}
                    isProgramMemberOrInvited={isProgramMember || programMembership?.isInvited}
                  >
                    <BrowseCatalogTabContent
                      programId={program.id}
                      thirdPartyOrganization={thirdPartyOrganization}
                      programSlug={program.metadata.slug}
                      hasTSPs={hasTSPs}
                      onProductCardClick={onProductCardClick}
                      isAuthenticatedUser={isAuthenticatedUser}
                      showSkillSets={showSkillSets}
                      catalogHelpIsExpanded={catalogHelpIsExpanded}
                      isProgramMember={isProgramMember}
                      isProgramAdmin={isProgramAdmin}
                      infoCard={
                        catalogHelpIsExpanded && (
                          <HowItWorksInformationCard
                            // @ts-expect-error FIXME result of fixing typing on coursera-ui aphrodite exports in PR#45351
                            rootClassName={styles.infoCard}
                            id={catalogHelpCardId}
                            isOpen={catalogHelpIsExpanded}
                            onRequestClose={toggleCatalogHelpIsExpanded}
                            userAuthenticated={isAuthenticatedUser}
                            hasTSPs={hasTSPs}
                            tutorialVideoAssetId={tutorialVideoAssetId}
                            tutorialVideoUrl={tutorialVideoUrl}
                          />
                        )
                      }
                      hasCuratedCollections={hasCuratedCollections}
                      hasBrowseCollections={hasBrowseCollections}
                      catalogCollectionCount={catalogCollectionCount}
                      catalogCollectionLength={catalogCollectionLength}
                      catalogCollectionShouldLoadMore={catalogCollectionShouldLoadMore}
                      isAllowlistedC4er={isAllowlistedC4er}
                      programCollectionSectionDescription={programCollectionSectionDescription}
                      programRecommendationsAvailableCoursesCopy={programRecommendationsAvailableCoursesCopy}
                      enableCurriculumBuilder={enableCurriculumBuilder}
                      allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
                      isGwGProgram={isGwGProgram}
                      skillSetLength={program.metadata?.linkedTargetSkillProfileIds?.length}
                      academicDisciplineName={academicDisciplineName}
                      enableEnterpriseCustomBadges={enableEnterpriseCustomBadges}
                      enableSkillsDashboard={enableSkillsDashboard}
                      isMyLearningTabEnabled={isMyLearningTabEnabled}
                      showSkillSetRoleFilter={showSkillSetRoleFilter}
                      isCareerAcademyFreemium={isCareerAcademyFreemium}
                      hasCompletedOnboarding={hasCompletedOnboarding}
                      onboardingDataLoading={onboardingDataLoading}
                      onboardingSkillNames={onboardingSkillNames}
                      showPersonalizedRecommendations={showPersonalizedRecommendations}
                      onTabChange={onTabChange}
                      tabNames={tabNames}
                      shouldHideCourseraRecommendations={shouldHideCourseraRecommendations}
                      isInDeepBrowseMode={isInDeepBrowseMode}
                      shouldShowShortFormContent={shouldShowShortFormContent}
                    />
                  </EnterpriseOnboardingHeaderProvider>
                </TabPanel>
              );
            case TabName.SkillSets:
              return (
                <TabPanel key={tabName} shouldShow={shouldShow} tabId={tabId}>
                  <SkillSetsTabContent
                    programId={program.id}
                    programSlug={program.metadata.slug}
                    thirdPartyOrganization={thirdPartyOrganization}
                    userSkillProfileStates={userSkillProfileStates}
                    objectivesHelpCardId={objectivesHelpCardId}
                    objectivesHelpIsExpanded={objectivesHelpIsExpanded}
                    toggleObjectivesHelpIsExpanded={toggleObjectivesHelpIsExpanded}
                    skillSetLength={program.metadata?.linkedTargetSkillProfileIds?.length}
                    tutorialVideoAssetId={tutorialVideoAssetId}
                    tutorialVideoUrl={tutorialVideoUrl}
                    showSkillSetRoleFilter={showSkillSetRoleFilter}
                  />
                </TabPanel>
              );
            case TabName.MyCourses:
              return (
                <TabPanel key={tabName} shouldShow={shouldShow} tabId={tabId}>
                  {myCoursesTabContent}
                </TabPanel>
              );
            case TabName.MyLearning:
              return (
                <EnterpriseOnboardingHeaderProvider
                  programId={program.id}
                  thirdPartyOrganizationId={thirdPartyOrganization.id}
                  isProgramMemberOrInvited={isProgramMember || programMembership?.isInvited}
                >
                  <MyLearningPage
                    programId={program.id}
                    programSlug={program.metadata.slug}
                    programName={program.metadata.name}
                    userId={userId}
                    onProductCardClick={onProductCardClick}
                    userSkillProfileStates={userSkillProfileStates}
                    userSkillProfileStatesLoading={userSkillProfileStatesLoading}
                    onSkillSetSaveToggle={onSkillSetSaveToggle}
                    userEmails={userEmails}
                    thirdPartyOrganization={thirdPartyOrganization}
                    shouldShowEulaBanner={shouldShowEulaBanner}
                    enableCurriculumBuilder={enableCurriculumBuilder}
                    allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
                    isCareerAcademyLimitedCatalogProgram={isCareerAcademyLimitedCatalogProgram}
                    enableSkillsDashboard={enableSkillsDashboard}
                    toggleCatalogTab={showCatalog}
                    isSummaryView={false}
                  />
                </EnterpriseOnboardingHeaderProvider>
              );

            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}

export default compose<Props, PropsFromCaller>(
  Naptime.createContainer<PropsFromNaptime, Props>(({ program, enableCareerAcademyTabToggle }) => ({
    programSettings:
      enableCareerAcademyTabToggle &&
      EnterpriseSettingsV1.finder('byProgram', {
        params: { programId: program.id, includeOrganizationSettings: true },
        fields: ['setting'],
      }),
  })),
  connectToRouter<PropsFromRouter, PropsFromCaller>((router, { tabNames }): PropsFromRouter => {
    const selectedIndex = Math.max(0, tabNames.indexOf(router.location.query.currentTab));
    const selectedTab = tabNames[selectedIndex];
    const hasSpecifiedInitialTab = Boolean(router.location.query.currentTab);

    return { router, selectedTab, hasSpecifiedInitialTab };
  }),
  withHandlers<PropsFromRouter & PropsFromHandlers & PropsFromCaller, PropsFromHandlers>({
    onTabChange:
      ({
        tabNames,
        router,
        selectedTab: prevSelectedTab,
        refetchUserSkillProfileStates,
        shouldRefetchUserSkillProfileStates,
        setShouldRefetchUserSkillProfileStates,
      }) =>
      (_, selectedIndex) => {
        const nextSelectedTab = tabNames[selectedIndex];
        if (nextSelectedTab === prevSelectedTab) {
          return;
        }

        if (refetchUserSkillProfileStates && shouldRefetchUserSkillProfileStates) {
          refetchUserSkillProfileStates();
          setShouldRefetchUserSkillProfileStates(false);
        }

        router.push({
          ...router.location,
          query: {
            ...router.location.query,
            currentTab: nextSelectedTab,
          },
        });
      },
    syncTabQuery:
      ({ router, selectedTab }) =>
      () => {
        if (router.location.query.currentTab === selectedTab) {
          return;
        }

        router.replace({
          ...router.location,
          query: {
            ...router.location.query,
            currentTab: selectedTab,
          },
        });
      },
  }),
  withHandlers<PropsFromWithCatalogCollectionProps & PropsFromCaller & PropsFromHandlers, PropsFromHandlersSkillSets>({
    showSkillSets:
      ({ tabNames, onTabChange, hasTSPs }) =>
      () => {
        const skillSetsTabIndex = tabNames.indexOf(TabName.SkillSets);
        if (hasTSPs && skillSetsTabIndex >= 0) {
          onTabChange(null, skillSetsTabIndex);
          window.scrollTo(0, 0);
        }
      },
    showCatalog:
      ({ onTabChange, tabNames }) =>
      () => {
        const catalogTabIndex = tabNames.indexOf(TabName.Catalog);
        if (catalogTabIndex >= 0) {
          onTabChange(null, catalogTabIndex);
          window.scrollTo(0, 0);
        }
      },
  }),
  withStateHandlers<StateForInformationCards, StateHandlersForInformationCards>(
    {
      catalogHelpCardId: 'ProgramHomeCatalogInformationCard',
      catalogHelpIsExpanded: false,
      objectivesHelpCardId: 'ProgramHomeObjectivesInformationCard',
      objectivesHelpIsExpanded: false,
    },
    {
      toggleCatalogHelpIsExpanded:
        ({ catalogHelpIsExpanded }) =>
        () => ({
          catalogHelpIsExpanded: !catalogHelpIsExpanded,
        }),
      toggleObjectivesHelpIsExpanded:
        ({ objectivesHelpIsExpanded }) =>
        () => ({
          objectivesHelpIsExpanded: !objectivesHelpIsExpanded,
        }),
    }
  ),
  lifecycle<PropsFromRouter & PropsFromHandlers, {}>({
    componentDidMount() {
      this.props.syncTabQuery();
    },
    componentDidUpdate({ selectedTab: prevSelectedTab }) {
      const { selectedTab: nextSelectedTab } = this.props;
      if (nextSelectedTab !== prevSelectedTab) {
        this.props.syncTabQuery();
      }
    },
  }),
  graphql<PropsFromCaller, AcademicDisciplineSummariesResult, AcademicDisciplineNameQueryVariables, PropsFromGraphql>(
    AcademicDisciplineNameQuery,
    {
      skip: ({ enableAcademicDisciplines, program }) =>
        !enableAcademicDisciplines || !program?.metadata?.linkedAcademicDisciplineIds?.length,
      options: ({ program }) => {
        return {
          // Id is checked for in the skip above
          variables: { disciplineId: program?.metadata.linkedAcademicDisciplineIds?.[0] },
          context: { clientName: 'gatewayGql' },
        };
      },
      props: ({ data }) => {
        const academicDisciplineName = data?.AcademicDiscipline?.queryAcademicDisciplineById.name;
        return { academicDisciplineName };
      },
    }
  )
)(SingleProgramTabs);
