/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { ARROW_CROSS_AXIS, ARROW_MAIN_AXIS, ARROW_OFFSET } from 'bundles/common/components/Tooltip/constants';
import type { Placement, Variant } from 'bundles/common/components/Tooltip/types';

const primaryStyles = {
  arrow: css`
    position: absolute;
    height: 0;
    width: 0;
    border: ${ARROW_OFFSET}px solid transparent;
    pointer-events: none;
  `,
  top: css`
    border-top-color: var(--cds-color-grey-975);
  `,
  bottom: css`
    border-bottom-color: var(--cds-color-grey-975);
  `,
  left: css`
    border-left-color: var(--cds-color-grey-975);
  `,
  right: css`
    border-right-color: var(--cds-color-grey-975);
  `,
};

const secondaryStyles = {
  arrow: css`
    position: absolute;
    height: 0;
    width: 0;
    border: ${ARROW_OFFSET}px solid transparent;
    pointer-events: none;

    ::after {
      content: ' ';
      position: absolute;
      height: 0;
      width: 0;
      border: ${ARROW_OFFSET}px solid transparent;
    }
  `,
  top: css`
    border-top-color: var(--cds-color-grey-200);

    ::after {
      top: -${ARROW_OFFSET + 1}px;
      left: -${ARROW_OFFSET}px;
      border-top-color: var(--cds-color-white-0);
    }
  `,
  bottom: css`
    border-bottom-color: var(--cds-color-grey-200);

    ::after {
      bottom: -${ARROW_OFFSET + 1}px;
      left: -${ARROW_OFFSET}px;
      border-bottom-color: var(--cds-color-white-0);
    }
  `,
  left: css`
    border-left-color: var(--cds-color-grey-200);

    ::after {
      top: -${ARROW_OFFSET}px;
      left: -${ARROW_OFFSET + 1}px;
      border-left-color: var(--cds-color-white-0);
    }
  `,
  right: css`
    border-right-color: var(--cds-color-grey-200);

    ::after {
      top: -${ARROW_OFFSET}px;
      right: -${ARROW_OFFSET + 1}px;
      border-right-color: var(--cds-color-white-0);
    }
  `,
};

export type Props = React.HTMLAttributes<HTMLElement> & {
  placement?: Placement;
  variant?: Variant;
};

const TooltipArrow = React.forwardRef<HTMLDivElement, Props>(
  ({ placement = 'top', variant = 'primary', ...attributes }, ref) => {
    const defaultStyles = css({
      [ARROW_CROSS_AXIS[placement]]: `calc(50% - ${ARROW_OFFSET}px)`,
      [ARROW_MAIN_AXIS[placement]]: `-${ARROW_OFFSET * 2}px`,
    });

    const styles = variant === 'secondary' ? secondaryStyles : primaryStyles;
    return <div ref={ref} css={[defaultStyles, styles.arrow, styles[placement]]} {...attributes} />;
  }
);

export default TooltipArrow;
