/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2, typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';

import _t from 'i18n!nls/finaid';

type Props = {
  courseId: string;
  originalPrice: number;
  currencyCode: string;
  courseName?: string;
  photoUrl?: string;
  partnerName?: string;
};

const useStyles = (theme: Theme) => ({
  badge: css({
    display: 'inline-block',
    backgroundColor: 'var(--cds-color-grey-50)',
    padding: '0 var(--cds-spacing-50)',
    borderRadius: 2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--cds-color-grey-975)',
  }),
});

export const CourseInfo = ({ courseName, photoUrl, partnerName, originalPrice, currencyCode }: Props) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const price =
    originalPrice && currencyCode ? <ReactPriceDisplay currency={currencyCode} value={originalPrice} /> : null;

  if (!courseName) {
    return null;
  }

  return (
    <div
      css={css({
        border: `1px solid var(--cds-color-grey-300)`,
        borderRadius: '4px',
        marginTop: 'var(--cds-spacing-150)',
        padding: 'var(--cds-spacing-100) var(--cds-spacing-400) var(--cds-spacing-300)',
      })}
    >
      <Grid
        container
        direction="row"
        spacing={24}
        wrap="nowrap"
        css={css({ marginTop: 'var(--cds-spacing-200)', color: 'var(--cds-color-grey-600)' })}
      >
        <Grid item>
          <img height="100" width="100" alt={courseName} src={photoUrl} />
        </Grid>

        <Grid item container direction="column" spacing={8}>
          <Grid item>
            <Typography2 component="p" color="body">
              {courseName}
            </Typography2>
          </Grid>
          <Grid item>
            {partnerName && (
              <Typography2 component="p" color="supportText" variant="bodySecondary">
                {partnerName}
              </Typography2>
            )}
          </Grid>
          <Grid item>
            <span css={[{ ...typography2.bodySecondary }, styles.badge]}>
              {_t('original price: ')}
              {price}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
