/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { Link, Typography2, breakpoints } from '@coursera/cds-core';
import type { LinkProps } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { Heading } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { TabName } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import { toProgramHomeTab } from 'bundles/enterprise-legacy-learner-home/links';
import withPreventDefault from 'bundles/program-home/utils/withPreventDefault';

import _t from 'i18n!nls/program-home';

type Props = {
  showSkillSets?: () => void;
  programSlug: string;
  programName?: string;
  title?: string;
  renderOrgHomeVariant?: boolean;
  isAnAcademicDisciplineProgram?: boolean;
};

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Link
);

const styles = {
  skillSetHeader: css`
    display: flex;
    justify-content: space-between;

    ${breakpoints.down('xs')} {
      flex-wrap: wrap;
    }
  `,
  headingContainer: css`
    max-width: 720px;
  `,
  header: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  subtitleContainer: css`
    margin-bottom: var(--cds-spacing-100);
    ${breakpoints.down('xs')} {
      margin-bottom: var(--cds-spacing-200);
    }
  `,
  boldedSubtitle: css`
    font-weight: 600;
  `,
  skillSetLink: css`
    margin: var(--cds-spacing-100) 0;
    margin-left: var(--cds-spacing-1000);
    flex-shrink: 0;
    height: fit-content;
    ${breakpoints.down('xs')} {
      margin: 0;
      margin-left: 0;
      margin-bottom: var(--cds-spacing-50);
    }
  `,
};

const SkillSetDiscoveryHeader: React.FC<Props> = ({
  programSlug,
  programName,
  showSkillSets,
  title,
  renderOrgHomeVariant,
  isAnAcademicDisciplineProgram,
}) => {
  return (
    <div css={styles.skillSetHeader}>
      <div css={styles.headingContainer}>
        <Heading variant={renderOrgHomeVariant ? undefined : 'h1semibold'} css={styles.header} defaultLevel={2}>
          {title ?? _t('SkillSets recommended by your organization')}
        </Heading>
        {!renderOrgHomeVariant && (
          <Typography2 variant="bodyPrimary" css={styles.subtitleContainer} component="div">
            {isAnAcademicDisciplineProgram ? (
              <span data-e2e="academic-discipline-skillset-card-copy">
                {_t('Develop career-relevant skills for popular industry roles.')}
              </span>
            ) : (
              <>
                <span css={styles.boldedSubtitle}>
                  {_t('Get the in-demand skills you need to grow professionally. ')}
                </span>
                {_t(
                  'Choose any SkillSet that aligns with your learning goals. Enroll in personalized recommendations from the SkillSet to increase your proficiency level.'
                )}
              </>
            )}
          </Typography2>
        )}
      </div>
      <TrackedLink
        {...Link.defaultProps}
        component={ReactRouterLink}
        trackingName="view_all_skillsets_header_link"
        css={styles.skillSetLink}
        to={toProgramHomeTab(programSlug, TabName.SkillSets)}
        aria-label={
          programName
            ? _t('View all SkillSets: #{programName}', { programName })
            : _t('View all SkillSets in this program')
        }
        typographyVariant="subtitleMedium"
        variant="quiet"
        onClick={showSkillSets ? withPreventDefault(showSkillSets) : undefined}
        icon={<ArrowNextIcon size="medium" />}
        iconPosition="after"
        data-testid="view-all-skill-sets-link"
      >
        {_t('View all')}
      </TrackedLink>
    </div>
  );
};

export default SkillSetDiscoveryHeader;
