/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

type Props = {
  title: string;
};

const styles = {
  title: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
};

export const NavigationTitle = (props: Props): React.ReactElement => {
  const { title } = props;

  return (
    <Typography2 variant="subtitleMedium" component="h3" css={styles.title}>
      {title}
    </Typography2>
  );
};

export default NavigationTitle;
