import * as React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { keyBy } from 'lodash';

import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import { LEADERSHIP_SKILLS, NUM_ENTRIES_PER_SKILL } from 'bundles/program-personalized-tab/constants';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import S12nAndProCertRecsContext from 'bundles/program-personalized-tab/contexts/S12nAndProCertRecsContext';
import { EnterpriseS12nRecommendationsForSkillIdQuery } from 'bundles/program-personalized-tab/queries/EnterpriseS12nRecommendationsForSkillIdQuery';
import { S12nMetadataForS12nRecommendations } from 'bundles/program-personalized-tab/queries/S12nMetadataForS12nRecommendations';
import type {
  EnterpriseS12nRecommendationsForSkillId as EnterpriseS12nRecommendationsForSkillIdQueryData,
  EnterpriseS12nRecommendationsForSkillIdVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseS12nRecommendationsForSkillId';
import type {
  S12nMetadataForS12nRecommendations as S12nMetadataForS12nRecommendationsQueryData,
  S12nMetadataForS12nRecommendationsVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/S12nMetadataForS12nRecommendations';
import type { ProductWithMetadata } from 'bundles/program-personalized-tab/types/sharedTypes';
import { s12nRecsDataToCollectionType } from 'bundles/program-personalized-tab/utils/productRecsUtils';
import { NormalS12n, ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';

type PropsToComponent = {
  programId: string;
  children: React.ReactNode;
};

const useS12nRecsForSkillId = (skillIds: string[], programId: string, areLeadershipRecs?: boolean) => {
  const {
    loading: isS12nRecsLoading,
    error,
    data: s12nRecsData,
  } = useQuery<EnterpriseS12nRecommendationsForSkillIdQueryData, EnterpriseS12nRecommendationsForSkillIdVariables>(
    EnterpriseS12nRecommendationsForSkillIdQuery,
    {
      variables: {
        programId,
        numEntriesPerCollection: NUM_ENTRIES_PER_SKILL,
        hasSkillId1: Boolean(skillIds[0]),
        skillId1: skillIds[0],
        hasSkillId2: Boolean(skillIds[1]),
        skillId2: skillIds[1],
        hasSkillId3: Boolean(skillIds[2]),
        skillId3: skillIds[2],
        hasSkillId4: Boolean(skillIds[3]),
        skillId4: skillIds[3],
        hasSkillId5: Boolean(skillIds[4]),
        skillId5: skillIds[4],
        hasSkillId6: Boolean(skillIds[5]),
        skillId6: skillIds[5],
      },
      skip: skillIds.length === 0,
      ssr: false,
      notifyOnNetworkStatusChange: true,
    }
  );

  const recs = s12nRecsDataToCollectionType(s12nRecsData, areLeadershipRecs);
  const s12nIdsForQuery = filterExistsOrDefault(recs?.s12nIds?.map((s12nId) => `SPECIALIZATION~${s12nId}`));

  const { loading: isS12nMetadataLoading, data: s12nMetadataData } = useQuery<
    S12nMetadataForS12nRecommendationsQueryData,
    S12nMetadataForS12nRecommendationsVariables
  >(S12nMetadataForS12nRecommendations, {
    skip: !s12nRecsData || s12nIdsForQuery.length < 0,
    variables: { s12nIds: s12nIdsForQuery },
    notifyOnNetworkStatusChange: true,
  });

  const s12nMetadataObj = keyBy(s12nMetadataData?.XdpV1Resource.multiGet?.elements, (s12n) => {
    const metadataId = s12n?.id;
    return metadataId?.substring(metadataId.indexOf('~') + 1);
  });

  const s12nsWithMetadata: ProductWithMetadata[] = [];
  const proCertsWithMetadata: ProductWithMetadata[] = [];
  recs?.s12ns?.elements.forEach((s12n) => {
    const s12nWithMetadata = {
      ...s12n,
      // Fix typing
      ...(s12n?.id ? (s12nMetadataObj[s12n.id] as { [key: string]: any })?.xdpMetadata.sdpMetadata : {}),
    };
    if (s12n?.productVariant === NormalS12n) {
      s12nsWithMetadata.push(s12nWithMetadata);
    }
    if (s12n?.productVariant === ProfessionalCertificateS12n) {
      proCertsWithMetadata.push(s12nWithMetadata);
    }
  });

  if (recs?.s12ns?.elements && s12nsWithMetadata.length > 0) {
    recs.s12ns.elements = s12nsWithMetadata;
  }
  if (recs?.s12ns?.elements && recs?.proCerts?.elements && proCertsWithMetadata.length > 0) {
    recs.proCerts.elements = proCertsWithMetadata;
  }

  return { loading: isS12nRecsLoading || isS12nMetadataLoading, error, recs };
};

const S12nAndProCertRecsDataProvider = ({ programId, children }: PropsToComponent) => {
  const onboardingData = useOnboardingData();
  const { loading, error, recs } = useS12nRecsForSkillId(
    onboardingData?.skills.map((skill) => skill.id) ?? [],
    programId
  );
  // This request should only execute if the learner has selected the leadership goal in onboarding
  const {
    loading: leadershipRecsLoading,
    error: leadershipRecsError,
    recs: leadershipRecs,
  } = useS12nRecsForSkillId(
    onboardingData?.selectedGoals?.includes('LEADER') ? LEADERSHIP_SKILLS.map((skill) => skill.id) : [],
    programId,
    true
  );

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <S12nAndProCertRecsContext.Provider
      value={{ loading, error, recs, leadershipRecs, leadershipRecsLoading, leadershipRecsError }}
    >
      {children}
    </S12nAndProCertRecsContext.Provider>
  );
};

export default S12nAndProCertRecsDataProvider;
