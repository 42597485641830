import * as React from 'react';
import { useMemo, useState } from 'react';

import { compose, setDisplayName, withProps } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type { CircleMenuItem } from 'bundles/enterprise-legacy-learner-home/components/multiCourseProductCard/MultiCourseProductCirclesMenu';
import MultiCourseProductCirclesMenu from 'bundles/enterprise-legacy-learner-home/components/multiCourseProductCard/MultiCourseProductCirclesMenu';
import MultiCourseProductCourseCards from 'bundles/enterprise-legacy-learner-home/components/multiCourseProductCard/MultiCourseProductCourseCards';
import { PRODUCT_ID_INFIX } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import Naptime from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import OnDemandSpecializationProgressV1 from 'bundles/naptimejs/resources/onDemandSpecializationProgress.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';

type S12nCourseState = {
  isEnrolled: boolean;
  isCompleted: boolean;
  courseId: string;
};

type PropsFromNaptime = {
  s12nProgress?: {
    lastActiveCourseId: string;
  };
  curriculumProduct?: {
    s12nCourseStates: S12nCourseState[];
  };
};

type PropsFromWithProps = {
  circleMenuData: CircleMenuItem[];
  initialSelectedCourseId: string;
};

type PropsFromCaller = {
  userId: number;
  s12nProgress?: {
    lastActiveCourseId: string;
  };
  itemType: 'COURSE' | 'S12N' | 'BADGE';
  id: string;
  programId: string;
  name: string;
  circleMenuData?: CircleMenuItem[];
  courseCount: number;
  capstoneCourses?: string[];
  firstCourseId: string;
};

type Props = PropsFromCaller & PropsFromWithProps;

const MultiCourseProductEnrolledCardExtended = ({
  userId,
  programId,
  name,
  courseCount,
  id,
  circleMenuData,
  capstoneCourses,
  initialSelectedCourseId,
  s12nProgress,
}: Props) => {
  const [currentCourseId, setCurrentCourseId] = useState(s12nProgress?.lastActiveCourseId || initialSelectedCourseId);

  const handleCirclesMenuSelect = (selectedCourseId: string) => {
    setCurrentCourseId(selectedCourseId);
  };

  const currentCircleMenuData = useMemo(
    () =>
      circleMenuData.map((circleMenuItem) => ({
        ...circleMenuItem,
        isActive: circleMenuItem.courseId === currentCourseId,
      })),
    [circleMenuData, currentCourseId]
  );

  if (circleMenuData.length === 0) return null;

  return (
    <div
      className="rc-MultiCourseProductEnrolledCardExtended"
      data-e2e={`MultiCourseProductEnrolledCardExtended~${id}`}
    >
      <MultiCourseProductCirclesMenu
        circleMenuData={currentCircleMenuData}
        handleSelect={handleCirclesMenuSelect}
        courseCount={courseCount}
        name={name}
        capstoneProjectCount={capstoneCourses?.length}
      />
      <MultiCourseProductCourseCards circleMenuData={currentCircleMenuData} userId={userId} programId={programId} />
    </div>
  );
};

export default compose<Props, PropsFromCaller>(
  setDisplayName('S12nEnrolledCardExtendedHOC'),
  Naptime.createContainer<PropsFromNaptime, PropsFromCaller>(({ circleMenuData, programId, id, userId, itemType }) => ({
    s12nProgress:
      userId && itemType === 'S12N'
        ? OnDemandSpecializationProgressV1.get(tupleToStringKey([userId, id]), {
            field: ['lastActiveCourseId'],
          })
        : undefined,
    curriculumProduct: !circleMenuData
      ? ProgramCurriculumProductsV1.get(tupleToStringKey([programId, PRODUCT_ID_INFIX.S12N, id]), {})
      : undefined,
  })),
  // TODO(brivera): Remove this and the call to curriculumProduct when we fully rollout the new enrolled products query
  withProps<PropsFromWithProps, PropsFromNaptime & PropsFromCaller>(
    ({ s12nProgress, curriculumProduct, firstCourseId, circleMenuData, capstoneCourses }) => {
      const initialSelectedCourseId = s12nProgress?.lastActiveCourseId || firstCourseId;

      if (circleMenuData) {
        return {
          circleMenuData: circleMenuData.map((circleMenuItem) => ({
            ...circleMenuItem,
            isActive: circleMenuItem.courseId === initialSelectedCourseId,
          })),
          initialSelectedCourseId,
        };
      }

      const s12nCourseStates = curriculumProduct?.s12nCourseStates;

      // We need to do this so that the s12n card still renders correctly in control
      const defaultCircleMenuData =
        s12nCourseStates?.map(({ isEnrolled, isCompleted, courseId }, index) => ({
          courseId,
          isActive: initialSelectedCourseId === courseId,
          isEnrolled,
          isCompleted,
          index,
          isCapstone: capstoneCourses?.includes(courseId),
        })) ?? [];

      return {
        circleMenuData: defaultCircleMenuData,
        initialSelectedCourseId,
      };
    }
  )
)(MultiCourseProductEnrolledCardExtended);

export const BaseComponent = MultiCourseProductEnrolledCardExtended;
