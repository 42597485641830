import * as React from 'react';

import { branch, compose, renderNothing } from 'recompose';

import user from 'js/lib/user';

import { Container } from '@coursera/coursera-ui';

import CourseRoles from 'bundles/common/constants/CourseRoles';
import AdminLink from 'bundles/enterprise-legacy-xdp/components/banner/admin/AdminLink';
import StaffPreviewLink from 'bundles/enterprise-legacy-xdp/components/banner/admin/StaffPreviewLink';
import Naptime from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import BasicProfilesV1 from 'bundles/naptimejs/resources/basicProfiles.v1';
import OpenCourseMembershipsV1 from 'bundles/naptimejs/resources/openCourseMemberships.v1';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/banner/admin/__styles__/AdminBanner';

type PropsFromCaller = {
  slug: string;
  courseId?: string;
  courseStatus?: string | null;
};

type PropsFromRequest = {
  basicProfiles?: BasicProfilesV1;
  openCourseMemberships?: OpenCourseMembershipsV1;
};

type Props = PropsFromCaller & PropsFromRequest;

const NotEnrolledRoles = new Set<keyof typeof CourseRoles>([CourseRoles.NOT_ENROLLED, CourseRoles.BROWSER]);

export const AdminBanner = (props: Props) => {
  const { basicProfiles, courseId, courseStatus, openCourseMemberships, slug } = props;

  const isStaff = basicProfiles && basicProfiles.isStaff;
  const isEnrolled = openCourseMemberships && !NotEnrolledRoles.has(openCourseMemberships.courseRole);

  const isBetaTester = courseStatus === 'preenroll' && openCourseMemberships && openCourseMemberships.isLearner;
  const isMentor = courseStatus === 'preenroll' && openCourseMemberships && openCourseMemberships.isMentor;
  const isEnrolledStaff = isStaff && isEnrolled;
  const isSuperuser = user.isSuperuser();

  if (!(isSuperuser || isBetaTester || isMentor || isEnrolledStaff)) {
    return null;
  }

  const getRole = (): string | undefined => {
    if (isSuperuser) {
      return _t('as Superuser');
    } else if (isEnrolledStaff) {
      return _t('as Staff');
    } else if (isBetaTester) {
      return _t('as Beta Tester');
    } else if (isMentor) {
      return _t('as Mentor');
    } else {
      // If not any of the roles above, user shouldn't see this banner.
      return undefined;
    }
  };

  const getAdminLink = () => {
    return isSuperuser && <AdminLink isCourse={!!courseId} slug={slug} linkLabel={_t('Edit content')} />;
  };

  const getPreviewLink = () => {
    if (courseId) {
      return (isBetaTester || isMentor || isEnrolledStaff) && <StaffPreviewLink slug={slug} />;
    }
    return null;
  };

  return (
    <Container>
      <div className="admin-banner">
        <div className="admin-text">
          <span>
            <strong>{_t('Viewing')}</strong>
          </span>
          <span className="admin-banner-role">{getRole()}</span>
        </div>

        <div className="admin-configs">
          {getAdminLink()}
          {getPreviewLink()}
        </div>
      </div>
    </Container>
  );
};

type NaptimeProps = {
  slug: string;
};

export default compose<Props, PropsFromCaller>(
  branch<PropsFromCaller>(
    () => user.isAuthenticatedUser(),
    Naptime.createContainer(({ slug }: NaptimeProps) => {
      const userId = user.get().id;

      return {
        basicProfiles: BasicProfilesV1.me({
          fields: ['emailAddress', 'isStaff'],
        }),
        // Need to use finder here. This component will not render if this responds with an error code.
        // The above queries are necessary, but this below one is optional.
        openCourseMemberships: OpenCourseMembershipsV1.byUserAndSlug(userId, slug, {
          fields: ['courseRole'],
        }),
      };
    }),
    renderNothing
  )
)(AdminBanner);
