import * as React from 'react';

import * as Sentry from '@sentry/react';
import URI from 'jsuri';
import uniqBy from 'lodash/uniqBy';
import { compose } from 'recompose';

import redirect from 'js/lib/coursera.redirect';

import { useTracker } from '@coursera/event-pulse/react';

import EnrollmentChoiceModalForMultiprogram from 'bundles/enterprise-legacy-learner-home/components/EnrollmentChoiceModalForMultiprogram';
import EnrollmentChoiceModalPlaceholder from 'bundles/enterprise-legacy-learner-home/components/EnrollmentChoiceModalPlaceholder';
import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import ProgramActionApiManager from 'bundles/enterprise-legacy-learner-home/models/ProgramActionApiManager';
import ThirdPartyOrganizationChoiceModalForXDP from 'bundles/enterprise-legacy-xdp/components/ThirdPartyOrganizationChoiceModalForXDP';
import type { InjectedNaptime } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type ThirdPartyOrganization = {
  id: string;
  name: string;
};

type Program = {
  id: string;
  metadata?: {
    slug: string;
    name: string;
  };
  thirdPartyOrganizationId: string;
};

type PropsFromCaller = {
  thirdPartyOrganizations?: ThirdPartyOrganization[];
  programs?: Program[];
  productId: string;
  productType: string;
  // Either the slug for current course, or the slug for the first course in the specialization.
  courseSlug?: string;
  userId: number;
  handleClose: () => void;
  invitedPrograms?: Program[];
  invitedThirdPartyOrganizations?: ThirdPartyOrganization[];
  waitForBothProgramDataFetch: boolean;
};

type Props = PropsFromCaller & { naptime: InjectedNaptime };

const EnterpriseEnrollmentChoiceModalForXDP = ({
  thirdPartyOrganizations = [],
  invitedThirdPartyOrganizations = [],
  programs = [],
  invitedPrograms = [],
  waitForBothProgramDataFetch,
  productId,
  productType,
  userId,
  handleClose,
  naptime,
  courseSlug,
}: Props) => {
  const [thirdPartyOrganizationId, setThirdPartyOrganizationId] = React.useState<string | undefined>();
  const [displayOrgListModal, setDisplayOrgListModal] = React.useState<boolean>(false);
  const [displayProgramListModal, setDisplayProgramListModal] = React.useState<boolean>(false);
  const [isEnrolling, setIsEnrolling] = React.useState(false);
  const track = useTracker();

  const allThirdPartyOrganizations = uniqBy([...thirdPartyOrganizations, ...invitedThirdPartyOrganizations], 'id');
  const allPrograms = uniqBy([...programs, ...invitedPrograms], 'id');
  React.useEffect(
    () => {
      if (waitForBothProgramDataFetch && (!thirdPartyOrganizations.length || !invitedThirdPartyOrganizations.length)) {
        return;
      }
      if (allThirdPartyOrganizations.length > 0) {
        if (allThirdPartyOrganizations.length === 1) {
          setThirdPartyOrganizationId(allThirdPartyOrganizations[0].id);
          setDisplayProgramListModal(true);
        } else if (thirdPartyOrganizationId === undefined) {
          setThirdPartyOrganizationId(allThirdPartyOrganizations[0].id);
          setDisplayOrgListModal(true);
        }
      }
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      thirdPartyOrganizationId,
      allThirdPartyOrganizations,
      setThirdPartyOrganizationId,
      setDisplayOrgListModal,
      setDisplayProgramListModal,
    ]
  );

  const onSelect = React.useCallback(
    (orgId?: string) => {
      setThirdPartyOrganizationId(orgId);
      setDisplayOrgListModal(false);
      setDisplayProgramListModal(true);
    },
    [setThirdPartyOrganizationId, setDisplayOrgListModal, setDisplayProgramListModal]
  );

  const onOrgModalClose = React.useCallback(() => {
    setDisplayOrgListModal(false);
    handleClose();
  }, [setDisplayOrgListModal, handleClose]);

  const onProgramModalClose = React.useCallback(() => {
    setDisplayProgramListModal(false);
    handleClose();
  }, [setDisplayProgramListModal, handleClose]);

  const onProgramSelect = React.useCallback(
    async (programId: string) => {
      if (isEnrolling) {
        return;
      }

      setIsEnrolling(true);
      track('click_button', {
        button: {
          name: 'continue_process',
        },
        modal: {
          name: 'enterprise_enrollment',
        },
        enrollmentOptions: {
          enrollmentOptionsType: 'enroll_through_enterprise',
        },
        product: {
          id: productId,
          type: productType === PRODUCT_TYPES.SPECIALIZATION ? 's12n' : 'course',
        },
      });

      // If they're already a program member, enroll here on consumer to reduce friction.
      const isInvited = invitedPrograms.find((x) => x.id === programId) != null;
      if (!isInvited) {
        const apiManager = new ProgramActionApiManager({ programId, naptime, userId });
        const enrollPromise =
          productType === PRODUCT_TYPES.SPECIALIZATION
            ? apiManager.getEnrollInS12nPromise({ firstCourseId: null, s12nId: productId, collectionId: null })
            : apiManager.getEnrollInCoursePromise({ courseId: productId, collectionId: null });
        try {
          await enrollPromise;
        } catch (err) {
          // If this fails for some reason, we'll report it, but continue redirecting to program-home, so that they
          // aren't stuck on consumer.
          Object.assign(err, { enrollParams: { programId, productType, productId } });
          Sentry.captureException(err);
        }
      }

      const selectedProgram = allPrograms.find((program) => program.id === programId);
      const programSlug = selectedProgram?.metadata?.slug;
      if (courseSlug) {
        redirect.setLocation(`/learn/${courseSlug}/home`);
      } else if (programSlug) {
        const uri = new URI(`/programs/${programSlug}`)
          .addQueryParam('productId', productId)
          .addQueryParam('productType', productType)
          .addQueryParam('showMiniModal', true)
          .addQueryParam('eoc', true);
        redirect.setLocation(uri.toString());
      } else {
        handleClose();
      }
    },
    // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allPrograms, productId, productType, userId, naptime, isEnrolling, handleClose, invitedPrograms]
  );

  if (displayOrgListModal) {
    return (
      <ThirdPartyOrganizationChoiceModalForXDP
        thirdPartyOrganizations={allThirdPartyOrganizations}
        defaultOrgId={allThirdPartyOrganizations?.[0]?.id}
        productId={productId}
        productType={productType}
        onSelect={onSelect}
        onClose={onOrgModalClose}
      />
    );
  }

  if (displayProgramListModal) {
    const programIds = allPrograms
      .filter((program) => program.thirdPartyOrganizationId === thirdPartyOrganizationId)
      .map((program) => program.id);
    const thirdPartyOrganizationName = allThirdPartyOrganizations.find(
      (thirdPartyOrganization) => thirdPartyOrganization.id === thirdPartyOrganizationId
    )?.name;
    if (thirdPartyOrganizationName) {
      return (
        <EnrollmentChoiceModalForMultiprogram
          productId={productId}
          productType={productType}
          programIds={programIds}
          headerTitle={_t('Enroll through my organization')}
          buttonTitle={isEnrolling ? _t('Enrolling...') : _t('Continue')}
          thirdPartyOrgName={thirdPartyOrganizationName}
          onClose={onProgramModalClose}
          userId={userId}
          programJoinTrackingVerbiage={true}
          onSelect={onProgramSelect}
          isEnrolling={isEnrolling}
        />
      );
    }
  }

  return (
    <EnrollmentChoiceModalPlaceholder
      productId={productId}
      productType={productType}
      headerTitle={_t('Enroll through my organization')}
      buttonTitle={_t('Continue')}
      onClose={handleClose}
    />
  );
};

export default compose<Props, PropsFromCaller>(
  // Get an InjectedNaptime instance for ProgramActionApiManager
  Naptime.createContainer(() => ({}))
)(EnterpriseEnrollmentChoiceModalForXDP);
