/**
 * Private module reserved for @coursera/cds-core package.
 */

import type React from 'react';

import { Checkbox as MuiCheckbox } from '@material-ui/core';

import clsx from 'clsx';

import { useCheckboxGroupContext } from '@core/CheckboxGroup/CheckboxGroupContext';
import getChoiceInputCss, { classes } from '@core/forms/getChoiceInputCss';

import CheckboxCheckedIcon from './CheckboxCheckedIcon';
import CheckboxIndeterminateIcon from './CheckboxIndeterminateIcon';
import CheckboxUncheckedIcon from './CheckboxUncheckedIcon';

export type Props = {
  /**
   * State of the component. When used as a controlled component, this prop controls whether the component appears as checked.
   */
  checked?: boolean;
  /**
   * State of the component. When used as an uncontrolled component, this prop controls whether the component appears as checked.
   */
  defaultChecked?: boolean;
  /**
   * if true, checkbox will be disabled.
   */
  disabled?: boolean;
  /**
   * CSS class applied to the root element
   */
  className?: string;
  /**
   * The id of the `input` element.
   */
  id?: string;
  /**
   * Ref that points to the `input` element node
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  /**
   * If `true`, the component appears indeterminate.
   * This does not set the native input element to indeterminate due
   * to inconsistent behavior across browsers.
   * However, we set a `data-indeterminate` attribute on the input.
   */
  indeterminate?: boolean;
  /**
   * Sets the `name` attribute of the `input` element
   */
  name?: string;
  /**
   * Sets the `required` attribute on the `input` element
   */
  required?: boolean;
  /**
   * Style applied to the root element
   */
  style?: React.CSSProperties;
  /**
   * Sets the `tabIndex` attribute on the `input` element
   */
  tabIndex?: number;
  /**
   * The value of the component. The DOM API casts this to a string.
   */
  value?: React.ReactText;
  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  /**
   * Classname for focus visible state
   */
  focusVisibleClassName?: string;

  /**
   * If `true`, focus visible state will not be applied.
   */
  disableFocusVisible?: boolean;
};

const CheckboxInput = (props: Props): React.ReactElement<Props> => {
  const {
    disableFocusVisible,
    focusVisibleClassName,
    value,
    checked: checkedFromProps,
    onChange,
    ...rest
  } = props;

  const context = useCheckboxGroupContext();
  let checked = checkedFromProps;

  if (value && context?.value) {
    checked = context.value.includes(value);
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    context?.onChange?.(event, checked);
    onChange?.(event, checked);
  };

  return (
    <MuiCheckbox
      {...rest}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      aria-disabled={props.disabled}
      checked={checked}
      checkedIcon={<CheckboxCheckedIcon />}
      classes={{
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
        indeterminate: classes.indeterminate,
      }}
      css={getChoiceInputCss}
      focusVisibleClassName={clsx(
        !disableFocusVisible && classes.focusVisible,
        focusVisibleClassName
      )}
      icon={<CheckboxUncheckedIcon />}
      indeterminateIcon={<CheckboxIndeterminateIcon />}
      size="medium"
      value={value}
      onChange={handleChange}
    />
  );
};

export default CheckboxInput;
