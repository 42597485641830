import * as React from 'react';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import gql from 'graphql-tag';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { Button, Typography2 } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';
import { DropDown } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { redirectClientOrServer } from 'bundles/common/utils/urlUtils';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import type {
  ProgramSwitcherQuery,
  ProgramSwitcherQueryVariables,
} from 'bundles/program-home/components/multiprogram/__generated__/ProgramSwitcherQuery';
import { org_coursera_program_membership_ProgramMembershipState as MembershipState } from 'bundles/program-home/components/multiprogram/__generated__/globalTypes';
import { useIsUnifiedAppCheck } from 'bundles/unified-common/contexts/UnifiedAppCheckContext';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/ProgramSwitcher';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

export const ProgramSwitcherQueryText = gql`
  query ProgramSwitcherQuery($userId: String!) {
    ProgramMembershipsV2Resource {
      byUser(userId: $userId) {
        elements {
          id
          membershipState
          enterpriseProgram(eventualConsistency: true) {
            id
            thirdPartyOrganizationId
            metadata {
              name
              slug
            }
          }
        }
      }
    }
  }
`;

type PropsFromCaller = {
  userId: number | string;
  programSlug?: string;
  thirdPartyOrganizationId: string;
  thirdPartyOrganizationSlug: string;
};

type Program = {
  id: string;
  slug: string;
  name: string;
};

export type Props = PropsFromCaller;

const handleRedirect = (router: InjectedRouter, path: string, isInUnifiedApp: boolean) => {
  // When in unified-career-academy, do not use client-side transition.
  if (isInUnifiedApp) {
    redirectClientOrServer(path);
  } else {
    router.push(path);
  }
};

export const ProgramSwitcherRenderer = ({
  userId,
  thirdPartyOrganizationSlug,
  thirdPartyOrganizationId,
  programSlug,
}: Props): JSX.Element | null => {
  const router = useRouter();
  const trackComponent = useRetracked();
  const isInUnifiedApp = useIsUnifiedAppCheck();

  const { data } = useQuery<ProgramSwitcherQuery, ProgramSwitcherQueryVariables>(ProgramSwitcherQueryText, {
    variables: { userId: String(userId) },
    // Query is executed by the page header, we're just piggy-backing off of it.
    fetchPolicy: 'cache-only',
  });

  const programs = filterExistsOrDefault(data?.ProgramMembershipsV2Resource?.byUser?.elements)
    .filter(
      (element) =>
        element.enterpriseProgram &&
        element.enterpriseProgram.thirdPartyOrganizationId === thirdPartyOrganizationId &&
        element.membershipState === MembershipState.MEMBER
    )
    .map((element) => ({
      // See above filter().
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: element.enterpriseProgram!.id,
      // See above filter().
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      slug: element.enterpriseProgram!.metadata.slug,
      // See above filter().
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: element.enterpriseProgram!.metadata.name,
    }));

  const program = programs.find((program0) => program0.slug === programSlug);

  const handleClickProgram = (program0: Program) => {
    trackComponent({ trackingData: { program: program0 }, trackingName: 'program_switcher_program', action: 'click' });
    handleRedirect(router, `/programs/${program0?.slug}`, isInUnifiedApp);
  };

  const handleClickAllPrograms = () => {
    trackComponent({
      trackingData: { thirdPartyOrganizationSlug },
      trackingName: 'program_switcher_all_programs',
      action: 'click',
    });
    handleRedirect(router, `/programs/all/${thirdPartyOrganizationSlug}`, isInUnifiedApp);
  };

  if (programs.length <= 0) {
    return null;
  }

  if (programs.length > 1) {
    return (
      <DropDown.ButtonMenuV2
        usePopper
        usePopperPositionFixed
        renderButton={({ getDropDownButtonProps }) => {
          const {
            onClick,
            onKeyDown,
            ref,
            'aria-haspopup': ariaHasPopup,
            'aria-expanded': ariaExpanded,
            ...buttonProps
          } = getDropDownButtonProps();
          return (
            <TrackedButton
              {...Button.defaultProps}
              className="ProgramSwitcher-Button"
              variant="secondary"
              trackingName="program_switcher_choose_program_button"
              trackingData={{ programCount: programs.length, program }}
              _refAltAsTrueRef={ref}
              onClick={onClick}
              onKeyDown={onKeyDown}
              aria-haspopup={ariaHasPopup}
              aria-expanded={ariaExpanded}
              icon={ariaExpanded ? <ChevronUpIcon size="small" /> : <ChevronDownIcon size="small" />}
              iconPosition="after"
              data-testid="multi-program-select-button"
              {...buttonProps}
            >
              {_t('Choose program')}
            </TrackedButton>
          );
        }}
      >
        <DropDown.Item rootClassName="ProgramSwitcher-Item all" onClick={handleClickAllPrograms}>
          <span className={classNames('ProgramSwitcher-Item-Text', { selected: !program })}>
            <Typography2 component="p" className="ProgramSwitcher-Item-Label" variant="bodySecondary">
              {_t('All learning programs')}
            </Typography2>

            <CheckIcon
              className="ProgramSwitcher-Item-Check"
              size="small"
              color="interactive"
              title={_t('selected')}
              aria-hidden={!!program}
            />
          </span>
        </DropDown.Item>
        {programs.map((program0) => (
          <DropDown.Item
            key={program0.id}
            rootClassName="ProgramSwitcher-Item"
            onClick={() => handleClickProgram(program0)}
          >
            <span className={classNames('ProgramSwitcher-Item-Text', { selected: program?.slug === program0.slug })}>
              <Typography2 component="p" className="ProgramSwitcher-Item-Label" variant="bodySecondary">
                {program0.name}
              </Typography2>
              <CheckIcon
                className="ProgramSwitcher-Item-Check"
                size="small"
                color="interactive"
                title={_t('selected')}
                aria-hidden={program?.slug !== program0.slug}
              />
            </span>
          </DropDown.Item>
        ))}
      </DropDown.ButtonMenuV2>
    );
  }

  if (program) {
    return (
      <TrackedButton
        {...Button.defaultProps}
        className="ProgramSwitcher-Button"
        variant="secondary"
        trackingName="back_to_home"
        onClick={handleClickAllPrograms}
        data-testid="single-program-home-button"
      >
        {_t('Back to home')}
      </TrackedButton>
    );
  }

  return (
    <TrackedButton
      {...Button.defaultProps}
      className="ProgramSwitcher-Button"
      variant="secondary"
      trackingName="program_switcher_explore_program_button"
      trackingData={{ program: programs[0] }}
      onClick={() => handleClickProgram(programs[0])}
    >
      {_t('Explore full program')}
    </TrackedButton>
  );
};

export default ProgramSwitcherRenderer;
