import _t from 'i18n!nls/ai-coach-platform';

export const MESSAGE_SENDER = {
  CLIENT: 'USER',
  RESPONSE: 'SYSTEM',
} as const;

export const MESSAGES_TYPES = {
  TEXT: 'text',
};

export const AICOACH_FEEDBACK: { like: 'LIKE'; dislike: 'DISLIKE'; unset: 'UNSET' } = {
  like: 'LIKE',
  dislike: 'DISLIKE',
  unset: 'UNSET',
};

export const getErrorMessageText = () => _t('Sorry, there was an error responding. Please try your message again.');

export const ACTIVE_COURSERA_COACH_SESSION_STORAGE_KEY = 'activeCourseraCoachSessionId';

export const COACH_RECOMMENDED_ACTION_CLICK = 'coursera_coach_recommended_action_click';
