/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { NavigationButton } from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/NavigationButton';
import { NavigationMetadata } from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/NavigationMetadata';
import { NavigationTitle } from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/NavigationTitle';
import type { NavigationButtonItem } from 'bundles/ai-coach-platform/components/patterns/chat/types';

type Props = {
  itemId: string;
  title: string;
  buttonLabel?: string | null;
  itemType: NavigationButtonItem;
  itemDuration: string[];
  handleNavButtonClick: () => void;
};

const styles = {
  navigationButtonContainer: css`
    display: flex;
    width: 100%;
    min-width: auto;
    padding: var(--cds-spacing-200);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--cds-spacing-200);
    border-radius: var(--cds-border-radius-200);
    border: 2px solid var(--cds-color-grey-100);
    background: #f4f7fa;
  `,
};

export const NavigationButtonContainer = (props: Props): React.ReactElement => {
  const { itemId, title, itemType, itemDuration, buttonLabel, handleNavButtonClick } = props;
  const containerId = `${itemId}-container`;

  return (
    <div css={styles.navigationButtonContainer} id={containerId}>
      <NavigationTitle title={title} />
      <NavigationMetadata itemDuration={itemDuration} itemType={itemType} />
      <NavigationButton
        buttonLabel={buttonLabel}
        handleNavButtonClick={handleNavButtonClick}
        aria-describedby={containerId}
      />
    </div>
  );
};

export default NavigationButtonContainer;
