/** @jsx jsx */
import { jsx } from '@emotion/react';
import type { Interpolation, Theme } from '@emotion/react';

import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Link, Typography2, useTheme } from '@coursera/cds-core';

import type { InformationCardProps } from 'bundles/enterprise-legacy-learner-home/components/InformationCard/InformationCard';
import { Tab } from 'bundles/enterprise-legacy-learner-home/components/InformationCard/TabListCardLayout';
import TabListInformationCard from 'bundles/enterprise-legacy-learner-home/components/InformationCard/TabListInformationCard';
import SvgWhatScoresMean from 'bundles/enterprise-legacy-learner-home/svg/SvgWhatScoresMean';
import SvgWhatTargetsMean from 'bundles/enterprise-legacy-learner-home/svg/SvgWhatTargetsMean';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type SkillScoringInformationCardProps = {
  showTargetsFAQ: boolean;
};
type Props = Omit<InformationCardProps, 'trackingName' | 'trackingData'> & SkillScoringInformationCardProps;

type FaqProps = {
  label: string;
  marginBottom: string;
};

const FaqWhatScoresMean = ({ marginBottom, label }: FaqProps) => {
  const styles: Record<string, Interpolation<Theme>> = {
    whatScoresMean: {
      display: 'inline-block',
      position: 'relative',
    },

    label: {
      position: 'absolute',
      textAlign: 'center',

      '& span': {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: '#fff',
      },
    },
  };
  return (
    <Tab label={label}>
      <Typography2 component="h3" variant="subtitleMedium" color="highlightBlue" css={{ marginBottom }}>
        {_t('What scores mean')}
      </Typography2>
      <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
        {_t('Skill scores are a way to measure your skill level.')}
      </Typography2>
      <Typography2 component="p" variant="bodySecondary">
        <FormattedHTMLMessage
          message={_t(
            '<b>Conversant (1-50):</b> You have familiarity with the vocabulary and are able to pass easy content.'
          )}
        />
      </Typography2>
      <Typography2 component="p" variant="bodySecondary">
        <FormattedHTMLMessage
          message={_t(
            '<b>Beginner (51-150):</b> You have a working knowledge and are able to pass beginner content, but limited experience applying it.'
          )}
        />
      </Typography2>
      <Typography2 component="p" variant="bodySecondary">
        <FormattedHTMLMessage
          message={_t(
            '<b>Intermediate (151-350):</b> You have a solid understanding of the material and are able to pass intermediate content. You can apply key concepts on the job.'
          )}
        />
      </Typography2>
      <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
        <FormattedHTMLMessage
          message={_t(
            '<b>Advanced (351-500):</b> You have a mastery of the material and are able to pass advanced content. You can both teach others and identify novel applications of this skill.'
          )}
        />
      </Typography2>
      <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
        <FormattedHTMLMessage
          message={_t(
            '<b>Scores are reported on a scale out of 500.</b> Your score is not a percentage score like a grade in a class. This customized scale allows for more accurate scoring and a better measure of your capability.'
          )}
        />
      </Typography2>
      <div css={styles.whatScoresMean} aria-hidden="true">
        {/* use style below to prevent being flipped by emotion in RTL */}
        <div css={styles.label} style={{ left: 16, top: 12, width: 30 }}>
          <Typography2 component="span">{_t('Conversant')}</Typography2>
        </div>
        <div css={styles.label} style={{ left: 64, top: 48, width: 76 }}>
          <Typography2 component="span">{_t('Beginner')}</Typography2>
        </div>
        <div css={styles.label} style={{ left: 160, top: 12, width: 172 }}>
          <Typography2 component="span">{_t('Intermediate')}</Typography2>
        </div>
        <div css={styles.label} style={{ left: 354, top: 48, width: 128 }}>
          <Typography2 component="span">{_t('Advanced')}</Typography2>
        </div>
        <SvgWhatScoresMean />
      </div>
    </Tab>
  );
};
const FaqHowScoresAreCalculated = ({ marginBottom, label }: FaqProps) => (
  <Tab label={label}>
    <Typography2 component="h3" variant="subtitleMedium" color="highlightBlue" css={{ marginBottom }}>
      {_t('How scores are calculated')}
    </Typography2>
    <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
      <FormattedHTMLMessage
        message={_t(
          'Your skill score <b>reflects your performance on graded assessments.</b> This includes graded in-course quizzes and assignments (but not peer-reviewed assignments), as well as LevelSet assessments.'
        )}
      />
    </Typography2>
    <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
      {_t(
        'As you pass assessments your score may increase at a different rate depending on your skill level and the difficulty of the material. The higher your skill level, the more advanced content you need to pass to increase your score. If you pass harder assessments on your first attempt, your score may increase faster.'
      )}
    </Typography2>

    <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
      <FormattedHTMLMessage
        message={_t(
          'If you want to know what is under the hood of the data model that powers skill scoring, read <a target="_blank" rel="nofollow noopener" href="https://files.eric.ed.gov/fulltext/ED599237.pdf">this research paper</a>  that explains all the nuts and bolts.'
        )}
      />
    </Typography2>
  </Tab>
);
const FaqWhatTargetMeans = ({ marginBottom, label }: FaqProps) => (
  <Tab label={label}>
    <Typography2 component="h3" variant="subtitleMedium" color="highlightBlue" css={{ marginBottom }}>
      {_t('What targets mean')}
    </Typography2>
    <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
      {_t(
        'Targets help you understand your organization’s skill development goals for your role. For example, a target score of 250 indicates that your organization is seeking an intermediate skill level.'
      )}
    </Typography2>
    <SvgWhatTargetsMean />
  </Tab>
);
const FaqWhoCanSeeMyScores = ({ marginBottom, label }: FaqProps) => (
  <Tab label={label}>
    <Typography2 component="h3" variant="subtitleMedium" color="highlightBlue" css={{ marginBottom }}>
      {_t('Who can see my scores?')}
    </Typography2>
    <Typography2 component="p" variant="bodySecondary">
      {_t(
        'If you use Coursera through your organization, your administrator be able to see your skill score and level (conversant, beginner, intermediate, advanced) for each skill you are learning.'
      )}
    </Typography2>
  </Tab>
);
const FaqWorkInProgress = ({ marginBottom, label }: FaqProps) => (
  <Tab label={label}>
    <Typography2 component="h3" variant="subtitleMedium" color="highlightBlue" css={{ marginBottom }}>
      {_t('A work in progress')}
    </Typography2>
    <Typography2 component="p" variant="bodySecondary" css={{ marginBottom }}>
      {_t(
        "We're always working to improve our scoring system. We want to ensure your skill scores provide the best snapshot of your skills and capabilities. Have any questions? "
      )}
      <Link typographyVariant="bodySecondary" href="mailto:skillbeta@coursera.org" target="_blank">
        {_t('Share your feedback with us!')}
      </Link>
    </Typography2>
  </Tab>
);

const SkillScoringInformationCard: React.FC<Props> = (props) => {
  const theme = useTheme();
  const tabs = [
    <FaqWhatScoresMean key="FaqWhatScoresMean" label={_t('What scores mean')} marginBottom="var(--cds-spacing-150)" />,
    <FaqHowScoresAreCalculated
      key="FaqHowScoresAreCalculated"
      label={_t('How scores are calculated')}
      marginBottom="var(--cds-spacing-150)"
    />,
    ...(props.showTargetsFAQ
      ? [
          <FaqWhatTargetMeans
            key="FaqWhatTargetMeans"
            label={_t('What targets mean')}
            marginBottom="var(--cds-spacing-150)"
          />,
        ]
      : []),
    <FaqWhoCanSeeMyScores
      key="FaqWhoCanSeeMyScores"
      label={_t('Who can see my scores?')}
      marginBottom="var(--cds-spacing-150)"
    />,
    <FaqWorkInProgress
      key="FaqWorkInProgress"
      label={_t('A work in progress')}
      marginBottom="var(--cds-spacing-150)"
    />,
  ];
  return (
    <TabListInformationCard {...props} trackingName="skill_scoring_information_card">
      {tabs}
    </TabListInformationCard>
  );
};

export default SkillScoringInformationCard;
