import * as React from 'react';

import { compose } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import connectToRouter from 'js/lib/connectToRouter';
import type { InjectedRouter } from 'js/lib/connectToRouter';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import { branchBypassLogin } from 'bundles/program-home/components/ProgramHomeApp';
import type {
  EnterpriseProgramBySlugQueryResult,
  EnterpriseProgramBySlugQueryVariables,
} from 'bundles/program-home/queries/EnterpriseProgramBySlugQuery';
import EnterpriseProgramBySlugQuery from 'bundles/program-home/queries/EnterpriseProgramBySlugQuery';
import type { Stores } from 'bundles/unified-career-academy/appEnv';
import UnifiedAppCheckProvider from 'bundles/unified-common/providers/UnifiedAppCheckProvider';
import withMarketingConsent from 'bundles/user-consent/components/withMarketingConsent';

import 'css!bundles/unified-career-academy/index';

type Props = {
  requestCountryCode?: string | null;
  csrfToken?: string | null;
};
type PropsFromRouter = {
  router: InjectedRouter;
  programSlug: string;
};
type PropsForWithEnterpriseProgramData = Pick<PropsFromRouter, 'programSlug'>;
type PropsFromWithEnterpriseProgramData = { thirdPartyOrganizationId?: string };
const UnifiedCareerAcademyApp: React.FC<Props> = ({ children }) => {
  return (
    <div className="rc-UnifiedCareerAcademyApp">
      <UnifiedAppCheckProvider>{children}</UnifiedAppCheckProvider>
    </div>
  );
};

export default compose(
  connectToStores<Props, {}, Stores>(['ApplicationStore'], ({ ApplicationStore }, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  })),
  connectToRouter<PropsFromRouter, {}>((router): PropsFromRouter => {
    return {
      programSlug: router.params.programSlug,
      router,
    };
  }),
  withMarketingConsent,
  waitForGraphQL<
    PropsForWithEnterpriseProgramData,
    EnterpriseProgramBySlugQueryResult,
    EnterpriseProgramBySlugQueryVariables,
    PropsFromWithEnterpriseProgramData
  >(EnterpriseProgramBySlugQuery, {
    options: ({ programSlug }) => ({ variables: { programSlug } }),
    props: ({ data }) => {
      return {
        thirdPartyOrganizationId: data?.EnterpriseProgramsV1?.slug?.elements?.[0].thirdPartyOrganizationId,
      };
    },
  }),
  branchBypassLogin
)(UnifiedCareerAcademyApp);
