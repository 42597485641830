/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import { Typography2, breakpoints } from '@coursera/cds-core';

import { Heading } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';

import _t from 'i18n!nls/program-home';

type Props = {
  programName: string;
};

const styles = {
  container: css`
    ${breakpoints.up('sm')} {
      text-align: center;
    }
  `,
  text: css`
    margin: var(--cds-spacing-300) 0;
  `,
};

export default function AgeVerificationFail({ programName }: Props): JSX.Element {
  return (
    <div css={styles.container}>
      <Heading defaultLevel={1} css={styles.text}>
        {_t('You cannot join the program')}
      </Heading>
      <Typography2 component="p" css={styles.text}>
        {_t("Unfortunately, you do not meet the criteria to join '#{programName}'.", { programName })}
      </Typography2>
      <Typography2 component="p" css={styles.text}>
        {_t('Please ask the person who shared this link with you for help.')}
      </Typography2>
    </div>
  );
}
