import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import ExpertiseTooltip from 'bundles/enterprise-legacy-xdp/components/ExpertiseTooltip';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/instructors/__styles__/TopInstructor';

type Props = {
  instructorCount?: number;
  instructorName: string | null;
  instructorId: string;
};

const TopInstructor: React.FC<Props> = ({ instructorCount = 1, instructorName, instructorId }) => {
  const topInstructorClassNames = classNames('rc-TopInstructor', 'control');

  return (
    <div className={topInstructorClassNames}>
      <TrackedDiv trackingName="label_top_instructor" trackClicks trackMouseOver withVisibilityTracking>
        <ExpertiseTooltip
          id={`top-instructor-${instructorId}`}
          text={
            <FormattedMessage
              message={_t(
                `{numInstructors, plural,
                  =1 {{instructorName} is rated among the top instructors on Coursera.}
                  other {Instructors in this course are rated among the top instructors on Coursera.}}`
              )}
              numInstructors={instructorCount}
              instructorName={instructorName}
            />
          }
          hideIcon
        >
          <span className="top-instructor-label">
            <FormattedMessage
              message={_t('{numInstructors, plural, =1 {Top Instructor} other {Top Instructors}}')}
              numInstructors={instructorCount}
            />
          </span>
        </ExpertiseTooltip>
      </TrackedDiv>
    </div>
  );
};

export default TopInstructor;
