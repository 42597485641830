import * as React from 'react';
import { graphql } from 'react-apollo';

import gql from 'graphql-tag';

import API from 'js/lib/api';
import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import { Button, InlineNotification } from '@coursera/cds-core';
import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import storageUtils from 'bundles/enterprise-legacy-learner-home/utils/storageUtils';
import type {
  ProgramSSOBannerQuery,
  ProgramSSOBannerQueryVariables,
} from 'bundles/program-home/components/__generated__/ProgramSSOBannerQuery';

import _t from 'i18n!nls/program-home';

const COOKIE_NAME = 'sso_banner_dismissed';
const FourteenDays = 14 * 24 * 60 * 60 * 1000;

type NotificationType = 'createEmail' | 'successConfirmation' | 'noPassword';

type PropsFromCaller = {
  className?: string;
  userEmails: UserEmail[];
  thirdPartyOrganizationId: string;
};

type PropsFromGraphql = {
  recoveryAccountOnHomePage: boolean | undefined;
};

type Props = PropsFromCaller & PropsFromGraphql;

export function ProgramSSOBanner({
  className,
  userEmails,
  thirdPartyOrganizationId,
  recoveryAccountOnHomePage,
}: Props) {
  const router = useRouter();

  const showAddEmailModalQuery = router.location.query.showAddEmailModal;

  const haveAlternativeEmail = userEmails?.length > 1;
  const havePassword = !user.get().generated_password;

  const [isDismissed, setIsDismissed] = React.useState(
    () =>
      (haveAlternativeEmail && havePassword) ||
      storageUtils.get(user.get().id, thirdPartyOrganizationId, COOKIE_NAME, -Infinity) > Date.now()
  );
  const [notificationType, setNotificationType] = React.useState<NotificationType>(() => {
    if (showAddEmailModalQuery === 'success') {
      return 'successConfirmation';
    } else if (haveAlternativeEmail) {
      return 'noPassword';
    } else {
      return 'createEmail';
    }
  });

  const dismissBanner = React.useCallback(() => setIsDismissed(true), []);
  const sendPasswordResetEmail = React.useCallback(() => {
    API('/api/password/v2/requestReset', { type: 'rest' }).post('', { data: { email: user.get().email_address } });
    setNotificationType('successConfirmation');
  }, []);

  const showAddEmailModal = React.useCallback(() => {
    const { params } = router;
    const { pathname, query } = router.location;
    router.push({ pathname, params, query: { ...query, showAddEmailModal: true } });
  }, [router]);
  const omitAddEmailQuery = React.useCallback(() => {
    const { params } = router;
    const { pathname, query } = router.location;
    router.push({ pathname, params, query: { ...query, showAddEmailModal: undefined } });
  }, [router]);
  const dismissBannerWithCookie = React.useCallback(() => {
    storageUtils.set(user.get().id, thirdPartyOrganizationId, COOKIE_NAME, Date.now() + FourteenDays);
    dismissBanner();
  }, [thirdPartyOrganizationId, dismissBanner]);

  const shouldRenderNothing = isDismissed || recoveryAccountOnHomePage === false;

  React.useEffect(() => {
    if (showAddEmailModalQuery === 'success') {
      setNotificationType('successConfirmation');
      omitAddEmailQuery();
    }
  }, [showAddEmailModalQuery, omitAddEmailQuery]);

  if (shouldRenderNothing) {
    return null;
  }

  const title = _t('Link a personal account');
  const notificationProps = {
    createEmail: {
      severity: 'information' as const,
      children: _t('Create a linked personal account, so you can keep track of your accomplishments.'),
      inlineAction: (
        <Button variant="ghost" size="small" onClick={showAddEmailModal}>
          {_t('Create Now')}
        </Button>
      ),
      onDismiss: dismissBannerWithCookie,
    },
    successConfirmation: {
      severity: 'success' as const,
      children: _t(
        'An email has been sent to #{email}. Please follow the instructions in the link to set up your personal account.',
        { email: user.get().email_address }
      ),
      onDismiss: dismissBanner,
    },
    noPassword: {
      severity: 'error' as const,
      children: _t('Your personal account is not set up yet, please re-send the email and follow the instructions.'),
      inlineAction: (
        <Button variant="ghost" size="small" onClick={sendPasswordResetEmail}>
          {_t('Re-send the email')}
        </Button>
      ),
      onDismiss: dismissBannerWithCookie,
    },
  };

  return (
    <InlineNotification
      className={className}
      title={title}
      dismissActionProps={{ 'aria-label': _t('Dismiss: #{title}', { title }) }}
      {...notificationProps[notificationType]}
    />
  );
}

export default graphql<PropsFromCaller, ProgramSSOBannerQuery, ProgramSSOBannerQueryVariables, PropsFromGraphql>(
  gql`
    query ProgramSSOBannerQuery($thirdPartyOrganizationId: String!) {
      GdprSettingsV1Resource {
        get(id: $thirdPartyOrganizationId) {
          id
          recoveryAccountOnHomePage
        }
      }
    }
  `,
  {
    // Copied from the init of isDismissed since it voids the query.
    skip: ({ userEmails, thirdPartyOrganizationId }) =>
      (userEmails?.length > 1 && !user.get().generated_password) ||
      storageUtils.get(user.get().id, thirdPartyOrganizationId, COOKIE_NAME, -Infinity) > Date.now(),
    options: ({ thirdPartyOrganizationId }) => ({ variables: { thirdPartyOrganizationId } }),
    props: ({ data }) => ({
      recoveryAccountOnHomePage: data?.GdprSettingsV1Resource?.get?.recoveryAccountOnHomePage,
    }),
  }
)(ProgramSSOBanner);
