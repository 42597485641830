import type { NavigationButtonItem } from 'bundles/ai-coach-platform/components/patterns/chat/types';

type GenerateCourseItemLink = {
  itemType: NavigationButtonItem;
  courseSlug: string;
  itemSlug: string;
  itemId: string;
};

// This is used for navigation buttons and generates a link to a course item based on itemType
export const generateCourseItemLink = ({ itemType, courseSlug, itemSlug, itemId }: GenerateCourseItemLink) => {
  let courseItemLink;

  switch (itemType) {
    case 'lecture':
      courseItemLink = `/learn/${courseSlug}/lecture/${itemId}/${itemSlug}`;
      break;
    case 'supplement':
      courseItemLink = `/learn/${courseSlug}/supplement/${itemId}/${itemSlug}`;
      break;
    default:
      // If there is no itemType, we don't need to generate a link because the learner will not see the recommended navigation
      courseItemLink = '';
  }

  return courseItemLink;
};
