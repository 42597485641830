import * as React from 'react';

import { pure } from 'recompose';

import type { ButtonProps } from '@coursera/coursera-ui';
import { ApiButton, Button, color } from '@coursera/coursera-ui';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';
import { SvgBookmarkFilled } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  btnAttributes?: ButtonProps;
  onClick: () => void;
  apiStatus: ApiStatus;
  canUnselect?: boolean;
  isSelected?: boolean;
  renderNothingIfConditionNotMet?: boolean;
  isProject?: boolean;
  initialBtnLabel?: string;
};

const CourseUnselectButton = ({
  btnAttributes = {},
  isProject,
  onClick,
  apiStatus,
  canUnselect,
  isSelected,
  renderNothingIfConditionNotMet,
  initialBtnLabel = _t('Remove from Saved'),
}: Props) => {
  const renderUnselect = isSelected && canUnselect;
  const renderSelected = isSelected && !renderNothingIfConditionNotMet;
  const buttonStyle = isProject ? { color: color.white } : { color: color.black };
  const bookmarkStyleLight = isProject ? color.white : color.primary;

  if (renderUnselect) {
    return (
      <ApiButton
        {...btnAttributes}
        style={buttonStyle}
        iconAttributes={{
          suppressTitle: true,
        }}
        onClick={onClick}
        apiStatus={apiStatus}
        htmlAttributes={{
          ...btnAttributes?.htmlAttributes,
          'aria-live': 'polite',
          'aria-busy': apiStatus === 'API_IN_PROGRESS',
        }}
        apiStatusAttributesConfig={{
          label: {
            API_BEFORE_SEND: initialBtnLabel,
            API_IN_PROGRESS: _t('Removing...'),
            API_SUCCESS: _t('Removed'),
            API_ERROR: _t('Failed'),
          },
        }}
      >
        <SvgBookmarkFilled
          color={bookmarkStyleLight}
          hoverColor={color.darkPrimary}
          htmlAttributes={{ 'aria-hidden': true }}
        />
      </ApiButton>
    );
  } else if (renderSelected) {
    return (
      <Button
        style={buttonStyle}
        {...btnAttributes}
        type="disabled"
        label={_t('Selected')}
        disabled={true}
        htmlAttributes={{ 'aria-label': _t('Selected') }}
      />
    );
  }
  return null;
};

export default pure(CourseUnselectButton);
