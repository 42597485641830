import type { XdpV1_Org_Coursera_Xdp_Sdp_SdpCourse as Course } from '__generated__/graphql-types';
import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import usePageData from 'bundles/enroll/data/usePageData';
import { PRODUCTS } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import GetSpecializationById from 'bundles/payments-common/api/GetSpecializationById.graphql';
import type {
  GetSpecializationByIdQuery as GetSpecializationByIdQueryData,
  GetSpecializationByIdQueryVariables,
  SpecializationFieldsFragment,
} from 'bundles/payments-common/api/__generated__/GetSpecializationById';
import withPromotionInfo, { withPromoCodeFromUrl } from 'bundles/promotions/components/withPromotionInfo';
import type { PropsFromWithPromotionInfo } from 'bundles/promotions/components/withPromotionInfo';
import { getS12nVariantLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';
import type {
  S12nCoursesByIdQuery,
  S12nCoursesByIdQueryVariables,
} from 'bundles/s12n-enroll/components/non-recurring/__generated__/S12nCoursesByIdQuery';

type PropsFromCaller = {
  s12nId: string;
  onSdp: boolean;
  courseId?: string;
};

type PropsFromCoursesGraphQL = {
  courses: Array<Course>;
};

type PropsFromGetSpecialization = {
  s12n?: SpecializationFieldsFragment;
};

export type PropsFromWithS12nProductInfo = {
  s12nName: string;
  productName: string;
  product: string;
  numOfCourses: number;
  partnerName: string;
  courseId: string;
  photoUrl?: string;
} & PropsFromCoursesGraphQL &
  PropsFromWithPromotionInfo;

const getS12nCoursesById = gql`
  query S12nCoursesByIdQuery($productId: String!) {
    XdpV1Resource {
      get(id: $productId) {
        id
        xdpMetadata {
          ... on XdpV1_sdpMetadataMember {
            sdpMetadata {
              id
              courses {
                id
                material {
                  weeks {
                    modules {
                      id
                      totalDuration
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const withOnDemandSpecializations = waitForGraphQL<
  PropsFromCaller,
  GetSpecializationByIdQueryData,
  GetSpecializationByIdQueryVariables,
  PropsFromGetSpecialization
>(GetSpecializationById, {
  options: ({ s12nId }) => ({
    variables: {
      id: s12nId,
    },
    context: {
      clientName: 'gatewayGql',
    },
    errorPolicy: 'all',
  }),
  props: ({ data }) => {
    return {
      s12n: data?.Specialization?.queryById ?? undefined,
    };
  },
});

export const withCoursesModulesDuration = () =>
  waitForGraphQL<{ s12nId: string }, S12nCoursesByIdQuery, S12nCoursesByIdQueryVariables, { courses?: Array<Course> }>(
    getS12nCoursesById,
    {
      options: ({ s12nId }: { s12nId: string }) => ({
        variables: {
          productId: tupleToStringKey([PRODUCTS.specialization, s12nId]),
        },
      }),
      props: ({ data }) => {
        const xdpMetadata = data?.XdpV1Resource?.get?.xdpMetadata;

        // check type since it could also be XdpV1_cdpMetadataMember
        if (xdpMetadata?.__typename === 'XdpV1_sdpMetadataMember') {
          const s12nCourses = xdpMetadata.sdpMetadata?.courses;
          // TS convert the courses variable into the format that is used in SDPQuery
          return { courses: s12nCourses as Array<Course> };
        }

        return { courses: undefined };
      },
    }
  );

export const useS12nProductInfo = ({ courseId, skip }: { courseId?: string; skip?: boolean }) => {
  const { s12n, course, courses, partners, promotionDetails, promotionEligibilities } = usePageData();

  if (skip) {
    return {};
  }

  const partner = partners?.[0];
  const s12nName = s12n?.name ?? '';
  const s12nProductVariant = s12n?.productVariant ?? undefined;

  return {
    productName: course?.name ?? s12nName,
    product: getS12nVariantLabel(s12nProductVariant),
    s12nName,
    partnerName: partner?.name ?? '',
    numOfCourses: s12n?.courseIds?.length ?? 0,
    courseId: courseId ?? s12n?.courseIds?.[0] ?? '',
    courses,
    promotionDetails,
    promotionEligibilities,
  };
};

export const withS12nProductInfo = <InputProps extends PropsFromCaller>() =>
  compose<PropsFromWithS12nProductInfo & InputProps, InputProps>(
    withOnDemandSpecializations,
    withCoursesModulesDuration(),
    withPromotionInfo(),
    withPromoCodeFromUrl(),
    withProps<
      PropsFromWithS12nProductInfo,
      InputProps & PropsFromGetSpecialization & PropsFromCoursesGraphQL & PropsFromWithPromotionInfo
    >(({ s12n, courses, courseId, promoCodes, promotionDetails, promotionEligibilities }) => {
      const course = s12n?.courses.find((c) => c?.id === courseId);
      const partner = s12n?.partners?.[0];
      const s12nName = s12n?.name ?? '';
      const s12nProductVariant = s12n?.productVariant ?? undefined;

      return {
        productName: course?.name ?? s12nName,
        product: getS12nVariantLabel(s12nProductVariant),
        s12nName,
        partnerName: partner?.name ?? '',
        numOfCourses: s12n?.courses?.length ?? 0,
        courseId: courseId ?? s12n?.courses?.[0].id ?? '',
        photoUrl: s12n?.cardImageUrl ?? undefined,
        courses,
        promoCodes,
        promotionDetails,
        promotionEligibilities,
      };
    })
  );
