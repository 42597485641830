/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';

import type { ToolbarPosition } from 'bundles/cml/editor/types/cmlEditorProps';
import { cdsToCMLStyles } from 'bundles/cml/legacy/components/cds/cdsToCMLStyles';

const styles = {
  editor: (theme: Theme) => css`
    outline: none;
    background-color: var(--cds-color-neutral-background-primary);
    padding: var(--cds-spacing-150) var(--cds-spacing-200);

    [data-slate-editor='true'] {
      ${cdsToCMLStyles(theme)}
      min-height: 100% !important;

      // Slate does not properly handle scrolling with void nodes (images, assets, code blocks, etc...).
      // The bug is due to the internal spacer node having "absolute" positioning. This is especially a problem for void nodes
      // that have their own scrollable sections like Code blocks. CP-12378
      // https://github.com/ianstormtaylor/slate/issues/2302
      [data-slate-void='true'],
      [data-slate-spacer='true'] {
        position: relative !important;
      }
    }
  `,
};

export type Props = {
  toolbarPosition?: ToolbarPosition;
  toolbar?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const EditorContainer = React.forwardRef<HTMLDivElement, Props>((props: Props) => {
  const { toolbar, toolbarPosition, children, className, style } = props;

  return (
    <React.Fragment>
      {toolbarPosition === 'top' && toolbar}
      <div className={`${className} .data-cml-editor-padding-container`} css={styles.editor} style={style}>
        {children}
      </div>
      {toolbarPosition === 'bottom' && toolbar}
    </React.Fragment>
  );
});

export default React.memo(EditorContainer);
