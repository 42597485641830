import * as React from 'react';

import { isUserRightToLeft } from 'js/lib/language';

import { Typography2 } from '@coursera/cds-core';
import { Box, Col, Row, css } from '@coursera/coursera-ui';
import { SvgPhone } from '@coursera/coursera-ui/svg';

import type { InformationCardProps } from 'bundles/enterprise-legacy-learner-home/components/InformationCard/InformationCard';
import InformationCard from 'bundles/enterprise-legacy-learner-home/components/InformationCard/InformationCard';
import InformationCardBody from 'bundles/enterprise-legacy-learner-home/components/InformationCard/InformationCardBody';
import InformationCardFooter from 'bundles/enterprise-legacy-learner-home/components/InformationCard/InformationCardFooter';
import HowItWorksSkillItem from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/HowItWorksSkillItem';
import WatchWelcomeVideoButton from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/WatchWelcomeVideoButton';
import { howItWorksInformationCardStyles } from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/styles';
import {
  CardTitle,
  HowItWorksCaption,
} from 'bundles/enterprise-legacy-learner-home/components/StaticInformationCards/typography';
import SvgAccessToAllContent from 'bundles/enterprise-legacy-learner-home/svg/SvgAccessToAllContent';
import SvgAccessToAllContentRtl from 'bundles/enterprise-legacy-learner-home/svg/SvgAccessToAllContentRtl';
import SvgCatalog from 'bundles/enterprise-legacy-learner-home/svg/SvgCatalog';
import SvgCatalogRtl from 'bundles/enterprise-legacy-learner-home/svg/SvgCatalogRtl';
import SvgCredentials from 'bundles/enterprise-legacy-learner-home/svg/SvgCredentials';
import SvgCredentialsRtl from 'bundles/enterprise-legacy-learner-home/svg/SvgCredentialsRtl';
import SvgGuidedProjects from 'bundles/enterprise-legacy-learner-home/svg/SvgGuidedProjects';
import SvgGuidedProjectsRtl from 'bundles/enterprise-legacy-learner-home/svg/SvgGuidedProjectsRtl';
import SvgSkills from 'bundles/enterprise-legacy-learner-home/svg/SvgSkills';
import SvgSkillsRtl from 'bundles/enterprise-legacy-learner-home/svg/SvgSkillsRtl';
import MobileAppBadges from 'bundles/page/components/MobileAppBadges';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = Omit<InformationCardProps, 'trackingName' | 'trackingData'> & {
  tutorialVideoUrl?: string;
  tutorialVideoAssetId?: string;
  userAuthenticated?: boolean;
  hasTSPs: boolean;
};

const HowItWorksInformationCard: React.FC<Props> = (props) => {
  const { tutorialVideoUrl, tutorialVideoAssetId, userAuthenticated, hasTSPs, ...rest } = props;
  const shouldShowVideoButton = userAuthenticated && (tutorialVideoUrl || tutorialVideoAssetId);
  const isRTL = isUserRightToLeft();

  let bonusInfoCard: JSX.Element | null;
  if (hasTSPs) {
    bonusInfoCard = (
      <HowItWorksSkillItem icon={isRTL ? SvgSkillsRtl : SvgSkills} title={_t('Skill Tracking')}>
        <Typography2 component="p">
          {_t(
            'Track your skill development over time and compare to industry benchmarks and your organization’s objectives'
          )}
        </Typography2>
      </HowItWorksSkillItem>
    );
  } else {
    bonusInfoCard = (
      <HowItWorksSkillItem
        icon={isRTL ? SvgAccessToAllContentRtl : SvgAccessToAllContent}
        title={_t('Access to all content')}
      >
        <Typography2 component="p">
          {_t('You have access to all of the content in this catalog. Enroll in any courses of your choice.')}
        </Typography2>
      </HowItWorksSkillItem>
    );
  }

  return (
    <InformationCard {...rest} trackingName="how_it_works_information_card">
      <InformationCardBody>
        <Row>
          <div {...css(howItWorksInformationCardStyles.cardHeader)}>
            <CardTitle>{_t('How it works')}</CardTitle>
          </div>
        </Row>
        <Row noGutter>
          <Col sm={12} md={6}>
            <HowItWorksSkillItem icon={isRTL ? SvgCatalogRtl : SvgCatalog} title={_t('Curated Catalog')}>
              <Typography2 component="p">
                {hasTSPs
                  ? _t(
                      'Browse through skill-based recommendations and enroll in courses from the world’s top universities and industry leaders'
                    )
                  : _t(
                      'Browse through recommendations and enroll in courses from the world’s top universities and industry leaders'
                    )}
              </Typography2>
            </HowItWorksSkillItem>
          </Col>
          <Col sm={12} md={6}>
            <HowItWorksSkillItem icon={isRTL ? SvgGuidedProjectsRtl : SvgGuidedProjects} title={_t('Guided Projects')}>
              <Typography2 component="p">
                {_t(
                  'Complete projects using real-world scenarios that prepare you to use what you learn on the job right away'
                )}
              </Typography2>
            </HowItWorksSkillItem>
          </Col>
        </Row>
        <Row noGutter>
          <Col sm={12} md={6}>
            {bonusInfoCard}
          </Col>
          <Col sm={12} md={6}>
            <HowItWorksSkillItem icon={isRTL ? SvgCredentialsRtl : SvgCredentials} title={_t('Shareable Credentials')}>
              <Typography2 component="p">
                {_t('Get recognized, earn certificates for completed courses and become a master in your field')}
              </Typography2>
            </HowItWorksSkillItem>
          </Col>
        </Row>
        {shouldShowVideoButton && (
          <Box justifyContent="center" rootClassName={howItWorksInformationCardStyles.cardActionBox}>
            <WatchWelcomeVideoButton videoAssetId={tutorialVideoAssetId} videoUrl={tutorialVideoUrl} />
          </Box>
        )}
      </InformationCardBody>
      <InformationCardFooter htmlAttributes={{ ...css(howItWorksInformationCardStyles.footer) }}>
        <Row alignItems="center" noGutter>
          <Col col>
            <Box alignItems="center">
              <div {...css(howItWorksInformationCardStyles.phoneIconWrapper)}>
                <SvgPhone size={48} />
              </div>

              <div>
                <HowItWorksCaption>{_t('Mobile Learning')}</HowItWorksCaption>
                <Typography2 component="p">
                  {_t("Download Coursera's mobile app to learn anywhere, anytime.")}
                </Typography2>
              </div>
            </Box>
          </Col>

          <MobileAppBadges target="_blank" />
        </Row>
      </InformationCardFooter>
    </InformationCard>
  );
};

export default HowItWorksInformationCard;
