/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

type Props = {
  size?: IconSize;
  fill?: string;
};

/**
 * MUI icon for microphone off/disabled mode
 */
const MicrophoneOffIcon = ({ size = 16, fill = 'inherit' }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 -960 960 960"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M672-377q-14-8-18-24.5t4-30.5q7-11 11.5-23.5T676-481q4-17 15.5-28t28.5-11q17 0 28 12t9 29q-3 23-10.5 45T727-392q-8 14-24.5 18.5T672-377ZM480-594Zm0-286q50 0 85 35t35 85v190q0 20-12.5 30T560-530q-15 0-27.5-10.5T520-571v-189q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v30q0 20-12.5 30T400-690q-15 0-27.5-10.5T360-731v-29q0-50 35-85t85-35Zm-40 720v-83q-92-12-157.5-77.5T203-479q-2-17 9-29t28-12q17 0 29 11.5t15 28.5q14 71 69 115.5T480-320q34 0 64.5-10.5T600-360l57 57q-29 23-63.5 39T520-243v83q0 17-11.5 28.5T480-120q-17 0-28.5-11.5T440-160Zm324 76L84-764q-11-11-11-28t11-28q11-11 28-11t28 11l680 680q11 11 11 28t-11 28q-11 11-28 11t-28-11Z"
    />
  </svg>
);

export default MicrophoneOffIcon;
