import * as React from 'react';

import URI from 'jsuri';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import type { Product } from 'bundles/enterprise-legacy-learner-home/types/programCommon';

type MiniModalProps = {
  tag?: 'div';
  isCourse: boolean;
  id: string;
  collectionId?: string;
  unifiedDescriptionPageProps?: UnifiedDescriptionPageProps;
} & React.HTMLAttributes<HTMLDivElement>;

type PropsFromCaller = {
  miniModalProps: MiniModalProps;
  onClick?: (event: React.SyntheticEvent) => void;
};

type PropsForWrappedComponent = {};
export type PropsForInjectedComponent = {
  onClick?: (event: React.SyntheticEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  role?: string;
  tabIndex?: number;
  style?: React.CSSProperties;
};

type PropsForComponent = PropsForWrappedComponent | PropsForInjectedComponent;

type PropsFromRouter = {
  router: InjectedRouter;
};

type UnifiedDescriptionPageProps = {
  slug?: string;
  productPagePath?: (typeof DESCRIPTION_PAGE_PATHS)[keyof typeof DESCRIPTION_PAGE_PATHS];
};

export const DESCRIPTION_PAGE_PATHS = {
  specialization: 'specializations',
  professionalCertificate: 'professional-certificates',
  project: 'projects',
  course: 'learn',
} as const;

export const getProductPath = (product: Product) => {
  if (product.courseTypeMetadata) {
    return product.courseTypeMetadata.courseTypeMetadata.__typename === 'CourseTypeMetadataV1_standardCourseMember'
      ? DESCRIPTION_PAGE_PATHS.course
      : DESCRIPTION_PAGE_PATHS.project;
  } else if (product.productVariant) {
    return product.productVariant === 'NormalS12n'
      ? DESCRIPTION_PAGE_PATHS.specialization
      : DESCRIPTION_PAGE_PATHS.professionalCertificate;
  } else {
    // If this returns null we will not redirect to unified description page from launchMiniModal function.
    return undefined;
  }
};

export function redirectToUnifiedDescriptionPage(
  router: InjectedRouter,
  unifiedDescriptionPageProps: UnifiedDescriptionPageProps,
  collectionId: string | undefined,
  query?: Record<string, string>,
  shouldNavigate: boolean = false
) {
  const uri = new URI(
    `/programs/${router.params.programSlug}/${unifiedDescriptionPageProps.productPagePath}/${unifiedDescriptionPageProps.slug}`
  );

  // Some query params we want to shed and some we'd like to keep. Params in this list will be retained.
  const queryParamsToRetain = new Set(['deepLinkItemId', 'fromClip', 'source']);
  const queryParams = { ...(router?.location?.query || {}), ...(query || {}) };

  if (Object.keys(queryParams).length > 0) {
    Object.keys(queryParams).forEach((paramKey) => {
      if (queryParamsToRetain.has(paramKey) && queryParams[paramKey]) {
        uri.addQueryParam(paramKey, queryParams[paramKey]);
      }
    });
  }

  if (collectionId) {
    uri.addQueryParam('collectionId', collectionId);
  }

  if (shouldNavigate) {
    window.location.assign(uri.toString());
  } else {
    window.open(uri.toString());
  }
}

export const shouldRedirectToUnifiedDescriptionPage = (router: InjectedRouter) => {
  // Unified description page only supports single program paths.
  return (
    !router.location.pathname.includes('programs/all/') &&
    !router.location.pathname.includes('/admin/') &&
    !router.location.pathname.includes('/o/') && // all of admin-dashboard
    router.params.programSlug
  );
};

export function launchMiniModal(
  router: InjectedRouter,
  id: string,
  isCourse: boolean,
  collectionId: string | undefined,
  pathname?: string,
  query?: Record<string, string>,
  unifiedDescriptionPageProps?: UnifiedDescriptionPageProps
) {
  if (!router) return;

  const safeToRedirectToUnifiedDescriptionPage = shouldRedirectToUnifiedDescriptionPage(router);

  if (
    safeToRedirectToUnifiedDescriptionPage &&
    unifiedDescriptionPageProps?.slug &&
    unifiedDescriptionPageProps?.productPagePath
  ) {
    redirectToUnifiedDescriptionPage(router, unifiedDescriptionPageProps, collectionId, query);
  } else if (
    !router.params.programSlug &&
    pathname &&
    unifiedDescriptionPageProps?.slug &&
    unifiedDescriptionPageProps?.productPagePath
  ) {
    // when not in enterprise land, we can redirect to the unified description page
    const programSlug = pathname.split('/')[2];
    redirectToUnifiedDescriptionPage(
      { ...router, params: { ...router.params, programSlug } },
      unifiedDescriptionPageProps,
      collectionId,
      query,
      true
    );
  } else if (id) {
    const productType = isCourse ? PRODUCT_TYPES.COURSE : PRODUCT_TYPES.SPECIALIZATION;

    router.push({
      pathname: pathname ?? router.location.pathname,
      query: {
        ...router.location.query,
        ...query,
        productId: id,
        productType,
        showMiniModal: true,
        collectionId,
      },
    });
  }
}

export default function withMiniModal<P extends PropsForComponent>() {
  const linkStyle = {
    cursor: 'pointer',
  };
  type OuterProps = PropsFromCaller & P;
  type MiddleProps = OuterProps & PropsFromRouter;
  type InnerProps = P & PropsForComponent;
  return (Component: React.ComponentType<InnerProps>): React.ComponentType<OuterProps> => {
    const componentName = Component.displayName || Component.name;

    const HOC: React.ComponentType<MiddleProps> = ({
      miniModalProps: { tag: Tag, isCourse, id, collectionId, unifiedDescriptionPageProps, ...wrapperProps },
      onClick,
      router,
      ...passthroughProps
    }: MiddleProps): JSX.Element => {
      // When the wrapped component is clicked, push the related route and bubble up call (if any)
      const handleClick = (e: React.SyntheticEvent) => {
        e?.preventDefault();
        if (onClick) {
          onClick(e);
        } else {
          launchMiniModal(router, id, isCourse, collectionId, undefined, undefined, unifiedDescriptionPageProps);
        }
      };

      const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === 'Spacebar' || e.key === ' ') {
          handleClick(e);
          e.preventDefault();
        }
      };

      const componentProps: P = passthroughProps as unknown as P;
      const buttonProps: PropsForInjectedComponent = {
        onClick: handleClick,
        onKeyDown: handleKeyDown,
        role: 'link',
        tabIndex: 0,
        style: linkStyle,
      };

      if (!Tag) {
        return <Component {...componentProps} {...buttonProps} />;
      }

      return (
        <Tag {...wrapperProps} {...buttonProps}>
          <Component {...componentProps} />
        </Tag>
      );
    };

    // Some usages don't specify props and rely on it being interpreted as an Injected component, launchMiniModal
    // cancels the onClick via !id check.
    Object.assign(HOC, { defaultProps: { miniModalProps: {} } });

    HOC.displayName = `withMiniModal(${componentName})`;

    hoistNonReactStatics(HOC, Component);
    return connectToRouter<MiddleProps, OuterProps>((router) => ({ router }))(HOC);
  };
}
