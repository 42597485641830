import * as React from 'react';
import { useEffect } from 'react';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import user from 'js/lib/user';

import SubscriptionTiersSideBySideEnrollModal from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersSideBySideEnrollModal';
import SubscriptionTiersWithS12nEnrollModal from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersWithS12nEnrollModal';
import AllPlansModal from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/AllPlansModal';
import CourseraPlusHeroModal from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/CourseraPlusHeroModal';
import IdealUpgradeEnrollModalContainer from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/IdealUpgradeEnrollModalContainer';
import IndiaPriceTestUpgradeModal from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/IndiaUpgrade/IndiaPriceTestUpgradeModal';
import {
  generateImpressionForTiersEnrollmentFromStandaloneCourses,
  getIsLearnerImpressedInIndiaTiersEpic,
  getIsStandaloneCourseEligibleForTiersEnroll,
  isLiteSideBySideEnrollModalShown,
  isSubscriptionTiersDesignV3Variant1,
  isSubscriptionTiersDesignV3Variant3,
  isSubscriptionTiersDesignV3Variant4,
  isSubscriptionTiersDesignV3Variant5,
  isSubscriptionTiersVariantC,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import ClosedCourseEnrollModal from 'bundles/enroll-course/components/ClosedCourseEnrollModal';
import CourseEnrollS12nSelectionModal from 'bundles/enroll-course/components/CourseEnrollS12nSelectionModal';
import CourseWithFullDiscountEnrollModal from 'bundles/enroll-course/components/CourseWithFullDiscountEnrollModal';
import StandaloneCourseEnrollModal from 'bundles/enroll-course/components/StandaloneCourseEnrollModal';
import UserInterestModal from 'bundles/enroll-course/components/user-interest/UserInterestModal';
import CourseraPlusEnrollModal from 'bundles/enroll/components/coursera-plus/CourseraPlusEnrollModal';
import GuidedProjectEnrollModal from 'bundles/enroll/components/guided-project/GuidedProjectEnrollModal';
import SelfPacedProjectEnrollModal from 'bundles/enroll/components/projects/SelfPacedProjectEnrollModal';
import SubscriptionEnrollModal from 'bundles/enroll/components/subscriptions/SubscriptionEnrollModal';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import EnterpriseEnrollmentChoiceModalForXDP from 'bundles/enterprise-legacy-learner-home/components/EnterpriseEnrollmentChoiceModalForXDP';
import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import paymentsExperiments from 'bundles/epic/clients/payments';
import { CourseType } from 'bundles/naptimejs/resources/courseTypeMetadata.v1';
import PartnersV1 from 'bundles/naptimejs/resources/partners.v1';
import NonRecurringEnrollModal from 'bundles/s12n-enroll/components/non-recurring/NonRecurringEnrollModal';
import S12nPaymentMethodEnrollModal from 'bundles/s12n-enroll/components/non-recurring/S12nPaymentMethodEnrollModal';
import CancelPreEnrollS12nSelectModal from 'bundles/s12n-enroll/components/pre-enrollment/CancelPreEnrollS12nSelectModal';
import S12nPreEnrollModal from 'bundles/s12n-enroll/components/pre-enrollment/S12nPreEnrollModal';

export type PropsFromCaller = {
  courseId: string;
  s12nId?: string;
  partnerIds?: Array<string>;
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onS12nSelect: (s12nId: string) => void;
  // Pre Enrollment V2
  fromMultipleLearningProgramsLink?: boolean;
  router?: InjectedRouter;
};

const CourseEnrollModal: React.FC<PropsFromCaller> = ({
  courseId,
  onClose,
  onS12nSelect,
  fromMultipleLearningProgramsLink,
  router,
}) => {
  const {
    course,
    s12n,
    s12ns,
    enrollmentAvailableChoices,
    courseTypeMetadataWithVersion,
    s12nDerivatives,
    programs,
    thirdPartyOrganizations,
    invitedPrograms,
    invitedThirdPartyOrganizations,
    ownsCourseraLite,
    partners,
    isCourseraPlusEligibleProduct,
    promotionEligibilities,
  } = useCourseEnrollModalData();

  const {
    canSubscribeToS12n,
    canEnrollThroughS12nPrepaid,
    canEnrollThroughCourseraPlus,
    didPurchase,
    isSpecializationUpgradeRequired,
    canEnrollThroughProgram,
    canEnrollCourseWithFullDiscount,
    canEnrollThroughProgramInvitation,
    canSubscribeToCourseraLite,
    canSubscribeToCourseraPlus,
    canPurchaseSingleCourse,
    canPurchaseThroughCourseraPlus,
    isEnrolled,
    isMixAndMatch,
    isPreEnrolled,
    preEnrolledS12nIds,
    selectedS12nIdOverrideToEnrollThroughCourseraPlus: selectedS12nIdOverride,
    isMixMatchWithS12nsThatCannotEnrollThroughPlus,
    canEnrollThroughPlusWithSelectedS12n,
  } = enrollmentAvailableChoices;

  const isMultiplePreEnrolledS12ns = preEnrolledS12nIds.length > 1;
  const showPreEnrollCancelModal = isMixAndMatch && isPreEnrolled && !fromMultipleLearningProgramsLink;
  const isEnterpriseUser = canEnrollThroughProgram || canEnrollThroughProgramInvitation;
  const hasPromotion = Boolean(promotionEligibilities?.isEligible ? promotionEligibilities?.promoCodeId : undefined);
  const { isClosedCourse } = course;
  // This could be an array of partner objects or partner objects that contain only their IDs
  const partnersListWithIds = partners ?? course.partnerIds?.map((id) => new PartnersV1({ id }));
  const hasSpecialization = Number(course?.s12nIds?.length) > 0;
  const isStandaloneCourseEligibleForTiersEnroll = getIsStandaloneCourseEligibleForTiersEnroll({
    hasSpecialization,
    enrollmentAvailableChoices,
    isClosedCourse: Boolean(isClosedCourse),
  });

  useEffect(() => {
    if (showPreEnrollCancelModal && !isMultiplePreEnrolledS12ns) {
      onS12nSelect(preEnrolledS12nIds?.[0]);
    }
  }, [showPreEnrollCancelModal, isMultiplePreEnrolledS12ns, preEnrolledS12nIds, onS12nSelect]);

  useEffect(() => {
    if (isMixAndMatch && selectedS12nIdOverride) {
      onS12nSelect(selectedS12nIdOverride);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedS12nIdOverride, isMixAndMatch]);

  const tiersImpressionUseEffectDepArray = [
    canSubscribeToCourseraPlus,
    hasPromotion,
    isStandaloneCourseEligibleForTiersEnroll,
    hasSpecialization,
    partnersListWithIds?.[0],
  ];

  useEffect(() => {
    if (
      canSubscribeToCourseraPlus &&
      !hasPromotion &&
      (hasSpecialization || isStandaloneCourseEligibleForTiersEnroll)
    ) {
      generateImpressionForTiersEnrollmentFromStandaloneCourses({
        hasSpecialization,
        hasPromotion,
        isStandaloneCourseEligibleForTiersEnroll,
        partners: partnersListWithIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, tiersImpressionUseEffectDepArray);

  if (showPreEnrollCancelModal && isMultiplePreEnrolledS12ns) {
    return (
      <CancelPreEnrollS12nSelectModal
        handleClose={onClose}
        automaticallyEnroll={isEnterpriseUser || canEnrollThroughCourseraPlus}
      />
    );
  }

  // If user can enroll into multiple s12ns, have user first select a specific s12n to enroll in
  if (isMixAndMatch && !s12n) {
    return (
      <CourseEnrollS12nSelectionModal
        onClose={onClose}
        onSubmit={onS12nSelect}
        isTerminal={canEnrollThroughCourseraPlus}
      />
    );
  }

  const waitForBothProgramDataFetch = canEnrollThroughProgram && canEnrollThroughProgramInvitation;

  const isFromS12nSelection = isMixAndMatch && Boolean(s12n);
  const s12nId = s12n?.id;
  const shouldShowPrepaidModal =
    s12nId &&
    ((canEnrollThroughS12nPrepaid && enrollmentAvailableChoices?.prepaidEnrollmentS12nIds?.includes(s12nId)) ||
      (canPurchaseThroughCourseraPlus &&
        enrollmentAvailableChoices?.courseraPlusPrepaidEnrollmentS12nIds?.includes(s12nId)));

  const shouldShowPreEnrollModal =
    !paymentsExperiments.preview('disablePreEnrollment') &&
    s12nId &&
    enrollmentAvailableChoices.canPreEnrollOrIsPreEnrolledS12n(s12nId);

  // This is defined when you click on a SCDP from a SDP
  const specializationSlugQueryParam = router?.location?.query?.specialization;
  const referrerS12n = s12ns?.find((s12nObj) => s12nObj.slug === specializationSlugQueryParam);

  const showSubscriptionTiersDesignV3Variant3 = isSubscriptionTiersDesignV3Variant3({
    hasSpecialization,
    partners: partnersListWithIds,
    isStandaloneCourseEligibleForTiersEnroll,
  });

  // For the list of s12ns that are in the pre enrolled flow, show the pre enroll modal before any other s12n modal
  if (shouldShowPreEnrollModal && s12nId) {
    return (
      <S12nPreEnrollModal
        s12nId={s12nId}
        onSdp={false}
        onClose={onClose}
        courseId={courseId}
        automaticallyEnroll={isEnterpriseUser || canEnrollThroughCourseraPlus}
      />
    );
    // No matter what happens, show the product interest modal if the course is not launched
  } else if (s12nDerivatives?.isSubscription && !course.isLaunched) {
    return <UserInterestModal courseId={courseId} onClose={onClose} />;
    // Only trigger Coursera Plus flow for users who are already subscribed users
  } else if (
    canEnrollThroughCourseraPlus &&
    (!isMixMatchWithS12nsThatCannotEnrollThroughPlus || canEnrollThroughPlusWithSelectedS12n(s12nId as string))
  ) {
    return <CourseraPlusEnrollModal courseId={courseId} onClose={onClose} />;
  } else if (isCourseraPlusEligibleProduct && isEnrolled && ownsCourseraLite) {
    if (getIsLearnerImpressedInIndiaTiersEpic()) {
      return <IndiaPriceTestUpgradeModal onClose={onClose} />;
    }
    return <IdealUpgradeEnrollModalContainer onClose={onClose} />;
  } else if (canSubscribeToCourseraLite && isSubscriptionTiersDesignV3Variant4() && !hasPromotion) {
    return <AllPlansModal onClose={onClose} />;
  } else if (
    canSubscribeToCourseraLite &&
    !hasPromotion &&
    (showSubscriptionTiersDesignV3Variant3 || isSubscriptionTiersDesignV3Variant5())
  ) {
    return <CourseraPlusHeroModal handleClose={onClose} isTiersVariant3={showSubscriptionTiersDesignV3Variant3} />;
  } else if (canSubscribeToCourseraLite && isLiteSideBySideEnrollModalShown() && !hasPromotion) {
    return <SubscriptionTiersSideBySideEnrollModal onClose={onClose} onSdp={false} />;
  } else if (
    canSubscribeToCourseraLite &&
    !hasPromotion &&
    (isSubscriptionTiersVariantC() || isSubscriptionTiersDesignV3Variant1())
  ) {
    return <SubscriptionTiersWithS12nEnrollModal onClose={onClose} onSdp={false} />;
  } else if (shouldShowPrepaidModal) {
    const PrepaidModal = enrollmentAvailableChoices?.subscriptionEnrollmentS12nIds.includes(s12nId)
      ? S12nPaymentMethodEnrollModal
      : NonRecurringEnrollModal;

    return (
      <PrepaidModal
        s12nId={s12nId}
        courseId={course.id}
        onSdp={false}
        onClose={onClose}
        isFromS12nSelection={isFromS12nSelection}
      />
    );
  } else if (canSubscribeToS12n) {
    return <SubscriptionEnrollModal onClose={onClose} onSdp={false} isFromS12nSelection={isFromS12nSelection} />;
  } else if (canEnrollCourseWithFullDiscount) {
    return <CourseWithFullDiscountEnrollModal courseId={courseId} onClose={onClose} />;
  }
  // Technically Guided Project is a closed course. We don't want to show the Closed course modal for this so
  // the Guided Project check should be placed before closed course.
  else if (
    courseTypeMetadataWithVersion?.courseTypeMetadata.typeName === CourseType.RHYME_PROJECT &&
    canPurchaseSingleCourse
  ) {
    return <GuidedProjectEnrollModal courseId={courseId} onClose={onClose} />;
  } else if (
    courseTypeMetadataWithVersion?.courseTypeMetadata.typeName === CourseType.PROJECT &&
    canPurchaseSingleCourse
  ) {
    return <SelfPacedProjectEnrollModal courseId={courseId} onClose={onClose} />;
  } else if (isClosedCourse && !didPurchase && !canEnrollThroughProgram && !isSpecializationUpgradeRequired) {
    return <ClosedCourseEnrollModal onClose={onClose} />;
  } else if (isEnterpriseUser) {
    return (
      <EnterpriseEnrollmentChoiceModalForXDP
        thirdPartyOrganizations={thirdPartyOrganizations}
        programs={programs}
        invitedThirdPartyOrganizations={invitedThirdPartyOrganizations}
        invitedPrograms={invitedPrograms}
        productId={course.id}
        productType={PRODUCT_TYPES.COURSE}
        userId={user.get().id}
        handleClose={onClose}
        waitForBothProgramDataFetch={waitForBothProgramDataFetch}
      />
    );
    // This modal both handles standalone course enrollment
    // and enrolling into a course as part of an existing s12n subscription/purchase
    // TODO: separate out these two cases being handled into different components
  } else {
    return <StandaloneCourseEnrollModal courseId={courseId} s12nId={s12nId} onClose={onClose} />;
  }
};

export default CourseEnrollModal;
