import * as React from 'react';

import { compose, pure } from 'recompose';

import { Button } from '@coursera/coursera-ui';

import withCourseSlug from 'bundles/enterprise-legacy-learner-home/components/programActionButton/withCourseSlug';

type PropsFromCaller = {
  id: string;
  htmlAttributes?: (
    | React.AnchorHTMLAttributes<HTMLAnchorElement>
    | React.ButtonHTMLAttributes<HTMLButtonElement>
    | React.HTMLAttributes<HTMLElement>
  ) & { 'data-e2e'?: string };
  linkToCDP?: boolean;
  label?: string | React.ReactNode;
};

type Props = {
  course: {
    homeLink: string;
    link: string;
    name: string;
  } | null;
} & PropsFromCaller;

/**
 * A universal CourseLinkButton
 * Can link to CDP or CourseHome
 * Can extend more if needed
 */
const CourseLinkButton = ({ htmlAttributes = {}, linkToCDP, course, label, ...rest }: Props) => {
  const homeLink = course?.homeLink ?? '';
  const link = course?.link ?? '';
  const name = course?.name ?? '';
  return (
    <Button
      type="primary"
      tag="a"
      size="md"
      label={label || name}
      htmlAttributes={{
        href: linkToCDP ? link : homeLink,
        target: '_blank',
        rel: 'noopener noreferrer',
        ...htmlAttributes,
      }}
      {...rest}
    />
  );
};

export default compose<Props, PropsFromCaller>(withCourseSlug, pure)(CourseLinkButton);
