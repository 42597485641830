import { StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import { getIsEnrollButtonDisabled, getIsEnrolled } from 'bundles/enroll/components/xdp/enrollSectionUtils';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import { policies } from 'bundles/enroll/utils/scopedContentRule';
import type { ScopedContentRule } from 'bundles/enroll/utils/scopedContentRulesQuery';
import type { CourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

import _t from 'i18n!nls/xdp';

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'inline-block',
    [`@media (max-width: ${breakPoint.md - 1}px)`]: {
      width: '100%',
    },
  },
});

type EnrollmentState = 'IsEnrolled' | 'EnrollForFree' | 'NotEnrolled';

/**
 * This holds general page information
 */
class EnrollButtonViewController {
  private declare title: string;

  private declare isEnrolled: boolean;

  private declare isEnrollButtonDisabled: boolean;

  private declare course: CoursesV1;

  private declare courseType: CourseType;

  constructor(
    courseType: CourseType,
    course: CoursesV1,
    enrollmentAvailableChoices?: EnrollmentChoices,
    scopedContentRule?: ScopedContentRule,
    productDisplayName?: string
  ) {
    this.course = course;
    this.courseType = courseType;
    const isSpecialization = false;
    const isEnrolled = getIsEnrolled({ enrollmentAvailableChoices, isSpecialization });
    let enrollmentState: EnrollmentState;
    if (isEnrolled) {
      enrollmentState = 'IsEnrolled';
    } else if (scopedContentRule?.policy === policies.ALLOW) {
      enrollmentState = 'EnrollForFree';
    } else {
      enrollmentState = 'NotEnrolled';
    }

    if (this.courseType === 'StandardCourse') {
      this.title = '';
    } else {
      this.title = this.getTitle(enrollmentState, this.getProductDisplayName(productDisplayName));
    }

    this.isEnrollButtonDisabled = getIsEnrollButtonDisabled({ enrollmentAvailableChoices, isSpecialization, course });
    this.isEnrolled = isEnrolled;
  }

  private getProductDisplayName(override?: string): string {
    if (override) {
      return override;
    } else {
      switch (this.courseType) {
        case 'Project':
          return _t('Project');
        case 'GuidedProject':
          return _t('Guided Project');
        default:
          return '';
      }
    }
  }

  private getTitle(enrollmentState: EnrollmentState, productDisplayName: string): string {
    switch (enrollmentState) {
      case 'IsEnrolled':
        return _t('Go to #{type}', { type: productDisplayName });
      case 'EnrollForFree':
        return _t('Enroll for Free');
      case 'NotEnrolled':
      default:
        return _t('Start #{type}', { type: productDisplayName });
    }
  }

  getButtonProps() {
    return {
      isEnrolled: this.isEnrolled,
      buttonLabel: this.title,
      buttonDisabled: this.isEnrollButtonDisabled,
      trackingName: 'sticky_header',
      rootClassName: css(styles.buttonContainer).className,
      mobileBreakpoint: breakPoint.md,
      slug: this.course.slug, // slug is used to redirect to project home if user is already enrolled
      courseType: this.courseType,
      name: this.course.name,
    };
  }
}

export default EnrollButtonViewController;
