import * as React from 'react';

import { rtlStyle } from 'js/lib/language';

import { Box, StyleSheet, css } from '@coursera/coursera-ui';
import { SvgCheck } from '@coursera/coursera-ui/svg';

import { CML } from 'bundles/cml';
import { getNaptimeCMLShape } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import type { BannerInfoBox_cmlLearningObjectives_cml as LearningObjectivesCML } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/BannerInfoBox';

const ICON_SIZE = 20;

type Props = {
  cml: LearningObjectivesCML;
};

const getContextualStyles = () =>
  StyleSheet.create(
    rtlStyle({
      container: {
        marginBottom: '8px',
        ':last-child': {
          marginBottom: '0',
        },
        // Aphrodite hack to target child svg
        ':nth-child(1n) > svg': {
          marginTop: '2px',
          // minWidth prevents the SVG from shrinking in flexbox
          minWidth: `${ICON_SIZE}px`,
        },
      },
      value: {
        marginLeft: '12px',
      },
    })
  );

const BannerInfoText: React.FunctionComponent<Props> = ({ cml }) => {
  const styles = getContextualStyles();
  const cmlNaptimeShape = getNaptimeCMLShape(cml);
  return (
    <Box alignItems="start" rootClassName={css(styles.container).className}>
      <SvgCheck size={ICON_SIZE} color="#2A73CC" suppressTitle={true} />
      <span {...css(styles.value)}>
        <CML cml={cmlNaptimeShape} />
      </span>
    </Box>
  );
};

export default BannerInfoText;
