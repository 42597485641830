/* eslint-disable camelcase */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { debounce } from 'lodash';

import { useRetracked } from 'js/lib/retracked';

import { TextField } from '@coursera/cds-core';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { SendIcon } from 'bundles/ai-coach-platform/components/icons/mui';
import SpeechToTextButton from 'bundles/ai-coach-platform/components/patterns/chat/common/SpeechToTextButton';
import useSpeechToText from 'bundles/ai-coach-platform/components/patterns/chat/utils/VoiceInput/useSpeechToText';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  root: (isFocused: boolean) => css`
    display: flex;
    flex-direction: column;
    background-color: var(--cds-color-neutral-background-primary-weak);
    border-radius: var(--cds-border-radius-100);
    padding: var(--cds-spacing-100) var(--cds-spacing-150);
    outline: ${isFocused ? '1px solid var(--cds-color-emphasis-neutral-background-xstrong)' : 'none'};
  `,

  preview: css`
    display: flex;
    overflow: auto;
    margin: var(--cds-spacing-50) 0;
  `,

  textField: (isListening: boolean) => css`
    /* Coach branding overrides on CDS Textfield */
    .cds-input-multiline {
      margin-bottom: var(--cds-spacing-100);
      background: transparent;

      fieldset {
        border: none !important;
      }
    }

    textarea.coach-rich-input-field-input {
      padding: var(--cds-spacing-50) 0;
      caret-color: ${isListening ? 'var(--cds-color-emphasis-primary-stroke-default)' : 'black'};
    }

    .cds-input-input {
      background: var(--cds-color-neutral-background-primary-weak);
    }

    .cds-input-focused {
      &::before {
        display: none;
      }

      &:hover {
        background: var(--cds-color-neutral-background-primary-weak) !important;
      }

      .cds-input-input::placeholder {
        color: var(--cds-color-grey-800) !important;
      }
    }
  `,

  footer: css`
    display: flex;
    flex-wrap: wrap;
  `,

  actionButtons: css`
    display: flex;
    gap: var(--cds-spacing-50);
  `,

  voiceTextInput: css`
    display: flex;
    flex: 1;
    gap: var(--cds-spacing-50);
    justify-content: flex-end;
  `,
};

export type Props = {
  /**
   * custom placeholder for the input field
   */
  placeholder?: string;

  /**
   * Custom preview area for media attachments
   */
  preview?: React.ReactNode;
  /**
   * Custom action buttons to be displayed in the footer
   */
  actionButtons?: React.ReactNode;
  /**
   * Custom input field component to be used instead of the default TextField
   */
  input?: React.ReactNode;
  /**
   * Callback for when the text input changes
   */
  onTextInputChange?: (text: string) => void;
  /**
   * Callback for when the user sends a message
   */
  onSend?: (text: string) => void;

  /**
   * Custom aria-label for input field
   */
  'aria-label'?: string;

  /**
   * Whether the input field should be disabled
   */
  disabledInput?: boolean;

  /**
   * Controlled text value for the input field
   */
  value?: string;
};

/**
 * Rich input field for Coach chat. Supports plain-text input as well as
 * voice input by default when supported by browsers.
 */
export const RichInputField = (props: Props): React.ReactElement => {
  const {
    onSend,
    onTextInputChange,
    disabledInput = false,
    'aria-label': ariaLabel,
    placeholder = _t('Ask me anything...'),
    input,
    preview,
    actionButtons,
    value = '',
  } = props;
  const [inputText, setInputText] = React.useState<string>(value);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const clearTranscriptRef = React.useRef<() => void>(() => {});
  const recognitionRef = React.useRef<SpeechRecognition | null>(null);
  const [interimTranscript, setInterimTranscript] = React.useState<string>('');

  // whether the component as a whole should maintain focused state (primarily for a11y)
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  // whether text was manually edited by user before sending
  const [editedInput, setEditedInput] = React.useState<boolean>(false);

  React.useEffect(() => {
    setInputText(value);
  }, [value]);

  React.useEffect(() => {
    return () => {
      // clean up STT resources on unmount
      clearTranscriptRef.current();
      recognitionRef.current?.abort();
    };
  }, []);

  // temporarily use v2 eventing to quickly get STT usage insights
  const trackRetracked = useRetracked();
  const trackingContext = useCoachTrackingContext()?.data;
  const [isListening, setIsListening] = React.useState<boolean>(false);

  const handleScroll = React.useCallback(() => {
    if (inputRef.current) {
      // auto-scroll text area so that overflow text is always visible
      inputRef.current.focus();
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  }, []);

  const scrollDebounce = React.useMemo(() => {
    return debounce(handleScroll, 100);
  }, [handleScroll]);

  const handleTranscript = React.useCallback(
    (transcript: string, isInterim: boolean) => {
      if (isInterim) {
        setInterimTranscript(transcript);
      } else {
        setInputText((prevText) => prevText + transcript);
        setInterimTranscript('');
      }

      scrollDebounce();
    },
    [scrollDebounce]
  );

  const { isSupportedBrowser } = useSpeechToText({ onTranscript: handleTranscript });

  const handleClickOutside = React.useCallback((event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsFocused(false);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleSend = () => {
    if (!inputText) {
      return;
    }

    onSend?.(inputText);

    trackRetracked({
      trackingData: {
        mode: trackingContext?.mode,
        input_text: inputText,
        // whether text was manually edited by user before sending
        was_text_edited: editedInput,
      },
      trackingName: 'ai_coach_platform_richinputfield_send_message',
      action: 'click',
    });

    setInputText('');
    // reset edited input state for new message
    setEditedInput(false);
    // put focus back on the input field after message is sent
    inputRef.current?.focus();
    // clear STT transcript after sending
    clearTranscriptRef.current();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // ignore keypress when STT is active
    if (isListening) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      handleSend();
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isListening) {
      // skip text input change when STT is active
      e.preventDefault();
      return;
    }

    const newText = e.target.value;
    setInputText(newText);
    onTextInputChange?.(newText);

    setEditedInput(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // document.activeElement updates after the blur event, so we need to wait for the next tick
    setTimeout(() => {
      // maintain focus state if any of the children elements are focused
      if (!containerRef.current?.contains(document.activeElement as Node)) {
        setIsFocused(false);
      }
    }, 0);
  };

  const handleVoiceStart = () => {
    trackRetracked({
      trackingData: {
        mode: trackingContext?.mode,
      },
      trackingName: 'ai_coach_platform_stt_start',
      action: 'click',
    });
  };

  const handleVoiceEnd = () => {
    trackRetracked({
      trackingData: {
        // final transcribed text after voice input ends
        input_text: inputRef.current?.value,
        mode: trackingContext?.mode,
      },
      trackingName: 'ai_coach_platform_stt_stop',
      action: 'click',
    });
  };

  const inputElementProps = {
    onKeyPress: handleKeyPress,
    required: false,
    className: 'coach-rich-input-field-input',
  };

  return (
    <div
      className="coach-rich-input-field"
      role="group"
      aria-label={ariaLabel ?? _t('Chat message composer')}
      css={styles.root(isFocused)}
      ref={containerRef}
    >
      {input ?? (
        <div className="rich-input-field-content">
          <TextField
            readOnly={disabledInput}
            placeholder={placeholder}
            fullWidth
            multiline
            maxRows={7}
            minRows={1}
            value={`${inputText}${interimTranscript}`}
            onChange={handleTextInputChange}
            inputProps={inputElementProps}
            aria-label={placeholder}
            data-testid="coach-rich-input-field-content"
            css={styles.textField(isListening)}
            inputRef={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      )}

      {preview ? (
        <div
          className="rich-input-field-preview"
          css={styles.preview}
          role="region"
          aria-label={_t('Media preview container')}
        >
          {preview}
        </div>
      ) : null}

      <div className="rich-input-field-footer" css={styles.footer} role="toolbar">
        {actionButtons ? (
          <div className="footer-action-buttons" css={styles.actionButtons}>
            {actionButtons}
          </div>
        ) : null}

        <div className="footer-voice-text-input" css={styles.voiceTextInput}>
          {isSupportedBrowser ? (
            <SpeechToTextButton
              onTranscript={handleTranscript}
              onStartListening={handleVoiceStart}
              onStopListening={handleVoiceEnd}
            >
              {({ clearTranscript, listening, recognition }) => {
                clearTranscriptRef.current = clearTranscript;
                recognitionRef.current = recognition;
                setIsListening(listening);
              }}
            </SpeechToTextButton>
          ) : null}

          <CoachIconButton
            onClick={handleSend}
            icon={<SendIcon />}
            aria-label={_t('Send message')}
            tooltip={_t('Send message')}
            disabled={!inputText || isListening}
            size="small"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default RichInputField;
