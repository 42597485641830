/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { DataValue } from 'react-apollo';
import Waypoint from 'react-waypoint';

import { useQuery } from '@apollo/client';
import { reject, times, unionBy } from 'lodash';

import { Button, Grid, InlineNotification, PageGridContainer, Typography2, useTheme } from '@coursera/cds-core';

import { redirectClientOrServer } from 'bundles/common/utils/urlUtils';
import { reorderCertificates } from 'bundles/enterprise-admin-grow-with-google/utils/helpers';
import Collection from 'bundles/enterprise-collections/components/Collection';
import { EnterpriseProductCardCollectionsPlaceholder } from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import { Heading, Section } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
import AcademicSubjectQuickJump from 'bundles/enterprise-legacy-learner-home/components/single-program/AcademicSubjectQuickJump';
import CurriculumCollectionsQuery from 'bundles/enterprise-legacy-learner-home/components/single-program/queries/CurriculumCollections.graphql';
import type {
  CurriculumCollectionsQuery as CurriculumCollectionsQueryData,
  CurriculumCollectionsQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/components/single-program/queries/__generated__/CurriculumCollections';
import type {
  CollectionListItemStruct,
  Product,
  ProductType,
} from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import fetchMoreErrorFilter from 'bundles/enterprise-legacy-learner-home/utils/fetchMoreErrorFilter';
import EnterpriseLearningPathsNotConfigurable from 'bundles/page-config-common/components/enterprise/EnterpriseLearningPathsNotConfigurable';
import useCourseEntitiesAsCurriculumCourses from 'bundles/page-config-common/hooks/useCourseEntitiesAsCurriculumCourses';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

export const COLLECTION_LOAD_LIMIT = 3;

type PropsFromCaller = {
  programId: string;
  programSlug: string;
  start?: string;
  limit?: number;
  isCSROnly?: boolean;
  onProductCardClick: OnProductCardClick;
  title?: string;
  description?: string;
  thirdPartyOrganizationId: string;
  enableCurriculumBuilder?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
  isGwGProgram?: boolean;
  academicDisciplineName?: string;
  isAllCollectionsPage: boolean;
};

export type Props = PropsFromCaller;

function updateCollectionQuery(
  prevResult: CurriculumCollectionsQueryData['NaptimeQueries'],
  newResult: CurriculumCollectionsQueryData['NaptimeQueries'] | undefined
) {
  if (!newResult?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram) {
    return prevResult;
  }
  const elements = unionBy(
    prevResult.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.elements,
    newResult.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.elements,
    (element) => element?.id
  );
  return {
    NaptimeQueries: {
      ProgramCurriculumCollectionsV1Resource: {
        __typename: newResult.__typename,
        curriculumTracksByProgram: {
          elements,
          paging: newResult.ProgramCurriculumCollectionsV1Resource.curriculumTracksByProgram.paging,
          __typename: newResult.ProgramCurriculumCollectionsV1Resource.curriculumTracksByProgram.__typename,
        },
      },
    },
  };
}

// filter out collections that contains the word "free"
// currently only LmyL-2WZTmeRq-rWx2tDVw
const BannedCollectionIds = new Set(['LmyL-2WZTmeRq-rWx2tDVw']);

export const filterBlacklistCollections = (collections?: CollectionListItemStruct[]): CollectionListItemStruct[] => {
  return reject(collections, (collection) => BannedCollectionIds.has(collection.id));
};

export const loadMoreItems = ({
  setPaginationError,
  loading,
  fetchMore,
  data,
}: {
  setPaginationError: (v: boolean) => void;
  loading: boolean;
  data?: CurriculumCollectionsQueryData;
  fetchMore: DataValue<CurriculumCollectionsQueryData, CurriculumCollectionsQueryVariables>['fetchMore'];
}) => {
  const start = data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.paging?.next;

  if (!data || loading || !start) {
    return;
  }

  fetchMore({
    variables: {
      start,
      limit: COLLECTION_LOAD_LIMIT,
    },
    // @ts-expect-error react-apollo@2.5.4
    updateQuery: (
      { NaptimeQueries: prevResult },
      // @ts-expect-error react-apollo@2.5.4
      { fetchMoreResult: { NaptimeQueries: newResult } }
    ) => {
      return updateCollectionQuery(prevResult, newResult);
    },
  })
    .catch(fetchMoreErrorFilter)
    .catch(() => {
      setPaginationError(true);
    });
};

export function AllCuratedCollections({
  onProductCardClick,
  start = '0',
  limit = COLLECTION_LOAD_LIMIT,
  title = _t('All recommendations from your organization'),
  description,
  isGwGProgram,
  academicDisciplineName,
  programSlug,
  isAllCollectionsPage,
  allowOrgForSpecializationConfiguration,
  programId,
  enableCurriculumBuilder,
}: Props) {
  const theme = useTheme();

  const styles = {
    title: css`
      margin-top: var(--cds-spacing-600);
      margin-bottom: var(--cds-spacing-300);
    `,
    description: css`
      margin-top: ${4 /* XXS */ - 24 /* L */}px;
      margin-bottom: var(--cds-spacing-200);
    `,
    wrapper: css`
      & .learning-path-container {
        padding: 0;
      }
    `,
  };

  const [paginationError, setPaginationError] = React.useState(false);

  const { data, loading, error, fetchMore } = useQuery<
    CurriculumCollectionsQueryData,
    CurriculumCollectionsQueryVariables
  >(CurriculumCollectionsQuery, {
    variables: {
      programId,
      start,
      // No limit on collections for AD programs so the quickjump component has access to every collection
      limit: academicDisciplineName ? null : limit,
      enableCurriculumBuilder: Boolean(enableCurriculumBuilder),
      allowOrgForSpecializationConfiguration: Boolean(allowOrgForSpecializationConfiguration),
    },
    context: { clientName: 'gatewayGql' },
    ssr: false,
  });
  const allCourseIds =
    data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.elements?.reduce(
      (acc, collection) => {
        if (collection?.courseIds?.length) {
          acc.push(...(collection.courseIds as string[]));
        }

        return acc;
      },
      [] as string[]
    );
  const [curriculumCourseMap, curriculumCourseMapLoading] = useCourseEntitiesAsCurriculumCourses(allCourseIds);

  const collections = filterBlacklistCollections(
    data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.elements as
      | CollectionListItemStruct[]
      | undefined
  ).map((collection) => ({
    ...collection,
    courses: {
      elements: curriculumCourseMap
        ? (collection?.courseIds?.map((id) => {
            if (curriculumCourseMap[id]) {
              return curriculumCourseMap[id];
            } else {
              return null;
            }
          }) as Array<Product | null>)
        : [],
    },
  }));
  const paging = data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.curriculumTracksByProgram?.paging;
  const isAllLoaded = paging != null && paging.next == null;

  const handleProductCardClick =
    (collectionTrackingId: string | null) => (product: Product, productType: ProductType) => {
      onProductCardClick(product, productType, collectionTrackingId);
    };

  const descriptionToUse = isGwGProgram
    ? _t(
        'Find the right certificate for you. Flexible online-training designed to put you on the fast track to jobs in high-growth fields.'
      )
    : description;

  const getTitleToUse = () => {
    if (academicDisciplineName) {
      return _t('Academic subjects recommended in #{academicDisciplineName}', { academicDisciplineName });
    } else if (isGwGProgram) {
      return _t('Ready to jumpstart your career? Take the next step towards your future');
    } else {
      return title;
    }
  };

  const onBack = () => {
    redirectClientOrServer(`/programs/${programSlug}`);
  };

  const descriptionElement = description && (
    <Typography2 component="p" css={styles.description}>
      {descriptionToUse}
    </Typography2>
  );

  if (error) {
    return (
      <InlineNotification severity="error">
        {_t('Sorry! Something went wrong. Please refresh the page.')}
      </InlineNotification>
    );
  }

  if (loading || curriculumCourseMapLoading) {
    return (
      <PageGridContainer
        className="rc-AllCuratedCollections CollectionListPlaceholder"
        data-testid="collections-placeholder"
      >
        <Grid item xs={12}>
          <Heading defaultLevel={2} css={styles.title}>
            {title}
          </Heading>
          <Section initialLevel={3}>
            {descriptionElement}
            {times(collections?.length || limit, (key) => (
              <EnterpriseProductCardCollectionsPlaceholder key={key} />
            ))}
          </Section>
        </Grid>
      </PageGridContainer>
    );
  }

  if (collections?.length) {
    const collectionToUse = isGwGProgram ? reorderCertificates(collections) : collections;
    return (
      <PageGridContainer
        className="rc-AllCuratedCollections"
        data-testid="all-curated-collections"
        css={styles.wrapper}
      >
        <Grid item xs={12}>
          <Heading defaultLevel={2} css={styles.title}>
            <Grid container justifyContent="space-between">
              {getTitleToUse()}
              {isAllCollectionsPage && (
                <Button onClick={onBack} variant="secondary">
                  {_t('Back to program home')}
                </Button>
              )}
            </Grid>
          </Heading>
          {Boolean(academicDisciplineName) && (
            <Section initialLevel={3}>
              <AcademicSubjectQuickJump collections={collectionToUse.map((c) => ({ id: c.id, title: c.title }))} />
            </Section>
          )}
          <EnterpriseLearningPathsNotConfigurable />
          <Section initialLevel={3}>
            {descriptionElement}
            {collectionToUse.map((collection, index) => (
              <div key={collection.id}>
                <Collection
                  collectionOrder={index + 1}
                  id={collection.id}
                  collection={collection}
                  onProductCardClick={handleProductCardClick(collection.collectionTrackingId)}
                  productMetadata={collection.programProductMetadata?.elements}
                  disableDescription={isGwGProgram}
                  allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
                />
              </div>
            ))}
            {!isAllLoaded && !paginationError && (
              <Waypoint onEnter={() => loadMoreItems({ data, loading, setPaginationError, fetchMore })}>
                <EnterpriseProductCardCollectionsPlaceholder />
              </Waypoint>
            )}
            {paginationError && (
              <InlineNotification severity="error">
                {_t('Sorry! Something went wrong. Please refresh the page.')}
              </InlineNotification>
            )}
          </Section>
        </Grid>
      </PageGridContainer>
    );
  }

  return null;
}

export default AllCuratedCollections;
