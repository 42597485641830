import * as React from 'react';
import type { DataValue } from 'react-apollo';
import { graphql } from 'react-apollo';
import Waypoint from 'react-waypoint';

import _ from 'lodash';
import { compose, mapProps, pure, withState } from 'recompose';

import { InlineNotification } from '@coursera/cds-core';

import ProductsListItem from 'bundles/enterprise-legacy-learner-home/components/available/ProductsListItem';
import fetchMoreErrorFilter from 'bundles/enterprise-legacy-learner-home/utils/fetchMoreErrorFilter';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import { SavedProductsQuery } from 'bundles/program-home/components/enterprise-home/EnterpriseHomeQueries';
import type {
  SavedProductsQuery_ProgramCurriculumProductsV1Resource as ProgramCurriculumProductsV1Resource,
  SavedProductsQuery_ProgramCurriculumProductsV1Resource_selected_elements as SavedProduct,
  SavedProductsQuery as SavedProductsQueryType,
  SavedProductsQueryVariables,
} from 'bundles/program-home/components/enterprise-home/__generated__/SavedProductsQuery';
import ProgramCurriculumProduct from 'bundles/program-home/components/enterprise-home/models/ProgramCurriculumProduct';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

export const FETCH_LIMIT = 8;
const TOTAL_DISPLAY_LIMIT = 80;

type PropsFromCaller = {
  programId: string;
  userId: number;
  onProductCardClick: OnProductCardClick;
  enableCurriculumBuilder?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

type PropsFromGraphql = {
  products: SavedProduct[];
  loading: boolean;
  error: boolean;
  loadMoreItems: () => void;
  isAllLoaded: boolean;
};

type PropsFromMapping = {
  products: ProgramCurriculumProduct[];
};

type PropsFromState = {
  paginationError: boolean;
  setPaginationError: (val: boolean) => void;
};

type Props = PropsFromCaller & PropsFromGraphql & PropsFromMapping & PropsFromState;

export const SavedCoursesContainer = ({
  products,
  onProductCardClick,
  loadMoreItems,
  isAllLoaded,
  loading,
  paginationError,
  programId,
  enableCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  ...rest
}: Props) => {
  if (!products && !loading) {
    return null;
  } else if (products.length === 0 && !loading) {
    return null;
  }
  return (
    <div className="rc-SavedCoursesContainer">
      <ProductsListItem
        products={products}
        onProductCardClick={onProductCardClick}
        displayLoading={loading}
        programId={programId}
        enableCurriculumBuilder={enableCurriculumBuilder}
        allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
        {...rest}
      />
      {!isAllLoaded && <Waypoint onEnter={loadMoreItems} />}
      {paginationError && (
        <InlineNotification severity="error">
          {_t('Sorry! Something went wrong. Please refresh the page.')}
        </InlineNotification>
      )}
    </div>
  );
};

export const updateSavedProductsQuery = (
  prevResult: ProgramCurriculumProductsV1Resource,
  newResult: ProgramCurriculumProductsV1Resource | undefined
) => {
  if (!newResult?.selected) {
    return prevResult;
  }

  const elements = _.unionBy(prevResult.selected?.elements, newResult.selected?.elements, (element) => element?.id);
  return {
    ProgramCurriculumProductsV1Resource: {
      __typename: newResult.__typename,
      selected: {
        elements,
        paging: newResult.selected.paging,
        __typename: newResult.selected.__typename,
      },
    },
  };
};

export const loadMoreItems =
  (
    data:
      | Pick<
          DataValue<SavedProductsQueryType, SavedProductsQueryVariables>,
          'loading' | 'fetchMore' | 'ProgramCurriculumProductsV1Resource'
        >
      | undefined,
    props: PropsFromCaller & PropsFromState
  ) =>
  () => {
    if (!data) {
      return;
    }

    const { loading, fetchMore } = data;
    if (loading) {
      return;
    }

    const start = data?.ProgramCurriculumProductsV1Resource?.selected?.paging?.next;
    if (!start) {
      return;
    }

    const { setPaginationError } = props;

    fetchMore({
      variables: {
        start,
      },
      // @ts-expect-error react-apollo@2.5.4
      updateQuery: (
        { ProgramCurriculumProductsV1Resource: prevResult },
        // @ts-expect-error react-apollo@2.5.4
        { fetchMoreResult: { ProgramCurriculumProductsV1Resource: newResult } }
      ) => {
        return updateSavedProductsQuery(prevResult, newResult);
      },
    })
      .catch(fetchMoreErrorFilter)
      .catch(() => {
        setPaginationError(true);
      });
  };

export const enhance = compose<Props, PropsFromCaller>(
  pure,
  withState('paginationError', 'setPaginationError', false),
  graphql<PropsFromCaller & PropsFromState, SavedProductsQueryType, SavedProductsQueryVariables, PropsFromGraphql>(
    SavedProductsQuery,
    {
      options: ({ programId }) => ({
        ssr: false,
        variables: {
          programId,
          start: '0',
          limit: FETCH_LIMIT,
        },
      }),
      props: ({ data, ownProps }) => {
        const savedProducts = filterExistsOrDefault(data?.ProgramCurriculumProductsV1Resource?.selected?.elements);
        const paging = data?.ProgramCurriculumProductsV1Resource?.selected?.paging;
        const isAllLoaded =
          (paging != null && paging.next == null) ||
          (savedProducts && savedProducts.length > TOTAL_DISPLAY_LIMIT) ||
          false;
        return {
          products: savedProducts,
          loading: data?.loading ?? true,
          error: Boolean(data?.error),
          loadMoreItems: loadMoreItems(data, ownProps),
          isAllLoaded,
        };
      },
    }
  ),
  mapProps<PropsFromMapping, PropsFromGraphql>(({ products, ...rest }) => {
    const mappedProducts = products.map(
      (product) => new ProgramCurriculumProduct({ productState: product.productState })
    );

    return {
      products: mappedProducts,
      ...rest,
    };
  })
);

export default enhance(SavedCoursesContainer);
