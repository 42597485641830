import * as React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { keyBy } from 'lodash';

import { getCourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseUtils';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import { LEADERSHIP_SKILLS, NUM_ENTRIES_PER_SKILL } from 'bundles/program-personalized-tab/constants';
import CourseRecsContext from 'bundles/program-personalized-tab/contexts/CourseRecsContext';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import { CourseMetadataForCourseRecommendations } from 'bundles/program-personalized-tab/queries/CourseMetadataForCourseRecommendations';
import { EnterpriseCourseRecommendationsForSkillIdQuery } from 'bundles/program-personalized-tab/queries/EnterpriseCourseRecommendationsForSkillIdQuery';
import type {
  CourseMetadataForCourseRecommendations as CourseMetadataForCourseRecommendationsQueryData,
  CourseMetadataForCourseRecommendationsVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/CourseMetadataForCourseRecommendations';
import type {
  EnterpriseCourseRecommendationsForSkillId as EnterpriseCourseRecommendationsForSkillIdQueryData,
  EnterpriseCourseRecommendationsForSkillIdVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseCourseRecommendationsForSkillId';
import type { ProductWithMetadata } from 'bundles/program-personalized-tab/types/sharedTypes';
import { courseRecsDataToCollectionType } from 'bundles/program-personalized-tab/utils/productRecsUtils';

type PropsToComponent = {
  programId: string;
  children: React.ReactNode;
};

const useCourseRecsForSkillId = (skillIds: string[], programId: string, areLeadershipRecs?: boolean) => {
  const {
    loading: isCourseRecsLoading,
    error,
    data: courseRecsData,
  } = useQuery<EnterpriseCourseRecommendationsForSkillIdQueryData, EnterpriseCourseRecommendationsForSkillIdVariables>(
    EnterpriseCourseRecommendationsForSkillIdQuery,
    {
      variables: {
        programId,
        numEntriesPerCollection: NUM_ENTRIES_PER_SKILL,
        hasSkillId1: Boolean(skillIds[0]),
        skillId1: skillIds[0],
        hasSkillId2: Boolean(skillIds[1]),
        skillId2: skillIds[1],
        hasSkillId3: Boolean(skillIds[2]),
        skillId3: skillIds[2],
        hasSkillId4: Boolean(skillIds[3]),
        skillId4: skillIds[3],
        hasSkillId5: Boolean(skillIds[4]),
        skillId5: skillIds[4],
        hasSkillId6: Boolean(skillIds[5]),
        skillId6: skillIds[5],
      },
      skip: skillIds.length === 0,
      notifyOnNetworkStatusChange: true,
    }
  );

  const recs = courseRecsDataToCollectionType(courseRecsData, areLeadershipRecs);
  const courseIdsForQuery = filterExistsOrDefault(recs?.courseIds?.map((courseId) => `COURSE~${courseId}`));

  const { loading: isCourseMetadataLoading, data: courseMetadataData } = useQuery<
    CourseMetadataForCourseRecommendationsQueryData,
    CourseMetadataForCourseRecommendationsVariables
  >(CourseMetadataForCourseRecommendations, {
    skip: !courseRecsData || courseIdsForQuery.length < 1,
    variables: { courseIds: courseIdsForQuery },
    notifyOnNetworkStatusChange: true,
  });

  const courseMetadataObj = keyBy(courseMetadataData?.XdpV1Resource.multiGet?.elements, (course) => {
    const metadataId = course?.id;
    return metadataId?.substring(metadataId.indexOf('~') + 1);
  });

  const courseRecsWithMetadata: ProductWithMetadata[] = [];
  const guidedProjectRecsWithMetadata: ProductWithMetadata[] = [];
  recs?.courses?.elements.forEach((course) => {
    if (getCourseType(course?.courseTypeMetadata) === 'StandardCourse') {
      courseRecsWithMetadata.push({
        ...course,
        // TODO: Fix typing
        ...(course?.id ? (courseMetadataObj[course.id] as { [key: string]: any })?.xdpMetadata.cdpMetadata : {}),
      });
    }
    if (getCourseType(course?.courseTypeMetadata) === 'GuidedProject') {
      guidedProjectRecsWithMetadata.push({
        ...course,
        // TODO: Fix typing
        ...(course?.id ? (courseMetadataObj[course.id] as { [key: string]: any })?.xdpMetadata.cdpMetadata : {}),
      });
    }
  });

  if (recs?.courses?.elements && courseRecsWithMetadata.length > 0) {
    recs.courses.elements = courseRecsWithMetadata;
  }
  if (recs && guidedProjectRecsWithMetadata.length > 0) {
    Object.assign(recs, { guidedProjects: { elements: guidedProjectRecsWithMetadata } });
  }

  return { loading: isCourseRecsLoading || isCourseMetadataLoading, error, recs };
};

const CourseRecsDataProvider = ({ programId, children }: PropsToComponent) => {
  const onboardingData = useOnboardingData();
  const { loading, error, recs } = useCourseRecsForSkillId(
    onboardingData?.skills.map((skill) => skill.id) ?? [],
    programId
  );
  // This request should only execute if the learner has selected the leadership goal in onboarding
  const {
    loading: leadershipRecsLoading,
    error: leadershipRecsError,
    recs: leadershipRecs,
  } = useCourseRecsForSkillId(
    onboardingData?.selectedGoals?.includes('LEADER') ? LEADERSHIP_SKILLS.map((skill) => skill.id) : [],
    programId,
    true
  );

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <CourseRecsContext.Provider
      value={{ loading, error, recs, leadershipRecs, leadershipRecsLoading, leadershipRecsError }}
    >
      {children}
    </CourseRecsContext.Provider>
  );
};

export default CourseRecsDataProvider;
