import * as React from 'react';

import classNames from 'classnames';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { getLanguageDirection } from 'js/lib/language';

import { Typography2 } from '@coursera/cds-core';
import { Box, StarRating, color } from '@coursera/coursera-ui';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  isThemeDark?: boolean;
  ratingColor?: string;
};

export type Ratings = {
  averageFiveStarRating?: number;
  ratingCount?: number;
  commentCount?: number;
  rootClassName?: string;
};

export default function CourseRating({
  averageFiveStarRating: rating,
  ratingCount,
  commentCount,
  rootClassName,
  isThemeDark,
  ratingColor = color.warning,
}: Props & Ratings) {
  return (
    <Box alignItems="center" rootClassName={classNames('CourseRating', rootClassName)}>
      {rating && (
        <StarRating
          rating={rating}
          enableColorOpacity
          dir={getLanguageDirection()}
          ariaLabel={_t('#{rating} out of 5 stars', { rating: Number(rating.toFixed(1)) })}
        />
      )}
      {rating && (
        <Typography2 component="span" className="m-l-1s m-r-1 m-b-0" style={{ color: ratingColor }}>
          {rating.toFixed(1)}
        </Typography2>
      )}
      {ratingCount && (
        <Typography2 component="div" className={classNames('m-r-1s', { 'color-white': isThemeDark })}>
          <FormattedMessage
            message={_t('{ratingCount, plural, =1 {# rating} other {# ratings}}')}
            ratingCount={ratingCount}
          />
        </Typography2>
      )}
      {commentCount && (
        <Box alignItems="center" rootClassName="hidden-sm-down">
          <Typography2 component="div" className={classNames({ 'color-white': isThemeDark })} aria-hidden>
            &bull;
          </Typography2>
          <Typography2 component="div" className={classNames('m-l-1s m-r-1', { 'color-white': isThemeDark })}>
            <FormattedMessage
              message={_t('{commentCount, plural, =1 {# review} other {# reviews}}')}
              commentCount={commentCount}
            />
          </Typography2>
        </Box>
      )}
    </Box>
  );
}
