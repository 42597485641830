import * as React from 'react';

import { isRightToLeft, rtlStyle } from 'js/lib/language';

import { Box, StyleSheet, breakPoint, css } from '@coursera/coursera-ui';
import {
  SvgChevronRight,
  SvgClock,
  SvgCloudDownload,
  SvgCommentDots,
  SvgLaptop,
  SvgLevelAdvanced,
  SvgLevelBeginner,
  SvgLevelIntermediate,
  SvgVideo,
} from '@coursera/coursera-ui/svg';

import { TooltipWrapper } from 'bundles/common/components/Tooltip';
import SvgAdvanced from 'bundles/enterprise-legacy-xdp/components/miniPDP/assets/SvgAdvanced';
import SvgBeginner from 'bundles/enterprise-legacy-xdp/components/miniPDP/assets/SvgBeginner';
import SvgCloud from 'bundles/enterprise-legacy-xdp/components/miniPDP/assets/SvgCloud';
import SvgIntermediate from 'bundles/enterprise-legacy-xdp/components/miniPDP/assets/SvgIntermediate';
import SvgVideoSquare from 'bundles/enterprise-legacy-xdp/components/miniPDP/assets/SvgVideoSquare';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const ICON_SIZE = 24;

const ALL_ICONS = {
  SvgClock,
  SvgCloudDownload,
  SvgVideo,
  SvgLaptop,
  SvgCommentDots,
  SvgLevelBeginner,
  SvgLevelIntermediate,
  SvgLevelAdvanced,
} as const;

export type LogoIconType = keyof typeof ALL_ICONS;

const DEFAULT_ICON = SvgChevronRight;

type Props = {
  logo: LogoIconType;
  value: string;
  hoverText?: React.ReactNode;
};

const getContextualStyles = () =>
  StyleSheet.create(
    rtlStyle({
      container: {
        marginBottom: '12px',
        ':last-child': {
          marginBottom: '0',
        },
        // Aphrodite hack to target child svg
        ':nth-child(1n) > svg': {
          // minWidth prevents the SVG from shrinking in flexbox
          minWidth: `${ICON_SIZE}px`,
        },
        [`@media (max-width: ${breakPoint.md - 1}px)`]: {
          paddingLeft: '4px',
        },
      },
      value: {
        fontWeight: 'bold',
        marginLeft: '12px',
      },
      underline: {
        textDecoration: 'underline',
      },
      hoverTextContainer: {
        padding: '12px',
      },
      hoverText: {
        fontSize: '14px',
        textAlign: 'left',
      },
    })
  );

const getIcon = (logo: LogoIconType) => {
  let IconTag = ALL_ICONS[logo] || DEFAULT_ICON;
  // override these icons until we can update Coursera-UI library.
  switch (IconTag) {
    case SvgLevelBeginner:
      IconTag = SvgBeginner;
      break;
    case SvgLevelIntermediate:
      IconTag = SvgIntermediate;
      break;
    case SvgLevelAdvanced:
      IconTag = SvgAdvanced;
      break;
    case SvgVideo:
      IconTag = SvgVideoSquare;
      break;
    case SvgCloudDownload:
      IconTag = SvgCloud;
      break;
    default:
      break;
  }
  // for RTL, flip the caption icon horizontally
  const style = IconTag === SvgCommentDots && isRightToLeft(_t.getLocale()) ? { transform: 'scale(-1, 1)' } : {};
  return <IconTag size={ICON_SIZE} color="#1F1F1F" style={style} />;
};

const BannerInfoLogoText: React.FunctionComponent<Props> = ({ logo, value, hoverText }) => {
  const styles = getContextualStyles();
  const icon = getIcon(logo);
  const row = hoverText ? (
    <TooltipWrapper
      placement="top"
      message={
        <div {...css(styles.hoverTextContainer)}>
          <span {...css(styles.hoverText)}>{hoverText}</span>
        </div>
      }
    >
      <span {...css(styles.value, styles.underline)}>{value}</span>
    </TooltipWrapper>
  ) : (
    <span {...css(styles.value)}>{value}</span>
  );

  return (
    <Box alignItems="start" rootClassName={css(styles.container).className}>
      {icon}
      {row}
    </Box>
  );
};

export default BannerInfoLogoText;
