import * as React from 'react';

import classNames from 'classnames';
import $ from 'jquery';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import 'css!./__styles__/TogglableContent';

const COLLAPSE_PX_RANGE = 40;

const Content = ({ children, forwardedRef, id, tabIndex }: $TSFixMe) => (
  <div className="content-inner" ref={forwardedRef} id={id} tabIndex={tabIndex}>
    {children}
  </div>
);
interface TogglableContentProps {
  id?: string;
  tabIndex?: number;
  ariaHidden?: boolean;
}

class TogglableContent extends React.Component<TogglableContentProps> {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    collapsedHeight: PropTypes.number,
    addCollapseAnimation: PropTypes.bool,
    disableCollapseScroll: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    toggleButtonClassName: PropTypes.string,
    hideShowLessBtn: PropTypes.bool,
    // Function that takes ({collapsed}), and returns React component that renders the button content
    renderToggleButtonContentFunction: PropTypes.func.isRequired,
    toggleButtonType: PropTypes.oneOf(['button', 'link']),
    trackingName: PropTypes.string.isRequired,
    trackingData: PropTypes.object,
    childrenWrapperTag: PropTypes.string,
    isContentMarkdown: PropTypes.bool,
    useSmartCollapse: PropTypes.bool,
    label: PropTypes.string,
    ariaHidden: PropTypes.bool,
  };

  static contextTypes = {
    getStore: PropTypes.func.isRequired,
  };

  declare context: LegacyContextType<typeof TogglableContent.contextTypes>;

  static defaultProps = {
    collapsedHeight: 250,
    toggleButtonType: 'button',
    isCollapsed: true,
    childrenWrapperTag: 'p',
    isContentMarkdown: false,
    hideShowLessBtn: false,
  };

  constructor(props: $TSFixMe, context: $TSFixMe) {
    super(props, context);
    this.state = {
      collapsed: props.isCollapsed,
      alwaysExpand: false,
      hasMounted: false,
    };

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'origContentHeight' does not exist on typ... Remove this comment to see the full error message
    this.origContentHeight = 0;
  }

  componentDidMount() {
    const alwaysExpand = this.shouldComponentExpand();

    this.setState(() => ({
      hasMounted: true,
      alwaysExpand,
    }));

    window.addEventListener('resize', this.onResize);
  }

  componentWillReceiveProps(nextProps: $TSFixMe) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isCollapsed' does not exist on type 'Rea... Remove this comment to see the full error message
    const { isCollapsed } = this.props;
    if (isCollapsed !== nextProps.isCollapsed) {
      this.setState({
        collapsed: nextProps.isCollapsed,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const alwaysExpand = this.shouldComponentExpand();

    this.setState(() => ({
      alwaysExpand,
    }));
  };

  shouldComponentExpand = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'contentInner' does not exist on type 'To... Remove this comment to see the full error message
    const $contentInner = $(this.contentInner);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'collapsedHeight' does not exist on type ... Remove this comment to see the full error message
    const { collapsedHeight, useSmartCollapse } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'origContentHeight' does not exist on typ... Remove this comment to see the full error message
    this.origContentHeight = $contentInner.height();

    if (useSmartCollapse) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'origContentHeight' does not exist on typ... Remove this comment to see the full error message
      return this.origContentHeight <= collapsedHeight + COLLAPSE_PX_RANGE;
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'origContentHeight' does not exist on typ... Remove this comment to see the full error message
      return this.origContentHeight <= collapsedHeight;
    }
  };

  splitContent = (content: $TSFixMe) => {
    /* This splits the string into paragraphs using the return `\r` or/and newline `\n` characters */
    const splitContent = content.split(/\r\n|\r|\n/);
    const firstParagraphContent = splitContent[0];
    const restParagraphContent = content.replace(`${firstParagraphContent}`, '').trim();

    return { firstParagraphContent, restParagraphContent };
  };

  toggleCollapse(ev: $TSFixMe) {
    if (ev) {
      ev.preventDefault();
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'disableCollapseScroll' does not exist on... Remove this comment to see the full error message
    const { disableCollapseScroll, addCollapseAnimation, collapsedHeight, id, tabIndex } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'collapsed' does not exist on type 'Reado... Remove this comment to see the full error message
    const { collapsed } = this.state;

    // @ts-expect-error ts-migrate(2551) FIXME: Property 'content' does not exist on type 'Togglab... Remove this comment to see the full error message
    const $content = $(this.content);

    if (collapsed) {
      // shift focus so screen readers recite the expanded content
      if (id && tabIndex && tabIndex < 0 && typeof document !== undefined) {
        document.getElementById(id)?.setAttribute('tabindex', '0');
        document.getElementById(id)?.focus({ preventScroll: true });
      }
      $content.animate(
        {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'origContentHeight' does not exist on typ... Remove this comment to see the full error message
          height: `${collapsed ? this.origContentHeight : collapsedHeight}px`,
        },
        300,
        () => this.setState({ collapsed: !collapsed })
      );
    } else {
      // collapsed content should not be focusable / read by screen readers
      if (id && typeof document !== undefined) {
        document.getElementById(id)?.setAttribute('tabindex', '-1');
      }
      // intended action: To disable scrolling to top
      if (!disableCollapseScroll) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        window.scrollTo(0, $content.offset().top);
      }
      // intended action: To add collapsing animation
      if (addCollapseAnimation) {
        $content.animate(
          {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'origContentHeight' does not exist on typ... Remove this comment to see the full error message
            height: `${collapsed ? this.origContentHeight : collapsedHeight}px`,
          },
          300
        );
      }
      this.setState({ collapsed: !collapsed });
    }
  }

  renderDefaultContentChild = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isContentMarkdown' does not exist on typ... Remove this comment to see the full error message
    const { children, isContentMarkdown, childrenWrapperTag } = this.props;

    if (isContentMarkdown) {
      return children;
    }

    return React.createElement(childrenWrapperTag, null, children);
  };

  /**
   * This splits the content into two paragraphs so that only the first
   * paragraph is announced when the component is announced
   */
  renderSplitContent = (content: $TSFixMe) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'collapsed' does not exist on type 'Reado... Remove this comment to see the full error message
    const { collapsed, alwaysExpand } = this.state;
    const { firstParagraphContent, restParagraphContent } = this.splitContent(content);

    return (
      <Content
        forwardedRef={(contentInner: $TSFixMe) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'contentInner' does not exist on type 'To... Remove this comment to see the full error message
          this.contentInner = contentInner;
        }}
        id={this.props.id}
      >
        <p>{firstParagraphContent}</p>
        {restParagraphContent && <p aria-hidden={collapsed && !alwaysExpand}>{restParagraphContent}</p>}
      </Content>
    );
  };

  renderContent = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isContentMarkdown' does not exist on typ... Remove this comment to see the full error message
    const { children, isContentMarkdown } = this.props;

    if (typeof children === 'string' && !isContentMarkdown) {
      return this.renderSplitContent(children);
    }

    return (
      <Content
        forwardedRef={(contentInner: $TSFixMe) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'contentInner' does not exist on type 'To... Remove this comment to see the full error message
          this.contentInner = contentInner;
        }}
      >
        {this.renderDefaultContentChild()}
      </Content>
    );
  };

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'trackingName' does not exist on type 'Re... Remove this comment to see the full error message
      trackingName,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'trackingData' does not exist on type 'Re... Remove this comment to see the full error message
      trackingData,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'hideShowLessBtn' does not exist on type ... Remove this comment to see the full error message
      hideShowLessBtn,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'renderToggleButtonContentFunction' does ... Remove this comment to see the full error message
      renderToggleButtonContentFunction,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'toggleButtonClassName' does not exist on... Remove this comment to see the full error message
      toggleButtonClassName,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'toggleButtonType' does not exist on type... Remove this comment to see the full error message
      toggleButtonType,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'collapsedHeight' does not exist on type ... Remove this comment to see the full error message
      collapsedHeight,
      ariaHidden,
    } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'collapsed' does not exist on type 'Reado... Remove this comment to see the full error message
    const { collapsed, alwaysExpand, hasMounted } = this.state;
    const { getStore } = this.context;

    const applicationStore = getStore && getStore('ApplicationStore');

    const userAgent = applicationStore && applicationStore.getUserAgent();

    const isSafari = userAgent && userAgent.browser.name === 'Safari';

    const elClassName = classNames('rc-TogglableContent', className, {
      collapsed,
      'is-safari': isSafari,
    });

    const ToggleButtonComp = toggleButtonType === 'button' ? TrackedButton : TrackedA;

    const toggleButtonProps = Object.assign({}, toggleButtonType === 'link' ? { href: '#' } : {}, {
      className: classNames(
        `toggle-${toggleButtonType}`,
        toggleButtonType === 'link' && 'primary',
        toggleButtonType === 'button' && 'cdp-view-all-button',
        { passive: toggleButtonType === 'button' && 'cdp-view-all-button' }
      ),
      onClick: (ev: $TSFixMe) => this.toggleCollapse(ev),
      trackingName: collapsed ? `expand_${trackingName}` : `collapse_${trackingName}`,
    });

    const contentStyle = {
      height: alwaysExpand || !collapsed ? 'auto' : `${collapsedHeight}px`,
    };

    const renderedButtonContent = renderToggleButtonContentFunction({ collapsed });
    const hideToggleBtnWhenExpanded = hideShowLessBtn && !collapsed;
    const shouldRenderToggleBtn = !hideToggleBtnWhenExpanded && hasMounted && !alwaysExpand;

    return (
      <div className={elClassName}>
        <div
          className="content"
          ref={(content) => {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'content' does not exist on type 'Togglab... Remove this comment to see the full error message
            this.content = content;
          }}
          style={contentStyle}
          aria-hidden={ariaHidden ?? collapsed}
        >
          {this.renderContent()}
        </div>
        {shouldRenderToggleBtn && (
          <div className="toggle-button-wrapper">
            {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'ToggleButtonComp' does not have ... Remove this comment to see the full error message */}
            <ToggleButtonComp
              {...toggleButtonProps}
              aria-hidden={!renderedButtonContent}
              className={toggleButtonClassName}
              tabIndex={!renderedButtonContent ? '-1' : '0'}
              data={trackingData}
            >
              {renderedButtonContent}
            </ToggleButtonComp>
          </div>
        )}
      </div>
    );
  }
}

export default TogglableContent;
