import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { Col, Row, StyleSheet, css } from '@coursera/coursera-ui';

import { XDP_EXPERTISE_ICONS } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import type { InstructorType } from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import ExpertiseTooltip from 'bundles/enterprise-legacy-xdp/components/ExpertiseTooltip';
import Instructor from 'bundles/enterprise-legacy-xdp/components/instructors/Instructor';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

import 'css!bundles/enterprise-legacy-xdp/components/instructors/__styles__/InstructorList';

const styles = StyleSheet.create({
  Images: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
  },
});

export type Props = {
  id: string;
  instructors: Array<InstructorType>;
  noLink?: boolean;
  averageInstructorRating?: number;
  instructorRatingCount?: number;
};

export const getTitle = (instructors: Array<InstructorType>): React.ReactElement<typeof FormattedMessage> => (
  <FormattedMessage
    message={_t('{instructorsCount, plural, =1 {Instructor} other {Instructors}}')}
    instructorsCount={instructors.length}
  />
);

export const shouldShowAvgInstructorRating = ({
  averageInstructorRating,
  instructorRatingCount,
}: Pick<Props, 'averageInstructorRating' | 'instructorRatingCount'>): boolean => {
  return !!averageInstructorRating && !!instructorRatingCount;
};

const InstructorList: React.SFC<Props> = ({
  id,
  instructors,
  noLink,
  averageInstructorRating,
  instructorRatingCount,
}) => {
  const showAvgInstructorRating = shouldShowAvgInstructorRating({
    averageInstructorRating,
    instructorRatingCount,
  });

  return (
    <TrackedDiv
      trackingName="social_proof_instructors"
      data={{ id }}
      requireFullyVisible={true}
      withVisibilityTracking={true}
      className={classNames('Instructors rc-InstructorList', {
        'rc-InstructorList--expertise-expt': !!averageInstructorRating && !!instructorRatingCount,
      })}
    >
      {showAvgInstructorRating && (
        <Row>
          <Col>
            <TrackedDiv withVisibilityTracking trackClicks trackMouseEnters trackingName="instructor_rating_tooltip">
              <ExpertiseTooltip
                id="instructor-rating-tooltip"
                text={_t(
                  'We asked all learners to give feedback on our instructors based on the quality of their teaching style.'
                )}
              >
                <span className="avg-instructor-rating">
                  <span className="avg-instructor-rating__title">{_t('Instructor rating')}</span>
                  <div {...css(styles.Images)}>
                    <LazyImgix src={XDP_EXPERTISE_ICONS.instructor} alt="" maxWidth={14} maxHeight={14} />
                  </div>{' '}
                  <span className="avg-instructor-rating__total">
                    <FormattedMessage
                      message={_t('{avgInstructorRating, number}/5')}
                      avgInstructorRating={averageInstructorRating}
                    />
                  </span>
                  &nbsp;
                  <span className="avg-instructor-rating__ratings-count">
                    <FormattedMessage
                      message={_t('({numInstructorRatings, number} Ratings)')}
                      numInstructorRatings={instructorRatingCount}
                    />
                  </span>
                </span>
              </ExpertiseTooltip>
            </TrackedDiv>
          </Col>
        </Row>
      )}
      <Row>
        {instructors.map(
          ({
            id: instructorId,
            title,
            fullName,
            photo,
            shortName,
            department,
            learnersReached,
            coursesTaught,
            isTopInstructor,
          }) => (
            <Instructor
              key={instructorId}
              id={instructorId}
              title={title}
              fullName={fullName}
              photo={photo}
              shortName={shortName}
              department={department}
              learnersReached={learnersReached}
              coursesTaught={coursesTaught}
              isTopInstructor={isTopInstructor}
              noLink={noLink}
            />
          )
        )}
      </Row>
    </TrackedDiv>
  );
};

export default InstructorList;
