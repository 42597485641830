import * as React from 'react';

import { Col } from '@coursera/coursera-ui';

import { Heading, Section } from 'bundles/enterprise-legacy-learner-home/components/AutoHeading';
import type { GroupedEnrolledProductsBySkillType } from 'bundles/enterprise-legacy-learner-home/components/available/MergedEnrolledProductsList';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import SkillSetEnrolledProducts from 'bundles/enterprise-legacy-learner-home/components/available/SkillSetEnrolledProducts';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/SavedSkillSetList';

export type Props = PropsFromSaveSkillSetToggle & {
  savedSkillSets: UserSkillProfileStateType[];
  enrolledProductsBySkill: GroupedEnrolledProductsBySkillType;
  programId: string;
  programSlug: string;
  userId: number;
  thirdPartyOrganizationId: string;
};

const SavedSkillSetList: React.FC<Props> = ({
  savedSkillSets,
  enrolledProductsBySkill,
  userId,
  programId,
  programSlug,
  onSkillSetSaveToggle,
  thirdPartyOrganizationId,
}) => {
  return (
    <Col col={12} rootClassName="rc-SavedSkillSetList">
      <Heading defaultLevel={2} className="header-section-title">
        {_t('Courses from my SkillSets')}
      </Heading>
      <Section initialLevel={3}>
        {savedSkillSets.map((savedSkillSet) => {
          const tspId = savedSkillSet.targetSkillProfileId;
          const tspTitle = savedSkillSet.targetSkillProfile?.title;
          const objectiveSlug = savedSkillSet.targetSkillProfile?.slug;
          const tspEnrolledProducts = enrolledProductsBySkill[tspId] || [];

          return (
            <SkillSetEnrolledProducts
              key={tspId}
              targetSkillProfileId={tspId}
              tspTitle={tspTitle}
              objectiveSlug={objectiveSlug}
              products={tspEnrolledProducts}
              programId={programId}
              programSlug={programSlug}
              userId={userId}
              onSkillSetSaveToggle={onSkillSetSaveToggle}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
            />
          );
        })}
      </Section>
    </Col>
  );
};

export default SavedSkillSetList;
