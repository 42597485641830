/** @jsx jsx */

/** @jsxFrag */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { omit } from 'lodash';

import useRouter from 'js/lib/useRouter';

import type { DiscoveryCollection } from 'bundles/browse/components/types/DiscoveryCollections';
import SearchBarContent from 'bundles/search-common/components/searchbar/SearchBarContent';
import { AUTOCOMPLETE_SECTION_PREFIXES, autocompletePrefixes } from 'bundles/search-common/constants/autocomplete';
import type { AutocompleteMode } from 'bundles/search-common/constants/autocomplete';
import SearchProvider from 'bundles/search-common/providers/SearchProvider';
import { saveRecentlySearched } from 'bundles/search-common/utils/SearchUtils';
import {
  getRecentlySearchedAutocompleteItems,
  getRecentlyViewedAutocompleteItems,
  getSearchUpdatedUrl,
  inferAutocompleteMode,
  isExactPartnerNameMatch,
} from 'bundles/search-common/utils/autocomplete';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';
import useIsConsumerSerp from 'bundles/search-common/utils/useIsConsumerSerp';
import useTrackSearch from 'bundles/search-common/utils/useTrackSearch';
import { useAutocompleteSearchConfigs } from 'bundles/search-v2/hooks/useAutoCompleteSearchConfigs';

export type Props = {
  searchIsOpen?: boolean;
  hideMobileSearchPage?: () => void;
  collectionRecommendations?: DiscoveryCollection | null;
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    width: 100%;
  `,

  /* necessary to remove inherited styles on account-profile (potentially elsewhere) */
  searchForm: css`
    margin-bottom: 0;
    width: 100%;
  `,
};

const SearchBarContentWrapper = (props: Props) => {
  const router = useRouter();
  const currentQueryParams = router.location?.query;
  const isConsumerSearchPage = useIsConsumerSerp();
  const [searchText, setSearchText] = useState<string>(currentQueryParams?.query || '');
  const recentlySearchedItems = getRecentlySearchedAutocompleteItems();
  const recentlyViewedItems = getRecentlyViewedAutocompleteItems(props.collectionRecommendations);
  const [autocompleteMode, setAutocompleteMode] = useState<AutocompleteMode>(
    inferAutocompleteMode(searchText, recentlySearchedItems, recentlyViewedItems)
  );
  const trackSearch = useTrackSearch();

  const searchConfigs = useAutocompleteSearchConfigs();

  const updateUrl = (searchQuery: string) => {
    let updatedQuery = searchQuery;
    const newQueryParams: Record<string, string> = {};
    // partner matches should set a filter value instead of a query
    if (isExactPartnerNameMatch(searchQuery)) {
      // add partner filter
      newQueryParams.partners = searchQuery;
      // remove query text
      updatedQuery = '';
    }

    const cleanedQueryParams = omit(currentQueryParams, ['page', 'sortBy']);
    const searchUrl = getSearchUpdatedUrl(updatedQuery, { ...cleanedQueryParams, ...newQueryParams });
    if (isConsumerSearchPage) {
      const legacySearchUrl = getSearchUpdatedUrl(updatedQuery, newQueryParams);
      router.push(isInFilterTestVariant() ? searchUrl : legacySearchUrl);
    } else {
      // inter-app routing does not currently work so resorting to global builtin
      window.location.assign(searchUrl);
    }
  };

  // fired on selection AND native form submit
  const handleAutocompleteSubmit = (rawQuery: string) => {
    trackSearch(rawQuery, autocompleteMode, recentlyViewedItems);

    const sectionIdentifierPrefix = autocompletePrefixes.find((prefix) => rawQuery.startsWith(prefix));
    const queryValue = sectionIdentifierPrefix ? rawQuery.replace(sectionIdentifierPrefix, '') : rawQuery;

    // special handling for recently viewed items
    const recentlyViewedItemMatch = recentlyViewedItems.find((item) => item.name === queryValue);
    if (sectionIdentifierPrefix === AUTOCOMPLETE_SECTION_PREFIXES.RECENTLY_VIEWED && recentlyViewedItemMatch?.path) {
      window?.location.assign(recentlyViewedItemMatch.path);
      return;
    }

    // special handling for direct product match item(s)
    if (sectionIdentifierPrefix === AUTOCOMPLETE_SECTION_PREFIXES.DIRECT_MATCHES && queryValue) {
      window?.location.assign(queryValue);
      return;
    }

    // save to local storage
    saveRecentlySearched(queryValue);
    updateUrl(queryValue);
  };

  const handleNativeFormSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    handleAutocompleteSubmit(searchText);
  };

  const handleInputChange = (query: string) => {
    setSearchText(query);
    setAutocompleteMode(inferAutocompleteMode(query, recentlySearchedItems, recentlyViewedItems));
  };

  // ensure the search text is updated when the url changes (e.g., suggested search click on the SERP)
  React.useEffect(() => {
    setSearchText(currentQueryParams?.query ?? '');
  }, [currentQueryParams?.query]);

  // ensure the menu state is updated when the recently viewed data loads
  React.useEffect(() => {
    setAutocompleteMode(inferAutocompleteMode(searchText, recentlySearchedItems, recentlyViewedItems));
  }, [recentlySearchedItems, recentlyViewedItems, searchText]);

  return (
    <div className="rc-SearchBarContentWrapper" css={styles.wrapper}>
      <SearchProvider searchConfigs={searchConfigs} ssr={false}>
        <form className="search-form" role="search" onSubmit={handleNativeFormSubmit} css={styles.searchForm}>
          <SearchBarContent
            {...props}
            searchText={searchText}
            onSubmit={handleAutocompleteSubmit}
            autocompleteMode={autocompleteMode}
            onInputChange={handleInputChange}
            recentlySearchedItems={recentlySearchedItems}
            recentlyViewedItems={recentlyViewedItems}
          />
        </form>
      </SearchProvider>
    </div>
  );
};

export default SearchBarContentWrapper;
