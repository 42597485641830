import * as React from 'react';

import { pure } from 'recompose';

import type { ButtonProps } from '@coursera/coursera-ui';
import { ApiButton, Button, color } from '@coursera/coursera-ui';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';
import { SvgBookmark } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

type Props = {
  btnAttributes?: ButtonProps;
  onClick: () => void;
  apiStatus: ApiStatus;
  isSelected?: boolean;
  canSelect?: boolean;
  renderNothingIfConditionNotMet?: boolean;
  isProject?: boolean;
  initialBtnLabel?: string;
};

const CourseSelectButton = ({
  btnAttributes = {},
  onClick,
  apiStatus,
  isSelected,
  canSelect,
  renderNothingIfConditionNotMet,
  isProject,
  initialBtnLabel = _t('Save for Later'),
}: Props) => {
  const renderSelect = !isSelected && canSelect;
  const renderSelected = isSelected && !renderNothingIfConditionNotMet;
  const buttonStyle = isProject ? { color: color.white } : { color: color.black };
  const bookmarkStyleLight = isProject ? color.white : color.primary;

  if (renderSelect) {
    return (
      <ApiButton
        {...btnAttributes}
        htmlAttributes={{
          ...btnAttributes?.htmlAttributes,
          'aria-live': 'polite',
          'aria-busy': apiStatus === 'API_IN_PROGRESS',
        }}
        style={buttonStyle}
        iconAttributes={{
          suppressTitle: true,
        }}
        onClick={onClick}
        apiStatus={apiStatus}
        apiStatusAttributesConfig={{
          label: {
            API_BEFORE_SEND: initialBtnLabel,
            API_IN_PROGRESS: _t('Saving...'),
            API_SUCCESS: _t('Saved'),
            API_ERROR: _t('Failed'),
          },
        }}
      >
        <SvgBookmark
          color={bookmarkStyleLight}
          hoverColor={color.darkPrimary}
          htmlAttributes={{ 'aria-hidden': true }}
        />
      </ApiButton>
    );
  } else if (renderSelected) {
    return (
      <Button
        style={buttonStyle}
        {...btnAttributes}
        type="disabled"
        label={_t('Selected')}
        disabled={true}
        htmlAttributes={{ 'aria-label': _t('Selected') }}
      />
    );
  }
  return null;
};

export default pure(CourseSelectButton);
